import * as yup from "yup";
import { Decimal } from "decimal.js";
import { EntityUrlRedirect } from "./EntityUrl";

export interface FooterCMSLink {
  text: string;
  url: string;
}

export interface FooterCMSLinkGroup {
  text: string;
  iconPath: string | null;
  links: FooterCMSLink[];
}

export interface RemoteRate {
  currencyAmount: string;
  points: string;
}

export interface Rate {
  currencyAmount: Decimal;
  points: Decimal;
}

export interface URLMappingRule {
  requestPath: string;
  targetPath: string;
}

export interface AppSystemConfig {
  iOSVersion: string | null;
  iOSLink: string | null;
  iOSRecommendVersion: string | null;
  androidVersion: string | null;
  androidLink: string | null;
  androidRecommendVersion: string | null;
}

export interface LiveEvent {
  enable: boolean;
  link: string | null;
}

export interface AppConfig {
  // cmsBlockId is used to show cms block in shopping cart page
  cmsBlockId?: string | null;
  allCategoryCMSPageID: number | null;
  footerCMSLinks: FooterCMSLinkGroup[];
  vendorPageUrlKey: string;
  popularArticleCategory: number | null;
  useClubPointsRate: Rate;
  earnClubPointsRate?: Rate;
  permanentRedirectToExternal: URLMappingRule[];
  appSystemConfig: AppSystemConfig;
  redemptionTabCategoryId: string | null;
  linkToRedemptionOrder: string | null;
  isStockAlertAllowed: boolean;
  isPriceAlertAllowed: boolean;
}

export const AppConfigGraphQLAttributes = `
  redemptionTabCategoryId: category_id
  allCategoryCMSPageID: all_category_cms_page_id
  cmsBlockId: cms_block_id
  footerCMSLinks: footer_cms_links {
    text
    iconPath: icon
    links {
      text
      url
    }
  }
  vendorPageUrlKey: vendor_page_url_key
  popularArticleCategory: popular_article_category
  useClubPointsRate: use_clubpoints_rate {
    currencyAmount: currency_amount
    points
  }
  earnClubPointsRate: earn_clubpoints_rate {
    currencyAmount: currency_amount
    points
  }
  permanentRedirectToExternal: permanent_redirect_to_external {
    requestPath: request_path
    targetPath: target_path
  }
  appSystemConfig: app_system_config {
    iOSVersion: ios_version
    iOSLink: ios_link
    iOSRecommendVersion: ios_recommend_version
    androidVersion: android_version
    androidLink: android_link
    androidRecommendVersion: android_recommend_version
  }
  linkToRedemptionOrder: link_to_redemption_order
  isStockAlertAllowed: is_stock_alert_allowed
  isPriceAlertAllowed: is_price_alert_allowed
`;

export const LiveEventGraphQLAttributes = `
  liveEvent: live_event {
    enable
    link
  }
`;

const FooterCMSLinkSchema = yup.object<FooterCMSLink>({
  text: yup.string().required(),
  url: yup.string().required(),
});

const FooterCMSLinkGroupSchema = yup.object<FooterCMSLinkGroup>({
  text: yup.string().required(),
  iconPath: yup.string().nullable(),
  links: yup.array().of(FooterCMSLinkSchema),
});

const URLMappingRuleSchema = yup.object<URLMappingRule>({
  requestPath: yup.string().required(),
  targetPath: yup.string().required(),
});

export const AppConfigSchema = yup.object<AppConfig>({
  redemptionTabCategoryId: yup.string().nullable(),
  linkToRedemptionOrder: yup.string().nullable(),
  cmsBlockId: yup.string().nullable(),
  allCategoryCMSPageID: yup.number().nullable(),
  footerCMSLinks: yup.array().of(FooterCMSLinkGroupSchema),
  vendorPageUrlKey: yup.string().required(),
  popularArticleCategory: yup.number().nullable(),
  useClubPointsRate: yup
    .object<Rate>({
      currencyAmount: yup
        .mixed()
        .transform((value: string) => new Decimal(value))
        .required(),
      points: yup
        .mixed()
        .transform((value: string) => new Decimal(value))
        .required(),
    })
    .required(),
  earnClubPointsRate: yup
    .object<Rate>({
      currencyAmount: yup
        .mixed()
        .transform((value: string) => new Decimal(value))
        .required(),
      points: yup
        .mixed()
        .transform((value: string) => new Decimal(value))
        .required(),
    })
    .required(),
  permanentRedirectToExternal: yup.array().of(URLMappingRuleSchema),
  appSystemConfig: yup.object<AppSystemConfig>({
    iOSVersion: yup.string().nullable(true),
    iOSRecommendVersion: yup.string().nullable(true),
    iOSLink: yup.string().nullable(true),
    androidVersion: yup.string().nullable(true),
    androidRecommendVersion: yup.string().nullable(true),
    androidLink: yup.string().nullable(true),
  }),
  isStockAlertAllowed: yup.boolean().required(),
  isPriceAlertAllowed: yup.boolean().required(),
});

export const LiveEventSchema = yup.object<LiveEvent>({
  enable: yup.boolean(),
  link: yup.string().nullable(true),
});

export const UrlRedirectSchema = yup.array().of(
  yup.object<EntityUrlRedirect>({
    id: yup.number().required(),
    type: yup.string().test(
      "isEntityUrlType",
      "${path} is not EntityUrlType", // eslint-disable-line no-template-curly-in-string
      v => ["PRODUCT", "CATEGORY", "CMS_PAGE", "SHOP"].indexOf(v) !== -1
    ) as any,
    canonicalUrlPath: yup.string().required(),
    targetPath: yup.string().required(),
  })
);

export function getClubPointConversionRate(
  appConfig: AppConfig | null
): number {
  if (!appConfig) {
    return 1;
  }
  return appConfig.useClubPointsRate.points
    .dividedBy(appConfig.useClubPointsRate.currencyAmount)
    .toNumber();
}
// Example
//
// "appConfig": {
//   "use_clubpoints_rate": {
//     "points": "5",
//     "currency_amount": "1.0000"
//   },
//   "earn_clubpoints_rate": {
//     "points": "1",
//     "currency_amount": "20.0000"
//   }
// }
//
// Rebate rate = (use_clubpoints_rate.currency_amount / use_clubpoints_rate.points) *
//               (earn_clubpoints_rate.points / earn_clubpoints_rate.currency_amount)
export function getRebateRate(appConfig: AppConfig | null): Decimal | null {
  if (!appConfig) {
    return null;
  }
  const { useClubPointsRate, earnClubPointsRate } = appConfig;
  if (!earnClubPointsRate) {
    return null;
  }
  return useClubPointsRate.currencyAmount
    .dividedBy(useClubPointsRate.points)
    .times(
      earnClubPointsRate.points.dividedBy(earnClubPointsRate.currencyAmount)
    );
}
