import { useContext, useRef, useEffect } from "react";
import { LocalizedAlertContext } from "../components/LocalizedAlertProvider";
import { RepositoryContext } from "../repository/State";

export default function usePresentSessionExpiredAlert(
  onLoginAgain: () => void,
  onCancel: () => void
) {
  const {
    state: { isLogoutBySessionExpired },
  } = useContext(RepositoryContext);
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);

  const isLogoutBySessionExpiredRef = useRef(isLogoutBySessionExpired);

  useEffect(() => {
    if (
      isLogoutBySessionExpired &&
      isLogoutBySessionExpiredRef.current !== isLogoutBySessionExpired
    ) {
      // presentLocalizedAlert({
      //   headerId: "alert.session_expired",
      //   buttons: [
      //     {
      //       textMessageID: "alert.session_expired.button.login_again",
      //       handler: () => {
      //         onLoginAgain();
      //       },
      //     },
      //     {
      //       textMessageID: "alert.session_expired.button.cancel",
      //       handler: () => {
      //         onCancel();
      //       },
      //     },
      //   ],
      // });
    }
    isLogoutBySessionExpiredRef.current = isLogoutBySessionExpired;
  }, [isLogoutBySessionExpired, onLoginAgain, onCancel, presentLocalizedAlert]);
}
