import {
  InAppBrowser,
  InAppBrowserOptions,
  InAppBrowserEvent,
} from "@ionic-native/in-app-browser";
import { v4 } from "uuid";
import { Plugins } from "@capacitor/core";
import { Locale } from "../i18n/locale";

import Config from "../Config";

const { Device } = Plugins;

const options: InAppBrowserOptions = {
  zoom: "no",
  location: "no",
  clearsessioncache: "yes",
  clearcache: "yes",
};

function getKcLocale(locale: Locale) {
  switch (locale) {
    case Locale.en:
      return "en-US";
    case Locale.zhHant:
      return "zh-HK";
    default:
      return "";
  }
}

function createLoginUrl(locale: Locale) {
  const state = v4();
  const nonce = v4();
  const kcLocale = getKcLocale(locale);

  return (
    Config.THE_CLUB_UAT_CONFIG.AUTH_SERVER_URL +
    "realms/" +
    encodeURIComponent(Config.THE_CLUB_UAT_CONFIG.REALM) +
    "/protocol/openid-connect/auth" +
    "?client_id=" +
    encodeURIComponent(Config.THE_CLUB_UAT_CONFIG.CLIENT_ID) +
    "&state=" +
    encodeURIComponent(state) +
    "&nonce=" +
    encodeURIComponent(nonce) +
    "&redirect_uri=" +
    encodeURIComponent(Config.THE_CLUB_UAT_CONFIG.REDIRECT_URI) +
    "&response_type=token" +
    "&login=true" +
    "&scope=openid" +
    "&back_uri=" +
    encodeURIComponent(Config.THE_CLUB_UAT_CONFIG.BACK_URI) +
    "&kc_locale=" +
    kcLocale
  );
}

function parseUrlParamsToObject(url: any) {
  const hashes = url.slice(url.indexOf("?") + 1).split("&");
  return hashes.reduce((params: any, hash: any) => {
    const [key, val] = hash.split("=");
    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
}

export function detectedCloseURL(url: any): boolean {
  let closeURL = new URL(Config.THE_CLUB_UAT_CONFIG.BACK_URI);
  let closePath = closeURL.pathname;
  let callbackPath = (new URL(url)).pathname;
  if (closePath === callbackPath) return true;
  else return false; 
}

interface LoginTheClubResponse {
  token?: string;
  cancelled: boolean;
}

function loginWithTheClubWebPopup(
  loginUrl: string
): Promise<LoginTheClubResponse> {
  return new Promise(resolve => {
    let interval: number | undefined;
    let done = false;
    const clearInterval = () => {
      if (interval != null) {
        window.clearInterval(interval);
        interval = undefined;
      }
    };
    const height = 820;
    const width = 510;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const externalWindow = window.open(
      loginUrl,
      "The Club",
      `width=${width},height=${height},left=${left},top=${top}`
    );

    if (!externalWindow || !externalWindow.window) {
      throw Error("cannot-open-window");
    }

    externalWindow.opener = null;
    const checkCode = () => {
      try {
        console.log(externalWindow.location.href);
        // Clear interval if externalWindow is closed
        if (
          !externalWindow ||
          !externalWindow.window ||
          externalWindow.closed ||
          externalWindow.window.closed ||
          detectedCloseURL(externalWindow.location.href)
        ) {
          clearInterval();
          if (!done) {
            externalWindow.window.close();
            resolve({ cancelled: true });
          }
          return;
        }
        const params = parseUrlParamsToObject(externalWindow.location.href);
        const token = params["access_token"] || undefined;
        if (!token) {
          return;
        }
        done = true;
        externalWindow.close();
        clearInterval();
        resolve({
          token,
          cancelled: false,
        });
      } catch (e) {}
    };
    interval = window.setInterval(checkCode, 500);
  });
}

export function presentLoginWithTheClubBrowser(
  locale: Locale
): Promise<LoginTheClubResponse> {
  return new Promise(resolve => {
    const loginUrl = createLoginUrl(locale);
    Device.getInfo().then(deviceInfo => {
      if (deviceInfo.platform !== "web") {
        const browser = InAppBrowser.create(loginUrl, "_blank", options);
        const listener = browser
          .on("loadstart")
          .subscribe((event: InAppBrowserEvent) => {
            const eventCallback = encodeURI(event.url);
            //Check the redirect uri
            if (eventCallback.includes(Config.THE_CLUB_UAT_CONFIG.BACK_URI)) {
              listener.unsubscribe();
              browser.close();
              resolve({ cancelled: true });
            }
            if (
              eventCallback.includes(Config.THE_CLUB_UAT_CONFIG.REDIRECT_URI)
            ) {
              listener.unsubscribe();
              browser.close();
              const parameters = parseUrlParamsToObject(event.url);
              resolve({
                cancelled: false,
                token: parameters.access_token,
              });
            }
          });
        const closeListener = browser.on("exit").subscribe(() => {
          closeListener.unsubscribe();
          resolve({ cancelled: true });
        });
      } else {
        resolve(loginWithTheClubWebPopup(loginUrl));
      }
    });
  });
}
