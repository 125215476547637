import React, {
  useEffect,
  useRef,
  useCallback,
  useContext,
  useState,
} from "react";
import { Plugins, PluginListenerHandle } from "@capacitor/core";
import { withRouter, RouteComponentProps } from "react-router";
import * as Sentry from "sentry-cordova";

import {
  useActivateCustomer,
  useVerifyChangeEmail,
} from "../repository/AuthRepository";
import { useResolveUrl } from "../repository/DeepLinkRepository";
import { useFetchProductSKUByUrlKey } from "../repository/ProductRepository";
import {
  getPathForTab,
  RootTab,
  getPathForSearch,
  getPathForProductDetailPage,
  genPathForEditProfile,
  getPathForMyOrders,
  getPathForMyDelivery,
  getPathForSingleCategoryPage,
  getPathForSelectInterestCategoryPage,
  getPathForCMSLanding,
  activeTabAsRootTab,
  getPathForSingleMerchant,
  getPathForHomePage,
} from "../navigation/routes";
import {
  DeepLinkRouteResult,
  CLDeepLinkRouteKey,
  makeCSRDeepLinkRouter,
  CSRDeepLinkRouteKey,
} from "../utils/DeepLinkRouter";
import { withProviders } from "./Provider";
import { LocalizedAlertContext } from "./LocalizedAlertProvider";
import {
  LoginSignupModalProvider,
  LoginSignupModalContext,
} from "./LoginSignupModalProvider";
import LoadingModalProvider, {
  LoadingModalContext,
} from "./LoadingModalProvider";
import { TokenStore } from "../api/TokenStore";
import { EntityUrl } from "../models/EntityUrl";
import { isCustomerInterestSet } from "../models/Customer";
import { AppConfig } from "../models/AppConfig";
import { useAppConfig } from "../repository/ConfigRepository";
import { appEventEmitter } from "../utils/SimpleEventEmitter";
import { isUrl } from "../utils/Url";
import { timeout } from "../utils/promise";
import { isiOS, isAndroid } from "../utils/Platform";
import { OurNavContext } from "../our-navigation";
import { getUrlKey } from "../models/DeepLink";
import { YourEmailUpdatedModal } from "./ChangeEmailModals";
import useOpenUrlWithBrowser from "../hook/useOpenUrlWithBrowser";
import Config from "../Config";

const { App, FirebaseDynamicLinksPlugin } = Plugins;
function tryCompleteUrl(url: string): string {
  const regex = new RegExp("(.+)://(.+)");
  if (regex.exec(url)) {
    // Normal case. Do nothing.
    return url;
  }
  if (url.startsWith("//")) {
    // Possibly protocol inheritance
    return `https:${url}`;
  }
  if (url.startsWith("/town")) {
    // Possibly path only
    return `${Config.SITE_URL}${url}`;
  }
  // Begin with non http and slash
  return `${Config.SITE_URL}/${url}`;
}

interface OwnProps {
  appConfig: AppConfig;
}

type Props = OwnProps & RouteComponentProps;

const DeepLinkHandler: React.FC<Props> = props => {
  const { appConfig, history } = props;
  const { navigate, goBack, replace, getActiveTab } = useContext(OurNavContext);
  const deepLinkRouter = useRef(makeCSRDeepLinkRouter(appConfig));
  useEffect(() => {
    deepLinkRouter.current = makeCSRDeepLinkRouter(appConfig);
  }, [appConfig]);
  const resolveUrl = useResolveUrl();
  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const { show: showLoadingView, hide: hideLoadingView } = useContext(
    LoadingModalContext
  );
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const activateCustomer = useActivateCustomer();

  const findCurrentTab = useCallback(() => {
    const activeTab = getActiveTab();
    if (activeTab == null) {
      return null;
    }
    return activeTabAsRootTab(activeTab);
  }, [getActiveTab]);

  const [
    isYourEmailUpdatedModalOpen,
    setIsYourEmailUpdatedModalOpen,
  ] = useState(false);
  const closeVerificationEmailSentModal = useCallback(() => {
    setIsYourEmailUpdatedModalOpen(false);
  }, []);
  const onClickGotoShopButton = useCallback(() => {
    setIsYourEmailUpdatedModalOpen(false);
    // FIXME: A super hacky way to back to top and then go to home page
    // I cannot find another way to do so... so sad
    setTimeout(() => {
      console.log(findCurrentTab());
      goBack(findCurrentTab() || RootTab.home);
      setTimeout(() => {
        history.replace(getPathForHomePage());
      }, 500);
    }, 250);
  }, [findCurrentTab, goBack, history]);

  const openUrlWithBrowser = useOpenUrlWithBrowser();
  const fetchProductSKUByUrlKey = useFetchProductSKUByUrlKey();

  const handleEntityUrl = useCallback(
    async (entityUrl: EntityUrl, originalUrl: string) => {
      const url = new URL(originalUrl);
      const { id } = entityUrl;
      switch (entityUrl.type) {
        case "PRODUCT": {
          const urlKey = getUrlKey(originalUrl);
          try {
            showLoadingView();
            const productSKU = await fetchProductSKUByUrlKey(urlKey);
            if (productSKU) {
              navigate(
                getPathForProductDetailPage(
                  findCurrentTab() || RootTab.home,
                  productSKU
                ) + url.search
              );
            }
          } finally {
            hideLoadingView();
          }
          break;
        }
        case "CATEGORY": {
          navigate(
            getPathForSingleCategoryPage(RootTab.category, id) + url.search
          );
          break;
        }
        case "CMS_PAGE": {
          navigate(
            getPathForCMSLanding(findCurrentTab() || RootTab.home, String(id)) +
              url.search
          );
          break;
        }
        default: {
          openUrlWithBrowser(originalUrl);
        }
      }
    },
    [
      navigate,
      showLoadingView,
      hideLoadingView,
      openUrlWithBrowser,
      findCurrentTab,
      fetchProductSKUByUrlKey,
    ]
  );

  const verifyChangeEmail = useVerifyChangeEmail();
  /* eslint-disable complexity */
  const handleDeepLinkRoute = useCallback(
    async (
      deepLinkRoute: DeepLinkRouteResult<CSRDeepLinkRouteKey>,
      originalUrl: string
    ) => {
      const url = new URL(originalUrl);
      console.log(`url`, url);
      console.log(`deepLinkRoute.queryParam`, deepLinkRoute.queryParam);
      switch (deepLinkRoute.routeKey) {
        case "home": {
          console.log("it is town")
          replace("/town");
          
          //replace(getPathForTab(RootTab.home) + url.search);
          break;
        }    
        case "my-account": {
          replace(getPathForTab(RootTab.account) + url.search);
          break;
        }
        
        case "my-order": {
          const gotoMyOrdersPage = () => {
            navigate(getPathForMyOrders(RootTab.account) + url.search);
          };
          if (TokenStore.accessToken) {
            gotoMyOrdersPage();
          } else {
            presentLoginModal(gotoMyOrdersPage);
          }
          break;
        }
        
        case "email-verification": {
          const {
            id: customerId,
            key: confirmationKey,
          } = deepLinkRoute.queryParam;
          if (customerId == null || confirmationKey == null) {
            return;
          }
          try {
            showLoadingView();
            const customer = await activateCustomer(
              customerId,
              confirmationKey
            );
            if (!customer) {
              throw new Error("cannot-get-customer");
            }
            if (!isCustomerInterestSet(customer)) {
              replace(
                getPathForSelectInterestCategoryPage(RootTab.home) + url.search
              );
            }
          } catch (e) {
            presentLocalizedAlert({
              headerId: "alert.error.title",
              messageId: "alert.deeplink.email_verification.fail",
              buttons: [
                {
                  textMessageID: "alert.button.ok",
                },
              ],
            });
          } finally {
            hideLoadingView();
          }
          break;
        }
        default: {
          openUrlWithBrowser(originalUrl);
        }
      }
    },
    [
      navigate,
      replace,
      presentLoginModal,
      showLoadingView,
      hideLoadingView,
      presentLocalizedAlert,
      activateCustomer,
      openUrlWithBrowser,
      findCurrentTab,
      fetchProductSKUByUrlKey,
      verifyChangeEmail,
    ]
  );
  /* eslint-enable complexity */

  const onAppUrlOpen = useCallback(
    async (url: string) => {
      url = tryCompleteUrl(url);
      if (!isUrl(url)) {
        presentLocalizedAlert({
          headerId: "alert.error.title",
          messageId: "alert.deeplink.invalid_url",
          messageArgs: {
            URL: url,
          },
          buttons: [
            {
              textMessageID: "alert.button.ok",
            },
          ],
        });
        return;
      }

      const appSiteUrlRegex = new RegExp(
        Config.DEEPLINK_URLS && Config.DEEPLINK_URLS.length > 0
          ? Config.DEEPLINK_URLS.map(u => `https://${u}(/.*)`)
              .concat(`${Config.SITE_URL}(/.*)`)
              .join("|")
          : `${Config.SITE_URL}(/.*)`
      );
      const appSiteUrlMatched = appSiteUrlRegex.exec(url);
      if (!appSiteUrlMatched) {
        openUrlWithBrowser(url);
        return;
      }
      const deepLinkRoute = deepLinkRouter.current.match(url);
      if (deepLinkRoute != null) {
        handleDeepLinkRoute(deepLinkRoute, url);
        return;
      }

      try {
        showLoadingView();
        const entityUrl = await resolveUrl(url);
        if (entityUrl != null) {
          handleEntityUrl(entityUrl, url);
          return;
        }
      } finally {
        hideLoadingView();
      }

      openUrlWithBrowser(url);
    },
    [
      resolveUrl,
      handleDeepLinkRoute,
      handleEntityUrl,
      openUrlWithBrowser,
      showLoadingView,
      hideLoadingView,
      presentLocalizedAlert,
    ]
  );


  useEffect(() => {
    const onClickViewMoreSub = appEventEmitter.subscribe(e => {
      if (e.type === "OnClickViewMore") {
        onAppUrlOpen(e.url);
      }
      if (e.type === "OnQRScanSuccess") {
        onAppUrlOpen(e.url);
      }
      if (e.type === "OnClickPromotionBanner") {
        onAppUrlOpen(e.url);
      }
      if (e.type === "PushNotificationGoTo") {
        onAppUrlOpen(e.url);
      }
    });
    return () => {
      onClickViewMoreSub.remove();
    };
  }, [onAppUrlOpen]);

  // Use url awaited to debounce url received from multiple plugins
  const [urlAwaited, setUrlAwaited] = useState<
    | { type: "appUrlOpen"; url: string }
    | { type: "dynamicLinkOpened"; url: string }
    | null
  >(null);
  // Do handle app url opened by waiting stable url awaited
  // after a timeout

  useEffect(() => {
    if (urlAwaited) {
      const timeout = window.setTimeout(() => {
        onAppUrlOpen(urlAwaited.url);
        setUrlAwaited(null);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
    return undefined;
  }, [urlAwaited, onAppUrlOpen]);

  useEffect(() => {
    const appUrlOpenSub = App.addListener("appUrlOpen", (e: any) => {
      if (e && e.url) {
        setUrlAwaited({ type: "appUrlOpen", url: e.url });
      }
    });
    return () => {
      appUrlOpenSub.remove();
    };
  }, []);

  useEffect(() => {
    let dynamicLinksOpenSub: PluginListenerHandle | null = null;
    if (isiOS() || isAndroid()) {
      dynamicLinksOpenSub = FirebaseDynamicLinksPlugin.addListener(
        "dynamicLinkOpened",
        (e: any) => {
          if (e && e.url) {
            setUrlAwaited(d => {
              if (!d) {
                return d;
              }
              // Rewrite url to dynamic link resolved
              // if the stored url is coming from appUrlOpen
              if (d.type === "appUrlOpen") {
                return {
                  type: "dynamicLinkOpened",
                  url: e.url,
                };
              }
              return d;
            });
          }
        }
      );
    }
    return () => {
      if (dynamicLinksOpenSub) {
        dynamicLinksOpenSub.remove();
      }
    };
  }, []);

  return (
    <>
      <YourEmailUpdatedModal
        isOpen={isYourEmailUpdatedModalOpen}
        onRequestDismiss={closeVerificationEmailSentModal}
        onClickGotoShopButton={onClickGotoShopButton}
      />
    </>
  );
};

function withAppConfig<P>(
  Component: React.ComponentType<P & { appConfig: AppConfig }>
): React.ComponentType<P> {
  const WithAppConfigComponent: React.FC<P> = props => {
    const appConfig = useAppConfig();
    if (appConfig == null) {
      return null;
    }
    return <Component {...props} appConfig={appConfig} />;
  };
  return WithAppConfigComponent;
}

export default React.memo(
  withProviders(
    withAppConfig<{}>(withRouter(DeepLinkHandler)),
    LoginSignupModalProvider,
    LoadingModalProvider
  )
);
