import React, { useRef, useCallback, useMemo, useState } from "react";
import { IonApp } from "@ionic/react";
import { Plugins } from "@capacitor/core";
import cn from "classnames";
import * as Sentry from "sentry-cordova";

import styles from "./styles.module.scss";
import { isHybrid } from "../../utils/Platform";

interface SplashScreenControllerContextValue {
  startAnimation(): void;
  hideAnimation(): void;
  isAnimationCompleted: boolean;
  hasAnimation: boolean;
}

export const SplashScreenControllerContext = React.createContext<
  SplashScreenControllerContextValue
>(null as any);

const AnimatedSplashScreenIonApp: React.FC = props => {
  const [isAnimationCompleted, setIsAnimationCompleted] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const hasAnimation = useMemo(() => isHybrid(), []);

  const fallbackTimer = useRef<any>(null);
  const splashScreenAnimationStage = useRef("");

  const onAnimationCompleted = useCallback(() => {
    if (fallbackTimer.current) {
      clearTimeout(fallbackTimer.current);
      fallbackTimer.current = null;
    }

    setIsAnimationCompleted(true);
  }, []);

  const startSplashScreenAnimation = useCallback(async () => {
    splashScreenAnimationStage.current = "before hiding splash screen";

    fallbackTimer.current = setTimeout(() => {
      fallbackTimer.current = null;
      Sentry.captureEvent({
        message: "Stuck at SplashScreen Animation",
        extra: {
          splashScreenAnimationStage: splashScreenAnimationStage.current,
        },
      });
      onAnimationCompleted();
    }, 10000);

    await Plugins.SplashScreen.hide();
    splashScreenAnimationStage.current = "after hiding splash screen";
    // FIXME:
    // Delay some time for animation data to be loaded
    // I tried add listener to event "data_ready"
    // but it seems not working

    setTimeout(() => {
      onAnimationCompleted();
    }, 2000);
  }, [onAnimationCompleted]);

  const hideSplashScreenAnimation = useCallback(() => {
    setIsHidden(true);
  }, []);

  const splashScreenControllerContextValue = useMemo(
    () => ({
      startAnimation: startSplashScreenAnimation,
      hideAnimation: hideSplashScreenAnimation,
      isAnimationCompleted,
      hasAnimation,
    }),
    [
      startSplashScreenAnimation,
      hideSplashScreenAnimation,
      isAnimationCompleted,
      hasAnimation,
    ]
  );

  return (
    <SplashScreenControllerContext.Provider
      value={splashScreenControllerContextValue}
    >
      <IonApp>{props.children}</IonApp>
    </SplashScreenControllerContext.Provider>
  );
};

export default AnimatedSplashScreenIonApp;
