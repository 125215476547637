import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { IonTabButton, IonLabel, IonTabBar } from "@ionic/react";
import cn from "classnames";

import {
  CategoryListPopoverProps,
  withCategoryListPopover,
} from "../CategoryListPopover";
import {
  LoginSignupModalProvider,
  LoginSignupModalContext,
} from "../LoginSignupModalProvider";

import { withProviders } from "../Provider";

import { MessageID } from "../../i18n/translations/type";
import { LocalizedText } from "../../i18n/Localization";

import {
  getPathForAllCategoriesPage,
  getPathForCategoryTab,
  getPathForArticleTab,
  getPathForHomePage,
  getPathForLikesTab,
  genPathForAccountTab,
  activeTabAsRootTab,
  RootTab,
} from "../../navigation/routes";

import { OurNavContext } from "../../our-navigation";

import { isTablet, isDesktop } from "../../utils/Platform";

import styles from "./AppNavigator.module.scss";
import { apply } from "../../utils/type";
import { useIsLoggedIn } from "../../repository/AuthRepository";
import {
  appEventEmitter,
  AppEventOnClickTabBar,
} from "../../utils/SimpleEventEmitter";

function shouldShowCategoryAsPopover() {
  return isTablet() || isDesktop();
}

const RootTabAnimation = {
  [RootTab.category]: require("../../resources/animation/tab-active-category.json"),
  [RootTab.articles]: require("../../resources/animation/tab-active-discovery.json"),
  [RootTab.home]: require("../../resources/animation/tab-active-home.json"),
  [RootTab.likes]: require("../../resources/animation/tab-active-likes.json"),
  [RootTab.account]: require("../../resources/animation/tab-active-account.json"),
};

type Props = CategoryListPopoverProps & RouteComponentProps;

const TabBar: React.FC<Props> = props => {
  const { location, openCategoryListPopover } = props;
  const { navigate, replace, getActiveTab, getActivePathForTab } = useContext(
    OurNavContext
  );
  const isLogginedIn = useIsLoggedIn();

  // NOTE:(jasonkit)
  // IonTabButton's href attribute did not change after render even if its
  // driving value is changed. Do due with this, we don't set href and use
  // onClick to do navigation.
  // As there is a <a> under <ion-tab-button>, url change will take effect
  // which will push an entry to browser history. To make back button
  // work properly for tab switching, here we will use replace instead of
  // push for navigation.
  const onTarBarPress = useCallback(
    (e: React.MouseEvent<HTMLIonTabButtonElement>) => {
      const tab =
        (e.currentTarget && e.currentTarget.getAttribute("tab")) || "";
      const activeTab = getActiveTab();
      const path = (activeTab !== tab && getActivePathForTab(tab)) || `/${tab}`;
      replace(path);
      const activeRootTab = activeTabAsRootTab(tab);
      if (activeRootTab != null) {
        appEventEmitter.publish(AppEventOnClickTabBar(activeRootTab));
      }
    },
    [replace, getActiveTab, getActivePathForTab]
  );

  const handleCategoryClick = useCallback(
    (e: MouseEvent) => {
      if (shouldShowCategoryAsPopover()) {
        openCategoryListPopover(e);
      } else {
        onTarBarPress(e as any);
      }
    },
    [openCategoryListPopover, onTarBarPress]
  );

  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const onClickLikesTab = useCallback(
    (e: React.MouseEvent<HTMLIonTabButtonElement>) => {
      if (isLogginedIn) {
        onTarBarPress(e);
      } else {
        e.preventDefault();
        e.stopPropagation();
        presentLoginModal(() => {
          navigate(getPathForLikesTab());
        });
      }
    },
    [isLogginedIn, onTarBarPress, presentLoginModal, navigate]
  );

  const renderTab = useCallback(
    (
      path: string,
      tabMessageID: MessageID,
      className: string,
      activeTabAnimationData: any,
      onClick: (e: any) => void
    ) => {
      const tabName = path.replace(/\//, "");
      const isActive = location.pathname.startsWith(path);
      return (
        <IonTabButton tab={tabName} onClick={onClick}>
          {isActive ? (
           <div
           className={cn(className, { [styles.isActiveTab]: isActive })}
          />
          ) : (
            <div
              className={cn(className, { [styles.isActiveTab]: isActive })}
            />
          )}
          <IonLabel className={isActive ? styles.isActiveTab : undefined}>
            <LocalizedText messageID={tabMessageID} />
          </IonLabel>
        </IonTabButton>
      );
    },
    [location]
  );

  return (
    <IonTabBar slot="bottom">
      {renderTab(
        shouldShowCategoryAsPopover()
          ? getPathForAllCategoriesPage()
          : getPathForCategoryTab(),
        "tab.category",
        styles.category,
        RootTabAnimation[RootTab.category],
        handleCategoryClick
      )}
      {renderTab(
        getPathForArticleTab(),
        "tab.articles",
        styles.article,
        RootTabAnimation[RootTab.articles],
        onTarBarPress
      )}
      {renderTab(
        getPathForHomePage(),
        "tab.home",
        styles.home,
        RootTabAnimation[RootTab.home],
        onTarBarPress
      )}
      {renderTab(
        getPathForLikesTab(),
        "tab.likes",
        styles.likes,
        RootTabAnimation[RootTab.likes],
        onClickLikesTab
      )}
      {renderTab(
        genPathForAccountTab(),
        "tab.account",
        styles.account,
        RootTabAnimation[RootTab.account],
        onTarBarPress
      )}
    </IonTabBar>
  );
};

export default React.memo(
  withCategoryListPopover<{}>(
    withRouter(withProviders(TabBar, LoginSignupModalProvider))
  )
);

export const TarBarHeightContext = createContext(0);
export const TarBarHeightProvider: React.FC = props => {
  const [height, setHeight] = useState(0);
  useEffect(() => {
    // Assume tabbar height won't change after initial layout
    const tryToGetAndSetTabBarHeight = () => {
      let h = 0;
      apply(document.querySelector("ion-tab-bar"), ionTabBar => {
        h = ionTabBar.getBoundingClientRect().height;
      });
      if (h > 0) {
        setHeight(h);
      }
      return h;
    };
    if (tryToGetAndSetTabBarHeight() > 0) {
      return;
    }
    const token = setInterval(() => {
      const tabBarHeight = tryToGetAndSetTabBarHeight();
      if (tabBarHeight) {
        clearInterval(token);
      }
    }, 100);
    return () => {
      clearInterval(token);
    };
  }, []);
  return (
    <TarBarHeightContext.Provider value={height}>
      {props.children}
    </TarBarHeightContext.Provider>
  );
};

export const TabBarSpacePlaceholder: React.FC = () => {
  const tabBarHeight = useContext(TarBarHeightContext);
  const style = useMemo<React.CSSProperties>(
    () => ({
      paddingBottom: tabBarHeight,
      width: "100%",
    }),
    [tabBarHeight]
  );
  return <div style={style} />;
};
