import {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { useIntl } from "../i18n/Localization";
import { useKeepUpdatingRef } from "../hook/utils";
import { useMagentoVersionFn } from "../hook/MagentoVersion";

import { RepositoryContext, PaginationInfo } from "./State";

import {
  fetchProduct,
  fetchProductOverviewsBySKUs,
  fetchProductOverviewsByCategoryId,
  fetchProductOverviewsFromCategoryListByCategoryId,
  fetchNormalProductOverviewsByMerchantId,
  fetchFeaturedProductOverviewsByMerchantId,
  searchProductOverviews,
  fetchProductSKUByUrlKey,
  fetchFeaturedProductsByMerchantId,
  fetchNormalProductsByMerchantId,
  fetchProductsBySKUs,
  searchProducts,
  fetchProductsFromCategoryListByCategoryId,
} from "../api/GraphQL";

import { useFetchResources_v2 } from "./Hooks";

import { Product } from "../models/ProductDetails";
import { ProductOverview } from "../models/ProductOverview";
import {
  ResourcesRequestState,
  isRequestLoading,
} from "../models/ResourcesRequestState";
import { MerchantID, EntityID as MerchantEntityID } from "../models/Merchant";

import { SearchTerm } from "../models/Search";
import { ProductFilterInfo } from "../models/filter";
import { PageInfo } from "../models/PageInfo";

export function useMemoryProductOverview(sku: string): ProductOverview | null {
  const { state } = useContext(RepositoryContext);
  return state.product.overviewBySKU[sku] || null;
}

export function useMemoryProductDetail(sku: string): Product | null {
  const { state } = useContext(RepositoryContext);
  return state.product.detailBySKU[sku] || null;
}

export function useFetchProductDetailBySKU(): [
  ResourcesRequestState<Product | null>,
  (sku: string) => Promise<Product | null>,
  (sku: string) => Promise<Product | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { state, dispatch } = useContext(RepositoryContext);
  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    Product | null,
    (sku: string) => Promise<Product | null>
  >({
    memoryCacheProvider: async (sku: string) =>
      state.product.detailBySKU[sku] || null,
    localCacheProvider: async (sku: string) => {
      const product = await fetchProduct(client, sku, locale, "cache-only");
      if (product) {
        dispatch({
          type: "UpdateProductDetail",
          product,
        });
      }
      return product;
    },
    remoteResourcesProvider: async (sku: string) => {
      const product = await fetchProduct(client, sku, locale, "network-only");
      if (product) {
        dispatch({
          type: "UpdateProductDetail",
          product,
        });
      }
      return product;
    },
  });
  return [requestState, fetch, refresh];
}

export function useFetchProductsBySKUs(): [
  ResourcesRequestState<ProductOverview[] | null>,
  (skus: string[]) => Promise<ProductOverview[] | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const {
    state: {
      product: { detailBySKU },
    },
    dispatch,
  } = useContext(RepositoryContext);

  const [requestState, { call: fetch }] = useFetchResources_v2<
    ProductOverview[] | null,
    (skus: string[]) => Promise<ProductOverview[] | null>
  >({
    memoryCacheProvider: async (skus: string[]) => {
      const products: ProductOverview[] = [];
      if (skus && skus.length > 0) {
        for (let i = 0; i < skus.length; i++) {
          const sku = skus[i];
          const product = detailBySKU[sku];
          if (!product) {
            return null;
          }
          products.push(product);
        }
        return products;
      }
      return [];
    },
    localCacheProvider: async (skus: string[]) => {
      const products = await fetchProductsBySKUs(
        client,
        skus,
        locale,
        "cache-only"
      );
      if (products) {
        dispatch({
          type: "UpdateProducts",
          products,
        });
      }
      return products;
    },
    remoteResourcesProvider: async (skus: string[]) => {
      const products = await fetchProductsBySKUs(
        client,
        skus,
        locale,
        "network-only"
      );
      if (products) {
        dispatch({
          type: "UpdateProducts",
          products,
        });
      }
      return products;
    },
  });

  return [requestState, fetch];
}

// TODO (Steven-Chan):
// should return a function for start requesting
export function useFetchProductsByCategoryId(
  categoryId: number,
  productFilterInfo: ProductFilterInfo
): {
  requestState: ResourcesRequestState<{
    products: Product[];
    hasMore: boolean;
    pageSize: number;
  } | null>;
  fetchNext: () => Promise<{
    products: Product[];
    hasMore: boolean;
    pageSize: number;
  } | null>;
  refresh: () => Promise<{
    products: Product[];
    hasMore: boolean;
    pageSize: number;
  } | null>;
  paginationInfo: PaginationInfo<string> | null;
} {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { dispatch, state } = useContext(RepositoryContext);
  const { productAttributeFilterInputMap } = state;

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
    string
  > | null>(null);
  const paginationInfoRef = useKeepUpdatingRef(paginationInfo);

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    {
      products: Product[];
      hasMore: boolean;
      pageSize: number;
    } | null,
    (
      page: number
    ) => Promise<{
      products: Product[];
      hasMore: boolean;
      pageSize: number;
    } | null>
  >({
    localCacheProvider: async (page: number) => {
      const result = await fetchProductsFromCategoryListByCategoryId(
        client,
        categoryId,
        page,
        productFilterInfo,
        productAttributeFilterInputMap,
        locale,
        "cache-only"
      );
      if (result) {
        const { products, hasMore, pageSize } = result;
        dispatch({
          type: "UpdateCategoryProductsPaginationInfo",
          products,
        });
        setPaginationInfo(p => {
          const skus = products.map(({ sku }) => sku);
          if (p === null || page === 1) {
            return {
              items: skus,
              currentPage: page,
              hasMore,
            };
          }
          let items = p.items;
          if (p.currentPage >= page) {
            items = items
              .slice(0, (page - 1) * pageSize)
              .concat(skus)
              .concat(items.slice(page * pageSize));
            return {
              items,
              currentPage: p.currentPage,
              hasMore,
            };
          }
          items = [...items, ...skus];
          return {
            items,
            currentPage: page,
            hasMore,
          };
        });
      }
      return result;
    },
    remoteResourcesProvider: async (page: number) => {
      const result = await fetchProductsFromCategoryListByCategoryId(
        client,
        categoryId,
        page,
        productFilterInfo,
        productAttributeFilterInputMap,
        locale,
        "network-only"
      );
      if (result) {
        const { products, hasMore, pageSize } = result;
        dispatch({
          type: "UpdateCategoryProductsPaginationInfo",
          products,
        });
        setPaginationInfo(p => {
          const skus = products.map(({ sku }) => sku);
          if (p === null || page === 1) {
            return {
              items: skus,
              currentPage: page,
              hasMore,
            };
          }
          let items = p.items;
          if (p.currentPage >= page) {
            items = items
              .slice(0, (page - 1) * pageSize)
              .concat(skus)
              .concat(items.slice(page * pageSize));
            return {
              items,
              currentPage: p.currentPage,
              hasMore,
            };
          }
          items = [...items, ...skus];
          return {
            items,
            currentPage: page,
            hasMore,
          };
        });
      }
      return result;
    },
  });

  const requestStateRef = useKeepUpdatingRef(requestState);

  const fetchNext = useCallback(async () => {
    const _paginationInfo = paginationInfoRef.current;
    if (_paginationInfo != null && !_paginationInfo.hasMore) {
      return null;
    }
    if (isRequestLoading(requestStateRef.current)) {
      return null;
    }
    if (_paginationInfo == null) {
      return fetch(1);
    }
    return fetch(_paginationInfo.currentPage + 1);
  }, [paginationInfoRef, requestStateRef, fetch]);

  const refresh_ = useCallback(() => refresh(1), [refresh]);

  return { requestState, fetchNext, refresh: refresh_, paginationInfo };
}

export function useFetchProductOverviewsByCategoryId(
  categoryId: number,
  productFilterInfo: ProductFilterInfo
): {
  requestState: ResourcesRequestState<{
    productOverviews: ProductOverview[];
    hasMore: boolean;
    pageSize: number;
  } | null>;
  fetchNext: () => Promise<{
    productOverviews: ProductOverview[];
    hasMore: boolean;
    pageSize: number;
  } | null>;
  refresh: () => Promise<{
    productOverviews: ProductOverview[];
    hasMore: boolean;
    pageSize: number;
  } | null>;
  paginationInfo: PaginationInfo<string> | null;
} {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { dispatch, state } = useContext(RepositoryContext);
  const { productAttributeFilterInputMap } = state;

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
    string
  > | null>(null);
  const paginationInfoRef = useKeepUpdatingRef(paginationInfo);

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    {
      productOverviews: ProductOverview[];
      hasMore: boolean;
      pageSize: number;
    } | null,
    (
      page: number
    ) => Promise<{
      productOverviews: ProductOverview[];
      hasMore: boolean;
      pageSize: number;
    } | null>
  >({
    localCacheProvider: async (page: number) => {
      const result = await fetchProductOverviewsFromCategoryListByCategoryId(
        client,
        categoryId,
        page,
        productFilterInfo,
        productAttributeFilterInputMap,
        locale,
        "cache-only"
      );
      if (result) {
        const { productOverviews, hasMore, pageSize } = result;
        dispatch({
          type: "UpdateCategoryProductOverviewsPaginationInfo",
          productOverviews,
        });
        setPaginationInfo(p => {
          const skus = productOverviews.map(({ sku }) => sku);
          if (p === null || page === 1) {
            return {
              items: skus,
              currentPage: page,
              hasMore,
            };
          }
          let items = p.items;
          if (p.currentPage >= page) {
            items = items
              .slice(0, (page - 1) * pageSize)
              .concat(skus)
              .concat(items.slice(page * pageSize));
            return {
              items,
              currentPage: p.currentPage,
              hasMore,
            };
          }
          items = [...items, ...skus];
          return {
            items,
            currentPage: page,
            hasMore,
          };
        });
      }
      return result;
    },
    remoteResourcesProvider: async (page: number) => {
      const result = await fetchProductOverviewsFromCategoryListByCategoryId(
        client,
        categoryId,
        page,
        productFilterInfo,
        productAttributeFilterInputMap,
        locale,
        "network-only"
      );
      if (result) {
        const { productOverviews, hasMore, pageSize } = result;
        dispatch({
          type: "UpdateCategoryProductOverviewsPaginationInfo",
          productOverviews,
        });
        setPaginationInfo(p => {
          const skus = productOverviews.map(({ sku }) => sku);
          if (p === null || page === 1) {
            return {
              items: skus,
              currentPage: page,
              hasMore,
            };
          }
          let items = p.items;
          if (p.currentPage >= page) {
            items = items
              .slice(0, (page - 1) * pageSize)
              .concat(skus)
              .concat(items.slice(page * pageSize));
            return {
              items,
              currentPage: p.currentPage,
              hasMore,
            };
          }
          items = [...items, ...skus];
          return {
            items,
            currentPage: page,
            hasMore,
          };
        });
      }
      return result;
    },
  });

  const requestStateRef = useKeepUpdatingRef(requestState);

  const fetchNext = useCallback(async () => {
    const _paginationInfo = paginationInfoRef.current;
    if (_paginationInfo != null && !_paginationInfo.hasMore) {
      return null;
    }
    if (isRequestLoading(requestStateRef.current)) {
      return null;
    }
    if (_paginationInfo == null) {
      return fetch(1);
    }
    return fetch(_paginationInfo.currentPage + 1);
  }, [paginationInfoRef, requestStateRef, fetch]);

  const refresh_ = useCallback(() => refresh(1), [refresh]);

  return { requestState, fetchNext, refresh: refresh_, paginationInfo };
}

export function useGetProductsBySkus(
  skus: string[]
): Product[] {
  const {
    state: {
      product: { detailBySKU },
    },
  } = useContext(RepositoryContext);

  return useMemo(() => {
    const res: Product[] = [];
    for (let i = 0; i < skus.length; i++) {
      const sku = skus[i];
      const product = detailBySKU[sku];
      if (product) {
        res.push(product);
      }
    }
    return res;
  }, [skus.join(":"), detailBySKU]); // eslint-disable-line
}

export function useGetProductOverviewsBySkus(
  skus: string[]
): ProductOverview[] {
  const {
    state: {
      product: { overviewBySKU },
    },
  } = useContext(RepositoryContext);

  return useMemo(() => {
    const res: ProductOverview[] = [];
    for (let i = 0; i < skus.length; i++) {
      const sku = skus[i];
      const productOverview = overviewBySKU[sku];
      if (productOverview) {
        res.push(productOverview);
      }
    }
    return res;
  }, [skus.join(":"), overviewBySKU]); // eslint-disable-line
}

export function useFetchFeaturedProductsByMerchantId(
  merchantEntityId: MerchantEntityID
): {
  requestState: ResourcesRequestState<{
  products: Product[];
  } | null>;
  fetchNext: () => Promise<{ products: Product[] } | null>;
  refresh: () => Promise<{ products: Product[] } | null>;
  paginationInfo: PaginationInfo<Product> | null;
} {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { dispatch } = useContext(RepositoryContext);

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
    Product
  > | null>(null);

  const paginationInfoRef = useKeepUpdatingRef(paginationInfo);

  const didFetchProducts = useCallback(
    (products: Product[], pageInfo: PageInfo) => {
      setPaginationInfo(p => {
        const newPaginationInfo: PaginationInfo<Product> = p || {
          items: [],
          currentPage: 0,
          hasMore: true,
        };
        if (pageInfo.currentPage === 1) {
          newPaginationInfo.items = products;
        } else if (pageInfo.currentPage === newPaginationInfo.currentPage) {
          // Handle remote success after cache success, which may update the fetched list
          newPaginationInfo.items = newPaginationInfo.items
            .slice(0, -products.length)
            .concat(products);
        } else {
          newPaginationInfo.items = newPaginationInfo.items.concat(
            products
          );
        }
        newPaginationInfo.currentPage = pageInfo.currentPage;
        newPaginationInfo.hasMore = pageInfo.currentPage < pageInfo.totalPages;
        return newPaginationInfo;
      });
    },
    []
  );

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    { products: Product[] } | null,
    (page: number) => Promise<{ products: Product[] } | null>
  >({
    remoteResourcesProvider: async (page: number) => {
      const result = await fetchFeaturedProductsByMerchantId(
        client,
        merchantEntityId,
        page,
        locale,
        "network-only"
      );
      if (result) {
        dispatch({
          type: "UpdateProducts",
          products: result.products,
        });
        didFetchProducts(result.products, result.pageInfo);
      }
      return result;
    },
  });

  const requestStateRef = useKeepUpdatingRef(requestState);

  const fetchNext = useCallback(async () => {
    const _paginationInfo = paginationInfoRef.current;
    if (_paginationInfo != null && !_paginationInfo.hasMore) {
      return null;
    }
    if (isRequestLoading(requestStateRef.current)) {
      return null;
    }
    if (_paginationInfo == null) {
      return fetch(1);
    }
    return fetch(_paginationInfo.currentPage + 1);
  }, [paginationInfoRef, requestStateRef, fetch]);

  const refresh_ = useCallback(() => refresh(1), [refresh]);

  return { requestState, fetchNext, refresh: refresh_, paginationInfo };
}

export function useFetchNormalProductsByMerchantId(
  entityId: MerchantEntityID,
  productFilterInfo: ProductFilterInfo
): {
  requestState: ResourcesRequestState<{
    products: ProductOverview[];
  } | null>;
  fetchNext: () => Promise<{ products: ProductOverview[] } | null>;
  refresh: () => Promise<{ products: ProductOverview[] } | null>;
  paginationInfo: PaginationInfo<ProductOverview> | null;
} {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { dispatch, state } = useContext(RepositoryContext);
  const { productAttributeFilterInputMap } = state;

  const fetchNormalProductsByMerchantId_ = useMagentoVersionFn(
    fetchNormalProductsByMerchantId
  );

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<ProductOverview> | null>(null);

  const paginationInfoRef = useKeepUpdatingRef(paginationInfo);

  const didFetchProducts = useCallback(
    (products: ProductOverview[], pageInfo: PageInfo) => {
      setPaginationInfo(p => {
        const newPaginationInfo: PaginationInfo<ProductOverview> = p || {
          items: [],
          currentPage: 0,
          hasMore: true,
        };
        if (pageInfo.currentPage === 1) {
          newPaginationInfo.items = products;
        } else if (pageInfo.currentPage === newPaginationInfo.currentPage) {
          // Handle remote success after cache success, which may update the fetched list
          newPaginationInfo.items = newPaginationInfo.items
            .slice(0, - products.length)
            .concat(products);
        } else {
          newPaginationInfo.items = newPaginationInfo.items.concat(
            products
          );
        }
        newPaginationInfo.currentPage = pageInfo.currentPage;
        newPaginationInfo.hasMore = pageInfo.currentPage < pageInfo.totalPages;
        return newPaginationInfo;
      });
    },
    []
  );

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    { products: ProductOverview[] } | null,
    (page: number) => Promise<{ products: ProductOverview[] } | null>
  >({
    localCacheProvider: async (page: number) => {
      const result = await fetchNormalProductsByMerchantId_(
        client,
        entityId,
        productFilterInfo,
        productAttributeFilterInputMap,
        page,
        locale,
        "cache-only"
      );
      if (result) {
        dispatch({
          type: "UpdateProducts",
          products: result.products,
        });
        didFetchProducts(result.products, result.pageInfo);
      }
      return result;
    },
    remoteResourcesProvider: async (page: number) => {
      const result = await fetchNormalProductsByMerchantId_(
        client,
        entityId,
        productFilterInfo,
        productAttributeFilterInputMap,
        page,
        locale,
        "network-only"
      );
      if (result) {
        dispatch({
          type: "UpdateProducts",
          products: result.products,
        });
        didFetchProducts(result.products, result.pageInfo);
      }
      return result;
    },
  });

  const requestStateRef = useKeepUpdatingRef(requestState);

  const fetchNext = useCallback(async () => {
    const _paginationInfo = paginationInfoRef.current;
    if (_paginationInfo != null && !_paginationInfo.hasMore) {
      return null;
    }
    if (isRequestLoading(requestStateRef.current)) {
      return null;
    }
    if (_paginationInfo == null) {
      return fetch(1);
    }
    return fetch(_paginationInfo.currentPage + 1);
  }, [paginationInfoRef, requestStateRef, fetch]);

  const refresh_ = useCallback(() => refresh(1), [refresh]);

  return { requestState, fetchNext, refresh: refresh_, paginationInfo };

}


export function useFetchNormalProductOverviewsByMerchantId(
  entityId: MerchantEntityID,
  productFilterInfo: ProductFilterInfo
): {
  requestState: ResourcesRequestState<{
    productOverviews: ProductOverview[];
  } | null>;
  fetchNext: () => Promise<{ productOverviews: ProductOverview[] } | null>;
  refresh: () => Promise<{ productOverviews: ProductOverview[] } | null>;
  paginationInfo: PaginationInfo<ProductOverview> | null;
} {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { dispatch, state } = useContext(RepositoryContext);
  const { productAttributeFilterInputMap } = state;

  const fetchNormalProductOverviewsByMerchantId_ = useMagentoVersionFn(
    fetchNormalProductOverviewsByMerchantId
  );

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
    ProductOverview
  > | null>(null);
  const paginationInfoRef = useKeepUpdatingRef(paginationInfo);

  const didFetchProducts = useCallback(
    (productOverviews: ProductOverview[], pageInfo: PageInfo) => {
      setPaginationInfo(p => {
        const newPaginationInfo: PaginationInfo<ProductOverview> = p || {
          items: [],
          currentPage: 0,
          hasMore: true,
        };
        if (pageInfo.currentPage === 1) {
          newPaginationInfo.items = productOverviews;
        } else if (pageInfo.currentPage === newPaginationInfo.currentPage) {
          // Handle remote success after cache success, which may update the fetched list
          newPaginationInfo.items = newPaginationInfo.items
            .slice(0, -productOverviews.length)
            .concat(productOverviews);
        } else {
          newPaginationInfo.items = newPaginationInfo.items.concat(
            productOverviews
          );
        }
        newPaginationInfo.currentPage = pageInfo.currentPage;
        newPaginationInfo.hasMore = pageInfo.currentPage < pageInfo.totalPages;
        return newPaginationInfo;
      });
    },
    []
  );

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    { productOverviews: ProductOverview[] } | null,
    (page: number) => Promise<{ productOverviews: ProductOverview[] } | null>
  >({
    localCacheProvider: async (page: number) => {
      const result = await fetchNormalProductOverviewsByMerchantId_(
        client,
        entityId,
        productFilterInfo,
        productAttributeFilterInputMap,
        page,
        locale,
        "cache-only"
      );
      if (result) {
        dispatch({
          type: "UpdateProductOverviews",
          productOverviews: result.productOverviews,
        });
        didFetchProducts(result.productOverviews, result.pageInfo);
      }
      return result;
    },
    remoteResourcesProvider: async (page: number) => {
      const result = await fetchNormalProductOverviewsByMerchantId_(
        client,
        entityId,
        productFilterInfo,
        productAttributeFilterInputMap,
        page,
        locale,
        "network-only"
      );
      if (result) {
        dispatch({
          type: "UpdateProductOverviews",
          productOverviews: result.productOverviews,
        });
        didFetchProducts(result.productOverviews, result.pageInfo);
      }
      return result;
    },
  });

  const requestStateRef = useKeepUpdatingRef(requestState);

  const fetchNext = useCallback(async () => {
    const _paginationInfo = paginationInfoRef.current;
    if (_paginationInfo != null && !_paginationInfo.hasMore) {
      return null;
    }
    if (isRequestLoading(requestStateRef.current)) {
      return null;
    }
    if (_paginationInfo == null) {
      return fetch(1);
    }
    return fetch(_paginationInfo.currentPage + 1);
  }, [paginationInfoRef, requestStateRef, fetch]);

  const refresh_ = useCallback(() => refresh(1), [refresh]);

  return { requestState, fetchNext, refresh: refresh_, paginationInfo };
}

export function useFetchFeaturedProductOverviewsByMerchantId(
  entityId: MerchantEntityID
): {
  requestState: ResourcesRequestState<{
    productOverviews: ProductOverview[];
  } | null>;
  fetchNext: () => Promise<{ productOverviews: ProductOverview[] } | null>;
  refresh: () => Promise<{ productOverviews: ProductOverview[] } | null>;
  paginationInfo: PaginationInfo<ProductOverview> | null;
} {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { dispatch } = useContext(RepositoryContext);

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
    ProductOverview
  > | null>(null);
  const paginationInfoRef = useKeepUpdatingRef(paginationInfo);

  const didFetchProducts = useCallback(
    (productOverviews: ProductOverview[], pageInfo: PageInfo) => {
      setPaginationInfo(p => {
        const newPaginationInfo: PaginationInfo<ProductOverview> = p || {
          items: [],
          currentPage: 0,
          hasMore: true,
        };
        if (pageInfo.currentPage === 1) {
          newPaginationInfo.items = productOverviews;
        } else if (pageInfo.currentPage === newPaginationInfo.currentPage) {
          // Handle remote success after cache success, which may update the fetched list
          newPaginationInfo.items = newPaginationInfo.items
            .slice(0, -productOverviews.length)
            .concat(productOverviews);
        } else {
          newPaginationInfo.items = newPaginationInfo.items.concat(
            productOverviews
          );
        }
        newPaginationInfo.currentPage = pageInfo.currentPage;
        newPaginationInfo.hasMore = pageInfo.currentPage < pageInfo.totalPages;
        return newPaginationInfo;
      });
    },
    []
  );

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    { productOverviews: ProductOverview[] } | null,
    (page: number) => Promise<{ productOverviews: ProductOverview[] } | null>
  >({
    remoteResourcesProvider: async (page: number) => {
      const result = await fetchFeaturedProductOverviewsByMerchantId(
        client,
        entityId,
        page,
        locale,
        "network-only"
      );
      if (result) {
        dispatch({
          type: "UpdateProductOverviews",
          productOverviews: result.productOverviews,
        });
        didFetchProducts(result.productOverviews, result.pageInfo);
      }
      return result;
    },
  });

  const requestStateRef = useKeepUpdatingRef(requestState);

  const fetchNext = useCallback(async () => {
    const _paginationInfo = paginationInfoRef.current;
    if (_paginationInfo != null && !_paginationInfo.hasMore) {
      return null;
    }
    if (isRequestLoading(requestStateRef.current)) {
      return null;
    }
    if (_paginationInfo == null) {
      return fetch(1);
    }
    return fetch(_paginationInfo.currentPage + 1);
  }, [paginationInfoRef, requestStateRef, fetch]);

  const refresh_ = useCallback(() => refresh(1), [refresh]);

  return { requestState, fetchNext, refresh: refresh_, paginationInfo };
}

export function useSearchProducts(
    searchTerm: SearchTerm,
    productFilterInfo: ProductFilterInfo | null
  ): {
    requestState: ResourcesRequestState<{
      productOverviews: Product[];
    } | null>;
    paginationInfo: PaginationInfo<Product> | null;
    fetchNext: () => Promise<{ productOverviews: Product[] } | null>;
    refresh: () => Promise<{ productOverviews: Product[] } | null>;
  } {
    const client = useApolloClient();
    const { locale } = useIntl();
    const { dispatch, state } = useContext(RepositoryContext);
    const { productAttributeFilterInputMap } = state;
  
    const canFetch = useMemo(() => productFilterInfo != null, [
      productFilterInfo,
    ]);
  
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
      Product
    > | null>(null);
    const paginationInfoRef = useRef(paginationInfo);
    paginationInfoRef.current = paginationInfo;
  
    const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
      { productOverviews: Product[] } | null,
      (page: number) => Promise<{ productOverviews: Product[] } | null>
    >({
      remoteResourcesProvider: async (page: number) => {
        if (productFilterInfo === null) {
          throw new Error("missing product filter info");
        }
        const result = await searchProducts(
          client,
          searchTerm,
          productFilterInfo,
          productAttributeFilterInputMap,
          page,
          locale,
          "network-only"
        );
        if (result) {
          const { pageInfo, productOverviews } = result;
          dispatch({
            type: "UpdateProductOverviews",
            productOverviews,
          });
          setPaginationInfo(paginationInfo => {
            const items: Product[] =
              page === 1 || paginationInfo == null ? [] : paginationInfo.items;
            items.push(...productOverviews);
            return {
              items,
              currentPage: pageInfo.currentPage,
              hasMore: pageInfo.currentPage < pageInfo.totalPages,
            };
          });
        }
        return result;
      },
    });
  
    const requestStateRef = useRef(requestState);
  
    const fetchNext = useCallback(async () => {
      if (!canFetch) {
        return null;
      }
      const _paginationInfo = paginationInfoRef.current;
      if (_paginationInfo != null && !_paginationInfo.hasMore) {
        return null;
      }
      if (isRequestLoading(requestStateRef.current)) {
        return null;
      }
      if (_paginationInfo == null) {
        return fetch(1);
      }
      return fetch(_paginationInfo.currentPage + 1);
    }, [paginationInfoRef, requestStateRef, fetch, canFetch]);
  
    const refresh_ = useCallback(() => refresh(1), [refresh]);
  
    return {
      requestState,
      paginationInfo,
      fetchNext,
      refresh: refresh_,
    };
  }

export function useSearchProductOverviews(
  searchTerm: SearchTerm,
  productFilterInfo: ProductFilterInfo | null
): {
  requestState: ResourcesRequestState<{
    productOverviews: ProductOverview[];
  } | null>;
  paginationInfo: PaginationInfo<ProductOverview> | null;
  fetchNext: () => Promise<{ productOverviews: ProductOverview[] } | null>;
  refresh: () => Promise<{ productOverviews: ProductOverview[] } | null>;
} {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { dispatch, state } = useContext(RepositoryContext);
  const { productAttributeFilterInputMap } = state;

  const canFetch = useMemo(() => productFilterInfo != null, [
    productFilterInfo,
  ]);

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
    ProductOverview
  > | null>(null);
  const paginationInfoRef = useRef(paginationInfo);
  paginationInfoRef.current = paginationInfo;

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    { productOverviews: ProductOverview[] } | null,
    (page: number) => Promise<{ productOverviews: ProductOverview[] } | null>
  >({
    remoteResourcesProvider: async (page: number) => {
      if (productFilterInfo === null) {
        throw new Error("missing product filter info");
      }
      const result = await searchProductOverviews(
        client,
        searchTerm,
        productFilterInfo,
        productAttributeFilterInputMap,
        page,
        locale,
        "network-only"
      );
      if (result) {
        const { pageInfo, productOverviews } = result;
        dispatch({
          type: "UpdateProductOverviews",
          productOverviews,
        });
        setPaginationInfo(paginationInfo => {
          const items: ProductOverview[] =
            page === 1 || paginationInfo == null ? [] : paginationInfo.items;
          items.push(...productOverviews);
          return {
            items,
            currentPage: pageInfo.currentPage,
            hasMore: pageInfo.currentPage < pageInfo.totalPages,
          };
        });
      }
      return result;
    },
  });

  const requestStateRef = useRef(requestState);

  const fetchNext = useCallback(async () => {
    if (!canFetch) {
      return null;
    }
    const _paginationInfo = paginationInfoRef.current;
    if (_paginationInfo != null && !_paginationInfo.hasMore) {
      return null;
    }
    if (isRequestLoading(requestStateRef.current)) {
      return null;
    }
    if (_paginationInfo == null) {
      return fetch(1);
    }
    return fetch(_paginationInfo.currentPage + 1);
  }, [paginationInfoRef, requestStateRef, fetch, canFetch]);

  const refresh_ = useCallback(() => refresh(1), [refresh]);

  return {
    requestState,
    paginationInfo,
    fetchNext,
    refresh: refresh_,
  };
}

export function useIsProductLiked(sku: string): boolean {
  const {
    state: {
      product: { likedProductSKU },
    },
  } = useContext(RepositoryContext);
  return likedProductSKU[sku] || false;
}

export function useFetchProductSKUByUrlKey(): (
  urlKey: string
) => Promise<string | null> {
  const client = useApolloClient();
  return useCallback(
    (urlKey: string) => {
      return fetchProductSKUByUrlKey(client, urlKey);
    },
    [client]
  );
}
