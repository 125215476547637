import React, { useEffect, useContext } from "react";
import CSRModal from "../CSRModal";
import styles from "./styles.module.scss";
import { LocalizedText } from "../../i18n/Localization";
import CLContent from "../CLContent";
import { Notification } from "../../models/Notification";
import { MessageID } from "../../i18n/translations/type";
import { IonThumbnail, IonItem, IonList, IonContent } from "@ionic/react";
import moment from "moment";
import { useMarkCSRNotificationAsRead } from "../../repository/CsrRepository";
import { getResources } from "../../models/ResourcesRequestState";
import { RepositoryContext } from "../../repository/State";

const EmptyMessageList: React.FC = () => {
  return (
    <div className={styles.message} style={{ padding: "30px" }}>
      <LocalizedText messageID="messagebox.empty" />
    </div>
  );
};

interface MessageListProp {
  messages: Notification[];
}
const MessageList: React.FC<MessageListProp> = ({ messages }) => {
  return (
    <IonList className={styles.messageList}>
      {messages.map((message: Notification) => {
        let messageID = `messagebox.${message.template}` as MessageID;
        return (
          <IonItem className={styles.message} key={`message-${message.id}`}>
            <div slot="start" className={styles.messageImg}></div>
            <div className={styles.messageContainer}>
              <p className={styles.messageBody}>
                <LocalizedText
                  messageID={messageID}
                  messageArgs={{
                    donationAmountMet: message.detail.donationAmountMet,
                    nextDonationAmount: message.detail.nextDonationAmount || "",
                  }}
                />
              </p>
              <small className={styles.messageDate}>
                {moment(message.creationTime).format("YYYY-MM-DD")}
              </small>
            </div>
          </IonItem>
        );
      })}
    </IonList>
  );
};
type MessageModalProps = {
  isOpen: boolean;
  messages: Notification[];
  onRequestDismiss: () => void;
};

const MessageModal: React.FC<MessageModalProps> = (props) => {
  const { isOpen, messages, onRequestDismiss } = props;
  const [
    markMessagesResource,
    callMarkMessages,
  ] = useMarkCSRNotificationAsRead();
  const { dispatch } = useContext(RepositoryContext);
/*
  useEffect(() => {
    return () => {
      dispatch({
        type: "UpdateCSRNotifications",
        notifications: [],
      });
      (async () => {
        if (messages && messages.length) {
          //await callMarkMessages(messages.map((m) => m.id));
        }
      })();
    };
  }, [dispatch, messages]);
  */
  return (
    <CSRModal
      isOpen={isOpen}
      hideHeader={true}
      onRequestDismiss={onRequestDismiss}
    >
      <CLContent>
        <div className={styles.modalBody}>
          <span className={styles.modalTitle}>
            <LocalizedText messageID="messagebox.title" />
          </span>
          <IonContent className={styles.bgGrey}>
            {messages && messages.length ? (
              <MessageList messages={messages} />
            ) : (
              <EmptyMessageList />
            )}
          </IonContent>
        </div>
      </CLContent>
    </CSRModal>
  );
};

export default MessageModal;
