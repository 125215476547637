import React, { useState, useContext, useEffect, useRef } from "react";
import { ApolloProvider } from "react-apollo";
import { useKeepUpdatingRef } from "./hook/utils";
import { ApolloClient, NormalizedCacheObject } from "apollo-boost";
import { ApolloProvider as ApolloClientProvider } from "@apollo/react-hooks";
import { useAccessToken } from "./api/TokenStore";
import { useLocale, Locale } from "./i18n/locale";
import {
  CartIDProvider,
  useCartIDFromStorage,
} from "./components/CartIDProvider";
import RepositoryProvider from "./repository/RepositoryProvider";
import { LocalizationProvider, useIntl } from "./i18n/Localization";
import { OurRouterOutletProvider } from "./our-navigation/OurRouterOutlet";
import MagentoVersionProvider from "./components/MagentoVersionProvider";
import ErrorBoundary from "./components/ErrorBoundary";
import { PushNotificationProvider } from "./components/PushNotificationProvider";
import { ShoppingCartItemCountProvider } from "./components/ShoppingCartItemCountProvider";
import { Provider as CheckoutProvider } from "./components/Checkout/Context";
//import AppNavigator from "./components/navigation/AppNavigator";
import CsrNavigator from "./components/navigation/CsrNavigator";
import { OrderIDProvider } from "./components/OrderIDProvider";
import {
  useFetchStoreConfig,
  useAutoFetchAppConfig,
  useStateMemoryConfigs,
} from "./repository/ConfigRepository";
import {
  LocalizedAlertProvider,
  LocalizedAlertContext,
} from "./components/LocalizedAlertProvider";
import RerenderBlocker from "./components/RerenderBlocker";
import WishlistProvider from "./components/WishlistProvider";
import { BackAndroidHandlerProvider } from "./components/BackAndroidHandler";
import { NetworkStatusProvider } from "./components/NetworkStatusProvider";
import AnimatedSplashScreenIonApp from "./components/AnimatedSplashScreenIonApp";
import { isiOS, isAndroid, isDesktop } from "./utils/Platform";
import { CookiesProvider } from "react-cookie";

import "bootstrap/dist/css/bootstrap.min.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import { useCLApolloClient } from "./api/GraphQL";
import Config from "./Config";
import { gtm } from "./utils/GTM";
import { ContentScrollProvider } from "./components/ContentScrollProvider";
import { getResources } from "./models/ResourcesRequestState";
import {
  useFetchCSRConfig,
  useFetchCSRZones,
} from "./repository/CsrRepository";
import { ZoneProvider } from "./components/ZoneProvider";

if (isiOS() && Config.IOS_GTM_ID) {
  gtm(Config.IOS_GTM_ID);
} else if (isAndroid() && Config.ANDROID_GTM_ID) {
  gtm(Config.ANDROID_GTM_ID);
} else if (isDesktop() && Config.WEB_GTM_ID) {
  gtm(Config.WEB_GTM_ID);
}

const FetchConfigs: React.FC = (props) => {
  const configs = useStateMemoryConfigs();
  const configsRef = useKeepUpdatingRef(configs);

  const [, fetchStoreConfig] = useFetchStoreConfig();
  const [, fetchAppConfig] = useAutoFetchAppConfig();

  const [csrConfigResource, fetchCsrConfig] = useFetchCSRConfig();

  const [csrZonesResource, fetchCsrZones] = useFetchCSRZones();
  const getConfigErrorCounterRef = useRef(0);

  const csrConfig = getResources(csrConfigResource);
  const csrZones = getResources(csrZonesResource);

  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);

  useEffect(() => {
    const fetchConfigs = () =>
      Promise.all([
        fetchStoreConfig(),
        fetchAppConfig(),
        !csrConfig ? fetchCsrConfig() : null,
        !csrZones ? fetchCsrZones() : null,
      ]).catch((err) => {
        getConfigErrorCounterRef.current++;
        console.log(err);
        if (configsRef.current != null) {
          return;
        }
        presentLocalizedAlert({
          headerId: "alert.fetch_config.error.title",
          buttons: [
            {
              textMessageID: "alert.fetch_config.error.retry",
              handler: () => {
                fetchConfigs();
              },
            },
          ],
        });
      });
    if (getConfigErrorCounterRef.current <= 3) {
      fetchConfigs();      
    }
  }, [
    configsRef,
    fetchStoreConfig,
    fetchAppConfig,
    presentLocalizedAlert,
    fetchCsrConfig,
    fetchCsrZones,
  ]);

  return <>{props.children}</>;
};

const App: React.FC<{
  apolloClient: ApolloClient<NormalizedCacheObject>;
  cartID: string | null;
  locale: Locale;
}> = (props) => {
  const [isSeenCity, setIsSeenCity] = React.useState("no");
  const [isSeenNgo, setIsSeenNgo] = React.useState("no");
  React.useEffect(() => {
    if(localStorage.getItem("isSeenNgo") === null){
      localStorage.setItem('isSeenNgo', isSeenNgo);
    }
    if(localStorage.getItem("isSeenCity") === null){
      localStorage.setItem('isSeenCity', isSeenCity);
    }
  }, [isSeenNgo,isSeenCity]);

  return (
    <OurRouterOutletProvider>
      {/* <HeadProvider> */}
      <NetworkStatusProvider>
        <LocalizationProvider locale={props.locale}>
          <ApolloProvider client={props.apolloClient}>
            <ApolloClientProvider client={props.apolloClient}>
              <LocalizedAlertProvider>
                <MagentoVersionProvider>
                  <CookiesProvider>
                    <PushNotificationProvider>
                      <RepositoryProvider>
                        <ContentScrollProvider>
                          <BackAndroidHandlerProvider>
                              <ShoppingCartItemCountProvider>
                                <CheckoutProvider>
                                  <CartIDProvider cartID={props.cartID}>
                                    <OrderIDProvider>
                                      <ZoneProvider>
                                        <FetchConfigs>
                                          <WishlistProvider>
                                            <RerenderBlocker>
                                              <CsrNavigator></CsrNavigator>
                                            </RerenderBlocker>
                                          </WishlistProvider>
                                        </FetchConfigs>
                                      </ZoneProvider>
                                    </OrderIDProvider>
                                  </CartIDProvider>
                                </CheckoutProvider>
                              </ShoppingCartItemCountProvider>
                          </BackAndroidHandlerProvider>
                        </ContentScrollProvider>
                      </RepositoryProvider>
                    </PushNotificationProvider>
                  </CookiesProvider>
                </MagentoVersionProvider>
              </LocalizedAlertProvider>
            </ApolloClientProvider>
          </ApolloProvider>
        </LocalizationProvider>
      </NetworkStatusProvider>
      {/* </HeadProvider> */}
    </OurRouterOutletProvider>
  );
};

const PrepareApp: React.FC = () => {
  const { apolloClient } = useCLApolloClient();
  const locale = useLocale();
  const getAccessTokenFinished = useAccessToken();

  const { cartID, getCartIDFinished } = useCartIDFromStorage();

  return (
    <AnimatedSplashScreenIonApp>
      {apolloClient != null &&
      locale != null &&
      getAccessTokenFinished &&
      getCartIDFinished ? (
        <App locale={locale} apolloClient={apolloClient} cartID={cartID} />
      ) : (
        <AppInitIssueReporter />
      )}
    </AnimatedSplashScreenIonApp>
  );
};

const AppInitIssueReporter: React.FC = () => {
  const apolloClient = useCLApolloClient();
  const locale = useLocale();
  const getAccessTokenFinished = useAccessToken();
  const { getCartIDFinished } = useCartIDFromStorage();

  const reportTimer = React.useRef<any>(null);

  React.useEffect(() => {
    reportTimer.current = setTimeout(() => {
      reportTimer.current = null;
      // Sentry.captureEvent({
      //   message: "Stuck at SplashScreen",
      //   extra: {
      //     isApolloClientReady: apolloClient != null,
      //     isLocaleReady: locale != null,
      //     getAccessTokenFinished,
      //     getCartIDFinished,
      //   },
      // });
    }, 15000);

    return () => {
      if (reportTimer.current) {
        clearTimeout(reportTimer.current);
        reportTimer.current = null;
      }
    };
  }, [apolloClient, locale, getAccessTokenFinished, getCartIDFinished]);
  return null;
};

export default function AppWithErrorBoundary() {
  useEffect(() => {
    if (!(isiOS() || isAndroid())) {
      // Only initialize it in web
      require("@codetrix-studio/capacitor-google-auth");
    }
  }, []);

  return (
    <ErrorBoundary>
      <PrepareApp />
    </ErrorBoundary>
  );
}
