import { useContext, useMemo, useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { RepositoryContext } from "./State";
import { useFetchResources, useFetchResources_v2 } from "./Hooks";
import {
  Category,
  makeAllCategory,
  CategoryTree,
  RemoteCategoryTree,
  computeChildenCategories,
} from "../models/category";
import { ResourcesRequestState } from "../models/ResourcesRequestState";
import {
  fetchCategoryTree,
  fetchCategoryList,
  fetchCategoryDescriptionByCategoryId,
  fetchCategoryDescriptionFromCategoryListByCategoryId,
} from "../api/GraphQL";
import { MagentoVersionContext } from "../components/MagentoVersionProvider";

import { useIntl } from "../i18n/Localization";
import { IndexMap } from "../utils/type";

import { getCategoryTree, setCategoryTree } from "../storage";

export function useFetchCategory(
  categoryId: number
): ResourcesRequestState<RemoteCategoryTree | null> {
  const client = useApolloClient();
  const { state, dispatch } = useContext(RepositoryContext);
  const { locale } = useIntl();
  const { ifMagentoVersion } = useContext(MagentoVersionContext);

  useEffect(() => {
    getCategoryTree(categoryId, locale).then(remoteCategoryTree => {
      if (remoteCategoryTree) {
        dispatch({
          type: "UpdateCategoryTree",
          remoteCategoryTree,
          locale,
        });
      }
    });
  }, [categoryId, locale, dispatch]);

  const { requestState, startRequesting } = useFetchResources(
    {
      needStoreConfig: true,
      memoryCacheProvider: () =>
        state.remoteCategoryTreeMap[locale][categoryId] || null,
      localCacheProvider: async () => {
        if (await ifMagentoVersion(">=", "2.3.4")) {
          return fetchCategoryList(client, categoryId, locale, "cache-only");
        }
        return fetchCategoryTree(client, categoryId, locale, "cache-only");
      },
      didFetchFromLocalCache: remoteCategoryTree => {
        if (remoteCategoryTree) {
          dispatch({
            type: "UpdateCategoryTree",
            remoteCategoryTree,
            locale,
          });
          setCategoryTree(remoteCategoryTree.id, remoteCategoryTree, locale);
        }
      },
      remoteResourcesProvider: async () => {
        if (await ifMagentoVersion(">=", "2.3.4")) {
          return fetchCategoryList(client, categoryId, locale, "network-only");
        }
        return fetchCategoryTree(client, categoryId, locale, "network-only");
      },
      didFetchFromRemote: remoteCategoryTree => {
        if (remoteCategoryTree) {
          dispatch({
            type: "UpdateCategoryTree",
            remoteCategoryTree,
            locale,
          });
          setCategoryTree(remoteCategoryTree.id, remoteCategoryTree, locale);
        }
      },
    },
    [locale, ifMagentoVersion]
  );

  useEffect(() => {
    startRequesting();
  }, [startRequesting]);

  return requestState;
}

export function useFetchCategoryDescription(
  categoryId: number
): [
  ResourcesRequestState<string | null>,
  () => Promise<string | null>,
  () => Promise<string | null>
] {
  const client = useApolloClient();
  const { state, dispatch } = useContext(RepositoryContext);
  const { locale } = useIntl();
  const { ifMagentoVersion } = useContext(MagentoVersionContext);

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    string | null,
    () => Promise<string | null>
  >({
    memoryCacheProvider: async () => {
      if (state && state.remoteCategoryTreeMap) {
        const category = state.remoteCategoryTreeMap[locale][categoryId];
        if (category && category.description) {
          return category.description;
        }
      }
      return null;
    },
    localCacheProvider: async () => {
      const description = await (async () => {
        if (await ifMagentoVersion(">=", "2.3.4")) {
          return fetchCategoryDescriptionFromCategoryListByCategoryId(
            client,
            categoryId,
            locale,
            "cache-only"
          );
        }
        return fetchCategoryDescriptionByCategoryId(
          client,
          categoryId,
          locale,
          "cache-only"
        );
      })();
      dispatch({
        type: "UpdateCategoryDescription",
        id: categoryId,
        description,
        locale,
      });
      return description;
    },
    remoteResourcesProvider: async () => {
      const description = await (async () => {
        if (await ifMagentoVersion(">=", "2.3.4")) {
          return fetchCategoryDescriptionFromCategoryListByCategoryId(
            client,
            categoryId,
            locale,
            "network-only"
          );
        }
        return fetchCategoryDescriptionByCategoryId(
          client,
          categoryId,
          locale,
          "network-only"
        );
      })();
      dispatch({
        type: "UpdateCategoryDescription",
        id: categoryId,
        description,
        locale,
      });
      return description;
    },
  });

  return [requestState, fetch, refresh];
}

export function useCategoryTreeMap() {
  const { state } = useContext(RepositoryContext);
  const { locale } = useIntl();
  return state.categoryTreeMap[locale] || {};
}

export function useAllCategory(): Category {
  const { locale } = useIntl();
  const allCategory = useMemo(() => makeAllCategory(locale), [locale]);
  return allCategory;
}

export function useChildrenCategories(
  categoryTree: CategoryTree | null,
  categoryTreeMap: IndexMap<string, CategoryTree>
) {
  return useMemo(() => {
    if (!categoryTree || !categoryTreeMap) {
      return [];
    }
    return computeChildenCategories(categoryTree, categoryTreeMap);
  }, [categoryTree, categoryTreeMap]);
}

export function useMainCategoryCount(categoryId: number) {
  const categoryTreeMap = useCategoryTreeMap();

  const categoryTree = useMemo(() => {
    const defaultCategoryTree = categoryTreeMap[categoryId];
    if (!defaultCategoryTree) {
      return null;
    }
    return defaultCategoryTree;
  }, [categoryId, categoryTreeMap]);

  const childrenCategories = useChildrenCategories(
    categoryTree,
    categoryTreeMap
  );

  return childrenCategories.length;
}
