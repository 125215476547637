import { ApolloClient, gql, FetchPolicy } from "apollo-boost";
import moment from "moment-timezone";

import { Locale, getStoreViewCodeForLocale } from "../../i18n/locale";

import {
  OS,
  Token,
  Isdn,
  NotificationEnableState,
} from "../../models/OPNSPushNotification";

import Config from "../../Config";

export async function getUnreadMessageCount(
  client: ApolloClient<any>,
  locale: Locale,
  os: OS,
  token: Token,
  isdn: Isdn,
  sendFromDate: Date | null,
  fetchPolicy: FetchPolicy
): Promise<number> {
  const sendFromMoment = sendFromDate ? moment(sendFromDate) : null;
  const sendFrom = sendFromMoment
    ? moment(sendFromMoment)
        .tz(Config.TIMEZONE)
        .format("YYYYMMDD")
    : null;

  const result = await client.query<{
    queryMessage: {
      msgs:
        | {
            sendDateString: string | null; // format: YYYYMMDDHHMM
          }[]
        | null;
    };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryMessages(
        $token: String!
        $messageType: String!
        $isdn: String!
        $os: String!
        $markRead: String!
        $sendFrom: String
      ) {
        queryMessage(
          accessToken: $token
          messageType: $messageType
          isdn: $isdn
          os: $os
          markRead: $markRead
          sendFrom: $sendFrom
        ) {
          msgs {
            sendDateString: sendDate
          }
        }
      }
    `,
    variables: {
      token,
      isdn,
      os,
      messageType: "INBOX",
      markRead: "N",
      sendFrom,
    },
    fetchPolicy,
  });

  const newMessages: { sendDateString: string | null }[] = result.data
    .queryMessage.msgs
    ? result.data.queryMessage.msgs.filter(({ sendDateString }) => {
        if (!sendFromMoment) {
          return true;
        }
        if (!sendDateString) {
          return false;
        }
        const sendMoment = moment.tz(
          sendDateString,
          "YYYYMMDDHHmm",
          Config.TIMEZONE
        );
        return sendMoment.isAfter(sendFromMoment);
      })
    : [];

  return newMessages.length;
}

export async function getOrderNotification(
  client: ApolloClient<any>,
  locale: Locale
): Promise<NotificationEnableState> {
  const result = await client.query<{
    getOrderNotification: { isEnabled: NotificationEnableState };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query GetOrderNotification {
        getOrderNotification {
          isEnabled
        }
      }
    `,
    fetchPolicy: "network-only",
  });

  return result.data.getOrderNotification.isEnabled;
}

export async function getPromotionNotification(
  client: ApolloClient<any>,
  locale: Locale
): Promise<NotificationEnableState> {
  const result = await client.query<{
    getPromotionNotification: { isEnabled: NotificationEnableState };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query GetPromotionNotification {
        getPromotionNotification {
          isEnabled
        }
      }
    `,
    fetchPolicy: "network-only",
  });

  return result.data.getPromotionNotification.isEnabled;
}
