import React from "react";
import styles from "./styles.module.scss";
import { LocalizedText } from "../../i18n/Localization";
import { IonModal } from "@ionic/react";

interface CSRLoadingModalProps {
  isOpen: boolean;
  showText?: boolean;
  showProgressBar?: boolean;
  loadingPercentage?: number;
  onDidDismiss?: () => void;
}

const CSRLoadingModal: React.FC<CSRLoadingModalProps> = ({
  isOpen,
  showText,
  showProgressBar,
  loadingPercentage,
  onDidDismiss,
}) => {
  return (
    <IonModal
      keyboardClose={true}
      backdropDismiss={true}
      isOpen={isOpen}
      onDidDismiss={() => onDidDismiss}
      cssClass={styles["csr-progress-bar"]}
    >
      <img src="./assets/images/map-loading.gif" />
      { showProgressBar ? <div className={styles["csr-progress-bar__bar"]}>
        { loadingPercentage !== undefined ? 
        <>
          <span>{(loadingPercentage*100).toFixed(0)}%</span>
          <div className={styles["csr-progress-bar__bar__inner"]} style={{ width: `calc(${loadingPercentage*100}% - 3px)` }} />
        </> : null
        }
      </div> : null }
      { showText ? <h5 className={styles["csr-progress-bar__text"]}>
        <LocalizedText messageID="all_zones.loading" />
      </h5>
      : null 
      }
    </IonModal>
  );
};
export default CSRLoadingModal;
