import * as yup from "yup";

export type ArticleFilter =
  | "archive"
  | "author"
  | "category"
  | "search"
  | "tag";

export interface ArticleCategory {
  categoryId: string;
  title: string;
}

export interface ArticlePreview {
  postId: string;
  categories: ArticleCategory[];
  featuredImage: string | null;
  firstImage: string | null;
  title: string;
  shortFilteredContent: string | null;
  publishTime: Date;
}

export const ArticleCategorySchema = yup
  .object<ArticleCategory>({
    categoryId: yup.string().required(),
    title: yup.string().required(),
  })
  .camelCase();

export interface ArticleTag {
  tagId: string;
  title: string;
}

export const ArticleTagSchema = yup
  .object<ArticleTag>({
    tagId: yup.string().required(),
    title: yup.string().required(),
  })
  .camelCase();

export interface ArticleAuthor {
  authorId: string;
  firstname: string;
  lastname: string;
}

export const ArticleAuthorSchema = yup
  .object<ArticleAuthor>({
    authorId: yup.string().required(),
    firstname: yup.string().required(),
    lastname: yup.string().required(),
  })
  .camelCase();

export const ArticlePreviewSchema = yup
  .object<ArticlePreview>({
    postId: yup.string().required(),
    categories: yup.array().of(ArticleCategorySchema),
    featuredImage: yup.string().nullable(),
    firstImage: yup.string().nullable(),
    title: yup.string(),
    shortFilteredContent: yup.string().nullable(),
    publishTime: yup.date(),
  })
  .camelCase();

export interface Article extends ArticlePreview {
  author: ArticleAuthor;
  filteredContent: string;
  postUrl: string;
  tags: ArticleTag[];
}

export const ArticleSchema: yup.Schema<Article> = ArticlePreviewSchema.shape({
  author: ArticleAuthorSchema.required(),
  filteredContent: yup.string(),
  postUrl: yup.string(),
  tags: yup.array().of(ArticleTagSchema),
}).camelCase();

export function getAuthorDisplayName(author: ArticleAuthor): string {
  return `${author.firstname} ${author.lastname}`;
}
