import { ResourcesRequestState } from "../models/ResourcesRequestState";
import CSRConfig from "../models/CSRConfig";
import { useContext, useRef, useEffect } from "react";
import { RepositoryContext } from "./State";
import { useFetchResources_v2, useFetchResources } from "./Hooks";
import { fetchCSRConfig, csrRestAPIClient, fetchCSRZones, fetchCSRNGOsByZoneId, loginCSR, fetchCSRNotification, makeCSRNotificationAsRead, fetchCSRReward, fetchCSRNGOByNGOId } from "../api/RESTful";
import { Zone, ZoneName } from "../models/Zone";
import { useParams, useLocation } from "react-router";
import { NGO } from "../models/NGO";
import { EntityID } from "../models/Merchant";
import { useApolloClient } from "react-apollo";
import { useIntl } from "../i18n/Localization";
import { fetchMerchant, fetchMerchants } from "../api/GraphQL";
import CSRUser from "../models/CSRUser";
import { AccessToken } from "@rdlabo/capacitor-facebook-login";
import { NoToneMapping } from "three";
import { Notification as CSRNotification } from "../models/Notification";
import { Reward } from "../models/Reward";

export type IntrospectUserResult = {
  id: number;
  clublikeId: string;
}



export function useFetchCSRConfig(): [
  ResourcesRequestState<CSRConfig | null>,
  () => Promise<CSRConfig | null>
] {
  const { state, dispatch } = useContext(RepositoryContext);

  const [ requestState, {call} ] = useFetchResources_v2<
    CSRConfig| null, 
    () => Promise<CSRConfig | null>
  >({
    memoryCacheProvider: () => Promise.resolve(state.csr.config || null),
    remoteResourcesProvider: async () => {
      const csrConfig = await fetchCSRConfig(csrRestAPIClient);
      if (csrConfig) {
        dispatch({
          type: "UpdateCSRConfigs",
          csrConfig
        })
      }
      return csrConfig;
    },
  });
  
  return [requestState, call]
}


export function useMarkCSRNotificationAsRead(): [
  ResourcesRequestState<void>,
  (messageIds: number[]) => Promise<void>,
] {
  const [requestState, { call }] = useFetchResources_v2<
    void,
    (messageIds: number[]) => Promise<void>
  >({
    remoteResourcesProvider: async (messageIds: number[]) => {
      const result = await makeCSRNotificationAsRead(csrRestAPIClient, messageIds);
    }
  });
  return [requestState, call];
}

export function useFetchCSRUserReward(): [
  ResourcesRequestState<Reward | null>,
  () => Promise<Reward | null>,
] {
  const [ requestState, { call }] = useFetchResources_v2<Reward | null, () => Promise<Reward | null>>({
    // memoryCacheProvider: () => Promise.resolve(state.csr.notifications ? state.csr.notifications : []),
    remoteResourcesProvider: async () => {
      const result = await fetchCSRReward(csrRestAPIClient);
      if (result) {
        return result;
      } else {
        return null;
      }
    }
  });
  return [requestState, call];
}

export function useFetchCSRNotification(): [
  ResourcesRequestState<CSRNotification[]>,
  () => Promise<CSRNotification[]>,
] {
  const { state, dispatch } = useContext(RepositoryContext);
  const [ requestState, { call }] = useFetchResources_v2<CSRNotification[], () => Promise<CSRNotification[]>>({
    memoryCacheProvider: () => Promise.resolve(state.csr.notifications ? state.csr.notifications : []),
    remoteResourcesProvider: async () => {
      const result = await fetchCSRNotification(csrRestAPIClient);
      if (result) {
        dispatch({
          type: "UpdateCSRNotifications",
          notifications: result,
        });
        return result;
      } else {
        return [];
      }
    }
  });
  return [requestState, call];
}

export function useFetchCSRProfile(): [
  ResourcesRequestState<CSRUser | null>,
  () => Promise<CSRUser | null>,
] {
  const { state, dispatch } = useContext(RepositoryContext);
  const [ requestState, { call }] = useFetchResources_v2<CSRUser | null, () => Promise<CSRUser | null>>({
    memoryCacheProvider: () => Promise.resolve(state.csr.user && state.csr.user ? state.csr.user : null),
    remoteResourcesProvider: async () => {
      const result = await loginCSR(csrRestAPIClient);
      if (result) {
        dispatch({
          type: "UpdateCSRUser",
          user: result
        });
        return result;
      } else return null;
    }, 
  });
  return [requestState, call];
}

export function useFetchCSRNGOsByZoneId(): [
  ResourcesRequestState<NGO | null>,
  (zoneId, ngoId) => Promise<NGO | null>,
] {
  const { state, dispatch } = useContext(RepositoryContext);
  const [ requestState, {call} ] = useFetchResources_v2<
    NGO | null, 
    (zoneId: number, ngoId: number) => Promise<NGO |null>
  >({
    remoteResourcesProvider: async (zoneId: number, ngoId: number) => {
      const ngo = await fetchCSRNGOByNGOId(csrRestAPIClient, zoneId, ngoId);
      if (ngo) return ngo;
      else return null;
    },
  });
  return [requestState, call];
}

export function useFetchCSRZones(): [
  ResourcesRequestState<Zone[]>,
  () => Promise<Zone[]>,
] {
  const { state, dispatch } = useContext(RepositoryContext);
  const [ requestState, {call} ] = useFetchResources_v2<
    Zone[], 
    () => Promise<Zone[]>
  >({
    memoryCacheProvider: () => Promise.resolve(state.csr.zones || []),
    remoteResourcesProvider: async () => {
      const csrZones = await fetchCSRZones(csrRestAPIClient);
      if (csrZones) {
        dispatch({
          type: "UpdateCSRZones",
          zones: csrZones,
        })
      }
      return csrZones;
    },
  });
  return [requestState, call];
}

export function useFetchNGOsByZoneId(): [
  ResourcesRequestState<NGO[]>,
  (zoneId: number) => Promise<NGO[]>,
] {
  const { state, dispatch } = useContext(RepositoryContext);
  const cachedNGOs = useRef<NGO[]>([]);
  const apolloClient = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call }] = useFetchResources_v2<
    NGO[],
    (zoneId: number) => Promise<NGO[]>
  >({
    memoryCacheProvider: () => Promise.resolve(cachedNGOs.current),
    remoteResourcesProvider: async (zoneId: number) => {
      const ngos = await fetchCSRNGOsByZoneId(csrRestAPIClient, zoneId);
      if (ngos && ngos.length) {
        cachedNGOs.current = ngos;
        let merchants = await fetchMerchants(
          apolloClient,
          ngos.map(ngo => ngo.clubLikeEntityId),
          locale,
          "network-only",
        );
        
        if (merchants && merchants.length) {
          for (let ngo of ngos ) {
            let merchant = merchants.find(merchant => merchant.clubLikeEntityId == ngo.clubLikeEntityId);
            if (merchant) {
              ngo.name = merchant.name;
              ngo.logo = merchant.logo ? merchant.logo : require("../resources/merchant-placeholder.svg");
              ngo.description = merchant.description;
              ngo.websiteUrl = merchant.websiteUrl;
              ngo.youtubeUrl = merchant.youtubeUrl;
              ngo.donationPurpose = merchant.donationPurpose;
            }
          }
          // dispatch({
          //   type: "UpdateNGODetails",
          //   merchants,
          // });
        }
      }
      return ngos;
    }
  });
  return [requestState, call];
}


export function useSelectedZone() {
  const { state } = useContext(RepositoryContext);
  return state.csr.selectedZone;
}

export function useSelectedNGO() {
  const { state } = useContext(RepositoryContext);
  return state.csr.selectedNGO;
}

export function useSearchZoneByZoneKey(
  zoneKey: ZoneName
) {
  const { state } = useContext(RepositoryContext);
  const { csr: { zones } } = state;
  return zones.find(zone => zone.nameKey == zoneKey);
}

export function useFindZoneByZoneSlug() {
  let { zoneSlug } = useParams();
  const { state, dispatch } = useContext(RepositoryContext);
  const { csr: { zones } } = state;
  
  if (zoneSlug) {
    let zone = zones.find(zone => zone.nameKey == zoneSlug);
    if (zone) {
      // dispatch({
      //   type: "setSelectedZone",
      //   payload: zone,
      // });
    } else return null;
    return zone;
  } else return null
}


export async function useFindNGOByNGOSlug() {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { state, dispatch } = useContext(RepositoryContext);
  const { csr: { zones } } = state;
  let { zoneSlug, ngoSlug } = useParams();
  if (zoneSlug) {
    let zone = zones.find(zone => zone.nameKey == zoneSlug);
    if (zone) {
      if (!zone.ngos || zone.ngos.length === 0) {
        let ngos = await fetchCSRNGOsByZoneId(csrRestAPIClient, zone.id);
        if (ngos) zone.ngos = ngos;
      }
      let ngo = zone.ngos.find(ngo => ngo.clubLikeMerchantId === ngoSlug);
      if (ngo) {
        let merchant = await fetchMerchant(client, ngo.clubLikeMerchantId, locale, "network-only");
        if (merchant) {
          ngo.name = merchant.name;
          ngo.logo = merchant.logo?merchant.logo : require("../resources/merchant-placeholder.svg");
        }
        return ngo;
      } else return null;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function useFetchNGODetails(entity_ids: EntityID[]) {
  const client = useApolloClient();
  const { locale } = useIntl();

  const {
    state: {
      csr: {
        selectedZone
      },
    },
    dispatch,
  } = useContext(RepositoryContext);
  let ngos = selectedZone && selectedZone.ngos ? selectedZone.ngos : null;
  const { requestState, startRequesting } = useFetchResources({
    needStoreConfig: true,
    memoryCacheProvider: () => null,
    localCacheProvider: () => Promise.resolve(null),
    didFetchFromLocalCache: () => null,
    remoteResourcesProvider: () =>
      fetchMerchants(client, entity_ids, locale, "network-only"),
    didFetchFromRemote: merchants => {
      if (merchants && merchants.length) {
        dispatch({
          type: "UpdateNGODetails",
          merchants,
        })
      }
    }
  }, [locale]);

  useEffect(() => {
    startRequesting(); 
  }, [startRequesting]);

  return { requestState };

}
