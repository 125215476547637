import pathToRegExp from "path-to-regexp";

import { AppConfig } from "../models/AppConfig";

import { escapeRegExp } from "./String";

export interface DeepLinkRouter<RouteKey extends string> {
  register: (routeKey: RouteKey, path: string) => void;
  match: (url: string) => DeepLinkRouteResult<RouteKey> | null;
}

interface Route<RouteKey extends string> {
  key: RouteKey;
  regexp: RegExp;
  pathToRegExpKeys: pathToRegExp.Key[];
  paramWordsSeparator: string;
  queryParams: { queryParamName: string; renamedName?: string }[];
}

export interface DeepLinkRouteResult<RouteKey extends string> {
  routeKey: RouteKey;
  param: any;
  queryParam: any;
}

export class CLDeepLinkRouter<RouteKey extends string>
  implements DeepLinkRouter<RouteKey> {
  private routes: Route<RouteKey>[] = [];

  register = (
    routeKey: RouteKey,
    path: string,
    {
      paramWordsSeparator = " ",
      queryParams = [],
    }: {
      paramWordsSeparator?: string;
      queryParams?: { queryParamName: string; renamedName?: string }[];
    } = {}
  ) => {
    const pathToRegExpKeys: pathToRegExp.Key[] = [];
    const regexp = pathToRegExp(path, pathToRegExpKeys);
    this.routes.push({
      key: routeKey,
      regexp,
      pathToRegExpKeys,
      paramWordsSeparator,
      queryParams,
    });
  };

  match = (urlString: string): DeepLinkRouteResult<RouteKey> | null => {
    const url = new URL(urlString);
    const path = url.pathname;
    for (const route of this.routes) {
      const result = route.regexp.exec(path);
      if (result == null) {
        continue;
      }
      const {
        key: routeKey,
        pathToRegExpKeys,
        paramWordsSeparator,
        queryParams,
      } = route;
      const param: any = {};
      // Note:
      // result[0] is the matched url
      // param results are started at index 1

      const decodeParamValue = (rawValue: string): string => {
        return decodeURIComponent(rawValue).replace(
          new RegExp(escapeRegExp(paramWordsSeparator), "g"),
          " "
        );
      };

      for (let i = 1; i < result.length; ++i) {
        const paramName = pathToRegExpKeys[i - 1].name;
        const paramValue = decodeParamValue(result[i]);
        param[paramName] = paramValue;
      }

      const queryParam_: any = {};
      for (const queryParam of queryParams) {
        const value = url.searchParams.get(queryParam.queryParamName);
        const name =
          queryParam.renamedName != null
            ? queryParam.renamedName
            : queryParam.queryParamName;
        queryParam_[name] = value != null ? decodeParamValue(value) : null;
      }

      return {
        routeKey,
        param,
        queryParam: queryParam_,
      };
    }
    return null;
  };
}

export type CSRDeepLinkRouteKey =
  | "home"
  | "town"
  //| "product"
  | "email-verification"
  //| "search"
  | "my-account"
  | "my-order"
  //| "likes-tab"
  //| "delivery-info"
  //| "edit-profile"
  //| "merchant"
  //| "change-email-verification";

export type CLDeepLinkRouteKey =
  | "home"
  | "product"
  | "email-verification"
  | "search"
  | "my-account"
  | "my-order"
  | "likes-tab"
  | "delivery-info"
  | "edit-profile"
  | "merchant"
  | "change-email-verification";

export function makeClublikeDeepLinkRouter(
  appConfig: AppConfig
): DeepLinkRouter<CLDeepLinkRouteKey> {
  const router = new CLDeepLinkRouter<CLDeepLinkRouteKey>();
  router.register("home", "/town");
  router.register("product", "/catalog/product/view/id/:productID/s/:urlKey");
  router.register("email-verification", "/customer/account/confirm", {
    queryParams: [{ queryParamName: "id" }, { queryParamName: "key" }],
  });
  router.register("search", "/search/:searchTerm", {
    paramWordsSeparator: "+",
  });
  router.register("search", "/catalogsearch/result", {
    paramWordsSeparator: "+",
    queryParams: [{ queryParamName: "q", renamedName: "searchTerm" }],
  });
  router.register("my-account", "/customer/account");
  router.register("my-order", "/sales/order/history");
  router.register("likes-tab", "/wishlist");
  router.register("delivery-info", "/customer/address/new");
  router.register("edit-profile", "/customer/account/edit");
  router.register("edit-profile", "/newsletter/manage");
  router.register("merchant", `/${appConfig.vendorPageUrlKey}/:venderId`);
  router.register("change-email-verification", "/account/email/confirm", {
    queryParams: [{ queryParamName: "id" }, { queryParamName: "key" }],
  });
  return router;
}

export function makeCSRDeepLinkRouter(
  appConfig: AppConfig
): DeepLinkRouter<CSRDeepLinkRouteKey> {
  const router = new CLDeepLinkRouter<CSRDeepLinkRouteKey>();
  router.register("home", "/town");
  router.register("town", "/town");
  router.register("my-account", "/profile");
  router.register("my-order", "/my-orders");
  router.register("email-verification", "/customer/account/confirm", {
    queryParams: [
      { queryParamName: "id" }, 
      { queryParamName: "key" },
      { queryParamName: "backUri" },
    ],
  });

  return router;
}
