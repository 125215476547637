import * as React from "react";
import * as Sentry from "sentry-cordova";
import { IonApp, IonPage } from "@ionic/react";
import CLContent from "./CLContent";

interface State {
  forceReRenderKey: number;
}

class ErrorBoundary extends React.PureComponent<{}, State> {
  state: State = {
    forceReRenderKey: 0,
  };

  componentDidCatch(error: Error, errorInfo: { [key in string]: any }) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
      this.setState(prevState => ({
        forceReRenderKey: prevState.forceReRenderKey + 1,
      }));
    });
  }

  render() {
    if (this.state.forceReRenderKey > 100) {
      return (
        <IonApp>
          <IonPage>
            <CLContent scrollY={false}>
              <h1>Something went wrong...</h1>
            </CLContent>
          </IonPage>
        </IonApp>
      );
    }

    return (
      <React.Fragment key={this.state.forceReRenderKey}>
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default ErrorBoundary;
