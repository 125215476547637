import * as yup from "yup";
import Config from "../Config";
import {
  CMSPageContent,
  parseCMSPageContentFromJson,
} from "../models/cmsBlock";
import {
  PromotionBanner,
  promotionBannerSchema,
} from "../models/PromotionBanner";
import { Locale, getStoreViewCodeForLocale } from "../i18n/locale";
import { TokenStore } from "./TokenStore";
import {
  ArticlePreview,
  ArticlePreviewSchema,
  ArticleCategory,
  ArticleCategorySchema,
  Article,
  ArticleSchema,
  ArticleFilter,
  ArticleAuthor,
  ArticleAuthorSchema,
  ArticleTag,
  ArticleTagSchema,
} from "../models/Article";
import { StoreConfig } from "../models/StoreConfig";
import { WishlistItem } from "../models/Wishlist";
import { NGO } from "../models/NGO";
import { Zone } from "../models/Zone";
import { Notification } from "../models/Notification";
import CSRConfig from "../models/CSRConfig";
import { ServerStatusSchema } from "../models/ServerStatus";

import CSRUser from "../models/CSRUser";
import { Reward } from "../models/Reward";

export type OAuthProvider = "facebook" | "google" | "the-club" | "apple";

export interface LinkSocialAccountResponse {
  success: boolean;
  errorMessage?: string;
}

export interface BannerItem {
  desktop_image_url: string;
  mobile_image_url: string;
  link_url: string;
}
export interface BannerList {
  type: string;
  items: [BannerItem];
}

interface RESTAPIClient {
  get: <T>(path: string, token?: string) => Promise<T>;
  post: <T>(path: string, data: any, token?: string) => Promise<T>;
  put: <T>(path: string, data: any, token?: string) => Promise<T>;
  delete: <T>(path: string, token?: string) => Promise<T>;
}

class CLRESTAPIClient implements RESTAPIClient {
  private endPoint: string;

  constructor(endPoint: string) {
    this.endPoint = endPoint;
  }

  private async handleResponse<T>(response: Response): Promise<T> {
    if (response.status < 200 || response.status >= 300) {
      const error = await response.json();
      throw new Error(error.message);
    }
    return response.json();
  }

  private makeGetRequestHeader(token?: string): Record<string, string> {
    const headers: { [key in string]: string } = {
      accept: "application/json",
    };
    if (token != null) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    return headers;
  }

  private makeRequestHeader(token?: string): Record<string, string> {
    const headers: { [key in string]: string } = {
      accept: "application/json",
      "Content-Type": "application/json",
    };
    if (token != null) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    return headers;
  }

  async get<T>(path: string, token?: string): Promise<T> {
    const response = await fetch(`${this.endPoint}${path}`, {
      headers: this.makeGetRequestHeader(token),
      cache: "no-store",
    });
    return this.handleResponse(response);
  }

  async post<T>(path: string, data: any, token?: string): Promise<T> {
    const response = await fetch(`${this.endPoint}${path}`, {
      method: "POST",
      headers: this.makeRequestHeader(token),
      body: data == null ? undefined : JSON.stringify(data),
    });
    return this.handleResponse(response);
  }

  async put<T>(path: string, data: any, token?: string): Promise<T> {
    const response = await fetch(`${this.endPoint}${path}`, {
      method: "PUT",
      headers: this.makeRequestHeader(token),
      body: data == null ? undefined : JSON.stringify(data),
    });
    return this.handleResponse(response);
  }

  async delete<T>(path: string, token?: string): Promise<T> {
    const response = await fetch(`${this.endPoint}${path}`, {
      method: "DELETE",
      headers: this.makeRequestHeader(token),
    });
    return this.handleResponse(response);
  }
}

class CSRRESTAPIClient implements RESTAPIClient {
  private endPoint: string;
  constructor(endPoint: string) {
    this.endPoint = endPoint;
  }

  private async handleResponse<T>(response: Response): Promise<T> {
    if (response.status < 200 || response.status >= 300) {
      const error = await response.json();
      throw new Error(error.message);
    }
    return response.json();
  }

  private makeGetRequestHeader(token?: string): Record<string, string> {
    const headers: { [key in string]: string } = {
      accept: "application/json",
    };
    if (token) {
      headers["token"] = token;
    }
    return headers;
  }

  private makeRequestHeader(token?: string): Record<string, string> {
    const headers: { [key in string]: string } = {
      accept: "application/json",
      "Content-Type": "application/json",
    };
    if (token) {
      headers["token"] = token;
    }
    return headers;
  }

  async get<T>(path: string, token?: string): Promise<T> {
    const response = await fetch(`${this.endPoint}${path}`, {
      headers: this.makeGetRequestHeader(token),
      cache: "no-store",
    });
    return this.handleResponse(response);
  }

  async post<T>(path: string, data: any, token?: string): Promise<T> {
    const response = await fetch(`${this.endPoint}${path}`, {
      method: "POST",
      //mode: 'cors', // fix cross domain issue
      //headers: this.makeRequestHeader(token),
      headers: this.makeRequestHeader(token),
      body: data == null ? undefined : JSON.stringify(data),
    });
    return this.handleResponse(response);
  }

  async put<T>(path: string, data: any, token?: string): Promise<T> {
    const response = await fetch(`${this.endPoint}${path}`, {
      method: "PUT",
      headers: this.makeRequestHeader(token),
      body: data == null ? undefined : JSON.stringify(data),
    });
    return this.handleResponse(response);
  }

  async delete<T>(path: string, token?: string): Promise<T> {
    const response = await fetch(`${this.endPoint}${path}`, {
      method: "DELETE",
      headers: this.makeRequestHeader(token),
    });
    return this.handleResponse(response);
  }
}

export const restAPIClient = new CLRESTAPIClient(Config.RESTAPI_ENDPOINT);
export const csrRestAPIClient = new CSRRESTAPIClient(Config.CSR_RESTAPI_ENDPOINT);

export async function fetchCSRConfig(
  client: CSRRESTAPIClient,
): Promise<CSRConfig | null> {
  let response = await client.get<any>(
    `/base/system/config`,
  ).catch((err) => {
    console.log(`Error in getting CSR config`)
    console.log(err);
  });
  return response;
}


export async function fetchCSRZones(
  client: CSRRESTAPIClient,
): Promise<Zone[]> {
  let response = await client.get<any>(
    `/base/zones`
  ).catch(err => console.log);
  if (response && response.zones) {
    const zones = response.zones;
    for(let zone of zones) {
      zone.ngos = await fetchCSRNGOsByZoneId(client, zone.id);
    }
    return zones;
    //return yup.array(ZoneSchema).validate(response.zones);
  } else {
    return [];
  }
}

export async function fetchCSRNGOsByZoneId(
  client: CSRRESTAPIClient,
  zoneId: number,
): Promise<NGO[]> {
  let response = await client.get<any>(
    `/base/zone/${zoneId}/ngos`
  ).catch(err => console.log);
  if (response && response.ngos) {
    return response.ngos;
  } else {
    return [];
  }
}

export async function fetchCSRNGOByNGOId(
  client: CSRRESTAPIClient,
  zoneId: number,
  ngoId: number
): Promise<NGO | null> {
  let response = await client.get<any>(
    `/base/zone/${zoneId}/ngo/${ngoId}`
  ).catch(err => console.log);
  if (response) {
    return response;
  } else {
    return null;
  }
}

export async function fetchPromotionBannersForProduct(
  client: RESTAPIClient,
  productId: number,
  locale: Locale
): Promise<PromotionBanner[]> {
  const response = await client.get<any>(
    `/rest/${getStoreViewCodeForLocale(
      locale
    )}/V1/product/promotion_banner/${productId}`
  );
  return yup.array(promotionBannerSchema).validate(response);
}

export async function makeCSRNotificationAsRead(
  client: CSRRESTAPIClient,
  messageId: number[],
): Promise<{notificationIds: number[]}> {
  if (!TokenStore.accessToken) {
    throw new Error("unauthorized");
  }
  try {
    const result = await client.post<any>(
      `/notification/readInappMails	`,
      {
        notificationIds: messageId,
      },
      TokenStore.accessToken
    );
    return result.notificationIds;
  } catch(err) {
    throw new Error("unauthorized");
  };
}

export async function fetchCSRReward(
  client: CSRRESTAPIClient,
): Promise<Reward | null> {
  if (!TokenStore.accessToken) {
    throw new Error("unauthorized");
  }
  try {
    const result = await client.get<Reward>(
      `/reward/getUserRewards`,
      TokenStore.accessToken
    );
    if (result) return result;
    else return null;
  } catch (err) {
    throw new Error(err);
  }
}

export async function fetchCSRNotification(
  client: CSRRESTAPIClient,
): Promise<Notification[]> {
  if (!TokenStore.accessToken) {
    throw new Error("unauthorized");
  }
  try {
    const result = await client.get<{ notifications: Notification[] }>(
      `/notification/getUnreadInappMails`,
      TokenStore.accessToken
    );
    return result.notifications;
  } catch(err) {
    throw new Error("unauthorized");
  };
}

export async function loginCSR(
  client: CSRRESTAPIClient,
): Promise<CSRUser | null> {
  if (!TokenStore.accessToken) {
    throw new Error("unauthorized");
  }
  try {
    const result = await client.post<CSRUser>(
      `/user/login`,
      null,
      TokenStore.accessToken
    );
    return result;
  } catch(err) {
    throw new Error("unauthorized");
  };
}

export async function loginWithOAuth(
  client: RESTAPIClient,
  locale: Locale,
  oauthAccessToken: string,
  provider: OAuthProvider
): Promise<string | null> {
  const result = await client.post<{ token: string }>(
    `/rest/${getStoreViewCodeForLocale(
      locale
    )}/V1/oauth/integration/customer/token`,
    {
      provider,
      accessToken: oauthAccessToken,
      //subscribeMarketing: false,
    }
  );
  return result.token;
}

export async function signupWithOAuth(
  client: RESTAPIClient,
  locale: Locale,
  oauthAccessToken: string,
  provider: OAuthProvider
): Promise<string | null> {
  try {
    const result = await client.post<{ id: string }>(
      `/rest/${getStoreViewCodeForLocale(locale)}/V1/oauth/customer`,
      {
        provider,
        accessToken: oauthAccessToken,
        subscribeMarketing: false,
      }
    );
    return result.id;
  } catch (e) {
    if (e.message === "Email already in used") {
      throw Error("email-already-in-use");
    }
    throw e;
  }
}

export async function resetPassword(
  client: RESTAPIClient,
  email: string,
  locale: Locale
): Promise<void> {
  const storeViewCode = getStoreViewCodeForLocale(locale);
  return client.put<any>(`/rest/${storeViewCode}/V1/customers/password`, {
    email,
    template: "email_reset",
    websiteId: 1,
  });
}

export async function fetchHomePageContent(
  client: RESTAPIClient,
  locale: Locale
): Promise<CMSPageContent> {
  const response = await client.get<any>(
    `/cmsIndexApi/index/index/?store_view=${getStoreViewCodeForLocale(locale)}`
  );
  const cmsPageContent = parseCMSPageContentFromJson(response);
  if (cmsPageContent == null) {
    throw new Error("Cannot parse cms content");
  }
  return cmsPageContent;
}

const ArticleListResponseSchema = yup
  .object<{
    posts: ArticlePreview[];
    currentPage: number;
    lastPage: number;
  }>({
    posts: yup.array().of(ArticlePreviewSchema),
    currentPage: yup.number(),
    lastPage: yup.number(),
  })
  .camelCase();

export async function fetchArticleList(
  client: RESTAPIClient,
  query: {
    type: ArticleFilter;
    id: string;
  } | null,
  page: number,
  storeConfig: StoreConfig
): Promise<{
  articles: ArticlePreview[];
  currentPage: number;
  lastPage: number;
}> {
  // TODO:
  // type and term are required fields but we don't need them for querying
  // latest article list
  // refs: https://github.com/magefan/module-blog/blob/3bb5759e80481305a0447af2c6afcda6d9d0238d/Model/PostManagement.php
  const type = query ? query.type : "all";
  const term = query ? query.id : "all";
  const limit = 20;
  const response = await client
    .get<any>(
      `/rest/V1/blog/post/list/${type}/${term}/${storeConfig.id}/${page}/${limit}?storeId=${storeConfig.id}`
    )
    .then(JSON.parse)
    .then(v => ArticleListResponseSchema.validate(v));
  return {
    articles: response.posts,
    currentPage: response.currentPage,
    lastPage: response.lastPage,
  };
}

export async function fetchArticleByID(
  client: RESTAPIClient,
  articleID: string,
  storeConfig: StoreConfig
): Promise<Article | null> {
  return client
    .get<any>(
      `/rest/V1/blog/post/view/${articleID}/${storeConfig.id}?storeId=${storeConfig.id}`
    )
    .then(JSON.parse)
    .then(v => ArticleSchema.validate(v))
    .catch(() => null);
}

export async function fetchAuthorByID(
  client: RESTAPIClient,
  authorID: string
): Promise<ArticleAuthor | null> {
  return client
    .get<any>(`/rest/V1/blog/author/view/${authorID}`)
    .then(JSON.parse)
    .then(v => ArticleAuthorSchema.validate(v))
    .catch(() => null);
}

export async function fetchAllArticleTagsByIDs(
  client: RESTAPIClient,
  tagIds: string[]
): Promise<ArticleTag[] | null> {
  return Promise.all(
    tagIds.map(tagId => {
      return client
        .get<any>(`/rest/V1/blog/tag/view/${tagId}`)
        .then(JSON.parse)
        .then(v => ArticleTagSchema.validate(v))
        .catch(() => null);
    })
  )
    .then(tags => {
      if (tags.some(tag => tag == null)) return null;
      return tags as ArticleTag[];
    })
    .catch(() => null);
}

const ArticleCategoryListResponseSchema = yup
  .object<{
    categories: ArticleCategory[];
    currentPage: number;
    lastPage: number;
  }>({
    categories: yup.array().of(ArticleCategorySchema),
    currentPage: yup.number(),
    lastPage: yup.number(),
  })
  .camelCase();

export async function fetchAllArticleCategories(
  client: RESTAPIClient,
  storeConfig: StoreConfig
): Promise<ArticleCategory[]> {
  const categories: ArticleCategory[] = [];
  const fetchArticleCategories = async (page: number) => {
    return client
      .get<any>(
        `/rest/V1/blog/category/list/all/all/${storeConfig.id}/${page}/50?storeId=${storeConfig.id}`
      )
      .then(JSON.parse)
      .then(v => ArticleCategoryListResponseSchema.validate(v));
  };
  // Iterate at most 1000 times.
  // It should be enough in reality.
  for (let i = 0; i < 1000; ++i) {
    // eslint-disable-next-line no-await-in-loop
    const result = await fetchArticleCategories(i);
    categories.push(...result.categories);
    if (result.currentPage === result.lastPage) {
      break;
    }
  }
  return categories;
}

export async function linkSocialAccount(
  client: RESTAPIClient,
  locale: Locale,
  oauthAccessToken: string,
  provider: OAuthProvider
): Promise<LinkSocialAccountResponse> {
  if (!TokenStore.accessToken) {
    return { success: false };
  }
  const result = await client.post<boolean | string>(
    `/rest/${getStoreViewCodeForLocale(
      locale
    )}/V1/oauth/integration/customer/linkage`,
    {
      provider,
      accessToken: oauthAccessToken,
      subscribeMarketing: false,
    },
    TokenStore.accessToken
  );
  if (result !== true) {
    return {
      success: false,
      errorMessage: typeof result === "string" ? result : undefined,
    };
  }
  return { success: result };
}

export async function unlinkSocialAccount(
  client: RESTAPIClient,
  locale: Locale,
  provider: OAuthProvider
): Promise<boolean> {
  if (!TokenStore.accessToken) {
    return false;
  }
  const result = await client.delete<boolean>(
    `/rest/${getStoreViewCodeForLocale(
      locale
    )}/V1/oauth/integration/customer/linkage/${provider}`,
    TokenStore.accessToken
  );
  return result;
}

export async function addProductToWishlist(
  client: RESTAPIClient,
  _isLoggedIn: true,
  sku: string
): Promise<boolean> {
  if (!TokenStore.accessToken) {
    throw new Error("unauthorized");
  }
  return client.put<boolean>(
    `/rest/V1/wishlist/${sku}`,
    null,
    TokenStore.accessToken
  );
}

export async function removeItemFromWishlist(
  client: RESTAPIClient,
  _isLoggedIn: true,
  wishlistItem: WishlistItem
): Promise<boolean> {
  if (!TokenStore.accessToken) {
    throw new Error("unauthorized");
  }
  return client.delete<boolean>(
    `/rest/V1/wishlist/${wishlistItem.id}`,
    TokenStore.accessToken
  );
}

export async function fetchMaintenanceStatus(
  client: RESTAPIClient
): Promise<boolean> {
  try {
    const resp = await client.get<any>(`/status.json`);
    const result = await ServerStatusSchema.validate(resp);
    return result.maintenance;
  } catch {
    // Offline / file not available / server not available
    return false;
  }
}

export async function fetchBannerList(
  client: CLRESTAPIClient,
  locale: Locale,
): Promise<BannerList[]> {
  try {
    const res = await client.get<any>(`${Config.CLUBLIKE_CMS_ENDPOINT}${getStoreViewCodeForLocale(
      locale
    )}`);
    return res.items;
  } catch (err) {
    console.log(err);
    return [];
  }
}