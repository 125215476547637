import cn from "classnames";
import React, { useState, useCallback, useContext, useRef, useMemo } from "react";
import { Animation, AnimationBuilder, createAnimation } from "@ionic/core";
import { IonButton, IonToolbar, IonIcon, IonTitle, IonButtons } from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router";

import { PrimaryButton, WhiteButton } from "../Button";
import { NavBar } from "../NavBar";
import CLModal from "../CLModal";
import CLContent from "../CLContent";
import OAuthButtons from "../OAuthButtons";
import { LocalizedText } from "../../i18n/Localization";
import PasswordInputField from "../PasswordInputField";
import SocialSignupTermsAndConditionsModal from "../SocialSignupTermsAndConditionsModal";
import Input from "../Form/Input";
import { isMobile } from '../../utils/Platform';

import { LocalizedAlertContext } from "../LocalizedAlertProvider";

import { OAuthProvider } from "../../api/RESTful";
import { isCustomerInterestSet, Customer } from "../../models/Customer";

import {
  useLoginWithEmailRequest,
  useLoginWithOAuthRequest,
} from "../../repository/AuthRepository";

import {
  useLoginWithTheClub,
  useLoginWithFacebook,
  SSOResponse,
  useLoginWithAppleSignIn,
} from "../../useCase/AuthUseCase";

import {
  RootTab,
  getPathForSelectInterestCategoryPage,
  getPathForForgotPassword,
} from "../../navigation/routes";
import { PresentationContext } from "../../our-navigation";

import { validateEmail } from "../../utils/validation";
import { actionEvent } from "../../utils/GTM";
import { MessageID } from "../../i18n/translations/type";

import styles from "./styles.module.scss";
import {
  LoginSignupModalContext,
} from "../LoginSignupModalProvider";
import ClubHopeBanner from "../ClubHopeBanner";
import CSRGlassModal from "../CSRGlassModal";
import { useIsMountedRef } from "../../hook/utils";

const EnterAnimation = (baseEl: any) => {
  const blackdropAnimation = createAnimation()
    .addElement(baseEl.querySelector("ion-backdrop"))
    .fromTo("opacity", 0.01, 0.4);

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelector(".modal-wrapper"))
    .beforeStyles({
      opacity: 0,
    })
    .fromTo("opacity", 0, 1);

  const baseAnimation = createAnimation()
    .addElement(baseEl)
    .easing("ease-in")
    .duration(250)
    .beforeAddClass("show-modal")
    .addAnimation([blackdropAnimation, wrapperAnimation]);
  return baseAnimation;
}


const LeaveAnimation = (baseEl:any) => {
  return EnterAnimation(baseEl).direction("reverse");
}


type LoginModalProps = RouteComponentProps & {
  isModalOpen: boolean;
  onRequestDismiss: () => void;
  onCreateAccountButtonClick: () => void;
  onForgotPaswordButtonClick: () => void;
  onDidLogin: ((customer: Customer) => void) | null;
};


const LoginModal: React.FC<LoginModalProps> = (props: LoginModalProps) => {
  const {
    isModalOpen,
    history,
    onRequestDismiss: onParentRequestDismiss,
    onForgotPaswordButtonClick,
    onDidLogin,
  } = props;
  const isMountedRef = useIsMountedRef();
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const [password, setPasswordValue] = useState("");
  const [email, setEmailValue] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const presentLoginFailAlert = useCallback(
    (errorMessageId: MessageID) => {
      presentLocalizedAlert({
        headerId: "login.login_failed",
        messageId: errorMessageId,
        buttons: [{ textMessageID: "try_again" }],
      });
    },
    [presentLocalizedAlert]
  );
  

  const isEmailValid = validateEmail(email);
  const [isLoading, setIsLoading] = useState(false);
  const onRequestDismiss = useCallback(() => {
    if (isLoading) {
      return;
    }
    if (!isMountedRef.current) return;
    setPasswordValue("");
    setEmailValue("");
    onParentRequestDismiss();
    
  }, [isLoading, setPasswordValue, setEmailValue, onParentRequestDismiss]);
  const onPasswordInputChange = useCallback(
    (value: string) => {
      setPasswordValue(value);
    },
    [setPasswordValue]
  );
  const onEmailInputChange = useCallback(
    (value: string) => {
      setEmailValue(value);
    },
    [setEmailValue]
  );
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const [
    socialSignupTermsModalIsOpen,
    setSocialSignupTermsModalIsOpen,
  ] = useState(false);
  const [ssoLoginResult, setSSOLoginResult] = useState<SSOResponse | undefined>(
    undefined
  );
  const onSocialSignupTermsModalRequestDismiss = useCallback(
    (dismissParent?: boolean) => {
      setSocialSignupTermsModalIsOpen(false);
      setSSOLoginResult(undefined);
      if (dismissParent) {
        onRequestDismiss();
      }
    },
    [setSocialSignupTermsModalIsOpen, onRequestDismiss]
  );
  const login = useCallback(
    async (loginProvider: () => Promise<Customer | null>) => {
      try {
        setIsLoading(true);
        const customer = await loginProvider();
        if (!customer) {
          throw new Error("cannot-get-customer");
        }
        if (!isCustomerInterestSet(customer)) {
          //history.replace(getPathForSelectInterestCategoryPage(RootTab.home));
        }
        onRequestDismiss();
        if (onDidLogin) {
          onDidLogin(customer);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [onRequestDismiss, history, onDidLogin]
  );

  const loginWithEmail = useLoginWithEmailRequest();
  const onLoginButtonClick = useCallback(async () => {
    actionEvent("Login", "Click", "Submit-Btn");
    try {
      await login(() => loginWithEmail(email, password));
      actionEvent("Login", "Click", "Goal");
    } catch {
      presentLoginFailAlert("login.login_failed_text");
    }
  }, [login, loginWithEmail, email, password, presentLoginFailAlert]);
  const onLoginFormSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
    },
    []
  );

  const loginWithOAuth = useLoginWithOAuthRequest();
  const loginWithOAuthToken = useCallback(
    (token: string, provider: OAuthProvider) => {
      return () => {
        return loginWithOAuth(token, provider);
      };
    },
    [loginWithOAuth]
  );

  const onClickSSOLoginButton = useCallback(
    async (loginProvider: () => Promise<SSOResponse>) => {
      try {
        const result = await loginProvider();
        setSSOLoginResult(result);
        await login(loginWithOAuthToken(result.token, result.provider));
      } catch (e) {
        console.log(e);
        if (e.message === "cancelled") {
          return;
        }
        if (e.message === "Customer not exist.") {
          setSocialSignupTermsModalIsOpen(true);
          return;
        }
        presentLoginFailAlert("login.login_with_oauth_failed_text");
      }
    },
    [login, presentLoginFailAlert, loginWithOAuthToken]
  );

  const loginWithTheClub = useLoginWithTheClub();
  const onTheClubButtonClick = useCallback(() => {
    onClickSSOLoginButton(loginWithTheClub);
  }, [onClickSSOLoginButton, loginWithTheClub]);

  const loginWithFacebook = useLoginWithFacebook();
  const onFacebookButtonClick = useCallback(() => {
    onClickSSOLoginButton(loginWithFacebook);
  }, [onClickSSOLoginButton, loginWithFacebook]);

  const loginWithAppleSignIn = useLoginWithAppleSignIn();
  const onAppleSignInButtonClick = useCallback(async () => {
    // TODO: Replace with onClickSSOLoginButton(loginWithAppleSignIn)
    try {
      const result = await loginWithAppleSignIn();
      presentLocalizedAlert({
        message: "Apple SignIn token: " + result.token,
        buttons: [{ textMessageID: "done" }],
      });
    } catch (e) {
      if (e.message === "cancelled") {
        return;
      }
      presentLoginFailAlert("login.login_with_oauth_failed_text");
    }
  }, [loginWithAppleSignIn, presentLocalizedAlert, presentLoginFailAlert]);

  const { present } = useContext(PresentationContext);
  const onClickForgotPassword = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onParentRequestDismiss();
      onForgotPaswordButtonClick();
      //present(getPathForForgotPassword());
    },
    [present, onParentRequestDismiss, onForgotPaswordButtonClick]
  );

  const isProductionEnv = useMemo(() => {
    const { hostname } = window.location;
    return process.env.NODE_ENV == "production" && hostname == "csr.theclub.com.hk";
  }, []);

  return (
    <>
      <CSRGlassModal
        className={cn(styles.modal, styles.shortModal)}
        hideHeader={true}
        isOpen={isModalOpen || true}
        onRequestDismiss={onRequestDismiss}
        backdropDismiss={false}
        enterAnimation={EnterAnimation}
        leaveAnimation={LeaveAnimation}
      >

        <CLContent className={styles.content} scrollY={true} style={{ position: "relative" }}>

        <div
          className={cn(styles.formAnimation)}
          style={{ height: "100%", width: "100%", position: "absolute", top: "0px", left: `${0 + (currentStep%2) * 100}%` }}
        >
          <IonToolbar className={cn(styles.formTitle, styles.formPadding)}>
            <IonTitle className="text-center" color="dark" size={!isMobile() ? "large": "small"} style={{ marginLeft: "-24px", fontWeight: 700 }}>
              <LocalizedText messageID="login.login_with_clubshopping"></LocalizedText>
            </IonTitle>
            <IonButtons slot="start" onClick={() => setCurrentStep(currentStep + 1)}>
              <IonButton>
                <IonIcon src={"/images/arrow-right.svg"} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <form onSubmit={onLoginFormSubmit} className={cn(styles.formPadding)}>
            <p className="text-center"><LocalizedText messageID="login.clubshopping_account_deprecate" /></p>
            <div className={styles.emailLoginContainer}>
                <div className={cn("text-muted", styles.loginTypeTitle)}>
                  <LocalizedText messageID="login.or_login_with_email" />
                </div>
              <Input
                className={cn(
                  styles.emailInput,
                  styles.input,
                  email !== "" && !isEmailValid && styles.error
                )}
                type="email"
                placeholderId="email"
                value={email}
                onChange={onEmailInputChange}
                ref={emailInputRef}
                nextFieldRef={passwordInputRef}
              />
              <PasswordInputField
                className={styles.passwordInput}
                placeholderID="password"
                value={password}
                onChange={onPasswordInputChange}
                ref={passwordInputRef}
              />
            </div>
            <PrimaryButton
              loading={isLoading}
              className={styles.loginButton}
              onClick={onLoginButtonClick}
              disabled={
                password === "" || email === "" || !isEmailValid || isLoading
              }
            >
              <LocalizedText messageID="login" />
            </PrimaryButton>
          </form>
          <div>
            <div
              className={styles.forgotPassword} onClick={onClickForgotPassword}
            >
              <LocalizedText messageID="login.forgot_password" />
            </div>
          </div>
          <div className={styles.signupText}>
            <LocalizedText
              messageID="login.do_not_have_an_account"
              messageArgs={{
                createAccountClassName: styles.createAccountButton,
                onCreateAccountButtonClick: () => {
                  let registerLink = isProductionEnv ? "https://www.theclub.com.hk/zh/signup.html": "https://www-d0.theclub.com.hk/zh/signup.html";
                  window.open(registerLink, "_blank");
                },
              }}
            />
          </div>
        </div>
        <div 
          className={cn(styles.formAnimation)}
          style={{ height: "100%", width: "100%", position: "absolute", top: "0px", left: `${-100+ (currentStep%2) * 100}%` }}
        >
          <div
            className={styles.modalHero}
            style={{
              backgroundImage: isMobile() ? `url(/assets/images/banner/login_app.jpg)`:`url(/assets/images/banner/login_web.jpg)`,
            }}
          ></div>
          <div className={styles.modalBody}>
            <div
              style={{ paddingTop: "20px", paddingBottom: "20px" }}
              className={cn(styles.formPadding)}
            >
              <h4 className={cn("text-center", styles.modalTitle)}>
                <LocalizedText messageID="login_or_register" />
              </h4>
              <OAuthButtons
                disabled={isLoading}
                showPromoText={false}
                onTheClubButtonClick={onTheClubButtonClick}
                onFacebookButtonClick={onFacebookButtonClick}
                onAppleSignInButtonClick={onAppleSignInButtonClick}
              />
            </div>
          </div>
          </div>
        </CLContent>
      </CSRGlassModal>
      <SocialSignupTermsAndConditionsModal
        isModalOpen={socialSignupTermsModalIsOpen}
        onRequestDismiss={onSocialSignupTermsModalRequestDismiss}
        provider={ssoLoginResult ? ssoLoginResult.provider : undefined}
        token={ssoLoginResult ? ssoLoginResult.token : undefined}
      />
      </>
  );
};

export default React.memo(withRouter(LoginModal));
