import React, { useRef } from "react";
import ReactModal from "react-modal";
import styles from "./styles.module.scss";
import { LoadingView } from "../LoadingView";
import ModalContainerWithBackdrop from "../ModalContainerWithBackdrop";
import { IonModal } from "@ionic/react";

const noop = () => {};

interface LoadingModalContext {
  show: () => void;
  hide: () => void;
}

export const LoadingModalContext = React.createContext<LoadingModalContext>({
  show: () => {},
  hide: () => {},
});

const LoadingModalProvider: React.FC = props => {
  const [isOpen, setIsOpen] = React.useState(false);
  const show = React.useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const hide = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const contextValue = React.useMemo(
    () => ({
      show,
      hide,
    }),
    [show, hide]
  );

  return (
    <>
      <LoadingModalContext.Provider value={contextValue}>
        {props.children}
      </LoadingModalContext.Provider>
      <IonModal 
        keyboardClose={true} 
        backdropDismiss={true} 
        isOpen={isOpen} 
        cssClass={styles.modalBackground}
      >
        <img src="./assets/images/map-loading.gif" />
      </IonModal>

      { false &&<ReactModal
        className={styles.modal}
        overlayClassName={styles.overlay}
        isOpen={isOpen}
        shouldCloseOnOverlayClick={false}
        onRequestClose={noop}
      >
        <ModalContainerWithBackdrop className={styles.modalBody} align="center">
          <div className={styles.loadingContainer}>
            <LoadingView />
          </div>
        </ModalContainerWithBackdrop>
      </ReactModal>
      }
    </>
  );
};
export default LoadingModalProvider;