import { ApolloClient, gql, FetchPolicy } from 'apollo-boost';
import { Locale, getStoreViewCodeForLocale } from '../../i18n/locale';
import { MoneyGraphQLAttributes } from '../../models/Price';
import { ProductConfigurableOptionGraphQLAttributes, ProductImageGraphQLAttributes } from '../../models/product';
import {
  SelectedConfigurableOptionGraphQL,
  SelectedCustomizableOptionGraphQL,
  CartDiscountBreakdownGraphQLAttributes,
  CartDiscountsGraphQLAttributes,
} from '../../models/cart';
import {
  getRequestStateError,
  isRequestLoading,
} from "../../models/ResourcesRequestState";
import { IfMagentoVersionFn } from '../../models/MagentoVersion';
import { useFetchResources_v2 } from '../../repository/Hooks';
import { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { GraphQLFn, parseGraphQLError } from '../../api/GraphQL';
import { PartialCart } from './models';
import { CartIDContext, CartIDInjectingFn } from '../CartIDProvider';
import { ShoppingCartItemCountContext } from '../ShoppingCartItemCountProvider';
import { useMagentoVersionFn } from '../../hook/MagentoVersion';

const ProductVariantGrapQLAttributes = `
product {
  id
  sku
  name
  type: type_id
  thumbnail {
    ${ProductImageGraphQLAttributes}
  }
  extraClubpoints: extra_clubpoints
  clClubPoint: cl_clubpoints
  clubPoint: clubpoints
  minClubPoint: min_clubpoints
  priceRange: price_range {
    minimumPrice: minimum_price {
      regularPrice: regular_price {
        ${MoneyGraphQLAttributes}
      }
      finalPrice: final_price {
        ${MoneyGraphQLAttributes}
      }
    }
    maximumPrice: maximum_price {
      regularPrice: regular_price {
        ${MoneyGraphQLAttributes}
      }
      finalPrice: final_price {
        ${MoneyGraphQLAttributes}
      }
    }
  }
  merchant {
    name: store_name
  }
  deliveryMethod: delivery_method
  stockStatus: stock_status
}
attributes {
  label
  value: value_index
  code
}
`;

const ProductGraphQLAttribute = `
id
sku
name
type: type_id
thumbnail {
  ${ProductImageGraphQLAttributes}
}
clubPoint: clubpoints
minClubPoint: min_clubpoints
extraClubpoints: extra_clubpoints
clClubPoint: cl_clubpoints
priceRange: price_range {
  minimumPrice: minimum_price {
    regularPrice: regular_price {
      ${MoneyGraphQLAttributes}
    }
    finalPrice: final_price {
      ${MoneyGraphQLAttributes}
    }
  }
  maximumPrice: maximum_price {
    regularPrice: regular_price {
      ${MoneyGraphQLAttributes}
    }
    finalPrice: final_price {
      ${MoneyGraphQLAttributes}
    }
  }
}
merchant {
  entityId: entity_id			
  name: store_name
  logo
}
stockStatus: stock_status
deliveryMethod: delivery_method
deliveryMethodBlockIdentifier: delivery_method_block_identifier
...on ConfigurableProduct {
  configurableOptions: configurable_options {
    ${ProductConfigurableOptionGraphQLAttributes}
  }
  variants {
    ${ProductVariantGrapQLAttributes}
  }
}
`;

function getCartGraphQL(
  appliedCouponField: "appliedCoupon" | "appliedCoupons",
  discountField: "discounts" | "discountBreakdown"
) {
  return `
items {
  id
  product {
    ${ProductGraphQLAttribute}
  }
  ...on VirtualCartItem {
    isFreeGift: is_free_gift
  }
  ...on SimpleCartItem {
    isFreeGift: is_free_gift
    customizableOptionsForSimpleCartItem: customizable_options {
      ${SelectedCustomizableOptionGraphQL}
    }
  }
  ...on ConfigurableCartItem {
    configurableOptions: configurable_options {
      ${SelectedConfigurableOptionGraphQL}
    }
    customizableOptionsForConfigurableCartItem: customizable_options {
      ${SelectedCustomizableOptionGraphQL}
    }
  }
  quantity
  isRecurring: is_recurring
  recurringOptions: recurring_options {
    label
    value
  }
}
prices {
  grandTotal: grand_total { ${MoneyGraphQLAttributes} }
  subtotalExcludingTax: subtotal_excluding_tax { ${MoneyGraphQLAttributes} }
  subtotalIncludingTax: subtotal_including_tax { ${MoneyGraphQLAttributes} }
  subtotalWithDiscountExcludingTax: subtotal_with_discount_excluding_tax { ${MoneyGraphQLAttributes} }
  discountAmount: discount_amount { ${MoneyGraphQLAttributes} }
  initialSubscriptionFee: initial_subscription_fee { ${MoneyGraphQLAttributes} }
${
  discountField === "discounts"
    ? `
  discounts { ${CartDiscountsGraphQLAttributes} }
`
    : discountField === "discountBreakdown"
    ? `
  discountBreakdown: discount_breakdown { ${CartDiscountBreakdownGraphQLAttributes} }
`
    : ""
}
  shippingAmount: shipping_amount { ${MoneyGraphQLAttributes} }
}
clubPointToBeEarned: clubpoints_to_be_earned
clubPointToBeUsed: clubpoints_to_be_used
clubPointRequired: clubpoints_required
${
  appliedCouponField === "appliedCoupons"
    ? `
appliedCoupons: applied_coupons {
  code
}
`
    : appliedCouponField === "appliedCoupon"
    ? `
appliedCoupon: applied_coupon {
  code
}
`
    : ""
}
`;
}

async function fetchCartAPI(
  ifMagentoVersion: IfMagentoVersionFn,
  client: ApolloClient<any>,
  locale: Locale,
  id: string,
  fetchPolicy: FetchPolicy
): Promise<PartialCart> {
  try {
    const result = await client.query<{ cart: PartialCart }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
      query QueryCart($cart_id: String!) {
        cart(cart_id: $cart_id) {
          ${getCartGraphQL(
            (await ifMagentoVersion(">=", "2.3.4"))
              ? "appliedCoupons"
              : "appliedCoupon",
            (await ifMagentoVersion(">=", "2.3.4"))
              ? "discounts"
              : "discountBreakdown"
          )}
        }
      }
    `,
      variables: {
        cart_id: id,
      },
      fetchPolicy,
    });
    return result.data.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

async function updateCartItemQuantityAPI(
  ifMagentoVersion: IfMagentoVersionFn,
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  itemId: number,
  quantity: number
): Promise<PartialCart> {
  const result = await client.mutate<{ cart: PartialCart }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation UpdateCartItemQuantity($cart_id: String!, $cart_item_id: Int!, $quantity: Float!) {
        updateCartItems(
          input: {
            cart_id: $cart_id,
            cart_items: [{
              cart_item_id: $cart_item_id
              quantity: $quantity
            }]
          }
        ) {
          cart {
            ${getCartGraphQL(
              (await ifMagentoVersion('>=', '2.3.4')) ? 'appliedCoupons' : 'appliedCoupon',
              (await ifMagentoVersion('>=', '2.3.4')) ? 'discounts' : 'discountBreakdown'
            )}
          }
        }
      }
    `,
    variables: {
      cart_id: cartId,
      cart_item_id: itemId,
      quantity,
    },
    fetchPolicy: 'no-cache',
  });
  return result.data.updateCartItems.cart;
}

async function removeCartItemAPI(
  ifMagentoVersion: IfMagentoVersionFn,
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  itemId: number
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{ cart: PartialCart }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
        mutation RemoveCartItem($cart_id: String!, $cart_item_id: Int!) {
          removeItemFromCart(
            input: {
              cart_id: $cart_id,
              cart_item_id: $cart_item_id
            }
          ) {
            cart {
              ${getCartGraphQL(
                (await ifMagentoVersion('>=', '2.3.4')) ? 'appliedCoupons' : 'appliedCoupon',
                (await ifMagentoVersion('>=', '2.3.4')) ? 'discounts' : 'discountBreakdown'
              )}
            }
          }
        }
      `,
      variables: {
        cart_id: cartId,
        cart_item_id: itemId,
      },
      fetchPolicy: 'no-cache',
    });
    return result.data.removeItemFromCart.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

async function removeCouponFromCartAPI(
  ifMagentoVersion: IfMagentoVersionFn,
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      removeCouponFromCart: { cart: PartialCart };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation RemoveCouponFromCart($cartId: String!) {
        removeCouponFromCart(input: { cart_id: $cartId }) {
          cart {
            ${getCartGraphQL(
              (await ifMagentoVersion('>=', '2.3.4')) ? 'appliedCoupons' : 'appliedCoupon',
              (await ifMagentoVersion('>=', '2.3.4')) ? 'discounts' : 'discountBreakdown'
            )}
          }
        }
      }
    `,
      variables: {
        cartId,
      },
    });
    return result.data.removeCouponFromCart.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

async function applyCouponToCartAPI(
  ifMagentoVersion: IfMagentoVersionFn,
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  couponCode: string
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      applyCouponToCart: { cart: PartialCart };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation ApplyCouponToCart($cartId: String!, $couponCode: String!) {
        applyCouponToCart(input: { cart_id: $cartId, coupon_code: $couponCode }) {
          cart {
            ${getCartGraphQL(
              (await ifMagentoVersion('>=', '2.3.4')) ? 'appliedCoupons' : 'appliedCoupon',
              (await ifMagentoVersion('>=', '2.3.4')) ? 'discounts' : 'discountBreakdown'
            )}
          }
        }
      }
    `,
      variables: {
        cartId,
        couponCode,
      },
    });
    return result.data.applyCouponToCart.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError && typeof graphQLError === "string" &&  graphQLError.indexOf("The coupon code isn't valid") > -1) {
      throw new Error(graphQLError); 
    }
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

async function setClubPointOnCartAPI(
  ifMagentoVersion: IfMagentoVersionFn,
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  clubpoint: number
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      setClubPointOnCart: { cart: PartialCart };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation SetClubPointOnCart($cartId: String!, $clubpoint: Int!) {
        setClubPointOnCart(input: { cart_id: $cartId, clubpoints: $clubpoint }) {
          cart {
            ${getCartGraphQL(
              (await ifMagentoVersion('>=', '2.3.4')) ? 'appliedCoupons' : 'appliedCoupon',
              (await ifMagentoVersion('>=', '2.3.4')) ? 'discounts' : 'discountBreakdown'
            )}
          }
        }
      }
    `,
      variables: {
        cartId,
        clubpoint,
      },
    });
    return result.data.setClubPointOnCart.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

interface CartStateFunctions {
  setCart: (v: PartialCart) => void;
  setActionInProgress: (v: boolean) => void;
  setActionError: (v: any) => void;
}

async function applyCartAction<T extends GraphQLFn<PartialCart>>(
  fn: T,
  gracefully: boolean,
  callCartAPI: CartIDInjectingFn,
  callCartAPIGracefully: CartIDInjectingFn,
  stateFns: CartStateFunctions,
  ...args: any
) {
  stateFns.setActionInProgress(true);
  try {
    const cart = await (gracefully ? callCartAPIGracefully(fn, ...args) : callCartAPI(fn, ...args));
    stateFns.setCart(cart);
    return cart;
  } catch (e) {
    if (gracefully) {
      stateFns.setActionError(e);
      return null;
    }
    throw e;
  } finally {
    stateFns.setActionInProgress(false);
  }
}

export function useCartResource() {
  const [cart, setCart] = useState<PartialCart | null>(null);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [setClubPointInProgress, setSetClubPointInProgress] = useState(false);
  const [actionError, setActionError] = useState<Error | null>(null);
  const { cartID, callCartAPI, callCartAPIGracefully } = useContext(CartIDContext);

  const { setCount: setShoppingCartItemCount } = useContext(ShoppingCartItemCountContext);

  const fetchCart = useMagentoVersionFn(fetchCartAPI);
  const removeCartItem = useMagentoVersionFn(removeCartItemAPI);
  const updateCartItemQuantity = useMagentoVersionFn(updateCartItemQuantityAPI);
  const removeCouponFromCart = useMagentoVersionFn(removeCouponFromCartAPI);
  const applyCouponToCart_ = useMagentoVersionFn(applyCouponToCartAPI);
  const setClubPointOnCart_ = useMagentoVersionFn(setClubPointOnCartAPI);

  useEffect(() => {
    if (!cart) {
      setShoppingCartItemCount(0);
      return;
    }
    const { items } = cart;
    let count = 0;
    for (const item of items) {
      count += item.quantity;
    }
    setShoppingCartItemCount(count);
  }, [cart, setShoppingCartItemCount]);

  const stateFns = useMemo(
    () => ({
      setCart,
      setActionInProgress,
      setActionError,
    }),
    [setCart, setActionInProgress, setActionError]
  );

  const setClubPointStateFns = useMemo(
    () => ({
      setCart,
      setActionInProgress: setSetClubPointInProgress,
      setActionError,
    }),
    [setCart, setActionError]
  );

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    PartialCart | null,
    () => Promise<PartialCart | null>
  >({
    memoryCacheProvider: () => Promise.resolve(cart),
    remoteResourcesProvider: async () => {
      const cart = await callCartAPIGracefully(fetchCart, "network-only");
      setCart(cart);
      return cart;
    },
  });
  const fetchCartIsLoading = useMemo(() => isRequestLoading(requestState), [
    requestState,
  ]);
  const fetchCartError = useMemo(() => getRequestStateError(requestState), [
    requestState,
  ]);
  const refreshCart = useCallback(() => {
    refresh().catch(() => {});
  }, [refresh]);
  useEffect(() => {
    fetch().catch(() => {});
  }, [fetch, cartID]); // eslint-disable-line react-hooks/exhaustive-deps
  const removeCartItemWithCartId = useCallback(
    async (itemId: number) => {
      return applyCartAction(
        removeCartItem,
        true,
        callCartAPI,
        callCartAPIGracefully,
        stateFns,
        itemId
      );
    },
    [callCartAPI, callCartAPIGracefully, removeCartItem, stateFns]
  );

  const updateCartItemQuantityWithCartId = useCallback(
    async (itemId: number, quantity: number) => {
      return applyCartAction(
        updateCartItemQuantity,
        true,
        callCartAPI,
        callCartAPIGracefully,
        stateFns,
        itemId,
        quantity
      );
    },
    [callCartAPI, callCartAPIGracefully, updateCartItemQuantity, stateFns]
  );

  const applyCouponToCart = useCallback(
    async (couponCode: string) => {
      const removeRes = await applyCartAction(
        removeCouponFromCart,
        true,
        callCartAPI,
        callCartAPIGracefully,
        stateFns
      );
      if (!couponCode) {
        return removeRes;
      }
      return applyCartAction(
        applyCouponToCart_,
        true,
        callCartAPI,
        callCartAPIGracefully,
        stateFns,
        couponCode
      );
    },
    [
      callCartAPI,
      callCartAPIGracefully,
      removeCouponFromCart,
      applyCouponToCart_,
      stateFns,
    ]
  );

  const setClubPointOnCart = useCallback(
    async (clubPoint: number) => {
      return applyCartAction(
        setClubPointOnCart_,
        true,
        callCartAPI,
        callCartAPIGracefully,
        setClubPointStateFns,
        clubPoint
      );
    },
    [callCartAPI, callCartAPIGracefully, setClubPointOnCart_, setClubPointStateFns]
  );

  const removeCouponCodeFromCart = useCallback(
    async () => {
      const removeRes = await applyCartAction(
        removeCouponFromCart,
        true,
        callCartAPI,
        callCartAPIGracefully,
        stateFns
      );
    }, [removeCouponFromCart, callCartAPI, callCartAPIGracefully, stateFns, applyCartAction]);

  return useMemo(
    () => ({
      cart,
      fetchCartIsLoading,
      fetchCartError,
      refreshCart,
      actionInProgress,
      setClubPointInProgress,
      actionError,
      removeCartItem: removeCartItemWithCartId,
      updateCartItemQuantity: updateCartItemQuantityWithCartId,
      applyCouponToCart,
      setClubPointOnCart,
      removeCouponCodeFromCart,
    }),
    [
      cart,
      fetchCartIsLoading,
      fetchCartError,
      refreshCart,
      actionInProgress,
      setClubPointInProgress,
      actionError,
      removeCartItemWithCartId,
      updateCartItemQuantityWithCartId,
      applyCouponToCart,
      setClubPointOnCart,
      removeCouponCodeFromCart,
    ]);
}
