export type EntityUrlType = "PRODUCT" | "CATEGORY" | "CMS_PAGE" | "SHOP";

export interface EntityUrl {
  id: number;
  // TODO: Confirm "Merchant" type enum
  // We will use "SHOP" for now per meeting with client
  type: EntityUrlType;
  canonicalUrlPath: string;
}

export const EntityUrlGraphQLAttributes = `
  id
  type
  canonicalUrlPath: canonical_url
`;

export interface EntityUrlRedirect extends EntityUrl {
  targetPath: string;
}
