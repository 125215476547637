import { useApolloClient } from "@apollo/react-hooks";
import { useIntl } from "../i18n/Localization";
import { useCallback } from "react";
import { GraphQLFnParams, GraphQLFn } from "../api/GraphQL";

export function useGraphQLFn<T, F extends GraphQLFn<T>>(fn: F) {
  const client = useApolloClient();
  const { locale } = useIntl();
  return useCallback(
    (...args: GraphQLFnParams<F>): ReturnType<F> => {
      return fn(client, locale, ...args) as any;
    },
    [client, locale, fn]
  );
}
