import React, { useState, useMemo } from 'react';
type Visibilities = {
  customerCard?: boolean;
  cart?: boolean;
  language?: boolean;
  info?: boolean;
  notification?: boolean;
  zoneNav?: boolean;   
  gift?: boolean,
}

interface UIVisibilityContext {
  visibilities: Visibilities;
  updateVisibilities: (newVisibilities: Visibilities) => void;
  resetVisibilities: () => void;
}

export const defaultVisbilities: Visibilities = {
  customerCard: true,
  cart: true,
  language: true,
  info: true,
  notification: true,
  zoneNav: true,
  gift: true,
};

export const UIVisibilityContext = React.createContext<UIVisibilityContext>({
  visibilities: defaultVisbilities,
  updateVisibilities: () => {},
  resetVisibilities: () => {},
});

export const UIVisibilityProvider: React.FC = (props) => {
  let [ visibilities, setVisibilities ] = useState(defaultVisbilities);

  const updateVisibilities = (newVisibilities: Visibilities) => {
    setVisibilities(Object.assign({}, visibilities, newVisibilities));
  }
  const resetVisibilities = () => {
    setVisibilities(defaultVisbilities);
  }

  const value = useMemo(() => {
    return {
      visibilities, 
      updateVisibilities,
      resetVisibilities,
    }
  }, [visibilities, setVisibilities]);

  return (
    <UIVisibilityContext.Provider value={value}>
      { props.children }
    </UIVisibilityContext.Provider>
  )
}


