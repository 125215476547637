import React, { useState, useCallback, useMemo } from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { withRouter, RouteComponentProps } from "react-router";
import classnames from "classnames";

import { Category, CategoryTree } from "../../models/category";
import { useChildrenCategories } from "../../repository/CategoryRepository";
import { useUrlRedirectConfig } from "../../repository/ConfigRepository";

import {
  getPathForSingleCategoryPage,
  getCurrentTabForRouteProps,
  RootTab,
} from "../../navigation/routes";
import CLLink from "../navigation/CLLink";
import { LocalizedText } from "../../i18n/Localization";

import { IndexMap } from "../../utils/type";
import findMatchedRedirectMapping from "../../utils/findMatchedRedirectMapping";

import styles from "./styles.module.scss";

interface DetailItemProps {
  category: Category;
  onClick?: (category: Category) => void;
  children: React.ReactNode;
}

const DetailItem = withRouter(
  (props: DetailItemProps & RouteComponentProps) => {
    const { category, onClick, children } = props;
    const tab = getCurrentTabForRouteProps(props) || RootTab.allCategories;

    const urlRedirectConfig = useUrlRedirectConfig();

    const handleClick = useCallback(() => {
      if (onClick) {
        onClick(category);
      }
    }, [category, onClick]);

    const matched = useMemo(
      () =>
        findMatchedRedirectMapping(
          urlRedirectConfig,
          "CATEGORY",
          `${category.id}`
        ),
      [urlRedirectConfig, category]
    );

    const link =
      matched != null
        ? matched.targetPath
        : getPathForSingleCategoryPage(tab, category.id);

    return (
      <>
        <CLLink className={styles.link} to={link} onClick={handleClick}>
          {children}
        </CLLink>
      </>
    );
  }
);

interface TreeProps {
  categoryTree: CategoryTree;
  categoryTreeMap: IndexMap<string, CategoryTree>;
  onDetailItemClick?: (category: Category) => void;
  onExpandCategoryTree: (
    categoryTree: CategoryTree,
    el: HTMLIonItemElement | null
  ) => void;
  expanded: boolean;
}

const Tree: React.FC<TreeProps> = (props: TreeProps) => {
  const {
    categoryTree,
    categoryTreeMap,
    onDetailItemClick,
    expanded,
    onExpandCategoryTree,
  } = props;

  const children = useChildrenCategories(categoryTree, categoryTreeMap);

  const expandable = children.length > 0;

  const expand = useCallback(
    (e: MouseEvent) => {
      if (expandable) {
        onExpandCategoryTree(categoryTree, e.target as HTMLIonItemElement);
      }
    },
    [expandable, categoryTree, onExpandCategoryTree]
  );

  return (
    <>
      {expandable && (
        <IonItem
          className={styles.item}
          button={true}
          //@ts-ignore
          onClick={expand}
          detailIcon={expanded ? "ios-arrow-up" : "ios-arrow-down"}
          mode="ios"
        >
          <IonLabel>{categoryTree.name}</IonLabel>
        </IonItem>
      )}
      {!expandable && (
        <DetailItem category={categoryTree} onClick={onDetailItemClick}>
          <IonItem className={styles.item} button={true} mode="ios">
            <IonLabel>{categoryTree.name}</IonLabel>
          </IonItem>
        </DetailItem>
      )}
      {expandable && expanded && (
        <>
          <DetailItem category={categoryTree} onClick={onDetailItemClick}>
            <IonItem className={styles.subItem} button={true} mode="ios">
              <IonLabel>
                <LocalizedText messageID="category.view_all" />
              </IonLabel>
            </IonItem>
          </DetailItem>
          {children.map((l2tree, i) => {
            if (!l2tree) {
              return null;
            }
            return (
              <DetailItem
                category={l2tree}
                onClick={onDetailItemClick}
                key={l2tree.id}
              >
                <IonItem
                  className={classnames(styles.subItem, {
                    [styles.lastSubItem]: i === children.length - 1,
                  })}
                  button={true}
                  mode="ios"
                >
                  <IonLabel>{l2tree.name}</IonLabel>
                </IonItem>
              </DetailItem>
            );
          })}
        </>
      )}
    </>
  );
};

interface Props {
  categoryTree: CategoryTree;
  categoryTreeMap: IndexMap<string, CategoryTree>;
  onDetailItemClick?: (category: Category) => void;
  onExpandableItemClick?: (el: HTMLIonItemElement) => void;
}

const CategoryTreeList: React.FC<Props> = (props: Props) => {
  const {
    categoryTree,
    categoryTreeMap,
    onDetailItemClick,
    onExpandableItemClick,
  } = props;

  const children = useChildrenCategories(categoryTree, categoryTreeMap);
  const [
    expandedCategoryTree,
    setExpandedCategoryTree,
  ] = useState<CategoryTree | null>(null);

  const handleExpandCategoryTree = useCallback(
    (categoryTree: CategoryTree, el: HTMLIonItemElement | null) => {
      if (expandedCategoryTree === categoryTree) {
        setExpandedCategoryTree(null);
      } else {
        setExpandedCategoryTree(categoryTree);
        if (el && onExpandableItemClick) {
          // Because of collapsing of prev active category tree,
          // defer the notification to get the updated position of el
          setTimeout(() => {
            onExpandableItemClick(el);
          });
        }
      }
    },
    [expandedCategoryTree, onExpandableItemClick]
  );

  return (
    <>
      <DetailItem category={categoryTree} onClick={onDetailItemClick}>
        <IonItem className={styles.item} detail={true} button={true} mode="ios">
          <IonLabel>
            <LocalizedText messageID="category.all" />
          </IonLabel>
        </IonItem>
      </DetailItem>
      {children.map(l2tree => {
        if (!l2tree) {
          return null;
        }
        return (
          <Tree
            categoryTree={l2tree}
            categoryTreeMap={categoryTreeMap}
            key={l2tree.id}
            onDetailItemClick={onDetailItemClick}
            expanded={
              expandedCategoryTree
                ? expandedCategoryTree.id === l2tree.id
                : false
            }
            onExpandCategoryTree={handleExpandCategoryTree}
          />
        );
      })}
    </>
  );
};

export default CategoryTreeList;
