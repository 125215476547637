import { Plugins } from "@capacitor/core";
import { isiOS, isHybrid } from "../utils/Platform";

export function isSignInWithAppleSupported(): boolean {
  return isiOS() && isHybrid();
}

export interface AppleSignInInfo {
  authorizationCode: string;
  identityToken: string;
  userID: string;
}

export async function signIn(): Promise<AppleSignInInfo> {
  if (isSignInWithAppleSupported()) {
    return Plugins.AppleSignInPlugin.signIn();
  }
  throw new Error("apple-signin-not-support");
}
