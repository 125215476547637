import React, { useEffect, useContext, useCallback, useState, useRef } from "react";
import { OurNavContext, PresentationContext } from "../our-navigation";
//import { useFetchCategory } from "../repository/CategoryRepository";
import { getResources } from "../models/ResourcesRequestState";
import { withRouter, RouteComponentProps } from "react-router";
import {
  useGetMyCustomer,
  useIsLoggedIn,
  useRefreshCustomer,
  useCustomer,
  useLogout,
  useCSRUser,
} from "../repository/AuthRepository";
import {
  useFetchCountryRegion,
  useFetchDistricts,
} from "../repository/CountryRegionRepository";
import {
  RootTab,
  getPathForSelectInterestCategoryPage,
} from "../navigation/routes";
import {
  isCustomerInterestSet,
  isCustomerRequireEmailVerificationPrompt,
} from "../models/Customer";
import { getOS, dummyIsdn } from "../models/OPNSPushNotification";
import { withProviders } from "./Provider";
import { WishlistContext } from "./WishlistProvider";
import { useCartResource } from "./ShoppingCartModal/api";
import VerifyEmailModal from "./VerifyEmailModal";
import useRefreshOnAppActive from "../utils/RefreshOnAppActive";
import { MagentoVersionContext } from "./MagentoVersionProvider";
import {
  LoginSignupModalContext,
  LoginSignupModalProvider,
} from "./LoginSignupModalProvider";
import PushNotificationReceiver from "./PushNotificationReceiver";
import { SplashScreenControllerContext } from "./AnimatedSplashScreenIonApp";
import { isiOS, isAndroid } from "../utils/Platform";
import { networkEventEmitter } from "../utils/SimpleEventEmitter";
import { useFetchProductAttributeFilterFields } from "../repository/ProductAttributeFilter";
import { useFetchLiveEvent } from "../repository/ConfigRepository";
import usePresentSessionExpiredAlert from "../hook/usePresentSessionExpiredAlert";
import { useKeepUpdatingRef, useInterval } from "../hook/utils";
import LocationService from "./LocationService";
import {
  useGetUnreadMessageCount,
  useGetOrderNotification,
  useGetPromotionNotification,
} from "./PushNotificationProvider/repository";
import { PushNotificationContext } from "./PushNotificationProvider";
import Config from "../Config";
import { useFetchCSRProfile, useFetchCSRNotification } from "../repository/CsrRepository";
import { NetworkStatusContext } from "./NetworkStatusProvider";

const OnAppLoad: React.FC<RouteComponentProps> = props => {
  const { history } = props;
  //useFetchCategory(Config.DEFAULT_CATEGORY_ID);

  //useFetchCountryRegion();
  //useFetchDistricts();
  
  // Initialize cart item count
  useCartResource();

  const { isOnline } = useContext(NetworkStatusContext);
  const presentationContext = useContext(PresentationContext);
  const { replace } = useContext(OurNavContext);
  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const { ifMagentoVersion } = useContext(MagentoVersionContext);
  const { isAnimationCompleted } = useContext(SplashScreenControllerContext);
  const loginRetryCounterRef = useRef(0);

  const {
    requestState: myCustomerRequestState,
    startRequesting: startRequestingGetMyCustomer,
  } = useGetMyCustomer();
  const customer = useCustomer();
  const csrUser = useCSRUser();
  const [verifyEmailModalIsOpen, setVerifyEmailModalIsOpen] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState<string>("");
  const onVerifyEmailModalDismiss = useCallback(
    () => setVerifyEmailModalIsOpen(false),
    [setVerifyEmailModalIsOpen]
  );

  useEffect(() => {
    startRequestingGetMyCustomer().catch(() => {});
  }, [startRequestingGetMyCustomer]);

  // get CSRProfile
  const [csrProfileResource, fetchCSRProfile] = useFetchCSRProfile();
  const [ csrNotificationResource, fetchCSRNotifications ] = useFetchCSRNotification();

  useEffect(() => {
    const customer = getResources(myCustomerRequestState);
  
    if (myCustomerRequestState.type === "loaded" && customer) {
      if (isCustomerRequireEmailVerificationPrompt(customer)) {
        setVerifyEmailModalIsOpen(true);
        setVerifyEmail(customer.email);
        return;
      }
      if (!isCustomerInterestSet(customer)) {
        //history.push(getPathForSelectInterestCategoryPage(RootTab.home));
      }
    }
  }, [myCustomerRequestState, setVerifyEmailModalIsOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSessionExpiredAlertLoginAgain = useCallback(() => {
    presentLoginModal();
  }, [presentLoginModal]);
  const handleSessionExpiredAlertCancel = useCallback(() => {
    presentationContext.dismiss();
    replace("/town");
  }, [presentationContext, replace]);
  usePresentSessionExpiredAlert(
    handleSessionExpiredAlertLoginAgain,
    handleSessionExpiredAlertCancel
  );
  useEffect(() => {
    const { remove } = networkEventEmitter.subscribe(e => {
      if (e.type === "ResumedFromMaintenance") {
        replace("/town");
      }
    });
    return () => {
      remove();
    };
  }, [replace]);

  const isLoggedIn = useIsLoggedIn();
  const logout = useLogout();
  
  useEffect(() => {
    const csrProfile = getResources(csrProfileResource);
    const csrNotification = getResources(csrNotificationResource);
    if ((csrProfileResource.type === "loaded" && csrUser) && csrNotificationResource.type === "loaded") {
      return;
    }
    const fetchCSR = async () => {
      try {
        if (isLoggedIn && !csrUser && loginRetryCounterRef.current <= 3) {
          await fetchCSRProfile();
          await fetchNotifications();
        }
      } catch(err) {
        loginRetryCounterRef.current++;
        console.log(err);
        //logout();
        //replace("/town");
      }
    }
    fetchCSR();
  }, [isLoggedIn, csrUser, csrProfileResource, fetchCSRProfile, getResources, csrNotificationResource]);
  
  const fetchNotifications = async() => {
    if (isLoggedIn && isOnline) {
      await fetchCSRNotifications();
    }
  }
  useInterval(fetchNotifications, 60*1000);


  const { refreshCustomer } = useRefreshCustomer();
  useRefreshOnAppActive(refreshCustomer);
  const {
    deviceToken,
    setUnreadMessageCount,
    setOrderNotification,
    setPromotionNotification,
    pushNotificationMessagesPageLastRead,
    pushNotificationMessagesPageLastReadInitialized,
  } = useContext(PushNotificationContext);
  const pushNotificationMessagesPageLastReadRef = useKeepUpdatingRef(
    pushNotificationMessagesPageLastRead
  );
  const getUnreadMessageCount = useGetUnreadMessageCount();
  useEffect(() => {
    const os = getOS();
    if (!deviceToken || !os) {
      return;
    }
    if (pushNotificationMessagesPageLastReadInitialized) {
      (async () => {
        const pushNotificationMessagesPageLastRead =
          pushNotificationMessagesPageLastReadRef.current;
        const c = await getUnreadMessageCount(
          os,
          deviceToken,
          dummyIsdn,
          pushNotificationMessagesPageLastRead
        );
        setUnreadMessageCount(c);
      })();
    }
  }, [
    deviceToken,
    isLoggedIn,
    getUnreadMessageCount,
    setUnreadMessageCount,
    pushNotificationMessagesPageLastReadRef,
    pushNotificationMessagesPageLastReadInitialized,
  ]);
  const [getOrderNotification] = useGetOrderNotification();
  const [getPromotionNotification] = useGetPromotionNotification();
  useEffect(() => {
    if (customer) {
      (async () => {
        const orderNotification = await getOrderNotification();
        if (orderNotification != null) {
          setOrderNotification(orderNotification);
        }
      })();
    }
  }, [customer, getOrderNotification, setOrderNotification]);
  useEffect(() => {
    if (customer) {
      (async () => {
        const promotionNotification = await getPromotionNotification();
        if (promotionNotification != null) {
          setPromotionNotification(promotionNotification);
        }
      })();
    }
  }, [customer, getPromotionNotification, setPromotionNotification]);
  const [, fetchLiveEvent, refreshLiveEvent] = useFetchLiveEvent();
  useRefreshOnAppActive(refreshLiveEvent);
  useEffect(() => {
    fetchLiveEvent();
  }, [fetchLiveEvent]);
  const [
    ,
    fetchProductAttributeFilterFields,
  ] = useFetchProductAttributeFilterFields();
  useEffect(() => {
    (async () => {
      if (await ifMagentoVersion(">=", "2.3.4")) {
        fetchProductAttributeFilterFields().catch(() => {});
      }
    })();
  }, [fetchProductAttributeFilterFields, ifMagentoVersion]);
  return (
    <>
      {props.children}
      {Config.USE_PUSH && isAnimationCompleted ? (
        <PushNotificationReceiver />
      ) : null}
      {isiOS() || isAndroid() ? <LocationService /> : null}
      <VerifyEmailModal
        isModalOpen={verifyEmailModalIsOpen}
        initialEmail={verifyEmail}
        onRequestDismiss={onVerifyEmailModalDismiss}
      />
    </>
  );
};
export default withRouter(withProviders(OnAppLoad, LoginSignupModalProvider));
