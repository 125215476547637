import { useCallback } from "react";
import { GooglePlus } from "@ionic-native/google-plus";
import "@codetrix-studio/capacitor-google-auth";
import { Plugins } from "@capacitor/core";

import { presentLoginWithTheClubBrowser } from "../utils/TheClub";
import { presentAppleSignInPopup } from "../utils/AppleSignIn";
import { OAuthProvider } from "../api/RESTful";
import { useIntl } from "../i18n/Localization";
import Config from "../Config";
import { isAndroid } from "../utils/Platform";


const { GoogleAuth, Device } = Plugins;

export interface SSOResponse {
  token: string;
  provider: OAuthProvider;
}

export function useLoginWithTheClub(): () => Promise<SSOResponse> {
  const { locale } = useIntl();
  return useCallback(async () => {
    const result = await presentLoginWithTheClubBrowser(locale);
    if (result.cancelled) {
      throw new Error("cancelled");
    }
    if (result.token == null) {
      throw new Error("cannot-get-token");
    }
    return {
      token: result.token,
      provider: "the-club",
    };
  }, [locale]);
}

export function useLoginWithFacebook(): () => Promise<SSOResponse> {
  return useCallback(async () => {
    // FacebookLogin SDK may store accessToken of a different Facebook user and
    // will cause the login to fail
    try {
      const currentTokenResult = await Plugins.FacebookLogin.getCurrentAccessToken();
      if (currentTokenResult.accessToken) {
        await Plugins.FacebookLogin.logout();
      }
    } catch {}
    try {
      const result = await Plugins.FacebookLogin.login({
        permissions: ["email", "public_profile"],
      });
      const token = result && result.accessToken && result.accessToken.token;
      if (!token || typeof token !== "string") {
        throw new Error("cannot-get-token");
      }
      return {
        token,
        provider: "facebook",
      };
    } catch (e) {
      // WTF: When close the Facebook popup without login, Plugins.FacebookLogin will throw an object with an empty accessToken.token.
      if (e.accessToken && e.accessToken.token === null) {
        throw new Error("cancelled");
      }
      throw e;
    }
  }, []);
}

export function useLoginWithAppleSignIn(): () => Promise<SSOResponse> {
  return useCallback(async () => {
    const result = await presentAppleSignInPopup();
    if (result.cancelled) {
      throw new Error("cancelled");
    }
    if (result.token == null) {
      throw new Error("cannot-get-token");
    }
    return {
      token: result.token,
      provider: "apple",
    };
  }, []);
}
