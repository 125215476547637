import * as yup from "yup";
import moment from "moment";
import { PushNotification } from "@capacitor/core";

import Config from "../Config";
import { isiOS, isAndroid } from "../utils/Platform";

export type OS = "ios" | "android";
export type Platform = "GCM" | "FCM" | "APNS";
export type Token = string;
export type Isdn = string;

export interface PNSResponse {
  code: string;
  description: string;
}

export function getOS(): OS | null {
  if (isiOS()) {
    return "ios";
  }
  if (isAndroid()) {
    return "android";
  }
  return null;
}

export function getPlatform(): Platform | null {
  if (isiOS()) {
    return "APNS";
  }
  if (isAndroid()) {
    return Config.OPNS_ANDROID_PLATFORM || null;
  }
  return null;
}

export const dummyIsdn = "85290000000";

export enum ReadState {
  New = 0,
  Read = 1,
}

export interface MessageExtraDataLink {
  type: "goTo";
  goTo: string;
}

export type MessageExtraData = MessageExtraDataLink;

export type MessageType = "order" | "promotion";

export interface Message {
  id: string;
  topic: string;
  title?: string | null;
  message: string;
  pushStartDate: Date;
  read: ReadState;
  extraData: MessageExtraData | null;
  type: MessageType | null;
}

export interface RemoteMessage {
  id: string;
  topic: string;
  title?: string | null;
  message: string;
  pushStartDate: Date;
  read: ReadState;
  extraData: { goTo?: string; type?: MessageType } | null;
}

export const MessageSchema = yup.object<RemoteMessage>({
  id: yup.string(),
  topic: yup.string(),
  message: yup.string(),
  pushStartDate: yup.date().transform(p => moment(p, "YYYYMMDDHHmm").toDate()),
  read: yup.number(),
  extraData: yup.mixed().transform(p => {
    if (!p) {
      return null;
    }
    try {
      const obj = JSON.parse(p);
      if (obj && obj instanceof Object) {
        return obj;
      }
      return null;
    } catch {
      return null;
    }
  }),
});

export function transformRemoteMessageToMessage(
  remoteMessage: RemoteMessage
): Message {
  const {
    id,
    topic,
    title,
    message,
    pushStartDate,
    read,
    extraData,
  } = remoteMessage;
  return {
    id,
    topic,
    title,
    message,
    pushStartDate,
    read,
    extraData:
      extraData && extraData.goTo != null
        ? { type: "goTo", goTo: extraData.goTo }
        : null,
    type: extraData && extraData.type ? extraData.type : null,
  };
}

export const MessageGraphQLAttributes = `
  id
  topic
  title
  message
  pushStartDate
  extraData
  read
`;

export function getLinkFromOPNSMessage(message: Message): string | null {
  const { extraData } = message;
  if (!extraData) {
    return null;
  }
  switch (extraData.type) {
    case "goTo":
      return extraData.goTo;
    default:
      return null;
  }
}

export function getLinkFromPushNotification(
  notification: PushNotification
): string | null {
  const { data } = notification;
  if (data.goTo) {
    return data.goTo;
  }
  return null;
}

export function getIdFromPushNotification(
  notification: PushNotification
): string | null {
  const { data } = notification;
  if (data.onepnsMsgId) {
    return data.onepnsMsgId;
  }
  return null;
}

export function doesMessageHaveFurtherAction(message: Message): boolean {
  const { extraData } = message;
  if (!extraData) {
    return false;
  }
  return extraData.type === "goTo";
}

export enum NotificationEnableState {
  No = 0,
  Yes = 1,
}
