import * as React from "react";
import {
  LocaleProvider,
  Context as MFContext,
  Components,
  FormattedMessage,
  Values,
} from "@oursky/react-messageformat";
import formatDate from "date-fns/format";

import { Locale } from "./locale";
import enTranslation from "./translations/en";
import zhHantTranslation from "./translations/zhHant";
import { MessageID } from "./translations/type";
import { setDisplayLanguage } from "../storage";
// import { setLocalizedStrings } from "./plugin";
import { OurRouterOutletContext } from "../our-navigation/OurRouterOutlet";
import { withProviders } from "../components/Provider";
import LoadingModalProvider, {
  LoadingModalContext,
} from "../components/LoadingModalProvider";
import { timeout } from "../utils/promise";

export type MessageArgs = Values;

const messageByLocale: {
  [key in Locale]: { [messageID in MessageID]: string };
} = {
  [Locale.en]: enTranslation,
  [Locale.zhHant]: zhHantTranslation,
};

interface LocalizationProviderProps {
  locale: Locale;
}

interface LocaleContext {
  locale: Locale | null;

  // return true if locale did change
  changeLocaleWithLoadingAndDestroyHistory: (
    locale: Locale
  ) => Promise<boolean>;
}

export const LocaleContext = React.createContext<LocaleContext>(null as any);

const LocalizationProvider_: React.FunctionComponent<
  LocalizationProviderProps
> = props => {
  const [locale, setLocale] = React.useState<Locale>(props.locale);
  const { show: showLoading, hide: hideLoading } = React.useContext(
    LoadingModalContext
  );
  const { dangerouslyTriggerRerender } = React.useContext(
    OurRouterOutletContext
  );
  React.useEffect(() => {
    setDisplayLanguage(locale);
  }, [locale]);
  const changeLocaleWithLoadingAndDestroyHistory = React.useCallback(
    async (nextLocale: Locale) => {
      if (nextLocale === locale) {
        return false;
      }

      showLoading();
      await timeout(300);
      const rerenderFinishFn = dangerouslyTriggerRerender();
      await timeout(100);
      setLocale(nextLocale);
      await timeout(100);
      rerenderFinishFn();
      await timeout(100);
      hideLoading();

      return true;
    },
    [locale, showLoading, dangerouslyTriggerRerender, setLocale, hideLoading]
  );
  const contextValue = React.useMemo(
    () => ({
      locale,
      changeLocaleWithLoadingAndDestroyHistory,
    }),
    [locale, changeLocaleWithLoadingAndDestroyHistory]
  );
  const message = messageByLocale[locale];

  // React.useEffect(() => {
  //   setLocalizedStrings(message);
  // }, [message]);

  return (
    <LocaleContext.Provider value={contextValue}>
      <LocaleProvider locale={locale} messageByID={message}>
        {props.children}
      </LocaleProvider>
    </LocaleContext.Provider>
  );
};

export const LocalizationProvider = withProviders(
  LocalizationProvider_,
  LoadingModalProvider
);

interface LocalizedTextProps {
  messageID: MessageID;
  messageArgs?: MessageArgs;
  components?: Components;
}
export const LocalizedText: React.FunctionComponent<
  LocalizedTextProps
> = React.memo(props => {
  const { messageID, messageArgs, components } = props;
  return (
    <FormattedMessage
      id={messageID}
      values={messageArgs}
      components={components}
    />
  );
});
LocalizedText.displayName = "LocalizedText";

interface LocalizedDateTimeText {
  datetime: Date;
  format: { [key in Locale]: string };
}
export const LocalizedDateTime: React.FC<LocalizedDateTimeText> = React.memo(
  props => {
    const { locale } = useIntl();
    const { datetime, format } = props;
    const dateFormat = format[locale];
    return React.useMemo(() => {
      return <>{formatDate(datetime, dateFormat)}</>;
    }, [datetime, dateFormat]);
  }
);

export type TranslateFn = (
  messageID: MessageID,
  messageArgs?: MessageArgs
) => string;

interface Intl {
  locale: Locale;
  translate: TranslateFn;
}

export function useIntl(): Intl {
  const { renderToString } = React.useContext(MFContext);
  const { locale } = React.useContext(LocaleContext);

  return {
    locale: locale || Locale.en,
    translate: React.useCallback(
      (messageID: MessageID, messageArgs?: MessageArgs) => {
        return renderToString(messageID, messageArgs);
      },
      [renderToString]
    ),
  };
}
