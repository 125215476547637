import React, { useReducer, useMemo, useRef, useEffect } from "react";
import { produce } from "immer";
import { Product } from "../models/ProductDetails";
import { ProductOverview } from "../models/ProductOverview";
import { ProductReview, CustomerProductReview } from "../models/ProductReview";
import { ProductLabel, ProductLabelMode } from "../models/ProductLabel";

import { IndexMap } from "../utils/type";

import { AppConfig, LiveEvent } from "../models/AppConfig";
import {
  ArticleCategory,
  Article,
  ArticleAuthor,
  ArticleTag,
} from "../models/Article";
import {
  CategoryTree,
  RemoteCategoryTree,
  flattenCategoryTree,
} from "../models/category";
import { Locale, DataByLocale } from "../i18n/locale";
import { StoreConfig } from "../models/StoreConfig";
import { Customer, RemoteAddress } from "../models/Customer";
import { PromotionBanner } from "../models/PromotionBanner";
import { Cart } from "../models/cart";
import {
  CMSPageContent,
  HTMLBasedCMSPageContent,
  CMS_PAGE_TYPE,
  HTML_BASED_CMS_PAGE_TYPE,
  CMSStaticBlockContent,
} from "../models/cmsBlock";
import { MerchantPreview, Merchant, MerchantID } from "../models/Merchant";
import { SearchTerm } from "../models/Search";
import {
  Country,
  CountryID,
  CountryCode,
  Region,
  RegionID,
  RegionCode,
  District,
  DistrictID,
  DistrictName,
  CountryRegions,
} from "../models/CountryRegion";
import { WishlistItem } from "../models/Wishlist";
import {
  CustomerSubscription,
  CustomerSubscriptionId,
} from "../models/CustomerSubscription";
import { FilterInputField } from "../models/filter";
import { Brand } from "../models/Brand";

import { PartialOrder as OrderDetailsPartialOrder } from "../components/OrderDetailPage/model";

import Config from "../Config";

import PromiseCache from "../utils/PromiseCache";
import { EntityUrlRedirect } from "../models/EntityUrl";
import CSRConfig from "../models/CSRConfig";
import { Zone, ZonePreview } from "../models/Zone";
import { NGO, PartialNGO } from "../models/NGO";
import CSRUser from "../models/CSRUser";
import { Notification as CSRNotification } from "../models/Notification"

export interface PaginationInfo<T> {
  items: T[];
  currentPage: number;
  hasMore: boolean;
}

export interface RepositoryState {
  csr: {
    user: CSRUser | null,
    config: CSRConfig | null,
    zonePreviews: ZonePreview[],
    zones: Zone[],
    selectedZone: Zone | null,
    selectedNGO: NGO | null,
    notifications: CSRNotification[],
  };
  needUpdate:
    | false
    | { type: "force"; forceUpdateLink: string }
    | { type: "recommend"; recommendUpdateLink: string };
  appConfig: DataByLocale<AppConfig> | null;
  liveEvent: DataByLocale<LiveEvent> | null;
  urlRedirect: DataByLocale<EntityUrlRedirect[]> | null;
  storeConfig: DataByLocale<StoreConfig> | null;
  article: {
    articleById: IndexMap<string, Article>;
    categoryById: IndexMap<string, ArticleCategory>;
    authorById: IndexMap<string, ArticleAuthor>;
    tagById: IndexMap<string, ArticleTag>;
  };
  cms: {
    pageContent: {
      [key in CMS_PAGE_TYPE["type"]]: key extends "home"
        ? CMSPageContent | null
        : IndexMap<string | number, CMSPageContent>;
    };
    htmlBasedCMSPageContent: {
      [key in HTML_BASED_CMS_PAGE_TYPE["type"]]: IndexMap<
        string | number,
        HTMLBasedCMSPageContent
      >;
    };
  };
  cart: Cart | null;
  product: {
    overviewBySKU: IndexMap<string, ProductOverview>;
    detailBySKU: IndexMap<string, Product>;
    promotionBannersById: IndexMap<string, PromotionBanner[]>;
    likedProductSKU: IndexMap<string, boolean>;
    wishlistItemByProductSKU: IndexMap<string, WishlistItem>;
    productLabelsByProductId: {
      [key in ProductLabelMode]: IndexMap<number, ProductLabel[]>;
    };
    productReviewsBySKU: IndexMap<string, ProductReview[]>;
  };
  remoteCategoryTreeMap: {
    [key in Locale]: IndexMap<number, RemoteCategoryTree>;
  };
  categoryTreeMap: { [key in Locale]: IndexMap<string, CategoryTree> };
  customer: Customer | null;
  customerProductReviews: CustomerProductReview[];
  isLogoutBySessionExpired: boolean;
  customerAddresses: RemoteAddress[];
  defaultBilling: number | null;
  defaultShipping: number | null;
  merchant: {
    previewById: IndexMap<string, MerchantPreview>;
    detailsById: IndexMap<MerchantID, Merchant>;
  };
  search: {
    recentlySearches: SearchTerm[] | null;
  };
  countries: Country[];
  countryByID: IndexMap<CountryID, Country>;
  countryByCountryCode: IndexMap<CountryCode, Country>;
  regionsByCountryID: IndexMap<CountryID, Region[]>;
  regionByID: IndexMap<RegionID, Region>;
  regionByRegionCode: IndexMap<RegionCode, Region>;
  districts: District[];
  districtsByRegionID: IndexMap<RegionID, District[]>;
  districtByID: IndexMap<DistrictID, District>;
  districtByDistrictName: IndexMap<DistrictName, District>;
  productAttributeFilterInputFields: FilterInputField[];
  productAttributeFilterFields: string[];
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>;
  customerSubscriptions: CustomerSubscription[] | null;
  customerSubscriptionMap: IndexMap<
    CustomerSubscriptionId,
    CustomerSubscription
  >;
  orderDetailsPartialOrderMap: IndexMap<string, OrderDetailsPartialOrder>;
  brands: Brand[] | null;
}

interface RepositoryContextValue {
  withPromiseCache: <T>(
    key: string,
    promiseFactory: () => Promise<T>
  ) => Promise<T>;
  clearPromiseCache: (key: string) => void;

  state: RepositoryState;
  dispatch: React.Dispatch<RepositoryAction>;

  waitForConfigs: (
    locale: Locale
  ) => Promise<{
    storeConfig: StoreConfig;
    appConfig: AppConfig;
  }>;
}

export type RepositoryAction =
  | {
    type: "UpdateCSRNotifications";
    notifications: CSRNotification[];
  } 
  | {
    type: "UpdateCSRUser";
    user: CSRUser;
  }
  | {
      type: "UpdateCSRConfigs";
      csrConfig: CSRConfig;
    }
  | {
    type: "UpdateCSRZones",
    zones: Zone[];
  }
  | {
    type: "UpdateCSRZoneNGOs",
    payload: {
      zoneId: string,
      ngos: NGO[],
    }
  }
  | {
    type: "setSelectedZone",
    payload: Zone,
  } |
  {
    type: "setSelectedNGO",
    payload: NGO,
  }
  | {
    type: "UpdateNGODetails",
    merchants: PartialNGO[],
  }
  | {
      type: "UpdateAppConfigs";
      appConfigs: DataByLocale<AppConfig>;
      urlRedirects: DataByLocale<EntityUrlRedirect[]>;
    }
  | {
      type: "UpdateLiveEvents";
      liveEvents: DataByLocale<LiveEvent>;
    }
  | {
      type: "UpdateArticleCategories";
      categories: ArticleCategory[];
    }
  | {
      type: "UpdateArticle";
      article: Article;
    }
  | {
      type: "UpdateArticleAuthor";
      authorId: string;
      author: ArticleAuthor;
    }
  | {
      type: "UpdateArticleTags";
      tags: ArticleTag[];
    }
  | {
      type: "UpdateCMSPageContent";
      content: CMSPageContent;
      pageType: CMS_PAGE_TYPE;
    }
  | {
      type: "UpdateHTMLBasedCMSPageContent";
      content: HTMLBasedCMSPageContent;
      pageType: HTML_BASED_CMS_PAGE_TYPE;
    }
  | {
      type: "UpdateCMSStaticBlockContents";
      cmsStaticBlockContents: CMSStaticBlockContent[];
    }
  | {
      type: "UpdateProductOverview";
      productOverview: ProductOverview;
    }
  | {
      type: "UpdateProductDetail";
      product: Product;
    }
  | {
      type: "UpdateProductPromotionBanners";
      productId: number;
      promotionBanners: PromotionBanner[];
    }
  | {
    type: "UpdateProducts";
    products: Product[];
  }
  | {
      type: "UpdateProductOverviews";
      productOverviews: ProductOverview[];
    }
  | {
      type: "UpdateProductLabelsByProductId";
      productLabelsByProductIdList: {
        productId: number;
        productLabels: ProductLabel[];
      }[];
      mode: ProductLabelMode;
    }
  | {
      type: "UpdateProductReviewsBySKU";
      productReviews: ProductReview[];
      sku: string;
    }
  | {
      type: "UpdateCustomerProductReviews";
      productReviews: CustomerProductReview[];
    }
  | {
      type: "UpdateCategoryTree";
      remoteCategoryTree: RemoteCategoryTree;
      locale: Locale;
    }
  | {
      type: "UpdateCategoryDescription";
      id: number;
      description: string | null;
      locale: Locale;
    }
  | {
      type: "UpdateStoreConfigs";
      storeConfigs: DataByLocale<StoreConfig>;
    }
    | {
      type: "UpdateCategoryProductsPaginationInfo";
      products: Product[];
    }
  | {
      type: "UpdateCategoryProductOverviewsPaginationInfo";
      productOverviews: ProductOverview[];
    }
  | {
      type: "UpdateCustomer";
      customer: Customer;
    }
  | {
      type: "SessionExpired";
    }
  | {
      type: "Logout";
    }
  | {
      type: "UpdateCustomerAddresses";
      addresses: RemoteAddress[];
      defaultShipping: number | null;
      defaultBilling: number | null;
    }
  | {
      type: "AddCustomerAddress";
      address: RemoteAddress;
    }
  | {
      type: "UpdateCustomerAddress";
      address: RemoteAddress;
    }
  | {
      type: "SetCustomerAddressDefaultShipping";
      addressID: number;
    }
  | {
      type: "SetCustomerAddressDefaultBilling";
      addressID: number;
    }
  | {
      type: "RemoveCustomerAddress";
      result: boolean;
      addressID: number;
    }
  | {
      type: "CreateCart";
      cart: Cart;
    }
  | {
      type: "UpdateMerchantDirectoryPaginationInfo";
      merchantPreviews: MerchantPreview[];
    }
  | {
      type: "UpdateMerchantPreview";
      merchantPreview: MerchantPreview;
    }
  | {
      type: "UpdateMerchantDetails";
      merchant: Merchant;
    }
  | {
      type: "UpdateRecentlySearches";
      searchTerms: SearchTerm[];
    }
  | {
      type: "UpdateCountryRegions";
      countryRegions: CountryRegions;
    }
  | {
      type: "UpdateDistricts";
      districts: District[];
    }
  | {
      type: "UpdateWishlistItems";
      items: WishlistItem[];
    }
  | {
      type: "AddProductToWishlist";
      sku: string;
    }
  | {
      type: "RemoveProductFromWishlist";
      sku: string;
    }
  | {
      type: "ClearProduct";
    }
  | {
      type: "ClearCMSPages";
    }
  | {
      type: "UpdateProductAttributeFilterFields";
      productAttributeFilterInputFields: FilterInputField[];
    }
  | {
      type: "UpdateCustomerSubscriptions";
      customerSubscriptions: CustomerSubscription[];
    }
  | {
      type: "UpdateCustomerSubscription";
      customerSubscription: CustomerSubscription;
    }
  | {
      type: "UpdateOrderDetailsSalesOrder";
      order: OrderDetailsPartialOrder;
    }
  | {
      type: "CheckAppNeedUpdate";
      needUpdate:
        | false
        | { type: "force"; forceUpdateLink: string }
        | { type: "recommend"; recommendUpdateLink: string };
    }
  | {
      type: "UpdateBrands";
      brands: Brand[];
    };

export const RepositoryContext = React.createContext<RepositoryContextValue>(
  null as any
);

const initialState: RepositoryState = {
  csr: {
    config: null,
    user: null,
    zones: [],
    zonePreviews: [],
    selectedZone: null,
    selectedNGO: null,
    notifications: [],
  },
  needUpdate: false,
  appConfig: null,
  liveEvent: null,
  urlRedirect: null,
  storeConfig: null,
  article: {
    articleById: {},
    categoryById: {},
    authorById: {},
    tagById: {},
  },
  cms: {
    pageContent: {
      home: null,
    },
    htmlBasedCMSPageContent: {
      cmsPage: {},
      category: {},
      merchant: {},
      cmsBlock: {},
    },
  },
  cart: null,
  product: {
    overviewBySKU: {},
    detailBySKU: {},
    promotionBannersById: {},
    likedProductSKU: {},
    wishlistItemByProductSKU: {},
    productLabelsByProductId: {
      category: {},
      product: {},
    },
    productReviewsBySKU: {},
  },
  remoteCategoryTreeMap: {
    [Locale.en]: {},
    [Locale.zhHant]: {},
  },
  categoryTreeMap: {
    [Locale.en]: {},
    [Locale.zhHant]: {},
  },
  customer: null,
  customerProductReviews: [],
  isLogoutBySessionExpired: false,
  customerAddresses: [],
  defaultShipping: null,
  defaultBilling: null,
  merchant: {
    previewById: {},
    detailsById: {},
  },
  search: {
    recentlySearches: null,
  },
  countries: [],
  countryByID: {},
  countryByCountryCode: {},
  regionsByCountryID: {},
  regionByID: {},
  regionByRegionCode: {},
  districts: [],
  districtsByRegionID: {},
  districtByID: {},
  districtByDistrictName: {},
  productAttributeFilterInputFields: [],
  productAttributeFilterFields: [],
  productAttributeFilterInputMap: {},
  customerSubscriptions: null,
  customerSubscriptionMap: {},
  orderDetailsPartialOrderMap: {},
  brands: [],
};

/* eslint-disable complexity */
function repositoryStateReducer(
  state: RepositoryState,
  action: RepositoryAction
): RepositoryState {
  return produce(state, draft => {
    switch (action.type) {
      case "UpdateCSRNotifications": {
        draft.csr.notifications = action.notifications;
        break;
      }
      case "UpdateCSRUser": {
        draft.csr.user = action.user;
        break;
      }
      case "UpdateCSRConfigs": {
        draft.csr.config = action.csrConfig;
        break;
      }
      case "UpdateCSRZones": {
        draft.csr.zones = action.zones;
        break;
      }
      case "UpdateAppConfigs": {
        draft.appConfig = action.appConfigs;
        draft.urlRedirect = action.urlRedirects;
        break;
      }
      case 'setSelectedZone': {
        draft.csr.selectedZone = action.payload;
        break;
      }
      case 'setSelectedNGO': {
        draft.csr.selectedNGO = action.payload;
        break;
      }
      case 'UpdateCSRZoneNGOs': {
        draft.csr.zones[action.payload.zoneId].ngos = action.payload.ngos;
        break;
      }
      case "UpdateNGODetails" : {
        
        let merchants = action.merchants
        if (draft.csr.selectedZone) {
          draft.csr.selectedZone.ngos = draft.csr.selectedZone.ngos.map(ngo => {
            let merchant = merchants.find(m => m.clubLikeEntityId == ngo.clubLikeEntityId );
            let newNGO = { ...ngo };
            if (merchant) {
              newNGO.name = merchant.name;
              newNGO.logo = merchant.logo?merchant.logo : require("../resources/merchant-placeholder.svg");
            }
            return newNGO;
          })
        }
        break;
      }
      case "UpdateLiveEvents": {
        draft.liveEvent = action.liveEvents;
        break;
      }
      case "UpdateArticleCategories": {
        for (const category of action.categories) {
          draft.article.categoryById[category.categoryId] = category;
        }
        break;
      }
      case "UpdateArticle": {
        draft.article.articleById[action.article.postId] = action.article;
        break;
      }
      case "UpdateArticleAuthor": {
        draft.article.authorById[action.authorId] = action.author;
        break;
      }
      case "UpdateArticleTags": {
        for (const tag of action.tags) {
          draft.article.tagById[tag.tagId] = tag;
        }
        break;
      }
      case "UpdateCMSPageContent": {
        const { pageType } = action;
        if (pageType.type === "home") {
          draft.cms.pageContent.home = action.content;
        }
        break;
      }
      case "UpdateHTMLBasedCMSPageContent": {
        const { pageType } = action;
        draft.cms.htmlBasedCMSPageContent[pageType.type][pageType.identifier] =
          action.content;
        break;
      }
      case "UpdateStoreConfigs": {
        draft.storeConfig = action.storeConfigs;
        break;
      }
      case "UpdateProductOverview": {
        draft.product.overviewBySKU[action.productOverview.sku] =
          action.productOverview;
        break;
      }
      case "UpdateProductDetail": {
        draft.product.detailBySKU[action.product.sku] = action.product;
        break;
      }
      case "UpdateProducts": {
        action.products.forEach(product => {
          draft.product.detailBySKU[product.sku] = product;
        });
        break;
      }
      case "UpdateProductOverviews": {
        action.productOverviews.forEach(productOverview => {
          draft.product.overviewBySKU[productOverview.sku] = productOverview;
        });
        break;
      }
      case "UpdateProductLabelsByProductId": {
        const { productLabelsByProductIdList, mode } = action;
        productLabelsByProductIdList.forEach(({ productId, productLabels }) => {
          draft.product.productLabelsByProductId[mode][
            productId
          ] = productLabels;
        });
        break;
      }
      case "UpdateProductReviewsBySKU": {
        const { productReviews, sku } = action;
        draft.product.productReviewsBySKU[sku] = productReviews;
        break;
      }
      case "UpdateCustomerProductReviews": {
        const { productReviews } = action;
        draft.customerProductReviews = productReviews;
        break;
      }
      case "UpdateProductPromotionBanners": {
        draft.product.promotionBannersById[action.productId] =
          action.promotionBanners;
        break;
      }
      case "UpdateWishlistItems": {
        draft.product.likedProductSKU = {};
        draft.product.wishlistItemByProductSKU = {};
        action.items.forEach(wishListItem => {
          const { product } = wishListItem;
          draft.product.overviewBySKU[product.sku] = product;
          draft.product.likedProductSKU[product.sku] = true;
          draft.product.wishlistItemByProductSKU[product.sku] = wishListItem;
        });
        break;
      }
      case "AddProductToWishlist": {
        draft.product.likedProductSKU[action.sku] = true;
        break;
      }
      case "RemoveProductFromWishlist": {
        draft.product.likedProductSKU[action.sku] = undefined;
        draft.product.wishlistItemByProductSKU[action.sku] = undefined;
        break;
      }
      case "UpdateCategoryTree": {
        const { remoteCategoryTree, locale } = action;
        draft.remoteCategoryTreeMap[locale][
          remoteCategoryTree.id
        ] = remoteCategoryTree;
        const categoryTrees = flattenCategoryTree(remoteCategoryTree);
        for (const categoryTree of categoryTrees) {
          draft.categoryTreeMap[locale][categoryTree.id] = categoryTree;
        }
        break;
      }
      case "UpdateCategoryDescription": {
        const { id, description, locale } = action;
        const remoteCategoryTree = draft.remoteCategoryTreeMap[locale][id];
        if (remoteCategoryTree) {
          remoteCategoryTree.description = description;
        }
        const categoryTree = draft.categoryTreeMap[locale][id];
        if (categoryTree) {
          categoryTree.description = description;
        }
        break;
      }
      case "UpdateCategoryProductsPaginationInfo": {
        const { products } = action;
        for (const product of products) {
          draft.product.detailBySKU[product.sku] = product;
        }
        break;
      }
      case "UpdateCategoryProductOverviewsPaginationInfo": {
        const { productOverviews } = action;
        for (const productOverview of productOverviews) {
          draft.product.overviewBySKU[productOverview.sku] = productOverview;
        }
        break;
      }
      case "UpdateCustomer": {
        draft.customer = action.customer;
        draft.isLogoutBySessionExpired = false;
        break;
      }
      case "SessionExpired": {
        draft.customer = null;
        draft.csr.user = null;
        draft.customerProductReviews = [];
        draft.customerAddresses = [];
        draft.product.likedProductSKU = {};
        draft.isLogoutBySessionExpired = true;
        draft.customerSubscriptions = null;
        draft.customerSubscriptionMap = {};
        break;
      }
      case "Logout": {
        draft.customer = null;
        draft.csr.user = null;
        draft.customerProductReviews = [];
        draft.customerAddresses = [];
        draft.product.likedProductSKU = {};
        draft.isLogoutBySessionExpired = false;
        draft.customerSubscriptions = null;
        draft.customerSubscriptionMap = {};
        break;
      }
      case "UpdateCustomerAddresses": {
        draft.customerAddresses = action.addresses;
        draft.defaultShipping = action.defaultShipping;
        draft.defaultBilling = action.defaultBilling;
        break;
      }
      case "AddCustomerAddress": {
        draft.customerAddresses = [...draft.customerAddresses, action.address];
        if (action.address.defaultShipping) {
          draft.defaultShipping = action.address.id;
        }
        if (action.address.defaultBilling) {
          draft.defaultBilling = action.address.id;
        }
        break;
      }
      case "UpdateCustomerAddress": {
        draft.customerAddresses = draft.customerAddresses.reduce(
          (acc, address, index) => {
            if (address.id !== action.address.id) {
              return acc;
            }

            acc[index] = address;
            return acc;
          },
          draft.customerAddresses
        );
        if (action.address.defaultShipping) {
          draft.defaultShipping = action.address.id;
        }
        if (action.address.defaultBilling) {
          draft.defaultBilling = action.address.id;
        }
        break;
      }
      case "SetCustomerAddressDefaultBilling": {
        draft.customerAddresses = draft.customerAddresses.map(address => {
          if (address.id !== action.addressID) {
            address.defaultBilling = false;
            return address;
          }
          address.defaultBilling = true;
          return address;
        });
        break;
      }
      case "SetCustomerAddressDefaultShipping": {
        draft.customerAddresses = draft.customerAddresses.map(address => {
          if (address.id !== action.addressID) {
            address.defaultShipping = false;
            return address;
          }
          address.defaultShipping = true;
          return address;
        });
        break;
      }
      case "RemoveCustomerAddress": {
        if (!action.result) {
          break;
        }

        draft.customerAddresses = draft.customerAddresses.filter(
          address => address.id !== action.addressID
        );
        break;
      }
      case "CreateCart": {
        draft.cart = action.cart;
        break;
      }
      case "UpdateMerchantDirectoryPaginationInfo": {
        const { merchantPreviews } = action;
        for (const merchantPreview of merchantPreviews) {
          draft.merchant.previewById[merchantPreview.id] = merchantPreview;
        }
        break;
      }
      case "UpdateMerchantPreview": {
        const { merchantPreview } = action;
        draft.merchant.previewById[merchantPreview.id] = merchantPreview;
        break;
      }
      case "UpdateMerchantDetails": {
        const { merchant } = action;
        draft.merchant.detailsById[merchant.id] = merchant;
        break;
      }
      case "UpdateRecentlySearches": {
        draft.search.recentlySearches = action.searchTerms.slice(
          0,
          Config.MAX_RECENTLY_SEARCHES_TO_STORE
        );
        break;
      }
      case "UpdateCountryRegions": {
        const { countries, regionsByCountryID } = action.countryRegions;
        draft.countries = countries;
        draft.regionsByCountryID = regionsByCountryID;

        draft.countryByID = {};
        draft.regionByID = {};

        draft.countryByCountryCode = {};
        draft.regionByRegionCode = {};

        for (const country of countries) {
          const { id, code } = country;
          draft.countryByID[id] = country;
          draft.countryByCountryCode[code] = country;

          const regions = regionsByCountryID[id];
          if (regions) {
            for (const region of regions) {
              const { id: rid, code: rcode } = region;
              draft.regionByID[rid] = region;
              draft.regionByRegionCode[rcode] = region;
            }
          }
        }

        break;
      }
      case "UpdateDistricts": {
        const { districts } = action;
        draft.districts = districts;

        draft.districtsByRegionID = {};
        draft.districtByID = {};
        draft.districtByDistrictName = {};

        for (const district of districts) {
          const { regionId, id, name } = district;
          if (!draft.districtsByRegionID[regionId]) {
            draft.districtsByRegionID[regionId] = [];
          }
          const mDistricts = draft.districtsByRegionID[regionId];
          if (mDistricts) {
            mDistricts.push(district);
          }

          draft.districtByID[id] = district;
          draft.districtByDistrictName[name] = district;
        }

        break;
      }
      case "ClearProduct": {
        draft.product = initialState.product;
        break;
      }
      case "ClearCMSPages": {
        draft.cms = initialState.cms;
        break;
      }
      case "UpdateProductAttributeFilterFields": {
        draft.productAttributeFilterInputFields =
          action.productAttributeFilterInputFields;
        draft.productAttributeFilterFields = action.productAttributeFilterInputFields.map(
          ({ name }) => name
        );
        draft.productAttributeFilterInputMap = {};
        for (const productAttributeFilterInput of action.productAttributeFilterInputFields) {
          const { name } = productAttributeFilterInput;
          draft.productAttributeFilterInputMap[
            name
          ] = productAttributeFilterInput;
        }
        break;
      }
      case "UpdateCustomerSubscriptions": {
        const { customerSubscriptions } = action;
        draft.customerSubscriptions = customerSubscriptions;
        for (const customerSubscription of customerSubscriptions) {
          if (
            customerSubscription.subscription &&
            customerSubscription.subscription.subscriptionId
          ) {
            draft.customerSubscriptionMap[
              customerSubscription.subscription.subscriptionId
            ] = customerSubscription;
          }
        }
        break;
      }
      case "UpdateCustomerSubscription": {
        const { customerSubscription } = action;
        draft.customerSubscriptions = draft.customerSubscriptions
          ? draft.customerSubscriptions.map(c => {
              if (
                c.subscription &&
                c.subscription.subscriptionId &&
                customerSubscription.subscription &&
                customerSubscription.subscription.subscriptionId &&
                c.subscription.subscriptionId ===
                  customerSubscription.subscription.subscriptionId
              ) {
                return customerSubscription;
              }
              return c;
            })
          : null;
        if (
          customerSubscription.subscription &&
          customerSubscription.subscription.subscriptionId
        ) {
          draft.customerSubscriptionMap[
            customerSubscription.subscription.subscriptionId
          ] = customerSubscription;
        }
        break;
      }
      case "UpdateOrderDetailsSalesOrder": {
        const { order } = action;
        draft.orderDetailsPartialOrderMap[`${order.entityID}`] = order;
        break;
      }
      case "CheckAppNeedUpdate": {
        draft.needUpdate = action.needUpdate;
        break;
      }
      case "UpdateBrands": {
        draft.brands = action.brands;
        break;
      }
      default:
        break;
    }
  });
}
/* eslint-enable complexity */

class ConfigLoader {
  private configs: {
    appConfig: DataByLocale<AppConfig>;
    storeConfig: DataByLocale<StoreConfig>;
  } | null = null;

  private subscribers: ((configs: {
    appConfig: DataByLocale<AppConfig>;
    storeConfig: DataByLocale<StoreConfig>;
  }) => void)[] = [];

  private resolveConfigsForLocale = (
    locale: Locale,
    configs: {
      appConfig: DataByLocale<AppConfig>;
      storeConfig: DataByLocale<StoreConfig>;
    }
  ): {
    appConfig: AppConfig;
    storeConfig: StoreConfig;
  } => {
    return {
      appConfig: configs.appConfig[locale],
      storeConfig: configs.storeConfig[locale],
    };
  };

  private subscribeForConfigsLoaded = (
    subscriber: (configs: {
      appConfig: DataByLocale<AppConfig>;
      storeConfig: DataByLocale<StoreConfig>;
    }) => void
  ) => {
    this.subscribers.push(subscriber);
  };

  setConfigs = (configs: {
    appConfig: DataByLocale<AppConfig>;
    storeConfig: DataByLocale<StoreConfig>;
  }) => {
    this.configs = configs;
    const subscribers = this.subscribers;
    this.subscribers = [];
    subscribers.forEach(sub => sub(configs));
  };

  waitForConfigs = (
    locale: Locale
  ): Promise<{
    storeConfig: StoreConfig;
    appConfig: AppConfig;
  }> => {
    return new Promise(resolve => {
      if (this.configs != null) {
        resolve(this.resolveConfigsForLocale(locale, this.configs));
      } else {
        this.subscribeForConfigsLoaded(configs =>
          resolve(this.resolveConfigsForLocale(locale, configs))
        );
      }
    });
  };
}

export function useRepositoryStateReducer() {
  const promiseCache = useRef(new PromiseCache());
  const [state, dispatch] = useReducer(repositoryStateReducer, initialState);
  const configLoader = useRef(new ConfigLoader());

  useEffect(() => {
    if (state.appConfig != null && state.storeConfig != null) {
      configLoader.current.setConfigs({
        appConfig: state.appConfig,
        storeConfig: state.storeConfig,
      });
    }
  }, [state.appConfig, state.storeConfig]);

  const waitForConfigs = configLoader.current.waitForConfigs;

  return useMemo(
    () => ({
      withPromiseCache: promiseCache.current.withCache,
      clearPromiseCache: promiseCache.current.clearCache,

      state,
      dispatch,

      waitForConfigs,
    }),
    [state, waitForConfigs]
  );
}
