import { BrowserTab } from "@ionic-native/browser-tab";
import { useCallback } from "react";
import { isAndroid, isCapacitor } from "../utils/Platform";
import { Plugins } from "@capacitor/core";

const { App } = Plugins;

async function checkBrowserTabIsAvailable(): Promise<boolean> {
  try {
    return await BrowserTab.isAvailable();
  } catch {
    return false;
  }
}

const useOpenUrlWithBrowser = () => {
  return useCallback(async (url: string) => {
    const browserTabIsAvailable = await checkBrowserTabIsAvailable();
    if (!isCapacitor()) {
      window.open(url);
    } else if (isAndroid() && browserTabIsAvailable) {
      BrowserTab.openUrl(url);
    } else {
      const canOpenUrl = await App.canOpenUrl({ url });
      if (canOpenUrl.value) {
        App.openUrl({ url });
      } else {
        window.open(url);
      }
    }
  }, []);
};

export default useOpenUrlWithBrowser;
