import React, { useMemo } from "react";

import Config from "../../Config";
import { Category } from "../../models/category";

import {
  useCategoryTreeMap,
  useChildrenCategories,
} from "../../repository/CategoryRepository";

interface Props {
  renderCategory: (category: Category) => JSX.Element | null;
}

const CategoryList: React.FC<Props> = (props: Props) => {
  const categoryTreeMap = useCategoryTreeMap();

  const categoryTree = useMemo(() => {
    const defaultCategoryTree = categoryTreeMap[Config.DEFAULT_CATEGORY_ID];
    if (!defaultCategoryTree) {
      return null;
    }
    return defaultCategoryTree;
  }, [categoryTreeMap]);

  const childrenCategories = useChildrenCategories(
    categoryTree,
    categoryTreeMap
  );

  return (
    <>
      {childrenCategories.map(category => {
        return props.renderCategory(category);
      })}
    </>
  );
};

export default CategoryList;
