import React, { createContext, Dispatch, useReducer, useContext } from "react";

import { Zone } from "../models/Zone";
import { NGO } from "../models/NGO";

type ZoneState = {
  ngos: NGO[];
  selectedZone: Zone | null;
  selectedNGO: NGO | null;
}

type ZoneActions = 
  | {
    type: "setSelectedZone",
    payload: Zone,
  } |
  {
    type: "setSelectedNGO",
    payload: NGO,
  } |
  {
    type: "setNGOList",
    payload: NGO[]
  };


const initialState: ZoneState = {
  ngos: [],
  selectedZone: null,
  selectedNGO: null,
}  

const initialZoneContext: { 
  zoneState: ZoneState;
  setZoneState: Dispatch<ZoneActions>;
} = {
  zoneState: initialState,
  setZoneState: () => {}
}

const ZoneContext = createContext(initialZoneContext);

const reducer = (state: ZoneState, action: ZoneActions) => {
  switch (action.type) {
    case 'setSelectedZone':
      return {
        ...state,
        selectedZone: action.payload,
      }
    case 'setSelectedNGO':
      return {
        ...state,
        selectedNGO: action.payload,
      }
    case 'setNGOList':
      return {
        ...state,
        ngos: action.payload,
      }
    default:
      return state;
  }
}

export const ZoneProvider: React.FC = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const zoneState = state;
  const setZoneState = dispatch;
  
  return (
    <ZoneContext.Provider value={{ zoneState, setZoneState }}>
      { props.children }
    </ZoneContext.Provider>
  )
};

export const useZoneState = () => useContext(ZoneContext);
