import qs from "qs";
import React, { createContext, useContext, useCallback, useEffect } from "react";
import { OurNavContext } from "../our-navigation";
import { LoadingModalContext } from "./LoadingModalProvider";
import { useRouteMatch, useLocation, useParams } from "react-router";
import { useIntl } from "../i18n/Localization";
import { useActivateCustomer, useHandleSessionExpired } from "../repository/AuthRepository";
import { fetchOrder } from "./CheckoutConfirmationPage/api";
import { useGraphQLFn } from "../hook/graphql";
import { purchaseEvent } from "../utils/GTM";
import VerifyEmailProvider from "./VerifyEmailProvider";
import { genPathForCheckoutOrdered } from "../navigation/routes";
import { useModalState } from "../hook/modal";
import CSRModal from "./CSRModal";
import { LocalizedAlertContext } from "./LocalizedAlertProvider";

enum OppPamyentResult {
  SUCCESS = "responsesuccess",
  FAIL = "responsecancel",
}

export interface MatchParams {
  oppPaymentResult: OppPamyentResult;
}

type InitialOppPaymentVerifyContext =  {
  order_id: string;
}

const OppPaymentVerifyContext = createContext(null);

const OppPaymentVerifyProvider: React.FC = props => {
  const { navigate } = useContext(OurNavContext);
  const { show: showLoadingView, hide: hideLoadingView } = useContext(LoadingModalContext);
  let match = useRouteMatch<MatchParams>({
    path: "/checkout/opp-payment/:oppPaymentResult"
  });

  const [ isOppPaymentVerifyModalOpened, presentOppPaymentVerifyModal, hideOppPaymentVerifyModal ] = useModalState();

  let { search } = useLocation();
  let oppPaymentResult = match && match.params && match.params.oppPaymentResult ? match.params.oppPaymentResult : null;
  const activateCustomer = useActivateCustomer();
  const fetchOrder_ = useHandleSessionExpired(useGraphQLFn(fetchOrder));
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);

  const handlePaymentCallback = useCallback(async(orderId: string) => {
    if (!orderId) {
      return ;
    }
    try {
      const order = await fetchOrder_(orderId);
      purchaseEvent(order)
    }catch (err) {

    } finally {

    }
  }, [navigate]);

  useEffect(() => {
    if(match) {
      showLoadingView();
      const { order_id: orderId } = qs.parse(search, { ignoreQueryPrefix: true }) as InitialOppPaymentVerifyContext;
      if (oppPaymentResult === "responsesuccess" && orderId ){        
        handlePaymentCallback(orderId);
        hideLoadingView();
        navigate(
          genPathForCheckoutOrdered(),
          {orderId});
        //presentOppPaymentVerifyModal();
      } else {
        hideLoadingView();
        navigate("/shopping-cart");
        presentLocalizedAlert({
          messageId: "opp_payment.failed",
          buttons: [{ textMessageID: "alert.button.ok" }],
        });
        // await reorderAndBackToShoppingCart(orderId);
      }
    }
  }, [match, search, oppPaymentResult]);

  return (
    <>
      <OppPaymentVerifyContext.Provider value={null}>
        { props.children }
      </OppPaymentVerifyContext.Provider>
      <CSRModal
        isOpen={isOppPaymentVerifyModalOpened}
        onRequestDismiss={hideOppPaymentVerifyModal}
      >
        OppPayment successful!
      </CSRModal>
    </>
  )
}

export default OppPaymentVerifyProvider;