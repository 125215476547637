import React, { useCallback, useState, useContext, useEffect } from "react";
// eslint-disable-next-line no-restricted-imports
import { IonModal, IonContent } from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router";

import styles from "./styles.module.scss";
import { LocalizedText } from "../../i18n/Localization";
import { OAuthProvider } from "../../api/RESTful";
import { useSignupWithOAuthRequest } from "../../repository/AuthRepository";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import { isCustomerRequireEmailVerificationPrompt } from "../../models/Customer";
import VerifyEmailModal from "../VerifyEmailModal";
import { FullContentLoadingView } from "../LoadingView"

type SocialSignupTermsAndConditionsModalProps = RouteComponentProps & {
  isModalOpen: boolean;
  onRequestDismiss: (dismissParent: boolean) => void;
  provider?: OAuthProvider;
  token?: string;
};

const SocialSignupTermsAndConditionsModal: React.FC<
  SocialSignupTermsAndConditionsModalProps
> = (props: SocialSignupTermsAndConditionsModalProps) => {
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribeToNewsletter, setIsSubscribeToNewsletter] = useState(false);
  const onIsSubscribeToNewsletterChange = useCallback(
    (value: boolean) => {
      setIsSubscribeToNewsletter(value);
    },
    [setIsSubscribeToNewsletter]
  );
  const [verifyEmailModalIsOpen, setVerifyEmailModalIsOpen] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState<string>("");
  const { isModalOpen, onRequestDismiss, token, provider, history } = props;

  const signupWithOAuth = useSignupWithOAuthRequest();
  const ssoSignup = useCallback(
    async (token: string, provider: OAuthProvider) => {
      try {
        setIsLoading(true);
        const customer = await signupWithOAuth(
          token,
          provider,
          isSubscribeToNewsletter
        );
        if (!customer) {
          presentLocalizedAlert({
            headerId: "signup.signup_failed",
            buttons: [{ textMessageID: "try_again" }],
          });
          return;
        }
        onRequestDismiss(true);
        if (isCustomerRequireEmailVerificationPrompt(customer)) {
          setVerifyEmailModalIsOpen(true);
          setVerifyEmail(customer.email || "");
          return;
        }
        history.replace("/town");
        //history.replace(getPathForSelectInterestCategoryPage(RootTab.home));
      } catch (e) {
        const messageId =
          e.message === "email-already-in-use"
            ? "signup.social_signup_email_used_fail_text"
            : undefined;
        presentLocalizedAlert({
          headerId:
            e.message === "email-already-in-use"
              ? "signup.social_signup_email_used_fail_title"
              : "signup.signup_failed",
          messageId,
          buttons: [{ textMessageID: "try_again" }],
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      history,
      setIsLoading,
      signupWithOAuth,
      isSubscribeToNewsletter,
      presentLocalizedAlert,
      onRequestDismiss,
    ]
  );

  const handleAcceptTermsLinkClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);
  const agreeOnClick = useCallback(() => {
    if (token && provider) {
      ssoSignup(token, provider);
    }
  }, [token, provider, ssoSignup]);

  const dismissOnClick = useCallback(() => {
    onRequestDismiss(false);
  }, [onRequestDismiss]);

  const onVerifyEmailModalDismiss = useCallback(() => {
    setVerifyEmailModalIsOpen(false);
    //history.replace(getPathForSelectInterestCategoryPage(RootTab.home));
    history.replace("/town");
  }, [history, setVerifyEmailModalIsOpen]);

  useEffect(()=>{
    if(isModalOpen && !isLoading){
      agreeOnClick();
    }
  },[agreeOnClick, isLoading, isModalOpen]);

  return (
    <>
      <IonModal
        // @ts-ignore
        className={styles.modal}
        isOpen={isModalOpen}
        onDidDismiss={dismissOnClick}
        backdropDismiss={isLoading}
      >
        <IonContent className={styles.modalContent}>
          <FullContentLoadingView/>
        </IonContent>
      </IonModal>
      <VerifyEmailModal
        isModalOpen={verifyEmailModalIsOpen}
        initialEmail={verifyEmail}
        onRequestDismiss={onVerifyEmailModalDismiss}
      />
    </>
  );
};

export default React.memo(withRouter(SocialSignupTermsAndConditionsModal));

interface AcceptTermsTextProps {
  termsAndConditionsLink: string;
  privacyPolicyLink: string;
}

const AcceptTermsText: React.FC<AcceptTermsTextProps> = props => {
  const termsAndConditionsLink = props.termsAndConditionsLink;
  const privacyPolicyLink = props.privacyPolicyLink;

  const handleLinkClick = useCallback((e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
  }, []);

  return (
    <LocalizedText
      messageID="signup.accept_terms"
      messageArgs={{
        termsAndPrivacyPolicy: styles.termsAndPrivacyPolicy,
        handleLinkClick,
        termsAndConditionsLink,
        privacyPolicyLink,
      }}
    />
  );
};

