import { RootTab } from "../navigation/routes";

class SimpleEventEmitter<Event> {
  private listeners: ((e: Event) => void)[] = [];

  subscribe = (listener: (e: Event) => void): { remove: () => void } => {
    this.listeners.push(listener);
    return {
      remove: () => {
        this.listeners = this.listeners.filter(l => l !== listener);
      },
    };
  };

  publish = (event: Event) => {
    this.listeners.forEach(l => l(event));
  };
}

export default SimpleEventEmitter;

// eslint-disable-next-line

type AppEvent =
  | AppEventOnClickViewMore
  | AppEventOnQRScanSuccess
  | AppEventOnSubmitChangeEmailSuccess
  | AppEventOnClickTabBar
  | AppEventOnClickPromotionBanner
  | AppEventPushNotificationGoTo
  | AppEventOnClickThirdPartyProductButtonUrl
  | AppEventLinkToExternalRedemptionOrder;

interface AppEventOnClickViewMore {
  type: "OnClickViewMore";
  url: string;
}
export function AppEventOnClickViewMore(url: string): AppEventOnClickViewMore {
  return {
    type: "OnClickViewMore",
    url,
  };
}

interface AppEventOnQRScanSuccess {
  type: "OnQRScanSuccess";
  url: string;
}
export function AppEventOnQRScanSuccess(url: string): AppEventOnQRScanSuccess {
  return {
    type: "OnQRScanSuccess",
    url,
  };
}

interface AppEventOnSubmitChangeEmailSuccess {
  type: "OnSubmitChangeEmailSuccess";
}
export const AppEventOnSubmitChangeEmailSuccess: AppEventOnSubmitChangeEmailSuccess = {
  type: "OnSubmitChangeEmailSuccess",
};

interface AppEventOnClickTabBar {
  type: "OnClickTabBar";
  tab: RootTab;
}
export function AppEventOnClickTabBar(tab: RootTab): AppEventOnClickTabBar {
  return {
    type: "OnClickTabBar",
    tab,
  };
}

interface AppEventOnClickPromotionBanner {
  type: "OnClickPromotionBanner";
  url: string;
}
export function AppEventOnClickPromotionBanner(
  url: string
): AppEventOnClickPromotionBanner {
  return {
    type: "OnClickPromotionBanner",
    url,
  };
}

interface AppEventPushNotificationGoTo {
  type: "PushNotificationGoTo";
  url: string;
}
export function AppEventPushNotificationGoTo(
  url: string
): AppEventPushNotificationGoTo {
  return {
    type: "PushNotificationGoTo",
    url,
  };
}

interface AppEventOnClickThirdPartyProductButtonUrl {
  type: "ClickThirdPartyProductButtonUrl";
  url: string;
}
export function AppEventOnClickThirdPartyProductButtonUrl(
  url: string
): AppEventOnClickThirdPartyProductButtonUrl {
  return {
    type: "ClickThirdPartyProductButtonUrl",
    url,
  };
}

interface AppEventLinkToExternalRedemptionOrder {
  type: "LinkToExternalRedemptionOrder";
  url: string;
}
export function AppEventLinkToExternalRedemptionOrder(
  url: string
): AppEventLinkToExternalRedemptionOrder {
  return {
    type: "LinkToExternalRedemptionOrder",
    url,
  };
}

export const appEventEmitter = new SimpleEventEmitter<AppEvent>();

type NetworkEvent =
  | NetworkEventMaintenance
  | NetworkEventResumedFromMaintenance;

interface NetworkEventMaintenance {
  type: "Maintenance";
}
export function NetworkEventMaintenance(): NetworkEventMaintenance {
  return {
    type: "Maintenance",
  };
}

interface NetworkEventResumedFromMaintenance {
  type: "ResumedFromMaintenance";
}
export function NetworkEventResumedFromMaintenance(): NetworkEventResumedFromMaintenance {
  return {
    type: "ResumedFromMaintenance",
  };
}

export const networkEventEmitter = new SimpleEventEmitter<NetworkEvent>();
