import React from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import styles from "./styles.module.scss";


const ClubHopeBanner: React.FC= () => {

  return (
    <div className={styles.container}>
      { false && <img className={styles.clubhope} src={require("../../resources/logo-clubhope.png")} /> }
      { false && <span className={styles.cross}>X</span> }
      <img className={styles.clublike} src={require("../../resources/club-shopping-logo.png")} />
    </div>
  )
}
export default ClubHopeBanner;