// MessageID naming: "page.view.some_thing.some_time.text"
export default {
  /* CSR */

  "landing.prompt_ie":
    "Your Browser is not Supported.\n\nTo experience Club Hope Online Public Welfare Platform, please use Chrome, Firefox, Safari or Edge.",
  "landing.menu.about": "About Us",
  "landing.menu.enter": "Enter Club Hope",
  "landing.menu.other_donations": "Enter Club Hope for more charitable items",
  "landing.menu.ngos": "Participating NGOs",
  "landing.menu.contact": "Contact Us",

  "landing.intro1":
    "Club Hope is HKT’s online charity platform aiming to increase public awareness of communities in need and raise funds for charity organisations. Anyone can participate in Club Hope to support as many as 12 charities in five zones — “Animal Welfare”, “Disability & Special Needs”, “Eco & Social Caring”, “Elderly Care” and “Music & Arts”. There are many ways to support them: purchase of a one-off donation voucher or the charities’ merchandise, support of their services, or monthly contribution.",

  "landing.intro2":
    "The virtual world of Club Hope is divided into “My Home” plus five charity zones —“Animal Welfare”, “Disability & Special Needs”, “Eco & Social Caring”, “Elderly Care” and “Music & Arts”. After logging in, participants can browse available initiatives from 13 charitable organisations and make a contribution to the ones they support.",
  "landing.intro3":
    "Participants contributing HK$300 or more in a quarter are eligible for rewards including cash coupons and Club Sim and Now E services. The higher the contribution, the greater the rewards!<br><br>Participants who successfully meet certain challenging goals will also receive badges of honor, including the title of “Generous Benefactor”, and other rewards. ",
  "landing.intro4":
    "The Club members can earn Clubpoints for the contributions they make. What’s more, Clubpoints can be used to pay for items purchased on the Club Hope platform. The use of Clubpoints is subject to Terms and Conditions.",
  "landing.intro5":
    "Participants can contribute on a one-off or monthly basis.",
  "landing.policy": "Privacy Policy",
  "landing.terms": "Terms & Conditions",

  copyright: "Club Hope © {year}. All Rights Reserved",
  "signup_to_donate.title": "Sign-up To Donate",

  "tutorial.previous": "Previous",
  "tutorial.next": "Next",
  "tutorial.close": "Close",
  "tutorial.dont_show_again": "Don't Show Next Time",
  "tutorial.skip": "Skip Tutorial",
  "tutorial.city.welcome": "Welcome to Club Hope",
  "tutorial.city.help1":
    "Please help us support the needy and raise funds for charitable organizations. Your involvement could help kindle a flame of hope and make the community more prosperous.",
  "tutorial.city.help2":
    "Feel free to make your way around the community. If you wish to enter a specific zone, simply left-click the curser and follow the instructions.",
  "tutorial.city.help3":
    "If you wish to find out how your involvement is helping the community, go to “My Home”.",
  "tutorial.city.help4":
    "Your generous support can help kindle a flame of hope and make the community more prosperous.",

  "tutorial.zone.welcome":
    "You are welcome to visit any zone in the community. Go to various floors to discover each charitable organization’s background, work and aims.",

  "tutorial.zone.help1":
    "Click the lift in the lobby to visit various floors and find out more about each charitable organization",
  "tutorial.zone.help2":
    "Click here to find out more about projects run by specific charitable organizations, so you can choose one or more to support.",
  "tutorial.zone.help3":
    "Click “Shopping Cart” in the top right-hand  corner to review a project. You can make a submission immediately after confirmation.",

  all_zones_ngos: "Zones & NGOs",
  all_zones: "All Zones",
  all_ngos: "All NGOs",
  "all_zones.loading": "We are bringing you to Club Hope",
  "zone.myhome": "My Home",
  "zone.eco-and-social": "Eco & Social Caring",
  "zone.music-arts-and-education": "Music & Arts",
  "zone.elderly": "Elderly Care",
  "zone.animals-and-pets": "Animal Welfare",
  "zone.disability-and-healthcare": "Disability & Special Needs",
  "zone.level": "Level {level}",
  "zone.lobby": "Lobby",
  "zone.elevator": "Lift",
  "zone.elevator_goto": "Switch Floor",

  "orders.title": "Transcation History",
  "orders.product.sku": "SKU",
  "orders.product.receiver": "Receiver",
  "orders.product.action": "Action",

  "ngo.error.mismatch_data":
    "Sorry, this NGO page is under maintenance. Please come back later.",
  "ngo.donate_for": "Support {name}",
  "ngo.about": "Background",
  "ngo.video_intro": "Video",
  "ngo.donation_purpose": "Projects",
  "ngo.selected": "Selected:",
  "ngo.one_time_donation": "One off Donation",
  "ngo.subscription_donation": "Monthly Subscription",
  "ngo.official_website": "Go to Official Website",

  "donation_bag.donation": "Donate",
  "donation_bag.basic_donation": "Donation",
  "donation_bag.other_donation": "More charitable items",
  "donation_bag.product": "Item",
  "donation_bag.price": "Price",
  "donation_bag.qty": "Qty.",
  "donation_bag.subtotal": "Subtotal",
  "donation_bag.add_to": "Add to Cart",
  "donation_bag.adding": "Please wait...",
  "donation_bag.add_success": "Successfully Added To Cart",
  "donation_bag.go_to_bag": "Shopping Cart",
  "donation_bag.donate_now": "Donate & Details",
  "donation_bag.title": "Shopping Cart",
  "donation_bag.go_to_details": "Go To Shopping Cart",
  "donation_bag.x_stock_left":
    "Last {stockLevel, plural, one{one}, other{{stockLevel} items}} left",
  "donation_bag.ordered.message.title":
    "Thank you for your support to Club Hope.Your Order Number is:",
  "donation_bag.ordered.message":
    "We are looking forward to seeing you soon at Club Hope in support of different programs.",
  "donation_bag.ordered.succeed": "Transaction Completed",
  "donation_bag.ordered.return_home": "Return To Homepage",
  "donation_bag.ordered.return_town": "Return To Club Hope City",
  "donation_bag.crowd_funding_success": "Successfully Crowd-funded",
  "donation_bag.out_of_stock": "Out of stock",
  "donation_bag.self_collection": "Self Collection Item",
  "donation_bag.details": "Details",
  "donation_bag.more_photos": "More Photos",
  "donation_bag.collapse": "Hide",
  "empty_donation_bag.title": "You have no items in your shopping cart",
  "empty_donation_bag.lets_go_shopping": "LET'S MAKE SOME DONATION",

  "messagebox.title": "Inbox / My Notifications",
  "messagebox.empty": "You don't have any message yet",

  "messagebox.GOLD_INCENTIVE_REQ_MET":
    "Your donation reached HK${donationAmountMet} this season. You received gold incentive. You can select your incentive at the beginning of next season.",
  "messagebox.SILVER_INCENTIVE_REQ_MET":
    "Your donation reached HK${donationAmountMet} this season. You can receive silver incentive at the beginning of next season. Or you can receive next level incentive when your donation amount reached HK${nextDonationAmount}.",
  "messagebox.BRONZE_INCENTIVE_REQ_MET":
    "Your donation reached HK${donationAmountMet} this season. You can receive bronze incentive at the beginning of next season. Or you can receive next level incentive when your donation amount reached HK${nextDonationAmount}.",

  "about.title": "About Club Hope",
  "about.description":
    "Club Hope is a new platform making donations for local charities.",

  "checkout.title": "Confirm Transcation",
  "user.profile.title": "My Profile",
  "user.profile.linkage": "Account Linkage",
  "user.profile.not_connected": "Not Connected",
  "user.profile.connected": "Connected: {email}",
  "user.profile.disconnect": "Disconnect",
  "user.profile.donation_summary":
    "Accumulated transaction amount in current quarter:",

  "user.title_level.1": "Participant",
  "user.title_level.2": "Level {level} Donor",
  "user.title_level.3": "Level {level} Dignitary",
  "user.title_level.4": "Level {level} Great Philanthrope",
  "subscription.subscribed": "Subscribed",
  "subscription.cycle": "Donate {cycle}",
  "subscription.each_amount": "Unit Amount: {amount}",
  "subscription.Once a month": "Monthly Subscription",
  "subscription.Once a day": "Daily Subscription",
  "subscription.Once a week": "Weekly Subscription",
  "subscription.Once a year": "Annual Subscription",
  "subscription.monthAmount": "Monthly Amount",
  "subscription.dayAmount": "Daily Amount",
  "subscription.weekAmount": "Weekly Amount",
  "subscription.yearAmount": "Annual Amount",

  "profile.title": "My Account",
  "profile.account.change_name": "Change Name",
  "profile.account.title": "Account Information",
  "profile.account.pic": "Profile Picture",
  "profile.account.select_pic": "Select a file",
  "profile.account.name": "My Name",
  "profile.account.change_name_note":
    "Please noted your Club Shopping account name will also be updated when you change your name here.",
  "profile.account.email": "Email",
  "profile.account.level": "Level",
  "profile.account.password": "Change Password",
  "profile.linkage.title": "Account Linkage",
  "profile.subscription.title": "Monthly Subscription",
  "profile.subscription.subscribed": "Subscribed",
  "profile.subscription.change_payment": "Change Payment Details",
  "profile.subscription.cancel": "Unsubscribe",
  "achievement.title": "My Contribution",
  "achievement.badges": "Badges",
  "achievement.certs": "Certificates",
  "statistics.title": "Progress of each zone",
  "statistics.zones_level": "Zone Levels",
  "statistics.zone": "Current Prosperous Level of <strong>{zone}</strong>",
  "statistics.mydonation": "My annual donation for this zone: {amount}",
  "statistics.level": "Level {level}",
  "statistics.completed": "Completed",
  "gifts.title": "My Gifts",
  "gifts.coming_soon": "Coming Soon",
  "history.title": "History",
  "history.btn_title": "Transaction History",

  "verify_the_club.verified_succeed.title": "Account Activated",
  "verify_the_club.verified_succeed":
    "You have successfully activated your account.",
  "verify_the_club.verified_failed":
    "Your activation is invalid or has expired.",

  "terms.p1":
    "All cash donations of HK$100 or above are tax-deductible and donation receipt will be issued by the selected beneficiary NGO.",
  "terms.p2":
    "Direct deposit for Donation (Monthly donation) and Purchase of Service (Monthly subscription) will be debited through credit card autopay. Beneficiary NGO will provide an annual donation receipt for donors in April each year.",
  "terms.p3":
    "Note: Receipt will not be issued for any purchase of merchandise or transactions made with Clubpoints via Club Hope which is not tax-deductible.",

  "seo.default.title": "Club Hope | Online Public Welfare Platform",
  "seo.default.sitename": "Club Hope",
  "seo.landing.description":
    "HKT introduces Club Hope, an online public welfare platform,  enabling us to join forces with the public to help the needy and raise much-needed support for charity organizations. Your support will kindle a flame of hope in the community.",
  "seo.default.meta.description":
    "HKT introduces Club Hope, an online public welfare platform,  enabling us to join forces with the public to help the needy and raise much-needed support for charity organizations. Your support will kindle a flame of hope in the community.",

  "seo.town.description":
    "You can visit different zones in the game city, namely Disability & Special Needs, Animal Welfare, Music and Arts, Elderly Care and Eco & Social Caring.",

  "seo.myhome.description":
    'You can review records of your level progression, transaction history, virtual badges and certificates under "My Home" when logged in.',

  "seo.zone.eco-and-social.description":
    "In Eco and Social Caring zone, your support will provide food and meals for the needy. You can also support children with illness to purchase rehabilitation equipment and create a wish journey for them.   ",
  "seo.zone.music-arts-and-education.description":
    "In Music and Arts zone, you can support children to attend music play groups and musical training. You can also purchase artwork by disabled people.",
  "seo.zone.elderly.description":
    "In Elderly Care zone, your support enables early diagnosis for people with suspected dementia. You can also support social welfare agencies to donate prevention materials, provide escort service and home cleaning for the elderly.",
  "seo.zone.animals-and-pets.description":
    "In Animal Welfare zone,  you can support to provide shelters for stray animals. You can sponsor neuter service, disease protection ,veterinary services and canned food for animals. You can also support guided dog training in Hong Kong.",
  "seo.zone.disability-and-healthcare.description":
    "In Disability and Special Needs zone, you can support Down syndrome and autistic communities, underprivileged children with speech delay, and people with mental illness. ",

  "rewards.certificate": "Donation Certificate",
  "rewards.issue_date": "Issue Date: {date}",
  "rewards.BADGE_FIRST_TIME_DONATION": "First Time Donation",
  "rewards.CERTIFICATE_DONATE_ALL_CATEGORIES":
    "Donate to at least one charity in all zones",

  "quick_donation.donate_now_title": "Donation",
  "quick_donation.donate_now_p1":
    "Your donation will directly help the charitable organizations.",
  "quick_donation.donate_now_p2":
    "All cash donations of HK$100 or above are tax-deductible and donation receipt will be issued by the selected beneficiary NGO, except for purchase of merchandise or transactions made with Clubpoints via Club Hope. ",
  "quick_donation.donate_now_p3":
    "“Donation” can only be settled by credit card payment, and Clubpoints deduction is not applicable.",
  "quick_donation.donate_now_p4":
    "According to the Government’s Consumption Voucher Scheme, consumption vouchers cannot be used for donation. Please note that payment with Tap & Go MasterCard on the Club Hope platform will be temporarily suspended from August 1, 2021 until 31 January, 2022.",
  "quick_donation.category": "Category",
  "quick_donation.donate_now": "Donate Now",
  "quick_donation.more_detail": "More NGO details",
  "quick_donation.less_detail": "Show Less",
  "quick_donation.ngo_intro": "Introduction",
  "quick_donation.ngo_website": "Webstie",
  "quick_donation.ngo_video": "Video",
  "quick_donation.donation_to": "Donate now to support {name}",
  "quick_donation.donation_type": "Donation Type",
  "quick_donation.oneoff": "One-off donation",
  "quick_donation.subscription": "Monthly donation (For 12 months)",
  "quick_donation.donation_options": "Enter an amount to donate",
  "quick_donation.donation_amount": "Enter an amount to donate",
  "quick_donation.per_month": "/ Per Month",
  "quick_donation.donation_helper1": "Minimum amount: HK$50",
  "quick_donation.donation_helper2": "(HK$50 per unit)",
  "quick_donation.i_want_donation": "Donate Now",

  cancel: "Cancel",
  copied: "Copied",
  done: "Done",
  save: "Save",
  next: "Next",
  submit: "Submit",
  not_enought_club_point: "Minimum Clubpoints is required",
  required: "Required",
  go_to_settings: "Go to Settings",
  "field.error_message": "Please enter a valid {field}",
  "select.placeholder": "Please Select",
  "alert.error.title": "Error",
  "alert.error.message": "Sorry! Something went wrong. Please try again.",
  "alert.button.ok": "OK",
  "alert.button.back": "Back",
  "alert.session_expired": "Your session has expired, please log in again.",
  "alert.session_expired.button.login_again": "Log In Again",
  "alert.session_expired.button.cancel": "Cancel",
  "alert.deeplink.invalid_url": "Invalid URL ({URL})",
  "alert.deeplink.email_verification.fail": "Fail to activate your account",
  "alert.deeplink.change_email_verification.fail":
    "Fail to verify your email update",
  "alert.fetch_config.error.title": "Cannot get configuration. Please retry.",
  "alert.fetch_config.error.retry": "retry",
  "alert.confirm_exit_app": "Do you want to quit the app?",
  "alert.confirm_exit_app.yes": "Yes",
  "alert.confirm_exit_app.no": "No",
  "in_app_browser.cancel": "Cancel",
  hello_world: "Hello world",
  skip_for_now: "Skip For Now",
  email_address: "Email Address",
  "tab.category": "Category",
  "tab.articles": "Discovery",
  "tab.home": "Home",
  "tab.likes": "Likes",
  "tab.account": "Account",
  "tab.rewards": "Rewards",
  login: "Sign In",
  "login_or_register": "Login or Register",
  "login.not_yet_pair": "Not yet paired with The Club account?",
  "login.login_with_clubshopping": "Login with Club Shopping Account",
  "login.clubshopping_account_deprecate": "Club Shopping login will no longer be available soon. Pair up with a The Club account to enjoy great shopping experience with us!",
  "login.visitor": "Visitor",
  "login.user_login": "Sign In",
  "login.login_with_social": "Login with The Club",
  "login.club_points_promo_text":
    "Spend less using {b, react, children{Clubpoints}} and earn them by linking your account at {b, react, children{The Club}}",
  "login.or_login_with_email": "Login with Email",
  "login.forgot_password": "Forgot Password?",
  "login.do_not_have_an_account.bak":
    "Do not have an account? {div, react, className{{createAccountClassName}} onClick{{onCreateAccountButtonClick}} children{Create Account}}",
  "login.do_not_have_an_account":
    "Do not have an account? {div, react, className{{createAccountClassName}} onClick{{onCreateAccountButtonClick}} children{Create Account}}",
  "login.login_failed": "Login Failed",
  "login.login_failed_text":
    "Invalid email or password, or the account is not activated.",
  "login.login_with_oauth_failed_text": "Cannot login. Please try again",
  "login.logging_in": "Logging In",
  email: "Email",
  password: "Password",
  redeem: "Redeem",
  remove: "Remove",
  or: "OR",
  "clubpoint_widget.use_your_clubpoint": "Spend less with Clubpoints",
  "clubpoint_widget.tax_disclaimer":
    "(Tax-deductible receipts will not provide for purchasing of merchandise or using Clubpoints for transactions made via Club Hope.)",
  "clubpoint_widget.clubpoint_used": "Use Clubpoints",
  "clubpoint_widget.per_block": "{perBlock} = Less HK$1",
  "clubpoint_widget.total_amount": "Amount Payable",
  verify_the_club: "Verify Your Account",
  "verify_the_club.description":
    "Protect your The Club membership account from unauthorized transactions. Please verify your registered email address and mobile number.",
  "verify_the_club.verify_now": "Verify Now",
  "verify_the_club.cancel": "Cancel",
  "verify_the_club.ask_refresh":
    "Please {RefreshButton, react, onClick{{handleRefreshClick}}} after verified your registered email address and mobile number.",
  "verify_the_club.refresh": "click here to refresh",
  "verify_the_club.refresh.still_not_verified":
    "Your account is not yet verified. Please try again later.",
  order_summary: "Order Summary",
  "order_summary.subtotal": "Subtotal",
  "order_summary.total": "Amount Payable",
  "order_summary.clubpoints_required": "Deduct Clubpoints",
  "order_summary.clubpoints_used": "Use Clubpoints",
  "order_summary.extra_clubpoints_used": "Use Extra Clubpoints",
  "order_summary.extra_item.discount": "Discount",
  "order_summary.extra_item.delivery_fee": "Shipping",
  "order_summary.extra_item.initial_subscription_fee":
    "Initial Subscription Fee",

  "order_summery.recurring.payment_cycle":
    "Payment Method: Charged on monthly basis",
  "order_summery.recurring.start_date":
    "Payment Start Date of Purchase of Service: {date}",
  "order_summery.recurring.end_date":
    "Payment End Date of Purchase of Service: {date}",
  "order_summery.recurring.duration": "Duration: {months} months",

  "category.all": "All",
  "category.view_all": "View All",
  "category.view_more": "View More",
  "category.not_found": "Category Not Found",
  no_internet_connection: "No Internet Connection",
  no_internet_description: "you're offline. Check your connection.",
  tap_to_retry: "Tap to Retry",
  "page.article_list.latest_post.title.bak": "LIKE Discovery",
  "page.article_list.latest_post.title": "LIKE Discovery",
  "page.article_list.popular_post.title": "POPULAR ARTICLES",
  "page.article_list.cell.post_date": "Posted: {PUBLISHED_DATE}",
  "page.article_list.cell.categories": "Categories: {CATEGORY_LIST}",
  "page.article_list.cell.read_more": "Read More",
  "page.article_detail.post_date": "Posted: {PUBLISHED_DATE}",
  "page.article_detail.categories":
    "Categories: {span, react, children{{CATEGORY_LIST_ELEMENTS}}}",
  "page.article_detail.tags":
    "Tags: {span, react, children{{TAG_LIST_ELEMENTS}}}",
  "page.article_detail.author":
    "Author: {span, react, children{{AUTHOR_ELEMENT}}}",
  "page.article_detail.alert.share_error": "Fail to share",
  "page.article_detail.share.message": `{ARTICLE_TITLE}
{ARTICLE_URL}`,
  "page.forgot_password.title": "Forgot Password",
  "page.forgot_password.instruction":
    "Please enter your e-mail.{br, react}We will send you a link to reset your password.",
  "page.forgot_password.input.email.label": "Email",
  "page.forgot_password.request_success":
    "You will receive an email with a link to reset your password.",
  "page.forgot_password.button.submit": "SUBMIT",
  "page.forgot_password.error.invalid_email_format": "Invalid email format",
  "page.forgot_password.error.fail_reset_password":
    "Email address is incorrect, please try again.",
  "page.product_detail.navbar.title": "Product Details",
  "page.product_detail.alert.product_not_found.message": "Product not found",
  "page.product_detail.sku":
    "{span, react, children{SKU#:}}\xa0{span, react, children{{PRODUCT_SKU}}}",
  "page.product_detail.subscribe_restock.success":
    "Subscribe to stock notification success.",
  "page.product_detail.subscribe_restock.failed":
    "Subscribe to stock notification failed. Please try again later.",
  "page.product_detail.button.add_to_cart": "ADD TO CART",
  "page.product_detail.button.subscribe_restock": "Notify me",
  "page.product_detail.button.customer_reviews":
    "{numberOfCustomerReviews, plural, one{1 customer review} other{# customer reviews}}",
  "page.product_detail.button.q_and_a_count":
    "{numberOfQAndA, plural, one{1 answered question} other{# answered questions}}",
  "page.product_detail.button.add_review": "+ Add Review",
  "page.product_detail.button.login_and_add_review": "Login to write a review",
  "page.product_detail.button.read_all_reviews":
    "READ ALL {numberOfCustomerReviews, plural, one{1 REVIEW} other{# REVIEWS}}",
  "page.product_detail.product_will_earn_club_points":
    "Earn {clubPoints, plural, one{1 Club Point} other{# Club Points}} on purchase",
  "page.product_detail.club_points_rebate_with_rebate_amount_currency":
    "Purchase this item to get {rebateAmount}% rebate: Earn a total of basic and extra rebate of {earnClubPointAmount} Clubpoints which is equivalent to HK${earnClubPointAmountInCurrency} off your next purchase.",
  "page.product_detail.club_points_rebate_with_rebate_amount":
    "Purchase this item to get {rebateAmount}% rebate: Earn a total of basic and extra rebate of {earnClubPointAmount} Clubpoints.",
  "page.product_detail.club_points_rebate":
    "Earn {earnClubPointAmount} Clubpoints on purchase.",
  "page.product_detail.clubpoints_calculator": "Spend less with Clubpoints",
  "page.product_detail.tax_disclaimer":
    "(Tax-deductible receipts will not provide for purchasing of merchandise or using Clubpoints for transactions made via Club Hope.)",
  "page.product_detail.button.try_now": "Try Now!",
  "page.product_detail.button.third_party_url": "Redeem",
  "page.product_detail.num_users_likes_this_item":
    "{likeCount, plural, one{1 person} other{# people}} Likes this item",
  "page.product_detail.product_description.title": "Description",
  "page.product_detail.delivery_method.title": "Delivery Method",
  "page.product_detail.promotions.title": "Promotions",
  "page.product_detail.similar_product.title": "SIMILAR PRODUCTS",
  "page.product_detail.detail_description.title": "PRODUCT DETAILS",
  "page.product_detail.specification.title": "PRODUCT SPECIFICATION",
  "page.product_detail.product_configuration.form.item_picker.placeholder":
    "Please select an option",
  "page.product_detail.product_configuration.form.button.done": "Done",
  "page.product_detail.purchase_product.button.add_to_cart": "ADD TO CART",
  "page.product_detail.purchase_product.form.radio.none": "None",
  "page.product_detail.purchase_product.form.color_picker.title":
    "Color Options",
  "page.product_detail.purchase_product.form.item_picker.placeholder":
    "Please select an option",
  "page.product_detail.purchase_product.form.item_picker.product_option.in_stock":
    "{OPTION}",
  "page.product_detail.purchase_product.form.item_picker.product_option.sold_out":
    "{OPTION} (SOLD OUT)",
  "page.product_detail.purchase_product.form.subscribe.not_subscribe":
    "Purchase only once.",
  "page.product_detail.purchase_product.form.subscribe.subscribe":
    "Subscribe to this product.",
  "page.product_detail.purchase_product.form.ree.service":
    'Regulated Electrical Equipment ("REE") Removal Service',
  "page.product_detail.purchase_product.form.ree.service.free_recycle":
    "Free statutory removal service",
  "page.product_detail.purchase_product.form.ree.service.no_recycle":
    "Decline free statutory removal service",
  "page.product_detail.purchase_product.form.ree.service.decide_later":
    "To be decided, will contact CS within 3 working days",
  "page.product_detail.purchase_product.form.ree.service.disclaimer":
    "I have read and agreed to be bound by {span, react, children{the terms and conditions of the statutory free removal service} onClick{{onClickDisclainerLink}} className{{disclaimerLinkClassName}}}.",
  "page.product_detail.purchase_product.form.club_protect.banner.main_text":
    "12-month {span, react, children{Electronics Extra Protection} className{{mainTextHighlight}}} plan",
  "page.product_detail.purchase_product.form.club_protect.main_text":
    "Complimentary 12-months Smart Protection",
  "page.product_detail.purchase_product.form.club_protect.service":
    "Accidental damage protection limit up to HK${protectionAmount}",
  "page.product_detail.club_protect_modal.title":
    "Smart Protection# Experience Carefree Shopping",
  "page.product_detail.club_protect_modal.body":
    "Smart Protection includes:{ul, react, children{{li, react, children{12 months accidental damage protection}}{li, react, children{Total maximum claim amount up to HK$20,000}}}}",
  "page.product_detail.club_protect_modal.ps":
    "# Terms and Conditions apply. Please refer to Policy Provision for details. This Smart Protection (“the Protection”) is underwritten by FWD General Insurance Company Limited (“FWD GI”), and HKT Financial Services (IA) Limited acts as an appointed licensed insurance agent for FWD GI to arrange the Protection and provide related services.",
  "page.product_detail.club_protect_modal.policy_provision": "Policy Provision",
  "page.product_detail.club_protect_modal.important_note": "Important Notes",
  "page.product_detail.club_protect_modal.product_terms_and_conditions":
    "Product Terms and Conditions",
  "page.product_detail.club_protect_modal.faq": "Learn More",
  "page.product_detail.club_protect_banner.main_text":
    "Acquire Smart Protection",
  "page.product_detail.club_protect_banner.details": "Details",
  "try_clubpoint.cannot_use_clubpoint":
    "“Donation” item(s) in the shopping cart, please settle your payment by credit card. Clubpoints deduction is not applicable.",
  "try_clubpoint.pay_less_with_clubpoints": "Spend less with Clubpoints",
  "try_clubpoint.tax_disclaimer":
    "(Tax-deductible receipts will not provide for purchasing of merchandise or using Clubpoints for transactions made via Club Hope.)",
  "try_clubpoint.per_block": "{perBlock} = Less HK$1",
  "try_clubpoint.clubpoint": "Clubpoint",
  "try_clubpoint.cash": "Amount Payable",
  "page.product_image_gallery.button.description_read_more": "read more",
  "page.product_image_gallery.button.description_show_less": "show less",
  "page.product_image_gallery.description_with_read_more":
    "{content}… {ReadMore, react, onClick{{onClickReadMore}}}",
  "page.product_image_gallery.description_with_show_less":
    "{content} {ShowLess, react, onClick{{onClickShowLess}}}",
  "page.product_image_gallery.image_360_hint": "Drag image to spin",
  "product.sale": "SALE",
  "product.hot": "Hot",
  "product.new": "New",
  "product.clubpoint_redemption": "Rewards",
  "product.add_to_cart": "Add To Cart",
  "product.add_to_cart.small": "Cart",
  "product.add_to_cart_success.dev": "[TODO] Add To Cart Success",
  "product.details": "Details",
  "product.as_low_as": "As low as",
  "product.from": "From",
  "product.sold_out": "SOLD OUT",
  "product.subscribe_restock": "Notify me",
  "product.subscribe_restock.success":
    "The in-stock reminder has been set successfully. We will send notification email to you once restock.",
  "product.subscribe_restock.failed":
    "Subscribe to stock notification failed. Please try again later.",
  "product.per_item":
    "{ProductPriceViewInMessageFormat, react, product{{product}} extraPrice{{extraPrice}}} / item",
  "page.product_detail.bundle_sale.title": "Bundle Sale",
  "page.product_detail.product_reviews.title": "Product Reviews",
  "page.product_detail.product_reviews.no_reviews": "No Reviews Yet",
  "page.product_detail.product_reviews.merchant_reply": "Merchant Reply",
  "page.product_detail.subscription_info.title": "Subscription Info",
  "page.product_detail.subscription_info.billing_cycle": "Donation type",
  "page.product_detail.subscription_info.free_trials": "Free Trials",
  "page.product_detail.subscription_info.free_shipping":
    "Free Shipping on This Product",
  "page.product_detail.subscription_info.start_date": "Donation Start Date",
  "page.product_detail.subscription_info.end_date": "Donation End Date",
  "page.product_detail.club_tier_quota_message.quota_message.bak":
    "Limited to only {max, plural, =1{# purchase} other{# purchases}} per each registered Club Shopping account.",
  "page.product_detail.club_tier_quota_message.quota_message":
    "Limited to only {max, plural, =1{# purchase} other{# purchases}} per each registered Club Shopping account.",
  "page.product_detail.club_tier_quota_message.member_quota_message":
    "Limited to only {max, plural, =1{# purchase} other{# purchases}} per each registered The Club account.",
  "page.product_detail.club_tier_quota_message.club_tier_quota_message":
    "Limited to only {max, plural, =1{# purchase} other{# purchases}} per each registered {clubTier} account.",
  "page.search_suggestion.list.filtered_listing.title": "Categories",
  "page.search_suggestion.list.hot_searches.title": "Hot Searches",
  "page.search_suggestion.list.popular_searches.title": "Popular Searches",
  "page.search_suggestion.list.product_searches.title": "Products",
  "page.search_suggestion.list.recently_searches.title": "Recently Searches",
  "page.search_suggestion.list.view_all": "View all ({TOTAL_COUNT})",
  "page.search_suggestion.navbar.button.cancel": "Cancel",
  "page.search_suggestion.navbar.search_input.placeholder":
    "Search Products / Merchants",
  "page.search.result_empty.title":
    "NO RELEVANT RESULT WAS FOUND FOR {span, react, className{{SEARCH_TERM_HIGHLIGHT_CLASSNAME}} children{{SEARCH_TERM}}}",
  "page.search.result_empty.back_to_storefront": "BACK TO STOREFRONT",
  "page.likes.wish_list.empty.title": "You have no items in your Like list",
  "page.likes.wish_list.empty.description":
    "Go shopping and pick your favourites.",
  "page.likes.wish_list.empty.button.lets_go_shopping": "SHOP NOW",
  "page.notification_settings.title": "Notification",
  "page.notification_settings.enable_notification": "Show Notification",
  "page.notification_settings.promotion_notification.bak":
    "Club Shopping Sales & Promotion",
  "page.notification_settings.promotion_notification":
    "Club Shopping Sales & Promotion",
  "page.notification_settings.order_notification": "Order & Shipping Status",
  "filter.category.title": "Category",
  "filter.secondary-category.title": "Secondary Category",
  "filter.sort.relevance": "Relevance",
  "filter.sort.recommendation": "Recommendation",
  "filter.sort.price": "Price",
  "filter.sort.product_name": "Product Name",
  "filter.sort-by": "Sort By",
  "filter.sort.ascending": "Ascending",
  "filter.sort.descending": "Descending",
  "filter.newest-arrivals": "Newest Arrivals",
  "filter.general": "General Filtering",
  "filter.reset-all": "Reset All",
  "filter.reset": "Reset",
  "filter.price": "Price Filtering (HKD)",
  "filter.apply": "Apply",
  "filter.cancel": "Cancel",
  "filter.title": "Filter",
  "filter.clear_all": "Clear all",
  "filter.applied_filters.range_format": "{title}: {min} - {max}",
  "filter.cp_predefined": "Product/Rewards",
  "filter.predefined.all_items": "All Items",
  "filter.predefined.no_min_club_point_products": "Merchandise",
  "filter.predefined.club_point_products": "Rewards",
  signup: "Sign Up",
  "signup.create_account.bak": "Create an account",
  "signup.create_account": "Create an account",
  "signup.already_have_an_account.bak":
    "Already have an account? {div, react, className{{loginButtonClassName}} onClick{{onLoginButtonClick}} children{Login Here}}",
  "signup.already_have_an_account":
    "Already have an account? {div, react, className{{loginButtonClassName}} onClick{{onLoginButtonClick}} children{Login Here}}",
  "signup.receive_marketing_materials.bak":
    "I want to receive your marketing materials",
  "signup.receive_marketing_materials":
    "I want to receive your marketing materials",
  "signup.accept_terms":
    "I have read and hereby agree to all the relevant terms and conditions, including {a, react, className{{termsAndPrivacyPolicy}} href{{termsAndConditionsLink}} target{_blank} children{Terms & Conditions} onClick{{handleLinkClick}}}, {a, react, className{{termsAndPrivacyPolicy}} href{http://www.hkt.com/Privacy+Statement?language=en_US} target{_blank} children{Privacy Statement} onClick{{handleLinkClick}}}, {a, react, className{{termsAndPrivacyPolicy}} href{{privacyPolicyLink}} target{_blank} children{Personal Information Collection Statement} onClick{{handleLinkClick}}} and relevant Key Information Sheet(s).",
  "signup.signup_failed": "Signup Failed",
  "signup.signup_with_social": "Signup with",
  "signup.or_signup_with_email": "Or Signup with Email",
  "signup.email_already_in_use": "Email already in use.",
  "signup_to_shop.title": "SIGN-IN TO SHOP",
  "signup.social_signup_email_used_fail_title": "There was a problem",
  "signup.social_signup_email_used_fail_text.bak":
    "Your account is already connected to another Club Shopping account. Please unlink first.",
  "signup.social_signup_email_used_fail_text":
    "Your account is already connected to another Club Shopping account. Please unlink first.",
  first_name: "First Name",
  last_name: "Last Name",
  tel: "Tel",
  "password.weak": "Weak",
  "password.good": "Good",
  "password.strong": "Strong",
  "password.requirement":
    "Minimum 8 characters with lower case, upper case, digits and special characters.",
  try_again: "Try Again",
  registration_successful: "Activation Email has been sent",
  "signup_success.text.bak":
    "An activation email has been sent to your email address. Please check your email before explore about Club Shopping",
  "signup_success.text":
    "An activation email has been sent to your email address. Please check your email before explore about Club Shopping",
  "signup_success.shop": "Shop",
  "page.display_language.title": "Display Language",
  "page.select_category.done":
    "Done {numOfSelectedCategories, plural, =0{} other{({numOfSelectedCategories})}}",
  "page.select_category.select_your_interest": "Select Your Interest",
  "page.select_category.fail": "Select Category Failed",
  "page.select_category.fail_text":
    "Cannot select interest category. Please try again",
  "page.select_category.description.bak":
    "Thank you for confirming your Club Shopping account. Tell us what you are interested in (select as many as you want). Your answers will help us to find the right ideas for you.",
  "page.select_category.description":
    "Thank you for confirming your Club Shopping account. Tell us what you are interested in (select as many as you want). Your answers will help us to find the right ideas for you.",
  "page.settings.orders": "My Orders",
  "page.settings.subscriptions": "My Subscriptions",
  "page.settings.reviews": "My Reviews",
  "page.settings.redemption": "Redeem Rewards",
  "page.settings.redemption_order":
    "Rewards Order {span, react, children{(Before Sep 1, 2020)} className{{redemptionDateStyle}}}",
  "page.settings.redemption_order_date": "Before 1/9/2020",
  "page.settings.address_book": "Address Book",
  "page.settings.my_cards": "My Cards",
  "page.settings.display_language": "Display Language",
  "page.settings.notifications": "Notifications",
  "page.settings.change_password": "Change Password",
  "page.settings.about_us": "About Us",
  "page.settings.help_center": "Helpe Center",
  "page.settings.logout": "Logout",
  "page.account.edit": "Edit",
  "page.account.signup": "Create an Account",
  "page.account.login": " / Sign In",
  "page.account.enjoy_redeeming_rewards":
    "Enjoy redeeming rewards? Link with the Club now!",
  "page.account.club_points_earned.label": "Clubpoints",
  "page.account.club_points_earned": "{clubPointsStr}",
  "page.account.club_tier":
    "{tier, select, blue{Blue} silver{Silver} gold{Gold} platinum{Platinum} black{Black} other{}}",
  "page.edit_profile.alert.cannot_fetch_customer":
    "Cannot update profile at the moment",
  "page.edit_profile.done": "Done",
  "page.edit_profile.title": "Edit profile",
  "page.edit_profile.change_profile_picture": "Change Profile Picture",
  "page.edit_profile.subscribe_newsletter": "Subscribe newsletter",
  "page.edit_profile.linked_account": "Linked Account",
  "page.edit_profile.my_email": "My Email",
  "page.edit_profile.verified_account": "Verified account",
  "page.edit_profile.change": "CHANGE",
  "page.edit_profile.update_profile_picture_error":
    "Cannot update profile picture",
  "page.edit_profile.update_profile_picture.error.from_photo_blocked":
    "User denied access to photo album. Please change the System Settings.",
  "page.edit_profile.update_profile_picture.error.from_photo_denied":
    "User denied access to photo album.",
  "page.edit_profile.update_profile_picture.error.take_picture_blocked":
    "User denied access to camera. Please change the System Settings.",
  "page.edit_profile.update_profile_picture.error.take_picture_denied":
    "User denied access to camera.",
  "page.edit_profile.update_profile_error": "Cannot update profile",
  "page.edit_profile.connect_account_error": "Cannot connect account",
  "page.edit_profile.link_account.error.email_registered": "There is a problem",
  "page.edit_profile.link_account.error.email_registered.message.bak":
    "Your The Club account is already connected to another Club Shopping account. Please unlink first.",
  "page.edit_profile.link_account.error.email_registered.message":
    "Your The Club account is already connected to another Club Shopping account. Please unlink first.",
  "page.edit_profile.disconnect_account_error": "Cannot disconnect account",
  "page.edit_profile.disconnect_account_confirm":
    "Please confirm if you would like to unlink your {provider} account?",
  "page.edit_profile.disconnect_account_confirm.yes": "Yes",
  "page.edit_profile.disconnect_account_confirm.no": "No",
  "page.edit_profile.action_sheet.camera.from_photos": "From Photos",
  "page.edit_profile.action_sheet.camera.take_picture": "Take Picture",
  "page.edit_profile.action_sheet.camera.cancel": "Cancel",
  "page.my_delivery.title": "Delivery Info",
  "wishlist.add_to_wishlist.error": "Cannot add product to wishlist",
  "wishlist.remove_from_wishlist.error": "Cannot remove product from wishlist",
  "my_delivery.no_stored": "NO STORED DELIVERY ADDRESS",
  "my_delivery.add_new_address": "+ ADD NEW ADDRESS",
  "my_delivery.edit": "Edit",
  "my_delivery.set_as_default_billing": "Set as default billing address",
  "my_delivery.unable_set_as_default_billing":
    "Unable to set default billing address",
  "my_delivery.set_as_default_shipping": "Set as default shipping address",
  "my_delivery.unable_set_as_default_shipping":
    "Unable to set default shipping address",
  "my_delivery.delete": "Delete",
  "my_delivery.unable_delete": "Unable to delete address",
  "my_delivery.delete.ask":
    "Please confirm if you would like to delete the address?",
  "my_delivery.shipping": "Shipping",
  "my_delivery.billing": "Billing",
  "page.add_address.title": "Add New Address",
  "page.edit_address.title": "Edit Address",
  "add_address.info": "Info",
  "add_address.address": "Address",
  "add_address.use_as_default_billing": "Use as my default billing address",
  "add_address.use_as_default_shipping": "Use as my default shipping address",
  "page.my_orders.title": "My Orders",
  "my_orders.order_info.order_id": "Order ID",
  "my_orders.order_info.quantity": "Quantity",
  "my_orders.order_info.order_total": "Grand Total",
  "my_orders.order_info.order_date": "Order Date",
  "my_orders.order_info.sub_total": "Sub-Total",
  "my_orders.order_info.club_point_earned": "Club Point Earned",
  "my_orders.order_info.club_point_earned_pts":
    "{pts, plural, one{#pt} other{#pts}}",
  "my_orders.order_info.club_protect_enabled":
    "Smart Protection covers one or all of the order items",
  "my_orders.order_info_details": "Details",
  "my_orders.status": "Status",
  "my_orders.status.canceled": "Canceled",
  "my_orders.status.closed": "Closed",
  "my_orders.status.complete": "Complete",
  "my_orders.status.pending": "Pending",
  "my_orders.status.pending_clubpoint": "Pending Club Points",
  "my_orders.status.pending_payment": "Pending Payment",
  "my_orders.status.processing": "Processing",
  "my_orders.no_orders_yet": "OPPS! NO ORDERS YET",
  "my_orders.lets_go_shopping": "LET'S GO SHOPPING",
  "order_detail.title": "Order Details",
  "order_detail.status.canceled": "Order Canceled",
  "order_detail.status.closed": "Order Closed",
  "order_detail.status.complete": "Order Complete",
  "order_detail.status.pending": "Order Pending",
  "order_detail.status.pending_clubpoint": "Pending Club Points",
  "order_detail.status.pending_payment": "Pending Payment",
  "order_detail.status.processing": "Order Processing",
  "order_detail.order_id": "Order ID",
  "order_detail.purchased_items": "Items Ordered",
  "order_detail.purchased_items.count":
    "{count, plural, one{# Item} other{# Items}}",
  "order_detail.product.title": "Product Name",
  "order_detail.item.club_protect.service":
    "Protection up to HK${protectionAmount} {span, react, children{FREE} className{{highlightClassName}}}",
  "order_detail.item.club_protect.service.joined": "Joined",
  "order_detail.item.club_protect.agreement_1":
    "I have read and understood the {a, react, children{Product Terms and Conditions} className{{agreementLinkClassName}} onClick{{productTermsAndConditionsClick}}} and {a, react, children{Terms & Conditions for the coverage of Electronics Extra Protection} className{{agreementLinkClassName}} onClick{{protectionTermsAndConditionsClick}}}, and I agreed to pass the above information to HKT Financial Services (IA) Limited (“HKTIA”) or FWD General Insurance Company Limited (“FWD GI”) for the purpose of applying for Electronics Extra Protection.",
  "order_detail.item.club_protect.agreement_2":
    "I hereby declare and confirm that I have read, understood and agreed to the 1) Declarations, 2) {a, react, children{Personal Information Collection Statement of HKT Financial Services (IA) Limited (“HKTIA PICS”)} className{{agreementLinkClassName}} onClick{{hktiaClick}}} and 3) {a, react, children{Personal Information Collection Statement of FWD General Insurance Company Limited (“FWD PICS”)} className{{agreementLinkClassName}} onClick{{fwdClick}}}, and agree to comply and be bound by HKTIA PICS and FWD PICS. (HKTIA PICS and FWD PICS, collectively, the “PICS”). I also consent to the use of my personal information in accordance with the PICS.",
  "order_detail.item.club_protect.in_protection":
    "This item is protected by Smart Protection",
  "order_detail.club_protect_modal.insurance_name":
    "Name of Protection: 12-months Smart Protection",
  "order_detail.club_protect_modal.insurance_period":
    "Protection Period: From {fromDate} to {toDate}",
  "order_detail.club_protect_modal.policy_provision": "Policy Provision",
  "order_detail.club_protect_modal.product_terms_and_conditions":
    "Product Terms and Conditions",
  "order_detail.club_protect_modal.faq": "Frequently Asked Questions",
  "order_detail.club_protect_modal.claim": "About Claim",
  "order_detail.promo_code_text": "Promo Code {index}: {promoCodeUrl}",
  "order_detail.promo_code_url":
    "Promo Code {index}: {a, react, children{{promoCodeUrl}} href{{promoCodeUrl}} onClick{{onLinkClicked}}}",
  "order_detail.quantity": "Quantity",
  "order_detail.price": "Price",
  "order_detail.subtotal": "Subtotal",
  "order_detail.club_point_earned": "Clubpoints Earned",
  "order_detail.reorder": "Reorder",
  "order_detail.review": "Review Item",
  "order_detail.view_delivery": "View Delivery",
  "order_detail.view_insurance": "View Smart Protection",
  "order_detail.redeem": "Redeem",
  "order_detail.order_details": "Order Details",
  "order_detail.billing_info": "Billing Info",
  "order_detail.delivery_method": "Shipping Method",
  "order_detail.delivery_time": "Order Delivery Details",
  "order_detail.address": "Shipping Address",
  "order_detail.billing_address": "Billing Address",
  "order_detail.payment_method": "Payment Method",
  "order_detail.promotions": "Promotions",
  "order_detail.description": "Description",
  "order_detail.payment_information": "Payment Information",
  "order_detail.payment_info.subtotal": "Subtotal",
  "order_detail.payment_info.club_points_required": "Deduct Clubpoints",
  "order_detail.payment_info.extra_club_points_used": "Use Extra Clubpoints",
  "order_detail.payment_info.club_points_used": "Use Clubpoints",
  "order_detail.payment_info.discount": "Discount",
  "order_detail.payment_info.shipping": "Shipping",
  "order_detail.payment_info.amount_payable": "Amount Payable",
  "order_detail.payment_info.shipping_fee": "Shipping Fee",
  "order_detail.payment_info.initial_subscription_fee":
    "Initial Subscription Fee",
  "order_detail.customer_service_contact_us": "Customer Service / Contact Us",
  "order_detail.fulfillment_code": "Collection Point QR Code",
  "order_details_qr_codes_redemption.title": "QR Code Redemption",
  "order_details_qr_codes_redemption.empty": "No Redemption Codes",
  "order_details_shipment_status_modal.title": "Shipment Status",
  "view.share_with_friends.title": "Share with friends",
  "picker.quantity": "Quantity",
  "product_list.no_product": "No Products",
  "single_category_page.no_products.message":
    "Oops! all sold out. Try other categories?",
  "single_category_page.no_products.clear_filter_message": "No products found.",
  "single_category_page.back": "Back and explore",
  "single_category_page.clear_filter": "Show All",
  "qr_image_picker.no_image": "No original image detected",
  "qr_image_picker_confirm.choose": "Choose",
  "qr_image_picker_confirm.cancel": "Cancel",
  "qr_image_picker_confirm.scan_failed_title": "Scan Failed",
  "qr_image_picker_confirm.scan_failed_message":
    "Unable to find QR code from this image",
  "qr_scanner.title": "Scan QR code",
  "qr_scanner.hint":
    "Please align the QR code to the device at distance from 15-25cm",
  "qr_scanner_not_found.cancel": "Cancel",
  "qr_scanner_not_found.try_again": "Try Again",
  "qr_scanner_not_found.message":
    "The item may have been removed, or you can scan it again",
  "qr_scanner_not_found.title": "Can't Find The Record",
  "camera.blocked":
    "User denied access to camera. Please change the System Settings.",
  "camera.denied": "User denied access to camera.",
  "qr_scanner_success.text": "Success to Scan",
  "qr_scanner_success.message": "{DATA}",
  "qr_scanner_failed.text": "Failed to Scan",
  "qr_scanner_failed.message": "{REASON}",
  "last_photo_retriever.error": "Cannot access photo album",
  "last_photo_retriever.error_unauthorized":
    "User denied access to photo album. Please change the System Settings.",
  "photos.error": "Cannot access photo album",
  "photos.error_unauthorized":
    "User denied access to photo album. Please change the System Settings.",
  "add_to_cart.items_has_been_added_to_cart":
    "{numOfItems, plural, one{1 item has} other{# items have}} been added to cart",
  "add_to_cart.subtotal": "CART SUBTOTAL",
  "add_to_cart.checkout":
    "CHECKOUT ({numOfItems, plural, one{1 ITEM} other{# ITEMS}})",
  "add_to_cart.error.sold_out":
    "Sorry! Could not add the following product to the shopping cart. This product is currently out of stock.",
  "shopping_cart.title": "Shopping Cart",
  "empty_shopping_cart.title": "OOPS! THERE'S NOTHING IN YOUR CART",
  "empty_shopping_cart.lets_go_shopping": "LET'S GO SHOPPING",
  "shopping_cart.subsciptional": "This is Subscriptional Product",
  "shopping_cart.extra_clubpoints": "{extraClubpoints}x Clubpoints",
  "shopping_cart.rebate_amount": "{rebateAmount}% Rebate",
  "shopping_cart.add_to_wishlist": "Move to Like List",
  "shopping_cart.free_delivery_service":
    "* Free self-collection for orders from HK$200.0 or above",
  "shopping_cart.complete_order_to_earn_clubpoints":
    "Complete order to earn {IonIcon, react, src{{clubpointIconSrc}} className{{clubpointsHighlight}}} {clubpointsAmount} {clubpointsAmount, plural, one{Clubpoint} other{Clubpoints}}",
  "shopping_cart.points_will_be_transferred":
    "Clubpoints will be credited to The Club account approximately 10 working days after the product(s) is/are delivered to you",
  "shopping_cart.checkout": "CHECKOUT",
  "shopping_cart.no_changes_are_allowed":
    "No changes are allowed on order after proceeding to payment",
  "shopping_cart.link_with": "Link with",
  "shopping_cart.link_to_earn_point":
    "Login The Club account to spend and earn {IonIcon, react, src{{theClubIconSrc}} name{club-point} className{{theClubIconClassName}}}{clubpointsAmount} for this order",
  "shopping_cart.product_sold_out": "This product is sold out",
  "merchant_directories.title": "Merchant List",
  "merchant_details.title": "Merchant Details",
  "merchant_details.about": "About",
  "merchant_details.about.contact_number": "Contact Number",
  "merchant_details.about.opening_hours": "Opening Hours",
  "merchant_details.return_policy": "Return Policy",
  "merchant_details.shipping_policy": "Shipping Policy",
  "link_switch.link": "CONNECTED",
  "link_switch.unlink": "NOT CONNECTED",
  "single_merchant.featured": "Featured",
  "single_merchant.products": "All Products",
  "single_merchant.no_segment": "Please select a section",
  "single_merchant.featured.no_products.message":
    "No featured product, please browse all products.",
  "error.unknown": "Unknown error",
  "error.unable_to_find_product": "Unable to find product",
  "club_point_journey.title": "Club Point Journey",
  "club_point_journey.point": "{point} pts",
  "select_saved_address.title": "Select a Saved Address",
  "address_card.title.index": "Address {index}",
  "address_card.name.title": "Name",
  "address_card.telephone.title": "Tel",
  "address_card.company.title": "Company",
  "address_card.address.title": "Address",
  "address_form.first_name": "First Name",
  "address_form.last_name": "Last Name",
  "address_form.company": "Company",
  "address_form.telephone": "Mobile",
  "address_form.telephone.purpose":
    "(For self-collection & notification purpose)",
  "address_form.address": "Address",
  "address_form.room_number": "Room no.",
  "address_form.room_number.example": "(e.g. Flat A)",
  "address_form.flat_or_floor": "Flat / Floor",
  "address_form.flat_or_floor.example": "(e.g. 43/F)",
  "address_form.block": "Block",
  "address_form.block.example": "(e.g. Block 1)",
  "address_form.building": "Building",
  "address_form.building.example":
    "(e.g. Tien Sing Mansion, Sing Fai Terrace, Taikoo Shing)",
  "address_form.street": "Street",
  "address_form.street.example": "(e.g. 18 Hennessy Road)",
  "address_form.district": "District",
  "address_form.district.placeholder": "Please Select Area",
  "address_form.area": "Area",
  "address_form.area.placeholder": "Please Select Area",
  "address_form.region": "Region",
  "address_form.region.placeholder": "Please Select Region",
  "address_form.non_delivery_area": "Click here to view the Non-delivery area",
  "non_delivery_area_modal.title": "Non-delivery area",
  "self_pickup_form.region": "Area",
  "self_pickup_form.district": "District",
  "self_pickup_form.store_type": "Type",
  "self_pickup_form.store_type.all": "All",
  "self_pickup_form.store_type.o2o_store": "Self-Collection Point",
  "self_pickup_form.store_type.elocker": "Smart Locker",
  "self_pickup_form.store_type.o2o_fulfillment": "Self-Collection And Pick Up",
  "self_pickup_form.store_type.unknown": "Unknown Type",
  "self_pickup_form.pickup_spot": "Location",
  "self_pickup_form.opening_hours": "Service Hours",
  "update_delivery_endpoint_for_o2o_store.error.no_telephone":
    "Please fill in telephone",
  "update_delivery_endpoint_for_o2o_store.error.no_o2o_store": "No Store Found",
  "promotion.code.title": "Enter coupon code",
  "promition.code.use": "Apply",
  "promition.code.value": "Applied Coupon Code - {code}",
  "promotion.code.error.invalid": "Coupon code is invalid.",
  "checkout.filling_info.title": "Checkout",
  "checkout.header.step.filling_info": "Filling Info",
  "checkout.header.step.confirmation": "Confirmation",
  "checkout.header.step.payment": "Payment",
  "checkout.header.step.ordered": "Ordered",
  "checkout.filling_info.select_a_saved_address": "Select a Saved Address",
  "checkout.filling_info.save_address.text": "Save this address for future",
  "checkout.filling_info.self_pickup.missing":
    "Please select self collection point",
  "checkout.filling_info.payment_option.free": "Use Clubpoints",
  "checkout.filling_info.payment_option.opp.new_card":
    "New Card<br>(Please input your credit card number in the next screen.)",
  "checkout.filling_info.payment_option.opp.save_card":
    "Save the card for next purchase",
  "checkout.filling_info.payment_option.opp.missing_card":
    "Please choose a card or new card",
  "the_club_otp.cancelled": "Clubpoints redemption is declined.",
  "asia_pay.cancelled": "User cancelled payment",
  "asia_pay.failed": "User failed to pay",
  "opp_payment.cancelled": "User cancelled payment",
  "opp_payment.failed": "User failed to pay",
  "checkout.filling_info.payment_option.tap_and_go": "Tap & Go",
  "checkout.filling_info.payment_option.missing":
    "Please select payment option",
  "checkout.delivery_info.title": "Shipping type",
  "checkout.delivery_info.delivery_address.text": "Delivery",
  "checkout.delivery_info.self_pickup.text": "Self-Collect",
  "checkout.delivery_info.missing": "Please select shipping method",
  "checkout.delivery_time.title": "Delivery Time",
  "checkout.delivery_time.choose_delivery_time.title": "Club Mall Delivery",
  "checkout.delivery_time.choose_delivery_time": "Choose delivery time",
  "choose_delivery_time.error.missing": "Please select address type",
  "checkout.billing_info.title": "Billing Info",
  "checkout.billing_info.p1":
    "All cash donations of HK$100 or above are tax-deductible and donation receipt will be issued by the selected beneficiary NGO, except for purchase of merchandise or transactions made with Clubpoints via Club Hope.",
  "checkout.billing_info.p2":
    "The personal information collected by beneficiary NGOs of Club Hope will be kept confidential and used for the purpose of issuing receipts. Please contact the related beneficiary NGOs for the matter of receipts.",
  "checkout.billing_info.same_as_delivery_address.text":
    "Same as Delivery Address",
  "checkout.billing_info.custom_address.text":
    "Different from Delivery Address",
  "checkout.billing_info.input_billing_address.text":
    "Please provide information to the beneficiary NGO for issuing donation receipt. [I consent to Club HKT Limited passing my personal data and other information (“Data”) to the selected beneficiary NGO(s) for issuing donation receipt purpose(s).]",
  "checkout.billing_info.missing": "Please select billing info",
  "checkout.payment_option.title": "Payment Option",
  "checkout.payment_info.title": "Payment Info",
  "checkout.payment_method.title": "Payment Method",
  "checkout.promotion.title": "Apply coupon code",
  "checkout.select_o2o_store.title": "Location",
  "checkout.confirmation.edit_button": "Edit",
  "checkout.confirmation.disclaimer.title": "Reminder - Donation Receipt",
  "checkout.confirmation.disclaimer.subtitle":
    "Donation receipt will be issued by the selected beneficiary NGO for cash donation of HK$100 or above for tax-exemption",
  "checkout.confirmation.disclaimer.subtitle_extended":
    "（Except for purchasing merchanises）",
  "checkout.confirmation.disclaimer.subtitle_fullstop": ".",
  "checkout.confirmation.disclaimer.extended_title":
    "Note: Receipt will not be issued for any purchase of merchandise or transactions made with Clubpoints via Club Hope which is not tax-deductible.",
  "checkout.confirmation.disclaimer.extended_p1": "Any use of Clubpoints; OR",
  "checkout.confirmation.disclaimer.extended_p2":
    "Transaction contains Club Hope merchanises",
  "checkout.confirmation.disclaimer.continue": "Proceed to Checkout",
  "checkout.confirmation.disclaimer.return": "Back to Shopping Cart",
  "checkout.ordered.title": "Payment Confirmed",
  "checkout.ordered.order_id": "Order ID",
  "checkout.ordered.message.title": "Thank you for your purchase",
  "checkout.ordered.button.my_transaction": "My Transaction",
  "checkout.ordered.button.continue_shopping": "Continue Shopping",
  "checkout.payment_qr_code.title": "Payment QR Code",
  "checkout.payment_qr_code.your_order_id_is": "Your order ID is {orderID}",
  "checkout.payment_qr_code.refresh": "Refresh",
  "checkout.email.title": "Email Address",
  "checkout.email.message": "We will send you order confirmation to this email",
  "checkout.email.change.alert.title": "Change Email",
  "checkout.email.change.alert.message":
    "Checkout will be dismissed and you will be brought to account settings to change the email",
  "checkout.burn_point.alert.message": "Cannot confirm redeeming Club Points",
  "share.product.messenger.message": `{PRODUCT_NAME}
{PRODUCT_LINK}`,
  "share.product.title.bak":
    "I want to recommend this product on Club Shopping to you",
  "share.product.title":
    "I want to recommend this product on Club Shopping to you",
  "share.product.error": "Cannot share",
  "page.signup_clubpoint.clubpoint": "Clubpoint",
  "page.signup_clubpoint.earn_clubpoints": "Shop to Earn Clubpoints",
  "page.signup_clubpoint.link_button": "Link to The Club Account",
  "modal.social_signup_terms.title": "Confirm Email & Accept terms",
  "modal.social_signup_terms.subscribe_news.bak":
    "I would like to receive news and offers from Club Shopping",
  "modal.social_signup_terms.subscribe_news":
    "I would like to receive news and offers from Club Shopping",
  "modal.social_signup_terms.agree": "I Agree",
  "modal.terms_and_conditions.navbar.title": "Terms And Conditions",
  "page.change_email.change_email": "Change Email",
  "page.change_email.submit": "Submit",
  "page.change_email.current_email": "Current Email *",
  "page.change_email.new_email": "New Email *",
  "page.change_email.re_enter_email": "Re-enter Email *",
  "page.change_email.password": "Password *",
  "page.change_email.change_email_error": "Cannot Change Email",
  "page.change_email.invalid_password": "Invalid password.",
  "page.change_email.verify_your_email.description":
    "Check your email ({NEW_EMAIL}) to confirm your new address. Until you confirm, notifications will continue to be sent to your current email address.",
  "page.change_email.verify_your_email.resend_confirmation":
    "Resend confirmation",
  "page.change_email.verify_your_email.cancel_this_change":
    "Cancel this change",
  "page.change_email.verify_your_email.toast.verification_email_sent":
    "Your verification email sent",
  "page.change_email.verify_your_email.toast.cancelled": "Cancelled",
  "page.change_email.modal.weve_sent_you_an_verification_email":
    "WE'VE SENT YOU AN VERIFICATION EMAIL",
  "page.change_email.modal.your_email_updated": "YOUR EMAIL UPDATED",
  "page.change_email.modal.button.shop": "Shop",
  "footer_cms_link.page_not_found": "Page not found",
  "customer_service.title": "Customer Service",
  "page.change_password.change_password": "Change Password",
  "page.change_password.save": "Save",
  "page.change_password.current_password": "Current Password *",
  "page.change_password.new_password": "New Password *",
  "page.change_password.re_enter_password": "Re-enter Password *",
  "page.change_password.current_password.invalid":
    "Incorrect current password.",
  "page.change_password.new_password.invalid":
    "Please input a valid new password.",
  "page.change_password.re_enter_password.invalid":
    "Does not match the new password.",
  "page.change_password.error.fail_change_password": "Cannot Change Password",
  "page.change_password.change_password_success":
    "Your password has been updated successfully!",
  "page.change_password.forgot_password": "Forgot Password?",
  "page.customer_product_reviews.navbar.title": "Product Reviews",
  "page.customer_product_reviews.lets_go_shopping": "Let's go shopping",
  "modal.the_club_email.verify": "Verify",
  "modal.the_club_email.verify_your_email": "Verify Your Email",
  "modal.the_club_email.cannot_verify_email": "Cannot Verify Email",
  "ree.header":
    "Producer Responsibility Scheme on Wastes Electrical and Electronic Equipment",
  "ree.message":
    "This product is regulated electrical equipment under the Product Eco-responsibility Ordinance (Cap.603), you can request free statutory removal service for the same type and quantity of used electrical appliances. You must choose from: (i) Request free statutory removal service (Delivery date of purchased electrical appliance different from removal date of used electrical appliance), (ii) Decline free statutory removal service, (iii) To be decided. Will contact Club Shopping Customer Service within 3 working days of purchase to request or decline free removal service. If no reply is received by Club Shopping Customer Service within the 3 working days period, you are deemed to have declined the removal service.",
  "store_type.o2o_store": "Self-Collection Point",
  "store_type.elocker": "Smart Locker",
  "app_update_dialog.force.title": "New Version Available",
  "app_update_dialog.force.description": `Looks like you have an older version of the app.
Please update to get latest features and best experience`,
  "app_update_dialog.recommend.title": "New Version Found",
  "app_update_dialog.recommend.description": `New version of Club Shopping apps is ready.
Please update the new version on app store.`,
  "app_update_dialog.no_thanks": "No Thanks",
  "app_update_dialog.update": "UPDATE NOW",
  "location.denied": "[PH] User denied location permission",
  "push_notification_messages.title": "Notification",
  "push_notification_messages.empty": "No messages",
  "push_notification_messages.error": "Something went wrong",
  "push_notification_messages.not_ready":
    "Push Notification is not enabled. Please enable Push Notification in System Settings.",
  "push_notification_alert.go_to": "Go",
  "customer_product_review.product_link.product_name": "Product Name",
  "customer_product_review.product_link.merchant_name": "Merchant Name",
  "add_product_review_modal.title": "How would you rate this product",
  "add_product_review_modal.detail.placeholder": "Write your own review...",
  "add_product_review.success.title": "Your review has been submitted",
  "page.my_cards.title": "My Cards",
  "my_cards.delete": "Delete",
  "my_cards.empty": "No Cards",
  "my_cards.remove_card.dialog.message": "Remove this card{numberQuoted}?",
  "my_cards.remove_card.dialog.button.remove": "Remove",
  "page.my_subscriptions.title": "My Subscriptions",
  "my_subscriptions.empty": "No Subscriptions",
  "my_subscriptions.subscription_info.subscription_id": "Subscription ID",
  "my_subscriptions.subscription_info.next_billing_amount":
    "Next billing amount",
  "my_subscriptions.subscription_info.subscription_name": "Subscription Name",
  "my_subscriptions.subscription_info.status": "Status",
  "my_subscriptions.subscription_info.details": "Details",
  "page.subscription_details.title": "Subscription Details",
  "subscription_details.subscription_id": "Subscription ID",
  "subscription_details.subscribed_item": "Subscribed Item",
  "subscription_details.subscription_name": "Subscription Name",
  "subscription_details.quantity": "Quantity",
  "subscription_details.start_date": "Start Date",
  "subscription_details.end_date": "End Date",
  "subscription_details.trial_start_date": "Trial Start Date",
  "subscription_details.trial_end_date": "Trial End Date",
  "subscription_details.last_billing_date": "Last Billing",
  "subscription_details.last_billing_amount": "Last Billing Amount",
  "subscription_details.next_billing_date": "Next Billing",
  "subscription_details.next_billing_amount": "Next Billing Amount",
  "subscription_details.status": "Status",
  "subscription_details.review": "Review Item",
  "subscription_details.cancel_subscription": "Cancel Subscription",
  "subscription_details.cancel.dialog.message":
    "Are you sure to cancel this subscription?",
  "subscription_details.cancel.dialog.button.confirm": "Cancel Subscription",
  "subscription_details.cancel.dialog.button.cancel": "Keep Subscription",
  "rebate_circle.rebate": "Rebate",
  "open_link_in_external_browser.header":
    "System browser is going to be opened",
  "open_link_in_external_browser.message":
    "The following link will be opened in external browser: {url}",
  "page.club_protect_claim_application.title": "About Claim",
  "page.club_protect_claim_application.faq": "Frequently Asked Questions",
  "hot_product_list.view_more": "View More",
  "page.brand_index.title": "Brands",
  "page.brand_index.filter.all": "All Brands",
  "page.brand_index.filter.others": "Others",
  "page.brand_index.search.navbar.search_input.placeholder": "Search Brands",
  "page.brand_index.search.navbar.button.cancel": "Cancel",
};
/* eslint-enable no-template-curly-in-string */
