import { RouteComponentProps } from "react-router";
import { MerchantID } from "../models/Merchant";
import { ArticleFilter } from "../models/Article";
import { CustomerSubscriptionId } from "../models/CustomerSubscription";

export enum RootTab {
  /* For Club-A-Wish */
  town = "town",
  zone = "zone",
  home = "home",
  /* End for Club-A-Wish */
  
  allCategories = "all-categories",
  category = "category",
  articles = "articles",
  //home = "home",
  likes = "likes",
  account = "account",
}

export const AllTabs: RootTab[] = [
  RootTab.town,
  RootTab.zone,

   RootTab.allCategories,
   RootTab.category,
   RootTab.articles,
   //RootTab.home,
   RootTab.likes,
   RootTab.account,
];

export function activeTabAsRootTab(activeTab: string): RootTab | null {
  return AllTabs.find(v => v === activeTab) || null;
}

export function getRouteDefinitionForTab(tab: RootTab): string {
  switch (tab) {
    case RootTab.town:
      return getRouteDefinitionForTownPage();
    case RootTab.zone:
      return getRouteDefinitionForZonePage();

    /* Clublike routes */
    case RootTab.allCategories:
      return getRouteDefinitionForAllCategoriesPage();
    case RootTab.category:
      return getRouteDefinitionForCategoryTab();
    case RootTab.articles:
      return getRouteDefinitionForArticlesTab();
    case RootTab.home:
     return getRouteDefinitionForHomePage();
    case RootTab.likes:
      return getRouteDefinitionForLikesTab();
    case RootTab.account:
      return getRouteDefinitionForAccountTab();
    default:
      throw new Error("Unreachable");
  }
}

export function getPathForTab(tab: RootTab): string {
  console.log(`tab: ${tab}`);
  switch (tab) {
    case RootTab.town:
      return getPathForTownPage();
    case RootTab.zone:
      return getPathForZonePage();
    case RootTab.allCategories:
      return getPathForAllCategoriesPage();
    case RootTab.category:
      return getPathForCategoryTab();
    case RootTab.articles:
      return getPathForArticleTab();
    case RootTab.home:
      return getPathForHomePage();
    case RootTab.likes:
      return getPathForLikesTab();
    case RootTab.account:
      return genPathForAccountTab();
    default:
      throw new Error("Unreachable");
  }
}

/* Router Utilities */
export function checkIsScreenActive(
  routeProps: RouteComponentProps<any>
): boolean {
  const { match, location } = routeProps;
  return match.url === location.pathname;
}

export function getCurrentTabForRouteProps(
  routeProps: RouteComponentProps<any>
): RootTab {
  return routeProps.match.params.tab;
}

/* Club-a-wish new route */
export function getRouteDefinitionForTownPage(): string {
  return `/:tab(${RootTab.town})`;
}
export function getRouteDefinitionForZonePage(): string {
  return `/:tab(${RootTab.zone})`;
}

export function getRouteDefinitionForMyHomePage(): string {
  return `/:tab(${RootTab.home})`;
}

export function getPathForTownPage() {
  return `/${RootTab.town}`;
}

export function getPathForZonePage() {
  return`/${RootTab.zone}`;
}

export function getPathForMyHome() {
  return `/${RootTab.home}`;
}



export function getRouteDefinitionForCategoryTab() {
  return `/:tab(${RootTab.category})`;
}

export function getPathForCategoryTab() {
  return `/${RootTab.category}`;
}

export function getRouteDefinitionForArticlesTab() {
  return `/:tab(${RootTab.articles})`;
}

export function getPathForArticleTab() {
  return `/${RootTab.articles}`;
}

export function getRouteDefinitionForHomePage() {
  return `/:tab(${RootTab.home})`;
}

export function getPathForHomePage() {
  return `/${RootTab.home}`;
}

export function getRouteDefinitionForLikesTab() {
  return `/:tab(${RootTab.likes})`;
}

export function getPathForLikesTab() {
  return `/${RootTab.likes}`;
}

export function getRouteDefinitionForAccountTab() {
  return `/:tab(${RootTab.account})`;
}

export function genPathForAccountTab() {
  return `/${RootTab.account}`;
}

export function getRouteDefinitionForDisplayLanguagePage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/display-language`;
}

export function getPathForDisplayLanguagePage(tab: RootTab) {
  return `${getPathForTab(tab)}/display-language`;
}

export function getRouteDefinitionForAllCategoriesPage() {
  return `${getRouteDefinitionForCategoryTab()}/categories`;
}

export function getPathForAllCategoriesPage() {
  return `${getPathForCategoryTab()}/categories`;
}

export function getRouteDefinitionForSingleCategoryPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/category/:categoryId`;
}

export function getPathForSingleCategoryPage(
  tab: RootTab,
  categoryId: number | string
) {
  return `${getPathForTab(tab)}/category/${categoryId}`;
}

export function getRouteDefinitionForSubCategoryListPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/sub-category-list/:categoryId`;
}

export function getPathForSubCategoryListPage(
  tab: RootTab,
  categoryId: number
) {
  return `${getPathForTab(tab)}/sub-category-list/${categoryId}`;
}

export function getRouteDefinitionForProductDetailPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/product/:sku`;
}
export function getPathForProductDetailPage(tab: RootTab, sku: string) {
  return `${getPathForTab(tab)}/product/${sku}`;
}

export function getRouteDefinitionForSelectInterestCategoryPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/select-categories`;
}

export function getPathForSelectInterestCategoryPage(tab: RootTab) {
  return `${getPathForTab(tab)}/select-categories`;
}

export function getRouteDefinitionForSignupClubPointPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/signup-clubpoint`;
}

export function getPathForSignupClubPointPage(tab: RootTab) {
  return `${getPathForTab(tab)}/signup-clubpoint`;
}

// Forget password
export function getRouteDefinitionForForgotPassword() {
  return `/forgot-password`;
}
export function getPathForForgotPassword() {
  return `/forgot-password`;
}

export function getPathForProductFilter() {
  return "/product-filter";
}

export function getPathForCategoryFilter() {
  return "/category-filter";
}

export function getPathForMerchantProductFilter() {
  return "/merchant-product-filter";
}

export function genPathForShoppingCart() {
  return "/shopping-cart";
}

export function getRouteDefinitionForCheckoutFillingInfo() {
  return "/cart/checkout";
}

export function getRouteDefinitionForCheckoutSelectO2oStore() {
  return "/cart/select-o2o-store";
}

export function genPathForCheckoutSelectO2oStore() {
  return "/cart/select-o2o-store";
}

export function genPathForCheckoutFillingInfo(
  section?:
    | "delivery-info"
    | "delivery-time"
    | "billing-info"
    | "payment-option"
    | "promotion"
) {
  return `/cart/checkout${section ? `#${section}` : ""}`;
}

export function getRouteDefinitionForCheckoutConfirmation() {
  return "/cart/confirmation";
}

export function getPathForCheckoutConfirmation() {
  return `/cart/confirmation`;
}

export function getRouteDefinitionForCheckoutOrdered() {
  return "/cart/ordered";
}

export function genPathForCheckoutOrdered() {
  return "/cart/ordered";
}

export function getRouteDefinitionForMPOSPayPayment() {
  return "/cart/mpospay-payment/:orderID";
}

export function genPathForMPOSPayPayment(orderID: string) {
  return `/cart/mpospay-payment/${orderID}`;
}

export function getRouteDefinitionForMerchantDirectories(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/merchants`;
}

export function getPathForMerchantDirectories(tab: RootTab) {
  return `${getPathForTab(tab)}/merchants`;
}

export function genPathForEditProfile(tab: RootTab) {
  return `${getPathForTab(tab)}/edit-profile`;
}

export function getRouteDefinitionForEditProfile(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/edit-profile`;
}

export function genPathForChangeEmail(tab: RootTab) {
  return `${genPathForEditProfile(tab)}/change-email`;
}

export function getRouteDefinitionForChangeEmail(tab: RootTab) {
  return `${getRouteDefinitionForEditProfile(tab)}/change-email`;
}

export function genPathForChangePassword(tab: RootTab) {
  return `${getPathForTab(tab)}/change-password`;
}

export function getRouteDefinitionForChangePassword(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/change-password`;
}

export function getRouteDefinitionForMyOrders(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/my-orders`;
}

export function getPathForMyOrders(tab: RootTab) {
  return `${getPathForTab(tab)}/my-orders`;
}

export function getRouteDefinitionForOrderDetail(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/order-detail/:id`;
}

export function getPathForOrderDetail(tab: RootTab, orderID: string) {
  return `${getPathForTab(tab)}/order-detail/${orderID}`;
}

export function getRouteDefinitionForSingleMerchant(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/merchant/:id`;
}

export function getPathForSingleMerchant(tab: RootTab, merchantID: MerchantID) {
  return `${getPathForTab(tab)}/merchant/${merchantID}`;
}

export function getRouteDefinitionForMerchantDetails(tab: RootTab) {
  return `${getRouteDefinitionForSingleMerchant(tab)}/details`;
}

export function getPathForMerchantDetails(
  tab: RootTab,
  merchantID: MerchantID
) {
  return `${getPathForSingleMerchant(tab, merchantID)}/details`;
}

export function getRouteDefinitionForSearchSuggestion(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/search-suggestion`;
}

export function getPathForSearchSuggestion(tab: RootTab) {
  return `${getPathForTab(tab)}/search-suggestion`;
}

export function getRouteDefinitionForSearch(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/search/:searchTerm`;
}

export function getPathForSearch(tab: RootTab, searchTerm: string) {
  return `${getPathForTab(tab)}/search/${encodeURIComponent(searchTerm)}`;
}

export function getRouteDefinitionForMyDelivery(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/my-delivery`;
}

export function getPathForMyDelivery(tab: RootTab) {
  return `${getPathForTab(tab)}/my-delivery`;
}

export function getRouteDefinitionForAddressForm() {
  return `/my-delivery/:id`;
}

export function getPathForAddNewAddress() {
  return `/my-delivery/new`;
}

export function getPathForEditAddress(addressID: number) {
  return `/my-delivery/${addressID}`;
}

export function getRouteDefinitionForArticleDetail(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/article/:id`;
}

export function getPathForArticleDetail(tab: RootTab, articleID: string) {
  return `${getPathForTab(tab)}/article/${articleID}`;
}

export function getRouteDefinitionForFilteredArticleListPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/articles/:type/:id`;
}

export function getPathForFilteredArticleListPage(
  tab: RootTab,
  type: ArticleFilter,
  id: string
) {
  return `${getPathForTab(tab)}/articles/${type}/${id}`;
}

export function getRouteDefinitionForCMSLanding(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/cms/:id`;
}

export function getPathForCMSLanding(tab: RootTab, id: string) {
  return `${getPathForTab(tab)}/cms/${id}`;
}

export function getRouteDefinitionForNotificationSettings(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/notification-settings`;
}

export function getPathForNotificationSettings(tab: RootTab) {
  return `${getPathForTab(tab)}/notification-settings`;
}

export function getRouteDefinitionForFooterCMSLinkGroup(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/footer-cms-link-group/:text`;
}

export function getPathForFooterCMSLinkGroup(tab: RootTab, text: string) {
  return `${getPathForTab(tab)}/footer-cms-link-group/${text}`;
}

export function getRouteDefinitionForFooterCMSLink(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/footer-cms-link/:groupText/:text`;
}

export function getPathForFooterCMSLink(
  tab: RootTab,
  groupText: string,
  text: string
) {
  return `${getPathForTab(tab)}/footer-cms-link/${groupText}/${text}`;
}

export function getRouteDefinitionForCustomerService(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/customer-service`;
}

export function getPathForCustomerService(tab: RootTab) {
  return `${getPathForTab(tab)}/customer-service`;
}


export function getRouteDefinitionForPushNotificationMessages() {
  return `/push-notification-messages`;
}

export function getPathForPushNotificationMessages() {
  return `/push-notification-messages`;
}

export function getRouteDefinitionForCustomerProductReview(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/customer-product-review`;
}

export function getPathForCustomerProductReview(tab: RootTab) {
  return `${getPathForTab(tab)}/customer-product-review`;
}

export function getRouteDefinitionForProductReviews() {
  return `/product-reviews/:sku`;
}

export function getPathForProductReviews(sku: string) {
  return `/product-reviews/${sku}`;
}

export function getPathForSubscriptionDetails(
  tab: RootTab,
  id: CustomerSubscriptionId
) {
  return `${getPathForTab(tab)}/subscription-details/${id}`;
}


