import {
  useState,
  useEffect,
  useCallback,
  useRef,
  MutableRefObject,
  RefObject,
} from "react";
import isEqual from "lodash.isequal";
import { useLocation } from "react-router";
import configData from "../config.json";

export function useDebounce<T>(value: T, delay?: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed ...
    // .. within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]); // Only re-call effect if value or delay changes

  return debouncedValue;
}

function isSame<A extends any[], B extends any[]>(a: A, b: B) {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export function useDebounceByCompletion<Args extends any[]>(
  fn: (...args: Args) => Promise<any>
): [(...args: Args) => Promise<any>, RefObject<boolean>] {
  const inProgressRef = useRef<boolean>(false);
  const nextValueRef = useRef<Args | null>(null);

  const debounced = useCallback(
    async (...args: Args) => {
      if (inProgressRef.current) {
        nextValueRef.current = args;
        return;
      }
      inProgressRef.current = true;
      await fn(...args);
      inProgressRef.current = false;
      if (nextValueRef.current != null) {
        if (isSame(nextValueRef.current, args)) {
          return;
        }
        const nextValue = nextValueRef.current;
        nextValueRef.current = null;
        debounced(...nextValue);
      }
    },
    [fn]
  );

  return [debounced, inProgressRef];
}

export function useChain(...fns: (() => void)[]) {
  return useCallback(() => {
    fns.forEach(fn => fn());
  }, [...fns]); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useKeepUpdatingRef<T>(value: T): MutableRefObject<T> {
  const ref = useRef(value);
  ref.current = value;
  return ref;
}

export function deepCompareEquals(a: any, b: any) {
  return isEqual(a, b);
}

export function useDeepCompareMemoize(value) {
  const ref = useRef();
  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

export function useDeepCompareEffect(callback, dependencies:Array<any>) {
  useEffect(callback, useDeepCompareMemoize(dependencies))
}

export function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => ++value); // update the state to force render
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export const useIsNight = () => {
  let startTime = configData.scene.nightStartTime;
  let endTime = configData.scene.nightEndTime;
  let timeNow = (new Date()).getHours() * 100 + (new Date()).getMinutes();
  let isNight = false;
  if (startTime > endTime) {
    if (timeNow > startTime || timeNow < endTime)
        isNight = true;
    } else {
      if (timeNow > startTime && timeNow < endTime) isNight = true;
    }
    return isNight;
}

export const useIsMountedRef = () => {
  const isMountedRef = useRef<boolean|null>(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false
    };
  });
  return isMountedRef;
}

type IntervalFunction = () => ( unknown | void );

export const useInterval = (callback: IntervalFunction, delay:number | null) => {
  const savedCallback = useRef<IntervalFunction| null>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      }
    }
  }, [callback, delay]);
}