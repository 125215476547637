import React, { useState, useCallback, useContext, useRef } from "react";
import cn from "classnames";
// eslint-disable-next-line no-restricted-imports

import styles from "./styles.module.scss";
import { LocalizedText } from "../../i18n/Localization";
import { validateEmail, validatePassword } from "../../utils/validation";
import { actionEvent, pageView } from "../../utils/GTM";
import CLContent from "../CLContent";
import Input from "../Form/Input";
import PasswordInputField from "../PasswordInputField";
import OAuthButtons from "../OAuthButtons";
import Checkbox from "../Checkbox";
import { PrimaryButton } from "../Button";
import {
  useSignupWithEmailRequest,
  useLoginWithOAuthRequest,
} from "../../repository/AuthRepository";
import SignupSuccessModal from "../SignupSuccessModal";
import CLModal from "../CLModal";
import ModalHeader from "../ModalHeader";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import {
  RootTab,
  getPathForSelectInterestCategoryPage,
} from "../../navigation/routes";
import { withRouter, RouteComponentProps } from "react-router";
import {
  isCustomerInterestSet,
  Customer,
  isCustomerRequireEmailVerificationPrompt,
} from "../../models/Customer";
import {
  useLoginWithTheClub,
  useLoginWithFacebook,
  SSOResponse,
  useLoginWithAppleSignIn,
} from "../../useCase/AuthUseCase";
import SocialSignupTermsAndConditionsModal from "../SocialSignupTermsAndConditionsModal";
import VerifyEmailModal from "../VerifyEmailModal";
import CSRModal from "../CSRModal";
import ClubHopeBanner from "../ClubHopeBanner";
import { NavBar } from "../NavBar";
import { useModalState } from "../../hook/modal";
import Config from "../../Config";

type SignupModalProps = RouteComponentProps & {
  isModalOpen: boolean;
  onRequestDismiss: () => void;
  onLoginButtonClick: () => void;
  onDidLogin: ((customer: Customer) => void) | null;
};

const SignupModal: React.FC<SignupModalProps> = (props: SignupModalProps) => {
  const { onDidLogin } = props;
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const presentOauthLoginFailAlert = useCallback(() => {
    presentLocalizedAlert({
      headerId: "login.login_failed",
      messageId: "login.login_with_oauth_failed_text",
      buttons: [{ textMessageID: "try_again" }],
    });
  }, [presentLocalizedAlert]);
  const { isModalOpen, onRequestDismiss, onLoginButtonClick, history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstNameValue] = useState("");
  const [lastName, setLastNameValue] = useState("");
  const [password, setPasswordValue] = useState("");
  const [email, setEmailValue] = useState("");
  const [receiveMarketingMaterials, setReceiveMarketingMaterials] = useState(
    false
  );
  const [acceptTerms, setIsAcceptTerms] = useState(false);
  const isEmailValid = validateEmail(email);
  const isPasswordValid = validatePassword(password);
  const isAllFieldsComplete =
    firstName.trim() !== "" &&
    lastName.trim() !== "" &&
    password !== "" &&
    email !== "";
  const onModalDismiss = useCallback(() => {
    setFirstNameValue("");
    setLastNameValue("");
    setPasswordValue("");
    setEmailValue("");
    setReceiveMarketingMaterials(false);
    setIsAcceptTerms(false);
    onRequestDismiss();
  }, [
    setFirstNameValue,
    setLastNameValue,
    setPasswordValue,
    setEmailValue,
    setReceiveMarketingMaterials,
    setIsAcceptTerms,
    onRequestDismiss,
  ]);
  const onPasswordInputChange = useCallback(
    (value: string) => {
      setPasswordValue(value);
    },
    [setPasswordValue]
  );
  const onEmailInputChange = useCallback(
    (value: string) => {
      setEmailValue(value);
    },
    [setEmailValue]
  );
  const onFirstNameInputChange = useCallback(
    (value: string) => {
      setFirstNameValue(value);
    },
    [setFirstNameValue]
  );
  const onLastNameInputChange = useCallback(
    (value: string) => {
      setLastNameValue(value);
    },
    [setLastNameValue]
  );
  const onReceiveMarketingMaterialsChange = useCallback(
    (value: boolean) => {
      setReceiveMarketingMaterials(value);
    },
    [setReceiveMarketingMaterials]
  );
  const onAcceptTermsChange = useCallback(
    (value: boolean) => {
      setIsAcceptTerms(value);
    },
    [setIsAcceptTerms]
  );
  const firstNameInputRef = useRef<HTMLInputElement>(null);
  const lastNameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const signupRequest = useSignupWithEmailRequest();

  //const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSuccessModalOpen, presentSuccessModal, dismissSuccessModal ] = useModalState();
  const onSuccessModalRequestDismiss = useCallback(() => {
    dismissSuccessModal();
    onModalDismiss();
  }, [onModalDismiss, dismissSuccessModal]);
  const [verifyEmailModalIsOpen, setVerifyEmailModalIsOpen] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState<string>("");
  const onVerifyEmailModalDismiss = useCallback(() => {
    setVerifyEmailModalIsOpen(false);
  }, [setVerifyEmailModalIsOpen]);

  const loginWithOAuth = useLoginWithOAuthRequest();
  const [ssoLoginResult, setSSOLoginResult] = useState<SSOResponse | undefined>(
    undefined
  );
  const [
    socialSignupTermsModalIsOpen,
    setSocialSignupTermsModalIsOpen,
  ] = useState(false);
  const onSocialSignupTermsModalRequestDismiss = useCallback(
    (dismissParent: boolean) => {
      setSocialSignupTermsModalIsOpen(false);
      setSSOLoginResult(undefined);
      if (dismissParent) {
        onRequestDismiss();
      }
    },
    [setSocialSignupTermsModalIsOpen, onRequestDismiss]
  );
  const onClickSSOLoginButton = useCallback(
    async (loginProvider: () => Promise<SSOResponse>) => {
      try {
        setIsLoading(true);
        const result = await loginProvider();
        setSSOLoginResult(result);
        const customer = await loginWithOAuth(result.token, result.provider);
        if (!customer) {
          throw new Error("cannot-get-customer");
        }
        if (!isCustomerInterestSet(customer)) {
          //history.replace(getPathForSelectInterestCategoryPage(RootTab.home));
        }
        if (isCustomerRequireEmailVerificationPrompt(customer)) {
          setVerifyEmailModalIsOpen(true);
          setVerifyEmail(customer.email || "");
        }
        onRequestDismiss();
        if (onDidLogin) {
          onDidLogin(customer);
        }
      } catch (e) {
        if (e.message === "cancelled") {
          return;
        }
        if (e.message === "Customer not exist.") {
          setSocialSignupTermsModalIsOpen(true);
          return;
        }
        presentOauthLoginFailAlert();
      } finally {
        setIsLoading(false);
      }
    },
    [
      presentOauthLoginFailAlert,
      history,
      onRequestDismiss,
      onDidLogin,
      loginWithOAuth,
    ]
  );

  const loginWithTheClub = useLoginWithTheClub();
  const onTheClubButtonClick = useCallback(async () => {
    onClickSSOLoginButton(loginWithTheClub);
  }, [onClickSSOLoginButton, loginWithTheClub]);

  const loginWithFacebook = useLoginWithFacebook();
  const onFacebookButtonClick = useCallback(() => {
    onClickSSOLoginButton(loginWithFacebook);
  }, [onClickSSOLoginButton, loginWithFacebook]);

  const loginWithAppleSignIn = useLoginWithAppleSignIn();
  const onAppleSignInButtonClick = useCallback(async () => {
    // TODO: Replace with onClickSSOLoginButton(loginWithAppleSignIn)
    try {
      const result = await loginWithAppleSignIn();
      presentLocalizedAlert({
        message: "Apple SignIn token: " + result.token,
        buttons: [{ textMessageID: "done" }],
      });
    } catch (e) {
      if (e.message === "cancelled") {
        return;
      }
      presentOauthLoginFailAlert();
    }
  }, [loginWithAppleSignIn, presentLocalizedAlert, presentOauthLoginFailAlert]);

  const onSignupButtonClick = useCallback(async () => {
    actionEvent("Create Account", "Click", "Signup Submit-Btn");
    setIsLoading(true);
    try {
      await signupRequest(
        firstName,
        lastName,
        email,
        password,
        receiveMarketingMaterials
      );
      actionEvent("Create Account", "Click", "Goal");
      onModalDismiss();
      presentSuccessModal();
      pageView({ page: "Verify Email" });
    } catch (e) {
      const messageId =
        e.message === "email-already-in-use"
          ? "signup.email_already_in_use"
          : undefined;
      presentLocalizedAlert({
        headerId: "signup.signup_failed",
        messageId,
        buttons: [{ textMessageID: "try_again" }],
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    firstName,
    lastName,
    email,
    password,
    receiveMarketingMaterials,
    setIsLoading,
    presentSuccessModal,
    signupRequest,
    presentLocalizedAlert,
  ]);

  const handleAcceptTermsLinkClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <>
      <CSRModal
        className={styles.modal}
        hideHeader={true}
        isOpen={isModalOpen}
        onRequestDismiss={onModalDismiss}
        backdropDismiss={false}
      >
        <NavBar
          toolbarClassName={styles.modalHeader}
          custom={<ClubHopeBanner />}
        />
        <CLContent scrollY={true} className={styles.content}>
          <div className={styles.modalBody}>
            <span className={styles.modalTitle}>
              <LocalizedText messageID="signup.create_account" />
            </span>
            <OAuthButtons
              onTheClubButtonClick={onTheClubButtonClick}
              onFacebookButtonClick={onFacebookButtonClick}
              onAppleSignInButtonClick={onAppleSignInButtonClick}
              disabled={isLoading}
              titleMessageId="signup.signup_with_social"
            />
            <div className={styles.emailLoginContainer}>
              <div className={styles.loginTypeTitle}>
                <LocalizedText messageID="signup.or_signup_with_email" />
              </div>
              <Input
                className={styles.input}
                type="text"
                placeholderId={"first_name"}
                value={firstName}
                onChange={onFirstNameInputChange}
                ref={firstNameInputRef}
                nextFieldRef={lastNameInputRef}
              />
              <Input
                className={styles.input}
                type="text"
                placeholderId={"last_name"}
                value={lastName}
                onChange={onLastNameInputChange}
                ref={lastNameInputRef}
                nextFieldRef={emailInputRef}
              />
              <Input
                className={cn(
                  styles.input,
                  email !== "" && !isEmailValid && styles.error
                )}
                type="email"
                placeholderId={"email"}
                value={email}
                onChange={onEmailInputChange}
                ref={emailInputRef}
                nextFieldRef={passwordInputRef}
              />
              <PasswordInputField
                placeholderID="password"
                className={cn(styles.passwordInput, {
                  [styles.error]: password && !isPasswordValid,
                })}
                value={password}
                onChange={onPasswordInputChange}
                ref={passwordInputRef}
              />
              <div className={styles.passwordRequirementText}>
                <LocalizedText messageID="password.requirement" />
              </div>
              <div className={styles.checkBoxInput}>
                <Checkbox
                  checked={receiveMarketingMaterials}
                  onCheckedChange={onReceiveMarketingMaterialsChange}
                >
                  <LocalizedText messageID="signup.receive_marketing_materials" />
                </Checkbox>
              </div>
              <div className={styles.checkBoxInput}>
                <Checkbox
                  checked={acceptTerms}
                  onCheckedChange={onAcceptTermsChange}
                >
                {Config.SIGN_UP_TERMS_AND_CONDITIONS_LINK &&
                  Config.SIGN_UP_PRIVACY_POLICY_LINK ? (
                    <AcceptTermsText
                      termsAndConditionsLink={
                        Config.SIGN_UP_TERMS_AND_CONDITIONS_LINK
                      }
                      privacyPolicyLink={Config.SIGN_UP_PRIVACY_POLICY_LINK}
                    />
                  ) : null}
                </Checkbox>
              </div>
              <PrimaryButton
                className={cn(styles.signupButton)}
                disabled={
                  !isAllFieldsComplete ||
                  !isEmailValid ||
                  !isPasswordValid ||
                  !acceptTerms ||
                  isLoading
                }
                loading={isLoading}
                onClick={onSignupButtonClick}
              >
                <LocalizedText messageID="signup" />
              </PrimaryButton>
              <div className={styles.loginText}>
                <LocalizedText
                  messageID="signup.already_have_an_account"
                  messageArgs={{
                    loginButtonClassName: styles.loginButton,
                    onLoginButtonClick: onLoginButtonClick,
                  }}
                />
              </div>
            </div>
          </div>
          {/* TODO: Register merchant account area */}
        </CLContent>
      </CSRModal>
      <SignupSuccessModal
        isModalOpen={isSuccessModalOpen}
        onRequestDismiss={onSuccessModalRequestDismiss}
      />
      <SocialSignupTermsAndConditionsModal
        isModalOpen={socialSignupTermsModalIsOpen}
        onRequestDismiss={onSocialSignupTermsModalRequestDismiss}
        provider={ssoLoginResult ? ssoLoginResult.provider : undefined}
        token={ssoLoginResult ? ssoLoginResult.token : undefined}
      />
      <VerifyEmailModal
        isModalOpen={verifyEmailModalIsOpen}
        initialEmail={verifyEmail}
        onRequestDismiss={onVerifyEmailModalDismiss}
      />
    </>
  );
};

export default React.memo(withRouter(SignupModal));

interface AcceptTermsTextProps {
  termsAndConditionsLink: string;
  privacyPolicyLink: string;
}


const AcceptTermsText: React.FC<AcceptTermsTextProps> = props => {
  const termsAndConditionsLink = props.termsAndConditionsLink
  ;
  const privacyPolicyLink = props.privacyPolicyLink;

  const handleLinkClick = useCallback((e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
  }, []);

  return (
    <LocalizedText
      messageID="signup.accept_terms"
      messageArgs={{
        termsAndPrivacyPolicy: styles.termsAndPrivacyPolicy,
        termsAndConditionsLink,
        privacyPolicyLink,
        handleLinkClick,
      }}
    />
  );
};