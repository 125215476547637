import React, { useCallback, useMemo, useReducer } from "react";
import { produce } from "immer";

import { NumericBoolean } from "../../utils/type";

import { Address, emptyAddress } from "../../models/Customer";
import { OppCard } from "../../models/OppCard";
import {
  DeliveryEndpointType,
  PaymentType,
  DeliveryTimeSlotValue,
} from "./models";

export type DeliveryAddress = Address;

export interface SelfPickup {
  telephone: string;
  region: string;
  district: string;
  storeType: string;
  pickupSpot: string;
}

export type SelfPickupKey = keyof SelfPickup;

interface DeliveryTime {
  timeOption: DeliveryTimeSlotValue;
}

export type BillingInfo = Address;

type PaymentOption = PaymentType | null;

export type OppCardOption = OppCardOptionNew | OppCardOptionExisting;

interface OppCardOptionNew {
  type: "new";
  saveCard: NumericBoolean;
}

interface OppCardOptionExisting {
  type: "existing";
  oppCard: OppCard;
}

interface Promotion {
  code: string;
  name: string;
}

export type BillingInfoOption = "sameAsDeliveryAddress" | "custom";

export interface State {
  deliveryInfoType: DeliveryEndpointType | null;
  deliveryAddress: DeliveryAddress;
  deliveryAddressSaveInAddressBook: boolean;
  selfPickup: SelfPickup;
  deliveryTime: DeliveryTime;
  billingInfoOption: BillingInfoOption | null;
  customBillingInfo: BillingInfo;
  customBillingInfoSaveInAddressBook: boolean;
  paymentOption: PaymentOption;
  oppCardOption: OppCardOption | null;
  promotion: Promotion;
}

const initialState: State = {
  deliveryInfoType: null,
  deliveryAddress: {
    ...emptyAddress,
  },
  deliveryAddressSaveInAddressBook: false,
  selfPickup: {
    telephone: "",
    region: "",
    district: "",
    storeType: "",
    pickupSpot: "",
  },
  deliveryTime: {
    timeOption: "",
  },
  billingInfoOption: null,
  customBillingInfo: {
    ...emptyAddress,
  },
  customBillingInfoSaveInAddressBook: false,
  paymentOption: null,
  oppCardOption: null,
  promotion: {
    name: "",
    code: "",
  },
};

type Action =
  | { type: "StartCheckout" }
  | { type: "SelectDeliveryInfoType"; deliveryInfoType: DeliveryEndpointType }
  | { type: "UpdateDeliveryAddress"; deliveryAddress: DeliveryAddress }
  | { type: "SetDeliveryAddressSaveInAddressBook"; saveInAddressBook: boolean }
  | { type: "UpdateSelfPickup"; selfPickup: SelfPickup }
  | { type: "ResetBillingInfo" }
  | { type: "SelectBillingInfoOption"; billingInfoOption: BillingInfoOption }
  | { type: "UpdateCustomBillingInfo"; billingInfo: BillingInfo }
  | {
      type: "SetCustomBillingInfoSaveInAddressBook";
      saveInAddressBook: boolean;
    }
  | { type: "SetDeliveryTime"; timeOption: DeliveryTimeSlotValue }
  | {
      type: "SetPaymentType";
      paymentType: PaymentType;
    }
  | {
      type: "SetOppCardOption";
      oppCardOption: OppCardOption;
    }
  | { type: "UpdatePromotionCode"; code: string };

function reducer(state: State, action: Action): State {
  return produce(state, draft => {
    switch (action.type) {
      case "StartCheckout": {
        draft.deliveryInfoType = initialState.deliveryInfoType;
        draft.deliveryAddress = { ...initialState.deliveryAddress };
        draft.deliveryAddressSaveInAddressBook =
          initialState.deliveryAddressSaveInAddressBook;
        draft.selfPickup = { ...initialState.selfPickup };
        draft.deliveryTime = { ...initialState.deliveryTime };
        draft.billingInfoOption = initialState.billingInfoOption;
        draft.customBillingInfo = { ...initialState.customBillingInfo };
        draft.customBillingInfoSaveInAddressBook =
          initialState.customBillingInfoSaveInAddressBook;
        draft.paymentOption = initialState.paymentOption;
        draft.oppCardOption = initialState.oppCardOption;
        draft.promotion = { ...initialState.promotion };
        return;
      }
      case "SelectDeliveryInfoType": {
        draft.deliveryInfoType = action.deliveryInfoType;
        if (draft.deliveryInfoType === "o2o-store") {
          draft.billingInfoOption = "custom";
        }
        break;
      }
      case "UpdateDeliveryAddress": {
        draft.deliveryAddress = action.deliveryAddress;
        break;
      }
      case "SetDeliveryAddressSaveInAddressBook": {
        draft.deliveryAddressSaveInAddressBook = action.saveInAddressBook;
        break;
      }
      case "UpdateSelfPickup": {
        draft.selfPickup = action.selfPickup;
        break;
      }
      case "ResetBillingInfo": {
        draft.billingInfoOption = null;
        draft.customBillingInfo = { ...emptyAddress };
        draft.customBillingInfoSaveInAddressBook = false;
        break;
      }
      case "SelectBillingInfoOption": {
        draft.billingInfoOption = action.billingInfoOption;
        break;
      }
      case "UpdateCustomBillingInfo": {
        draft.customBillingInfo = action.billingInfo;
        break;
      }
      case "SetCustomBillingInfoSaveInAddressBook": {
        draft.customBillingInfoSaveInAddressBook = action.saveInAddressBook;
        break;
      }
      case "SetDeliveryTime": {
        draft.deliveryTime.timeOption = action.timeOption;
        break;
      }
      case "SetPaymentType": {
        draft.paymentOption = action.paymentType;
        if (draft.paymentOption !== "opppayment") {
          draft.oppCardOption = null;
        }
        break;
      }
      case "SetOppCardOption": {
        draft.oppCardOption = action.oppCardOption;
        break;
      }
      case "UpdatePromotionCode": {
        draft.promotion.code = action.code;
        break;
      }
      default:
        break;
    }
  });
}

interface ContextValue {
  state: State;
  startCheckout: () => void;
  selectDeliveryInfoType: (deliveryInfoType: DeliveryEndpointType) => void;
  updateDeliveryAddress: (deliveryAddress: DeliveryAddress) => void;
  setDeliveryAddressSaveInAddressBook: (saveInAddressBook: boolean) => void;
  updateSelfPickup: (selfPickup: SelfPickup) => void;
  resetBillingInfo: () => void;
  selectBillingInfoOption: (billingInfoOption: BillingInfoOption) => void;
  updateCustomBillingInfo: (billingInfo: BillingInfo) => void;
  setCustomBillingInfoSaveInAddressBook: (saveInAddressBook: boolean) => void;
  setDeliveryTime: (timeOption: DeliveryTimeSlotValue) => void;
  setPaymentType: (paymentType: PaymentType) => void;
  setOppCardOption: (oppCardOption: OppCardOption) => void;
  updatePromotionCode: (code: string) => void;
}

export const Context = React.createContext<ContextValue>(null as any);

export const Provider: React.FC = props => {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const startCheckout = useCallback(() => {
    dispatch({
      type: "StartCheckout",
    });
  }, [dispatch]);

  const selectDeliveryInfoType = useCallback(
    (deliveryInfoType: DeliveryEndpointType) => {
      dispatch({
        type: "SelectDeliveryInfoType",
        deliveryInfoType,
      });
    },
    [dispatch]
  );

  const updateDeliveryAddress = useCallback(
    (deliveryAddress: DeliveryAddress) => {
      dispatch({
        type: "UpdateDeliveryAddress",
        deliveryAddress,
      });
    },
    [dispatch]
  );

  const setDeliveryAddressSaveInAddressBook = useCallback(
    (saveInAddressBook: boolean) => {
      dispatch({
        type: "SetDeliveryAddressSaveInAddressBook",
        saveInAddressBook,
      });
    },
    [dispatch]
  );

  const updateSelfPickup = useCallback(
    (selfPickup: SelfPickup) => {
      dispatch({
        type: "UpdateSelfPickup",
        selfPickup,
      });
    },
    [dispatch]
  );

  const resetBillingInfo = useCallback(() => {
    dispatch({
      type: "ResetBillingInfo",
    });
  }, [dispatch]);

  const selectBillingInfoOption = useCallback(
    (billingInfoOption: BillingInfoOption) => {
      dispatch({
        type: "SelectBillingInfoOption",
        billingInfoOption,
      });
    },
    [dispatch]
  );

  const updateCustomBillingInfo = useCallback(
    (billingInfo: BillingInfo) => {
      dispatch({
        type: "UpdateCustomBillingInfo",
        billingInfo,
      });
    },
    [dispatch]
  );

  const setCustomBillingInfoSaveInAddressBook = useCallback(
    (saveInAddressBook: boolean) => {
      dispatch({
        type: "SetCustomBillingInfoSaveInAddressBook",
        saveInAddressBook,
      });
    },
    [dispatch]
  );

  const setDeliveryTime = useCallback(
    (timeOption: DeliveryTimeSlotValue) => {
      dispatch({
        type: "SetDeliveryTime",
        timeOption,
      });
    },
    [dispatch]
  );

  const setPaymentType = useCallback(
    (paymentType: PaymentType) => {
      dispatch({
        type: "SetPaymentType",
        paymentType,
      });
    },
    [dispatch]
  );

  const setOppCardOption = useCallback(
    (oppCardOption: OppCardOption) => {
      dispatch({
        type: "SetOppCardOption",
        oppCardOption,
      });
    },
    [dispatch]
  );

  const updatePromotionCode = useCallback(
    (code: string) => {
      dispatch({
        type: "UpdatePromotionCode",
        code,
      });
    },
    [dispatch]
  );

  const contextValue = useMemo(
    () => ({
      state,
      startCheckout,
      selectDeliveryInfoType,
      updateDeliveryAddress,
      setDeliveryAddressSaveInAddressBook,
      updateSelfPickup,
      resetBillingInfo,
      selectBillingInfoOption,
      updateCustomBillingInfo,
      setCustomBillingInfoSaveInAddressBook,
      setDeliveryTime,
      setPaymentType,
      setOppCardOption,
      updatePromotionCode,
    }),
    [
      state,
      startCheckout,
      selectDeliveryInfoType,
      updateDeliveryAddress,
      setDeliveryAddressSaveInAddressBook,
      updateSelfPickup,
      resetBillingInfo,
      selectBillingInfoOption,
      updateCustomBillingInfo,
      setCustomBillingInfoSaveInAddressBook,
      setDeliveryTime,
      setPaymentType,
      setOppCardOption,
      updatePromotionCode,
    ]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
