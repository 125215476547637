import React, { useCallback, useContext } from "react";
import cn from "classnames";
// eslint-disable-next-line
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonButton,
  IonIcon,
  IonBadge,
} from "@ionic/react";

import { OurNavContext } from "../../our-navigation";

import styles from "./styles.module.scss";
import CSRStyles from "../CSRModal/styles.module.scss"
import { useLocation } from "react-router";
import { useIonContentScrollEl } from "../../utils/ionContentScrollEl";

interface NavBarProps {
  headerClassName?: string;
  toolbarClassName?: string;
  titleClassName?: string;
  headerLeft?: React.ReactNode;
  headerTitle?: React.ReactNode;
  headerRight?: React.ReactNode;
  custom?: React.ReactNode;
}

export const NavBar: React.FC<NavBarProps> = props => {
  const { headerClassName, headerLeft, headerTitle, headerRight, custom } = props;
  return (
    <IonHeader mode="ios" className={cn(headerClassName)}>
      <IonToolbar mode="ios" className={cn(props.toolbarClassName)} style={{ "--border-width": "0px"}}>
        {headerLeft !== undefined && (
          <IonButtons slot="secondary">{headerLeft}</IonButtons>
        )}
        {headerTitle !== undefined && (
          <IonTitle className={cn(styles.navBarTitle, props.titleClassName)}>
            {headerTitle}
          </IonTitle>
        )}
        {headerRight !== undefined && (
          <IonButtons slot="primary">{headerRight}</IonButtons>
        )}
        {custom !== undefined && custom}
      </IonToolbar>
    </IonHeader>
  );
};

export const CSRNavBarBackButton: React.FC<{
  backDefaultHref?: string;
  useWindowLocation?: boolean;
}> = props => {
  const { search } = useLocation();
  let params = new URLSearchParams(search);
  let expressCheckout = params.get("express");
  const navContext = useContext(OurNavContext);
  const { goBack, navigate } = navContext;
  const onClickBackButton = useCallback(() => {
    if (expressCheckout) {
     return navigate("/donation", null, "back");
    }
    if (props.useWindowLocation && props.backDefaultHref) {
      window.location.href = props.backDefaultHref;
    }
    return goBack(props.backDefaultHref);
  }, [goBack, props.backDefaultHref, props.useWindowLocation, expressCheckout]);
  return (
    <IonButton className={styles.csrBackButton} onClick={onClickBackButton}>
      <img className={CSRStyles.csrBackButtonIcon} src="/images/back.png" />
    </IonButton>
  );
}

export const NavBarBackButton: React.FC<{
  backDefaultHref?: string;
}> = props => {
  const navContext = useContext(OurNavContext);
  const { goBack } = navContext;
  const onClickBackButton = useCallback(() => {
    goBack(props.backDefaultHref);
  }, [goBack, props.backDefaultHref]);
  return (
    <IonButton className={styles.backButton} onClick={onClickBackButton}>
      <div className={styles.backIcon} />
    </IonButton>
  );
};

export const NavBarCrossButton: React.FC<{
  onClick?: () => void;
  disabled?: boolean;
}> = props => {
  const { onClick, disabled } = props;
  return (
    <IonButton
      className={styles.crossButton}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={styles.crossIcon} />
    </IonButton>
  );
};

export const NavBarShareButton: React.FC<{
  onClick: () => void;
}> = props => {
  const { onClick } = props;
  return (
    <IonButton className={styles.shareButton} onClick={onClick}>
      <IonIcon name="cl-share" className={styles.shareIcon} />
    </IonButton>
  );
};
export const NavBarMessageButton: React.FC<{
  onClick: () => void;
  count: number;
}> = props => {
  const { onClick, count } = props;
  return (
    <IonButton className={styles.messageButton} onClick={onClick}>
      <IonIcon name="cl-message" className={styles.messageIcon} />
      {count ? (
        <IonBadge className={styles.countBadge}>
          {count >= 10 ? "9+" : count}
        </IonBadge>
      ) : null}
    </IonButton>
  );
};

export const NavBarHomeButton: React.FC<{
  onClick: () => void;
}> = props => {
  const { onClick } = props;
  return (
    <IonButton className={styles.homeButton} onClick={onClick}>
      <IonIcon name="cl-home" className={styles.homeIcon} />
    </IonButton>
  );
};

export const NavBarLiveChatButton: React.FC<{
  onClick: () => void;
}> = props => {
  const { onClick } = props;
  return (
    <IonButton className={styles.liveChatButton} onClick={onClick}>
      <IonIcon name="cl-livechat" className={styles.liveChatIcon} />
    </IonButton>
  );
};
