import { useContext } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { fetchProductAttributeFilterInputFields } from "../api/GraphQL";
import { ResourcesRequestState } from "../models/ResourcesRequestState";
import { FilterInputField } from "../models/filter";

import { RepositoryContext } from "./State";
import { useFetchResources_v2 } from "./Hooks";

export function useFetchProductAttributeFilterFields(): [
  ResourcesRequestState<FilterInputField[]>,
  () => Promise<FilterInputField[]>
] {
  const client = useApolloClient();
  const { state, dispatch } = useContext(RepositoryContext);

  const [requestState, { call }] = useFetchResources_v2<
    FilterInputField[],
    () => Promise<FilterInputField[]>
  >({
    memoryCacheProvider: () =>
      Promise.resolve(state.productAttributeFilterInputFields),
    localCacheProvider: async () => {
      const productAttributeFilterInputFields = await fetchProductAttributeFilterInputFields(
        client,
        "cache-only"
      );
      dispatch({
        type: "UpdateProductAttributeFilterFields",
        productAttributeFilterInputFields,
      });
      return productAttributeFilterInputFields;
    },
    remoteResourcesProvider: async () => {
      const productAttributeFilterInputFields = await fetchProductAttributeFilterInputFields(
        client,
        "network-only"
      );
      dispatch({
        type: "UpdateProductAttributeFilterFields",
        productAttributeFilterInputFields,
      });
      return productAttributeFilterInputFields;
    },
  });

  return [requestState, call];
}
