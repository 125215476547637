import { Plugins } from "@capacitor/core";
import * as yup from "yup";
import moment from "moment";
import { Locale, stringToLocale } from "./i18n/locale";
import { RemoteCategoryTree, CategoryTreeSchema } from "./models/category";
import { StoreConfig, StoreConfigSchema } from "./models/StoreConfig";
import { CMSPageContent, parseCMSPageContentFromJson } from "./models/cmsBlock";
import { SearchTerm, SearchTermSchema } from "./models/Search";
import { ArticleCategory, ArticleCategorySchema } from "./models/Article";
import {
  AppConfig,
  AppConfigSchema,
  UrlRedirectSchema,
} from "./models/AppConfig";
import { IndexMap } from "./utils/type";
import { EntityUrlRedirect } from "./models/EntityUrl";
import { Customer } from "./models/Customer";

const { Storage } = Plugins;

export default Storage;

export enum StorageKey {
  displayLanguage = "display_language",
  appConfig = "appConfig",
  customer = "customer",
  urlRedirect = "urlRedirect",
  storeConfig = "storeConfig",
  cartID = "cartID",
  categoryTree = "categoryTree",
  csrUser = "csrUser",
  accessToken = "accessToken",
  homePageContent = "homePageContent",
  recentSearch = "recentSearch",
  articleCategories = "articleCategories",
  pushNotificationEnabled = "pushNotificationEnabled",
  pnDeviceToken = "pnDeviceToken",
  pushNotificationMessagesPageLastRead = "pushNotificationMessagesPageLastRead",
}

async function setItem(key: StorageKey, value: string) {
  await Storage.set({
    key: key,
    value: value,
  });
}

async function getItem(key: StorageKey): Promise<string | null> {
  const res = await Storage.get({ key: key });
  return res.value;
}

async function clearItem(key: StorageKey): Promise<void> {
  return Storage.remove({
    key,
  });
}

export async function setDisplayLanguage(locale: Locale) {
  await setItem(StorageKey.displayLanguage, locale);
}

export async function getDisplayLanguage(): Promise<Locale | null> {
  const localeValue = await getItem(StorageKey.displayLanguage);
  if (localeValue != null) {
    return stringToLocale(localeValue);
  }
  return null;
}

function getItemKey(
  locale: Locale,
  storageKey: StorageKey,
  params: IndexMap<string, any> = {}
): StorageKey {
  let key = `${locale}_${storageKey}`;
  if (Object.keys(params).length) {
    const paramPart = Object.keys(params)
      .sort()
      .map(key => `${key}:${params[key]}`)
      .join("_");
    key = `${key}_${paramPart}`;
  }
  return key as StorageKey;
}

export async function setCartID(cartID: string | null) {
  if (cartID != null) {
    await setItem(StorageKey.cartID, cartID);
  } else {
    await clearItem(StorageKey.cartID);
  }
}

export async function getCartById(cartId): Promise<string | null> {
  let cartStr = await getItem(cartId);
  if (cartStr) {
    return JSON.parse(cartStr);
  } else {
    return null;
  }
}

export async function getCartID(): Promise<string | null> {
  return getItem(StorageKey.cartID);
}

export async function setCategoryTree(
  categoryId: number,
  remoteCategoryTree: RemoteCategoryTree,
  locale: Locale
) {
  return setItem(
    getItemKey(locale, StorageKey.categoryTree, { categoryId }),
    JSON.stringify(remoteCategoryTree)
  );
}

export async function getCategoryTree(
  categoryId: number,
  locale: Locale
): Promise<RemoteCategoryTree | null> {
  const value = await getItem(
    getItemKey(locale, StorageKey.categoryTree, { categoryId })
  );
  if (value == null) {
    return null;
  }
  try {
    return await CategoryTreeSchema.validate(JSON.parse(value));
  } catch {
    clearItem(getItemKey(locale, StorageKey.categoryTree));
    return null;
  }
}

export async function setStoreConfig(storeConfig: StoreConfig, locale: Locale) {
  return setItem(
    getItemKey(locale, StorageKey.storeConfig),
    JSON.stringify(storeConfig)
  );
}

export async function getStoreConfig(locale: Locale) {
  const value = await getItem(getItemKey(locale, StorageKey.storeConfig));
  if (value == null) {
    return null;
  }

  try {
    return await StoreConfigSchema.validate(JSON.parse(value));
  } catch {
    clearItem(getItemKey(locale, StorageKey.storeConfig));
    return null;
  }
}

export async function storeAppConfig(
  appConfig: AppConfig,
  locale: Locale
): Promise<void> {
  return setItem(
    getItemKey(locale, StorageKey.appConfig),
    JSON.stringify(appConfig)
  );
}

export async function getAppConfig(locale: Locale): Promise<AppConfig | null> {
  const value = await getItem(getItemKey(locale, StorageKey.appConfig));
  if (value == null) {
    return null;
  }

  try {
    return await AppConfigSchema.validate(JSON.parse(value));
  } catch {
    clearItem(getItemKey(locale, StorageKey.appConfig));
    return null;
  }
}

export async function storeUrlRedirect(
  urlRedirect: EntityUrlRedirect[],
  locale: Locale
): Promise<void> {
  return setItem(
    getItemKey(locale, StorageKey.urlRedirect),
    JSON.stringify(urlRedirect)
  );
}

export async function getUrlRedirect(
  locale: Locale
): Promise<EntityUrlRedirect[] | null> {
  const value = await getItem(getItemKey(locale, StorageKey.urlRedirect));
  if (value == null) {
    return null;
  }

  try {
    return await UrlRedirectSchema.validate(JSON.parse(value));
  } catch {
    clearItem(getItemKey(locale, StorageKey.urlRedirect));
    return null;
  }
}

export async function getAccessToken(): Promise<string | null> {
  const accessToken = await getItem(StorageKey.accessToken);
  return accessToken;
}

export async function setAccessTokenToStorage(accessToken: string) {
  return setItem(StorageKey.accessToken, accessToken);
}

export async function clearAccessToken() {
  return clearItem(StorageKey.accessToken);
}

export async function setHomePageContent(
  locale: Locale,
  content: CMSPageContent
) {
  return setItem(
    getItemKey(locale, StorageKey.homePageContent),
    JSON.stringify(content)
  );
}

export async function getHomePageContent(
  locale: Locale
): Promise<CMSPageContent | null> {
  const value = await getItem(getItemKey(locale, StorageKey.homePageContent));
  if (value == null) {
    return null;
  }
  const json = JSON.parse(value);
  const cmsPageContent = parseCMSPageContentFromJson(json);
  if (cmsPageContent == null) {
    clearItem(getItemKey(locale, StorageKey.homePageContent));
    return null;
  }
  return cmsPageContent;
}

export async function clearHomePageContent(locale: Locale) {
  clearItem(getItemKey(locale, StorageKey.homePageContent));
}

export async function storeRecentlySearches(recentSearches: SearchTerm[]) {
  return setItem(StorageKey.recentSearch, JSON.stringify(recentSearches));
}

export async function getRecentlySearches(): Promise<SearchTerm[]> {
  const value = await getItem(StorageKey.recentSearch);
  if (value == null) {
    return [];
  }
  try {
    const searches = JSON.parse(value);
    return await yup
      .array()
      .of(SearchTermSchema)
      .validate(searches);
  } catch {
    return [];
  }
}

export async function storeAllArticleCategories(
  articleCategories: ArticleCategory[],
  locale: Locale
) {
  return setItem(
    getItemKey(locale, StorageKey.articleCategories),
    JSON.stringify(articleCategories)
  );
}

export async function getAllArticleCategories(
  locale: Locale
): Promise<ArticleCategory[] | null> {
  const value = await getItem(getItemKey(locale, StorageKey.articleCategories));
  if (value == null) {
    return null;
  }
  try {
    const rawCategories = JSON.parse(value);
    return await yup
      .array()
      .of(ArticleCategorySchema)
      .validate(rawCategories);
  } catch {
    return null;
  }
}

export async function setCustomer(customer: Customer) {
  return setItem(StorageKey.customer, JSON.stringify(customer));
}

export async function getCustomer(): Promise<Customer | null> {
  const customerString = await getItem(StorageKey.customer);
  if (!customerString) {
    return null;
  }
  try {
    const customer = JSON.parse(customerString);
    return customer;
  } catch {
    return null;
  }
}
export async function clearCSRUser() {
  return clearItem(StorageKey.csrUser);
}
export async function clearCustomer() {
  return clearItem(StorageKey.customer);
}

export async function hasEnablePushNotificationEntry(): Promise<boolean> {
  return !!(await getItem(StorageKey.pushNotificationEnabled));
}

export async function setEnablePushNotification(enable: boolean) {
  return setItem(StorageKey.pushNotificationEnabled, `${enable}`);
}

export async function getEnablePushNotification(): Promise<boolean> {
  return (await getItem(StorageKey.pushNotificationEnabled)) === "true";
}

export async function deleteEnablePushNotification() {
  return clearItem(StorageKey.pushNotificationEnabled);
}

export async function setPNDeviceToken(deviceToken: string) {
  return setItem(StorageKey.pnDeviceToken, deviceToken);
}

export async function getPNDeviceToken(): Promise<string | null> {
  const pnDeviceToken = await getItem(StorageKey.pnDeviceToken);
  if (!pnDeviceToken) {
    return null;
  }
  return pnDeviceToken;
}

export async function deletePNDeviceToken() {
  return clearItem(StorageKey.pnDeviceToken);
}

export async function getPushNotificationMessagesPageLastRead(): Promise<Date | null> {
  const dateString = await getItem(
    StorageKey.pushNotificationMessagesPageLastRead
  );
  if (!dateString) {
    return null;
  }
  return moment(dateString).toDate();
}

export async function setPushNotificationMessagesPageLastRead(date: Date) {
  return setItem(
    StorageKey.pushNotificationMessagesPageLastRead,
    moment(date).format()
  );
}

export async function deletePushNotificationMessagesPageLastRead() {
  return clearItem(StorageKey.pushNotificationMessagesPageLastRead);
}
