import React, { useMemo, useContext, useState, useCallback } from "react";
import {
  useCustomer,
  useIsLoggedIn,
  useCSRUser,
} from "../../repository/AuthRepository";
import { Customer, getCustomerProfilePicUrl } from "../../models/Customer";
import styles from "./styles.module.scss";
import { LocalizedText } from "../../i18n/Localization";
import CSRModal from "../CSRModal";
import { LoginSignupModalContext } from "../LoginSignupModalProvider";
import { MessageID } from "../../i18n/translations/type";
import classnames from "classnames";
import { useModalState } from "../../hook/modal";
import CustomerModal from "./Modal";

function checkIsLoggedIn(
  customer: Customer | null
): [true, Customer] | [false, null] {
  return customer != null ? [true, customer] : [false, null];
}

export const TitleBadge: React.FC = () => {
  const csrUser = useCSRUser();
  let titleLevelMessage: MessageID = "user.title_level.1";
  if (csrUser) {
    switch (csrUser.titleLevel) {
      case 1:
        titleLevelMessage = "user.title_level.1";
        break;
      case 2:
        titleLevelMessage = "user.title_level.2";
        break;
      case 3:
        titleLevelMessage = "user.title_level.3";
        break;
      case 4:
        titleLevelMessage = "user.title_level.4";
        break;
    }
  }
  return csrUser ? (
    <div
      className={styles.titleBadge}
      style={{
        backgroundImage: `url(./assets/images/Icon/badge-bg-${csrUser.titleLevel}.png)`,
      }}
    >
      <LocalizedText
        messageID={titleLevelMessage}
        messageArgs={{ level: csrUser.gradingLevel }}
      />
    </div>
  ) : null;
};

const CustomerCard: React.FC = () => {
  const customer = useCustomer();
  const csrUser = useCSRUser();
  const isLoggedIn = useIsLoggedIn();

  const { presentLoginModal, presentSignupModal } = useContext(
    LoginSignupModalContext
  );

  const [
    isCustomerCardModalOpened,
    presentCustomerCardModal,
    dismissCustomerCardModal,
  ] = useModalState();

  const isProductionEnv = useMemo(() => {
    const { hostname } = window.location;
    return process.env.NODE_ENV == "production" && hostname == "csr.theclub.com.hk";
  }, []);

  return (
    <>
      {isLoggedIn && customer ? (
        <>
          <div
            id="user-profile"
            className="parallelogram-outside logged-in hover-glow"
            onClick={presentCustomerCardModal}
          >
            <div
              className={classnames(
                "parallelogram-inside",
                styles.customerCard
              )}
            >
              <div className={styles.customerCardImage}>
                {getCustomerProfilePicUrl(customer) ? (
                  <img
                    className="user-pic-img"
                    src={
                      getCustomerProfilePicUrl(customer) ||
                      "./assets/images/Avator.png"
                    }
                    alt=""
                  />
                ) : (
                  <img
                    className="user-pic-img"
                    src="./assets/images/Avator.png"
                    alt=""
                  />
                )}
              </div>
              <div className={styles.customerCardData}>
                <div className={styles.customerUsername}>
                  {customer.firstname} {customer.lastname}
                </div>
                <div className={styles.title}>
                  <TitleBadge />
                </div>
              </div>
            </div>
          </div>
          {isCustomerCardModalOpened ? (
            <CustomerModal
              isOpen={isCustomerCardModalOpened}
              onModalDismiss={dismissCustomerCardModal}
            />
          ) : null}
        </>
      ) : (
        <div id="user-profile" className="user-guest parallelogram-outside">
          <div
            className={classnames("parallelogram-inside", styles.customerCard)}
          >
            <div className={styles.customerCardImage}>
              <img src="./assets/images/Avator.png" alt="" />
            </div>
            <div className={styles.customerCardData}>
              <div className={styles.customerUsername}>
                <LocalizedText messageID="login.visitor" />
              </div>
              <div className={styles.buttons}>
                <div
                  id="btn-reg"
                  className="user-btn parallelogram-outside hover-glow"
                  onClick={() => {
                    let registerLink = isProductionEnv ? "https://www.theclub.com.hk/zh/signup.html": "https://www-d0.theclub.com.hk/zh/signup.html";
                    window.open(registerLink, "_blank");
                  }}
                >
                  <span className="parallelogram-inside">
                    <LocalizedText messageID="signup" />
                  </span>
                </div>
                <div
                  id="btn-login"
                  className="user-btn parallelogram-outside hover-glow"
                  onClick={() => presentLoginModal()}
                >
                  <span className="parallelogram-inside">
                    <LocalizedText messageID="login" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerCard;
