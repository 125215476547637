import {
  ProductType,
  ProductImage,
  CLClubPoint,
  PriceRange,
  Product360Image,
  ProductStockStatus,
  ThirdPartyProductDisplayType,
  ProductConfigurableAttributeOption,
  Product360ImageGraphQLAttributes,
  ProductDeliveryMethod,
  ProductConfigurableOption,
  RemoteClubTierQuota,
  ProductSpec,
  ProductCustomizationOption,
  ProductLink,
  ProductTodoAttribute_Number,
  RecurringConfiguration,
  ClubTierQuota,
  Quota,
  RemoteRecurringConfiguration,
  ProductConfigurableOptionGraphQLAttributes,
  ProductCustomizationOptionInterfaceGraphQLAttributes,
  ProductCustomizationOptionTextAreaGraphQLAttributes,
  ProductCustomizationOptionDateGraphQLAttributes,
  ProductCustomizationOptionDropDownGraphQLAttributes,
  ProductCustomizationOptionMultipleSelectGraphQLAttributes,
  ProductCustomizableOptionTextFieldGraphQLAttributes,
  ProductCustomizableOptionFileGraphQLAttributes,
  ProductCustomizableOptionRadioGraphQLAttributes,
  ProductCustomizableOptionCheckboxGraphQLAttributes,
  ProductImageGraphQLAttributes,
} from "./product";
import { MediaContent, MediaContentGraphQLAttributes } from "./Media";
import { MerchantPreview, MerchantPreviewGraphQLAttributes } from "./Merchant";
import { MoneyGraphQLAttributes } from "./Price";
import { Override } from "../utils/type";
import { HTMLText, HTMLTextGrapQLAttributes } from "./HTMLText";

import Config from "../Config";
import {
  ProductOverview,
  ProductOverviewGraphQLAttributes,
} from "./ProductOverview";
import { StoreConfig } from "./StoreConfig";

export interface Product {
  id: number;
  entityId: number;
  sku: string;
  name: string;
  type: ProductType;
  thumbnail: ProductImage | null;
  image: ProductImage | null;
  clubPoint: number;
  minClubPoint: number;
  extraClubpoints: number | null;
  clClubPoint?: CLClubPoint | null;
  priceRange: PriceRange | null;
  specialFromDateStr: string | null;
  specialToDateStr: string | null;
  newFromDateStr: string | null;
  newToDateStr: string | null;

  mediaContents: MediaContent[];
  magic360Images: Product360Image[];
  merchant: [MerchantPreview | null];

  rating: number;

  stockStatus: ProductStockStatus;

  enableDisclaimer: boolean;
  isDisclaimerRequired: boolean;
  manufacturerSuggestedRetailPrice?: string | null;

  configurableOptions?: ProductConfigurableOption[] | null;
  variants?: ProductVariant[] | null;

  displayType?: ThirdPartyProductDisplayType | null;
  buttonUrl?: string | null;
  infoMessage?: string | null;

  shortDescription: HTMLText;
  longDescription: HTMLText;
  specs: ProductSpec[];

  // ---

  deliveryMethod: ProductDeliveryMethod | null;
  deliveryMethodBlockIdentifier: string | null;

  reviewCount: number;
  productLinks: ProductLink[] | null;
  relatedProducts: ProductOverview[] | null;
  urlKey: string;

  customizableOptions: ProductCustomizationOption[] | null;
  recurringConfiguration: RecurringConfiguration | null;

  // ---

  qAndACount: ProductTodoAttribute_Number;
  likeCount: ProductTodoAttribute_Number;

  clubTierQuota?: ClubTierQuota | null;
  quota?: Quota | null;
  memberQuota?: Quota | null;

  enableClubProtection?: boolean | null;
}

export type RemoteProduct = Override<
  Product,
  {
    recurringConfiguration: RemoteRecurringConfiguration | null;
    clubTierQuota?: RemoteClubTierQuota | null;

    variants?: RemoteProductVariant[] | null;
  }
>;

export interface ProductVariant {
  product: ConfiguredProduct;
  attributes: ProductConfigurableAttributeOption[];
}

export interface RemoteProductVariant {
  product: RemoteConfiguredProduct;
  attributes: ProductConfigurableAttributeOption[];
}

export type RemoteConfiguredProduct = Override<
  ConfiguredProduct,
  {
    clubTierQuota?: RemoteClubTierQuota | null;
  }
>;

export interface ConfiguredProduct {
  id: number;
  entityId: number;
  sku: string;
  name: string;
  type: ProductType;
  thumbnail: ProductImage | null;
  image: ProductImage | null;
  clubPoint: number;
  minClubPoint: number;
  extraClubpoints: number | null;
  clClubPoint?: CLClubPoint | null;
  priceRange: PriceRange | null;
  specialFromDateStr: string | null;
  specialToDateStr: string | null;
  newFromDateStr: string | null;
  newToDateStr: string | null;

  mediaContents: MediaContent[];
  magic360Images: Product360Image[];
  merchant: [MerchantPreview | null];

  rating: number;

  stockStatus: ProductStockStatus;
  manufacturerSuggestedRetailPrice?: string | null;

  shortDescription: HTMLText;
  longDescription: HTMLText;

  specs: ProductSpec[];

  deliveryMethod: ProductDeliveryMethod | null;
  deliveryMethodBlockIdentifier: string | null;

  enableDisclaimer: boolean;
  isDisclaimerRequired: boolean;

  displayType?: ThirdPartyProductDisplayType | null;
  buttonUrl?: string | null;
  infoMessage?: string | null;
  clubTierQuota?: ClubTierQuota | null;
  quota?: Quota | null;
  memberQuota?: Quota | null;

  enableClubProtection?: boolean | null;
}

export const ProductVariantGrapQLAttributes = `
  product {
    id
    entityId: entity_id
    sku
    name
    type: type_id
    thumbnail {
      ${ProductImageGraphQLAttributes}
    }
    image {
      ${ProductImageGraphQLAttributes}
    }
    clubPoint: clubpoints
    minClubPoint: min_clubpoints
    extraClubpoints: extra_clubpoints
    clClubPoint: cl_clubpoints
    priceRange: price_range {
      minimumPrice: minimum_price {
        regularPrice: regular_price {
          ${MoneyGraphQLAttributes}
        }
        finalPrice: final_price {
          ${MoneyGraphQLAttributes}
        }
      }
      maximumPrice: maximum_price {
        regularPrice: regular_price {
          ${MoneyGraphQLAttributes}
        }
        finalPrice: final_price {
          ${MoneyGraphQLAttributes}
        }
      }
    }
    specialFromDateStr: special_from_date
    specialToDateStr: special_to_date
    newFromDateStr: new_from_date
    newToDateStr: new_to_date
    mediaContents: media_gallery_entries {
      ${MediaContentGraphQLAttributes}
    }
    magic360Images: magic360_image {
      ${Product360ImageGraphQLAttributes}
    }
    merchant {
      ${MerchantPreviewGraphQLAttributes}
    }
    rating
    stockStatus: stock_status
    manufacturerSuggestedRetailPrice: pro_msrp

    shortDescription: short_description {
      ${HTMLTextGrapQLAttributes}
    }
    longDescription: description {
      ${HTMLTextGrapQLAttributes}
    }
    specs: custom_attributes {
      label
      value
    }
    ${
      Config.ENABLE_CLUB_PROTECT
        ? "enableClubProtection: enable_club_protection"
        : ""
    }
  }
  attributes {
    label
    value: value_index
    code
  }
`;

export const ProductBaseGraphQLAttributes = `
  id
  entityId: entity_id
  sku
  name
  type: type_id
  thumbnail {
    ${ProductImageGraphQLAttributes}
  }
  image {
    ${ProductImageGraphQLAttributes}
  }
  clubPoint: clubpoints
  minClubPoint: min_clubpoints
  extraClubpoints: extra_clubpoints
  clClubPoint: cl_clubpoints
  priceRange: price_range {
    minimumPrice: minimum_price {
      regularPrice: regular_price {
        ${MoneyGraphQLAttributes}
      }
      finalPrice: final_price {
        ${MoneyGraphQLAttributes}
      }
    }
    maximumPrice: maximum_price {
      regularPrice: regular_price {
        ${MoneyGraphQLAttributes}
      }
      finalPrice: final_price {
        ${MoneyGraphQLAttributes}
      }
    }
  }
  specialFromDateStr: special_from_date
  specialToDateStr: special_to_date
  newFromDateStr: new_from_date
  newToDateStr: new_to_date
  mediaContents: media_gallery_entries {
    ${MediaContentGraphQLAttributes}
  }
  magic360Images: magic360_image {
    ${Product360ImageGraphQLAttributes}
  }
  merchant {
    ${MerchantPreviewGraphQLAttributes}
  }
  rating
  stockStatus: stock_status
  enableDisclaimer: enable_disclaimer
  isDisclaimerRequired: is_disclaimer_required
  manufacturerSuggestedRetailPrice: pro_msrp

  ... on ConfigurableProduct {
    configurableOptions: configurable_options {
      ${ProductConfigurableOptionGraphQLAttributes}
    }
    variants {
      ${ProductVariantGrapQLAttributes}
    }
  }
  ... on ThirdPartyProduct {
    displayType: display_type
    buttonUrl: button_url
    infoMessage: info_message
  }

  shortDescription: short_description {
    ${HTMLTextGrapQLAttributes}
  }
  longDescription: description {
    ${HTMLTextGrapQLAttributes}
  }
  specs: custom_attributes {
    label
    value
  }
  ${
    Config.ENABLE_CLUB_PROTECT
      ? "enableClubProtection: enable_club_protection"
      : ""
  }
`;

const RecurringConfigurationGraphQLAttributes = `
isRecurringEnable: is_recurring_enable
isSubscriptionOnly: is_subscription_only
billingCycle: billing_cycle
isEnableTrial: is_enable_trial
isFreeShipping: is_free_shipping
startDate: start_date
endDate: end_date
`;

export const ProductDetailsAdditionalGraphQLAttributes = `
  id
  deliveryMethod: delivery_method
  deliveryMethodBlockIdentifier: delivery_method_block_identifier
  reviewCount: review_count
  productLinks: product_links {
    linkType: link_type
    linkedProductSKU: linked_product_sku
  }
  relatedProducts: related_products {
    ${ProductOverviewGraphQLAttributes}
  }
  urlKey: url_key
  ... on CustomizableProductInterface {
    customizableOptions: options {
      ${ProductCustomizationOptionInterfaceGraphQLAttributes}
      ... on CustomizableAreaOption {
        ${ProductCustomizationOptionTextAreaGraphQLAttributes}
      }
      ... on CustomizableDateOption {
        ${ProductCustomizationOptionDateGraphQLAttributes}
      }
      ... on CustomizableDropDownOption {
        ${ProductCustomizationOptionDropDownGraphQLAttributes}
      }
      ... on CustomizableMultipleOption {
        ${ProductCustomizationOptionMultipleSelectGraphQLAttributes}
      }
      ... on CustomizableFieldOption {
        ${ProductCustomizableOptionTextFieldGraphQLAttributes}
      }
      ... on CustomizableFileOption {
        ${ProductCustomizableOptionFileGraphQLAttributes}
      }
      ... on CustomizableRadioOption {
        ${ProductCustomizableOptionRadioGraphQLAttributes}
      }
      ... on CustomizableCheckboxOption {
        ${ProductCustomizableOptionCheckboxGraphQLAttributes}
      }
    }
  }
  recurringConfiguration: recurring_configuration {
    ${RecurringConfigurationGraphQLAttributes}
  }
`;

export function constructMediaUrlForProduct(
  storeConfig: StoreConfig,
  mediaContent: MediaContent
): string {
  return `${storeConfig.baseMediaUrl}catalog/product/${mediaContent.filePath}`;
}