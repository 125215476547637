import {
  ProductDeliveryMethod,
  getPriceOfCustomizableOptionPriceType,
  getProductMerchant,
  ProductType,
  CLClubPoint,
  PriceRange,
  ProductConfigurableOption,
  ProductConfigurableOptionGraphQLAttributes,
  ProductConfigurableAttributeOption,
  ProductStockStatus,
  ProductImage,
  ProductImageGraphQLAttributes,
} from "./product";
import { EntityID as MerchantEntityID, MerchantID, MerchantPreview, MerchantPreviewGraphQLAttributes } from "./Merchant";
import { Money, MoneyGraphQLAttributes } from "./Price";
import { NumericBoolean, Override } from "../utils/type";
import {
  getOrderedItemOptions,
  getOrderedCustomizableOptions,
} from "../utils/ShoppingCart";
import { CartAddress } from "../components/Checkout/models";

export interface Product {
  id: number;
  entityId: number;
  sku: string;
  name: string;
  type: ProductType;
  thumbnail: ProductImage | null;
  clubPoint: number;
  minClubPoint: number;
  extraClubpoints: number | null;
  clClubPoint?: CLClubPoint | null;
  priceRange: PriceRange | null;
  merchant: [MerchantPreview | null];
  deliveryMethod: ProductDeliveryMethod | null;
  stockStatus: ProductStockStatus;
  configurableOptions?: ProductConfigurableOption[] | null;
  variants?: ProductVariant[] | null;
}

export interface ProductVariant {
  product: ConfiguredProduct;
  attributes: ProductConfigurableAttributeOption[];
}


export interface ConfiguredProduct {
  id: number;
  entityId: number;
  sku: string;
  name: string;
  type: ProductType;
  thumbnail: ProductImage | null;
  clubPoint: number;
  minClubPoint: number;
  extraClubpoints: number | null;
  clClubPoint?: CLClubPoint | null;
  priceRange: PriceRange | null;
  merchant: [MerchantPreview | null];
  deliveryMethod: ProductDeliveryMethod | null;
  stockStatus: ProductStockStatus;
}

export const ProductVariantGrapQLAttributes = `
id
entityId: entity_id
sku
name
type: type_id
thumbnail {
  ${ProductImageGraphQLAttributes}
}
clubPoint: clubpoints
minClubPoint: min_clubpoints
extraClubpoints: extra_clubpoints
clClubPoint: cl_clubpoints
priceRange: price_range {
  minimumPrice: minimum_price {
    regularPrice: regular_price {
      ${MoneyGraphQLAttributes}
    }
    finalPrice: final_price {
      ${MoneyGraphQLAttributes}
    }
  }
  maximumPrice: maximum_price {
    regularPrice: regular_price {
      ${MoneyGraphQLAttributes}
    }
    finalPrice: final_price {
      ${MoneyGraphQLAttributes}
    }
  }
}
merchant {
  ${MerchantPreviewGraphQLAttributes}
}
deliveryMethod: delivery_method
stockStatus: stock_status
`;

export const ProductGraphQLAttributes = `
id
entityId: entity_id
sku
name
type: type_id
thumbnail {
  ${ProductImageGraphQLAttributes}
}
clubPoint: clubpoints
minClubPoint: min_clubpoints
extraClubpoints: extra_clubpoints
clClubPoint: cl_clubpoints
priceRange: price_range {
  minimumPrice: minimum_price {
    regularPrice: regular_price {
      ${MoneyGraphQLAttributes}
    }
    finalPrice: final_price {
      ${MoneyGraphQLAttributes}
    }
  }
  maximumPrice: maximum_price {
    regularPrice: regular_price {
      ${MoneyGraphQLAttributes}
    }
    finalPrice: final_price {
      ${MoneyGraphQLAttributes}
    }
  }
}
merchant {
  ${MerchantPreviewGraphQLAttributes}
}
deliveryMethod: delivery_method
stockStatus: stock_status

... on ConfigurableProduct {
  configurableOptions: configurable_options {
    ${ProductConfigurableOptionGraphQLAttributes}
  }
  variants {
    product{
      ${ProductVariantGrapQLAttributes}
    }
    attributes {
      label
      value: value_index
      code
    }
  }
}
`;

export interface CartDiscountBreakdown {
  ruleName: string;
  ruleAmount: string;
}

export const CartDiscountBreakdownGraphQLAttributes = `
  ruleAmount: rule_amount
  ruleName: rule_name
`;

export interface CartDiscount {
  label: string;
  amount: Money;
}

export const CartDiscountsGraphQLAttributes = `
  amount {
    ${MoneyGraphQLAttributes}
  }
  label
`;

export type CartDiscountTypes =
  | {
      type: "discounts";
      discounts: CartDiscount[];
    }
  | {
      type: "discountBreakdown";
      discountBreakdown: CartDiscountBreakdown[];
    };

export interface CartPrices {
  // TODO (Steven-Chan): includes applied_taxes
  grandTotal: Money;
  subtotalExcludingTax: Money;
  subtotalIncludingTax: Money;
  subtotalWithDiscountExcludingTax: Money;
  discountAmount: Money;
  shippingAmount: Money;
  discountBreakdown?: CartDiscountBreakdown[];
  discounts?: CartDiscount[];
  initialSubscriptionFee?: Money;
}

export const CartPricesGraphQLAttributes = `
  grandTotal: grand_total { ${MoneyGraphQLAttributes} }
  subtotalExcludingTax: subtotal_excluding_tax { ${MoneyGraphQLAttributes} }
  subtotalIncludingTax: subtotal_including_tax { ${MoneyGraphQLAttributes} }
  subtotalWithDiscountExcludingTax: subtotal_with_discount_excluding_tax { ${MoneyGraphQLAttributes} }
  discountBreakdown: discount_breakdown { ${CartDiscountBreakdownGraphQLAttributes} }
`;

export interface Cart {
  id: string;
  items: CartItem[];
  prices: CartPrices;
  clubPointToBeEarned: number;
  clubPointToBeUsed: number;
  clubPointRequired?: number | null;
}


type CartItemWithDeliveryMethod = Override<
  Pick<CartItem, "product">,
  {
    product: Pick<Product, "deliveryMethod">;
  }
>;

export function getCartItemsByDeliveryMethod<
  T extends CartItemWithDeliveryMethod
>(cartItems: T[]): [{ [blockId in ProductDeliveryMethod]: T[] }, T[]] {
  const resWithDeliveryMethod: { [blockId in ProductDeliveryMethod]: T[] } = {
    warehouse: [],
    pickupnow: [],
    evoucher: [],
  };
  const resWithoutDeliveryMethod: T[] = [];
  for (const item of cartItems) {
    const { deliveryMethod } = item.product;
    if (deliveryMethod == null) {
      resWithoutDeliveryMethod.push(item);
    } else {
      if (resWithDeliveryMethod[deliveryMethod] == null) {
        resWithDeliveryMethod[deliveryMethod] = [];
      }
      resWithDeliveryMethod[deliveryMethod].push(item);
    }
  }
  return [resWithDeliveryMethod, resWithoutDeliveryMethod];
}

export function getCartItemsByMerchant<
  T extends CartItemWithMerchant
>(cartItems: T[]): { [merchantId in MerchantID]: T[] } {
  const items: { [merchantId in MerchantID]: T[]} = {};
  for (let item of cartItems) {
    let merchant = getProductMerchant(item.product);
    if (merchant) {
      if (items[merchant.name] == null) {
        items[merchant.name] = [];
      }
      items[merchant.name] = [
        ...items[merchant.name],
        item,
      ]
    }
  }
  return items;
  /*
  return cartItems.reduce<{ [merchantId in MerchantID]: T[]}>(
    (acc, item) => {
      let merchant = getProductMerchant(item.product);
      if (merchant) {
        if (acc[merchant.name] == null) {
          acc[merchant.name] = [];
        }
        acc[merchant.name] = [
          ...acc[merchant.name],
          item,
        ]
      }
      return acc;
    },
    {}
  )
  */
}

type CartItemWithMerchant = Override<
  Pick<CartItem, "product">,
  {
    product: Override<
      Pick<Product, "merchant">,
      {
        merchant: [Pick<MerchantPreview, "entityId" | "name"> | null]
      }
    >
  }
>;

export const SelectedCustomizableOptionGraphQL = `
  isRequired: is_required
  label
  sortOrder: sort_order
  values {
    label
    price {
      type
      units
      value
    }
    value
  }
`;

export interface CartItemSelectedOptionValuePrice {
  type: "FIXED" | "PERCENT" | "DYNAMIC";
  units: string;
  value: number;
}

export interface SelectedCustomizableOptionValue {
  // FIXME
  // Server returns same id for option value which leads to we cannot
  // dispaly multiple value
  // Hide id for now to hotfix the issue
  // id: number;
  label: string;
  price: CartItemSelectedOptionValuePrice;
  value: string;
}

export interface SelectedCustomizableOption {
  id: number;
  isRequired: boolean;
  label: string;
  sortOrder: number;
  values: SelectedCustomizableOptionValue[];
}

export const SelectedConfigurableOptionGraphQL = `
  optionLabel: option_label
  valueId: value_id
  valueLabel: value_label
`;

export interface SelectedConfigurableOption {
  id: number;
  optionLabel: string;
  valueId: number;
  valueLabel: string;
}

// TODO (Steven-Chan): update this
export const CartGraphQLAttributes = `
  email
  clubPointRequired: clubpoints_required
  items {
    id
    product {
      ${ProductGraphQLAttributes}
    }
    quantity
    ...on VirtualCartItem {
      isFreeGift: is_free_gift
    }
    ...on SimpleCartItem {
      isFreeGift: is_free_gift
      customizableOptionsForSimpleCartItem: customizable_options {
        ${SelectedCustomizableOptionGraphQL}
      }
    }
    ...on ConfigurableCartItem {
      configurableOptions: configurable_options {
        ${SelectedConfigurableOptionGraphQL}
      }
      customizableOptionsForConfigurableCartItem: customizable_options {
        ${SelectedCustomizableOptionGraphQL}
      }
    }
  }
  prices {
    ${CartPricesGraphQLAttributes}
  }
`;

export interface CartItem {
  id: string;
  product: Product;
  isFreeGift?: boolean;
  quantity: number;
  configurableOptions?: SelectedConfigurableOption[];
  customizableOptionsForSimpleCartItem?: SelectedCustomizableOption[];
  customizableOptionsForConfigurableCartItem?: SelectedCustomizableOption[];
}

	
export interface RecurringOption {
  label: string | null;
  value: string | null;
}

export interface CartItemInput {
  sku: string;
  quantity: number;
  subscribe: SubscribeEnum;
  disclaimer?: DisclaimerInput;
  clubprotection?: ClubProtectionInput;
}

export interface DisclaimerInput {
  agreement?: 0 | 1;
  service?: ReeService;
}

export interface CustomizableOptionInput {
  id: number;
  valueString: string;
}

export interface SimpleProductCartItemInput {
  data: CartItemInput;
  customizableOptions?: CustomizableOptionInput[];
}

export function serializeSimpleProductCartItemInput(
  input: SimpleProductCartItemInput
) {
  return {
    data: input.data,
    customizable_options: input.customizableOptions
      ? input.customizableOptions.map(o => ({
          id: o.id,
          value_string: o.valueString,
        }))
      : undefined,
  };
}

export interface ConfigurableProductCartItemInput
  extends SimpleProductCartItemInput {
  parentSku: string;
  variantSku: string;
}

export function serializeConfigurableProductCartItemInput(
  input: ConfigurableProductCartItemInput
) {
  return {
    parent_sku: input.parentSku,
    variant_sku: input.variantSku,
    data: input.data,
    customizable_options: input.customizableOptions
      ? input.customizableOptions.map(o => ({
          id: o.id,
          value_string: o.valueString,
        }))
      : undefined,
  };
}

export function isConfigurableProductCartItemInput(
  input: SimpleProductCartItemInput
): input is ConfigurableProductCartItemInput {
  return typeof (input as any).parentSku === "string";
}

export type ReeService = "FREE_RECYCLE" | "NO_RECYCLE" | "DECIDE_LATER";

export enum ReeServiceInt {
  FreeRecycle = 0,
  NoRecycle = 1,
  DecideLater = 2,
}
export function getReeService(service: ReeServiceInt): ReeService {
  switch (service) {
    case ReeServiceInt.FreeRecycle:
      return "FREE_RECYCLE";
    case ReeServiceInt.NoRecycle:
      return "NO_RECYCLE";
    case ReeServiceInt.DecideLater:
      return "DECIDE_LATER";
    default:
      throw new Error("Unknown REE Service");
  }
}

export enum SubscribeEnum {
  subscribe = "subscribe",
  notSubscribe = "not_subscribe",
}

export enum ClubProtectionEnum {
  Yes = "YES",
  No = "NO",
}
export interface ClubProtectionInput {
  agreement_1: NumericBoolean;
  agreement_2: NumericBoolean;
  service: ClubProtectionEnum;
}


export function getCartItemSelectedOptions(
  item: Override<
    Pick<
      CartItem,
      | "product"
      | "configurableOptions"
      | "customizableOptionsForSimpleCartItem"
      | "customizableOptionsForConfigurableCartItem"
    >,
    {
      product: Pick<CartItem["product"], "configurableOptions">;
    }
  >
): { title: string; displayValue: string }[] {
  const {
    product,
    configurableOptions: _configurableOptions,
    customizableOptionsForSimpleCartItem: _customizableOptionsForSimpleCartItem,
    customizableOptionsForConfigurableCartItem: _customizableOptionsForConfigurableCartItem,
  } = item;
  const configurableOptions =
    _configurableOptions && product.configurableOptions
      ? getOrderedItemOptions(_configurableOptions, product.configurableOptions)
      : [];
  const customizableOptionsForSimpleCartItem = _customizableOptionsForSimpleCartItem
    ? getOrderedCustomizableOptions(_customizableOptionsForSimpleCartItem)
    : [];
  const customizableOptionsForConfigurableCartItem = _customizableOptionsForConfigurableCartItem
    ? getOrderedCustomizableOptions(_customizableOptionsForConfigurableCartItem)
    : [];
  const options: { title: string; displayValue: string }[] = [];
  options.push(
    ...configurableOptions.map(o => ({
      title: o.optionLabel,
      displayValue: o.valueLabel,
    }))
  );
  const selectedCustomizableOptions: SelectedCustomizableOption[] = [];
  selectedCustomizableOptions.push(...customizableOptionsForSimpleCartItem);
  selectedCustomizableOptions.push(
    ...customizableOptionsForConfigurableCartItem
  );
  options.push(
    ...selectedCustomizableOptions.map(o => ({
      title: o.label,
      displayValue: o.values.map(v => v.label || v.value).join(", "),
    }))
  );
  return options;
}


export function getCartItemSelectedOptionsAdditionalCost(
  originalPrice: number,
  cartItem: Pick<
    CartItem,
    | "customizableOptionsForSimpleCartItem"
    | "customizableOptionsForConfigurableCartItem"
  >
): number {
  const selectedCustomizableOptions: SelectedCustomizableOption[] = [];
  if (cartItem.customizableOptionsForSimpleCartItem != null) {
    selectedCustomizableOptions.push(
      ...cartItem.customizableOptionsForSimpleCartItem
    );
  }
  if (cartItem.customizableOptionsForConfigurableCartItem != null) {
    selectedCustomizableOptions.push(
      ...cartItem.customizableOptionsForConfigurableCartItem
    );
  }
  let additionalCost = 0;
  for (const option of selectedCustomizableOptions) {
    for (const _value of option.values) {
      const { type, value } = _value.price;
      additionalCost += getPriceOfCustomizableOptionPriceType(
        originalPrice,
        type,
        value
      );
    }
  }
  return additionalCost;
}

export function getDiscountBreakdown(prices: CartPrices): CartDiscountTypes {
  if (prices.discountBreakdown != null) {
    return {
      type: "discountBreakdown",
      discountBreakdown: prices.discountBreakdown,
    };
  } else if (prices.discounts != null) {
    return {
      type: "discounts",
      discounts: prices.discounts,
    };
  }
  return {
    type: "discounts",
    discounts: [],
  };
}

export function getMinClubPointUsed(
  cart: Pick<Cart, "clubPointRequired">
): number {
  return cart.clubPointRequired || 0;
}

export function isCartAddressEmpty(address: Partial<CartAddress>): boolean {
  const { firstname, lastname, street, city, region, country, telephone, company } = address;

  const areSomeFieldsNotEmpty =
    (firstname && firstname.trim()) ||
    (lastname && lastname.trim()) ||
    (street && street.length) ||
    (city && city.trim()) ||
    (region && region.code) ||
    (country && country.code) ||
    (telephone && telephone.trim()) ||
    (company && company.trim());

  return !areSomeFieldsNotEmpty;
}