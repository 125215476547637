import { MessageID } from "../i18n/translations/type";

interface RemoteError {
  type: "remote";
  message: string;
}
export function RemoteError(message: string): RemoteError {
  return {
    type: "remote",
    message,
  };
}
export function isRemoteError(error: CLError): error is RemoteError {
  return error.type === "remote";
}

interface LocalError {
  type: "local";
  messageID: MessageID;
}
export function LocalError(messageID: MessageID): LocalError {
  return {
    type: "local",
    messageID,
  };
}
export function isLocalError(error: CLError): error is LocalError {
  return error.type === "local";
}

export type CLError = RemoteError | LocalError;
