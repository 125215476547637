import { useState, useEffect } from "react";
import {
  ApolloClient,
  InMemoryCache,
  gql,
  FetchPolicy,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject,
} from "apollo-boost";
import { ApolloLink, Observable, FetchResult } from "apollo-link";
import { PersistedData } from "apollo-cache-persist/types";
import { createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import { CachePersistor } from "apollo-cache-persist";
import Storage from "../storage";
import introspectionQueryResultData from "./fragmentTypes.json";
import { makeCLGraphQLFetch } from "./fetch";
import { makeCLGraphQLFetch as makeCLGraphQLHashedQueryFetch } from "./hashedQueryFetch";

import { fetchMaintenanceStatus, restAPIClient } from "./RESTful";
import { isObject } from 'lodash';
import Config from "../Config";

import { TokenStore } from "./TokenStore";

import {
  AppConfigGraphQLAttributes,
  RemoteRate,
  AppConfig,
  AppConfigSchema,
  LiveEvent,
  LiveEventGraphQLAttributes,
  LiveEventSchema,
} from "../models/AppConfig";
import {
  StoreConfig,
  StoreConfigGraphQLAttributes,
} from "../models/StoreConfig";
import {
  ClubTierQuota,
  Quota,
  ProductOverviewBaseClubTierQuotaGraphQLAttributes,
  ProductOverviewVariantProductCubTierQuotaGraphQLAttributes,
  augmentProductWithNotImplementedAttribute,
  RecurringConfiguration,
  transformRemoteRecurringConfigurationToRecurringConfiguration,
  transformRemoteClubTierQuotaToClubTierQuota,
} from "../models/product";
import {
  Product,
  RemoteProduct,
  ProductBaseGraphQLAttributes,
  ProductDetailsAdditionalGraphQLAttributes,
} from "../models/ProductDetails";
import {
  ProductOverview,
  ProductOverviewGraphQLAttributes,
} from "../models/ProductOverview";
import {
  ProductLabel,
  ProductLabelMode,
  ProductLabelGraphQLAttributes,
} from "../models/ProductLabel";
import {
  RemoteProductReview,
  ProductReview,
  ProductReviewSchema,
  ProductReviewGraphQLAttributes,
  RemoteCustomerProductReview,
  CustomerProductReview,
  CustomerProductReviewsSchema,
  CustomerProductReviewGraphqlAttributes,
  RatingCode,
  RatingCodeGraphQLAttributes,
  RatingVote,
} from "../models/ProductReview";
import {
  RemoteCategoryTree,
  RawRemoteCategoryTree,
  CategoryTreeSchema,
  CategoryTreeGraphQLAttributes,
  filterDisabledCategories,
} from "../models/category";
import {
  SimpleProductCartItemInput,
  serializeSimpleProductCartItemInput,
  CartGraphQLAttributes,
  ConfigurableProductCartItemInput,
  isConfigurableProductCartItemInput,
  serializeConfigurableProductCartItemInput,
  Cart,
} from "../models/cart";
import {
  MerchantID,
  EntityID as MerchantEntityID,
  MerchantPreview,
  MerchantPreviewGraphQLAttributes,
  Merchant,
  MerchantGraphQLAttributes,
} from "../models/Merchant";

import { Locale, getStoreViewCodeForLocale } from "../i18n/locale";
import {
  Customer,
  CustomerGraphQLAttributes,
  CustomerAddressGraphQLAttributes,
  RemoteAddress,
} from "../models/Customer";
import {
  CMSStaticBlockContent,
  CMSStaticBlockContentGraphQLAttributes,
  CMSPageContent,
  HTMLBasedCMSPageContent,
} from "../models/cmsBlock";
import {
  District,
  RemoteCountry,
  CountrySchema,
  DistrictSchema,
  CountryGraphQLAttributes,
  DistrictGraphQLAttributes,
} from "../models/CountryRegion";
import {
  SearchTerm,
  SearchAutoSuggestion,
  SearchAutoSuggestionGraphQLAttributes,
} from "../models/Search";
import { PageInfo, PageInfoGraphQLAttributes } from "../models/PageInfo";
import { EntityUrl, EntityUrlGraphQLAttributes } from "../models/EntityUrl";
import {
  FilterInputField,
  ProductFilterInfo,
  mapSortAttributeToGraphQLVariable,
  makeGraphQLFilter,
  GraphQLFilter,
  SortFieldOption,
  SortField,
  getApplicableProductFilterInfo,
} from "../models/filter";
import {
  WishlistItem,
  WishlistItemGraphQLAttribtues,
} from "../models/Wishlist";
import { IfMagentoVersionFn, MagentoVersion } from "../models/MagentoVersion";
import {
  extractCMSBlocksFromContentForApp,
  extractCMSBlocksFromContentForAppWithWaitingToFillHTML,
} from "../utils/CMSBlockExtractor";
import {
  Aggregation,
  AggregationGraphQLAttributes,
} from "../models/LayeredNavigation";
import {
  OS,
  Platform,
  Token,
  Isdn,
  PNSResponse,
  NotificationEnableState,
} from "../models/OPNSPushNotification";
import { OppCard, OppCardGraphQLAttributes } from "../models/OppCard";
import {
  CustomerSubscriptionId,
  RemoteCustomerSubscription,
  CustomerSubscription,
  transformRemoteCustomerSubscriptionToCustomerSubscription,
  CustomerSubscriptionGraphQLAttributes,
} from "../models/CustomerSubscription";
import { Override, IndexMap } from "../utils/type";

import {
  networkEventEmitter,
  NetworkEventMaintenance,
} from "../utils/SimpleEventEmitter";
import {
  ServerVirtualWaitingRoomConfig,
  ServerVirtualWaitingRoomConfigGraphQLAttributes,
} from "../models/VirtualWaitingRoom";
import { PartialNGO } from "../models/NGO";

export type GraphQLFn<T> = (
  client: ApolloClient<any>,
  locale: Locale,
  ...args: any
) => Promise<T>;

export type GraphQLFnParams<F extends GraphQLFn<any>> = F extends (
  client: ApolloClient<any>,
  locale: Locale,
  ...args: infer A
) => Promise<any>
  ? A
  : never;

const httpLink = createHttpLink({
  uri: Config.GRAPHQL_ENDPOINT,
  fetch: Config.ENABLE_HASHED_QUERY
    ? makeCLGraphQLHashedQueryFetch()
    : makeCLGraphQLFetch({ useGETForQueries: true }),
});
const authContext = setContext((_, { headers }) => {
  if (TokenStore.accessToken == null) {
    return { headers };
  }

  const token = TokenStore.accessToken;
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const errorLink = onError(error => {
  const { operation } = error;
  const { operationName } = operation;
  const errorMessage = parseGraphQLError(error);
  // Let sentry capture more info in breadcrumbs
  console.info(
    `GraphQL error: ${errorMessage} (operationName: ${operationName})`
  );
});

/**
 * Use apollo link to handle errors raised by network or api server
 * Specific errors are checked and see if they are caused by maintenance mode
 * by checking maintenance mode flag.
 *
 * If maintenance mode is checked by this link, a maintenance event will be emitted.
 * The app should act according to emission of maintenance event.
 */
const maintenanceLink = new ApolloLink((operation, forward) => {
  return new Observable<FetchResult>(observer => {
    if (!forward) {
      observer.complete();
      return;
    }
    let sub: ReturnType<Observable<FetchResult>["subscribe"]> | null = null;
    try {
      sub = forward(operation).subscribe({
        next: result => {
          // Check for api server error. There maybe some error messages from server
          // we should care to check the maintenance mode.
          if (result.errors) {
            const errorMessage = parseGraphQLError({
              graphQLErrors: result.errors,
            });
            if (errorMessage) {
              const errorMessagesForMaintenanceCheck: string[] = [];
              if (errorMessagesForMaintenanceCheck.length === 0) {
                observer.next(result);
                return;
              }
              const errorMessagesForMaintenanceCheckRegExp = new RegExp(
                errorMessagesForMaintenanceCheck.join("|")
              );
              if (errorMessagesForMaintenanceCheckRegExp.exec(errorMessage)) {
                fetchMaintenanceStatus(restAPIClient).then(isMaintenance => {
                  if (!isMaintenance) {
                    observer.next(result);
                  } else {
                    networkEventEmitter.publish(NetworkEventMaintenance());
                    observer.complete();
                  }
                });
                return;
              }
            }
          }
          observer.next(result);
        },
        error: networkError => {
          // Check for network error. Mainly server inaccessable.
          if (networkError) {
            const errorMessage = networkError.message;
            if (errorMessage) {
              const errorMessagesForMaintenanceCheck: string[] = [
                "Failed to fetch",
                // ios
                "(.+)is not allowed by Access-Control-Allow-Origin.",
              ];
              if (errorMessagesForMaintenanceCheck.length === 0) {
                observer.error(networkError);
                return;
              }
              const errorMessagesForMaintenanceCheckRegExp = new RegExp(
                errorMessagesForMaintenanceCheck.join("|")
              );
              if (errorMessagesForMaintenanceCheckRegExp.exec(errorMessage)) {
                fetchMaintenanceStatus(restAPIClient).then(isMaintenance => {
                  if (!isMaintenance) {
                    observer.error(networkError);
                  } else {
                    networkEventEmitter.publish(NetworkEventMaintenance());
                    observer.complete();
                  }
                });
                return;
              }
            }
          }
          observer.error(networkError);
        },
        complete: () => {
          observer.complete();
        },
      });
    } catch (e) {
      observer.error(e);
    }
    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  });
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({
  fragmentMatcher,
});

// This is the internal apolloClient
//
// Please use getApolloClient to ensure this instance is initialized properly
let apolloClient: ApolloClient<NormalizedCacheObject> | null;

// Get the internal apolloClient with React hook
export function useCLApolloClient() {
  const [apolloClient, setApolloClient] = useState<ApolloClient<
    NormalizedCacheObject
  > | null>(null);
  const [cachePersistor, setCachePersistor] = useState<CachePersistor<
    NormalizedCacheObject
  > | null>(null);

  useEffect(() => {
    (async () => {
      const [a, c] = await getApolloClient();
      setApolloClient(a);
      setCachePersistor(c);
    })();
  }, []);

  return {
    apolloClient,
    cachePersistor,
  };
}

export async function getApolloClient(): Promise<
  [ApolloClient<any>, CachePersistor<any>]
> {
  const cachePersistor = new CachePersistor({
    cache,
    storage: {
      getItem: async (key: string): Promise<NormalizedCacheObject> => {
        const result = await Storage.get({ key });
        const value = result.value;
        if (value == null) {
          return {};
        }
        return JSON.parse(value);
      },
      setItem: async (
        key: string,
        data: PersistedData<NormalizedCacheObject>
      ): Promise<void> => {
        const serializedData = JSON.stringify(data);
        try {
          await Storage.set({ key, value: serializedData });
        } catch {
          await Storage.remove({ key });
        }
      },
      removeItem: async (key: string): Promise<void> => {
        await Storage.remove({ key });
      },
    },
    trigger: "write",
    debounce: 5000,
    serialize: false,
    maxSize: 4000000,
  });

  await cachePersistor.restore();

  if (apolloClient != null) {
    return [apolloClient, cachePersistor];
  }

  apolloClient = new ApolloClient({
    link: errorLink
      .concat(maintenanceLink)
      .concat(authContext)
      .concat(httpLink),
    cache,
  });

  return [apolloClient, cachePersistor];
}

// see: https://www.apollographql.com/docs/react/features/error-handling/#error-policies
//
// return null if not able to parse the error
export function parseGraphQLError(error: any): string | null {
  if (Array.isArray(error.graphQLErrors) && error.graphQLErrors.length > 0) {
    if (error.graphQLErrors.length > 1) {
      console.warn("parseGraphQLError, graphQLErrors.length > 1");
    }

    const { message, debugMessage } = error.graphQLErrors[0];

    if (message === "Internal server error") {
      return debugMessage || message;
    }

    return message;
  }

  if (error.hasOwnProperty('message')) {
    return error.message;
  }

  if (error.networkError != null) {
    return error.networkError.message;
  }

  console.warn(
    "parseGraphQLError, neither graphQLErrors nor networkError found",
    error
  );
  return null;
}

export async function fetchStoreConfig(
  client: ApolloClient<any>,
  locale: Locale
): Promise<StoreConfig> {
  const result = await client.query({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      {
        storeConfig {
          ${StoreConfigGraphQLAttributes}
        }
      }
    `,
    fetchPolicy: "network-only",
  });
  return result.data.storeConfig;
}

export async function fetchAppConfig(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<AppConfig | null> {
  const result = await client.query<{
    appConfig: Override<
      Omit<AppConfig, "cmsBlockId">,
      { useClubPointsRate: RemoteRate }
    >;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      {
        appConfig {
          ${AppConfigGraphQLAttributes}
        }
      }
    `,
    fetchPolicy,
  });

  return AppConfigSchema.validateSync(result.data.appConfig);
}

export async function fetchLiveEvent(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<LiveEvent | null> {
  const result = await client.query<{
    appConfig: {
      liveEvent: LiveEvent;
    };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      {
        appConfig {
          ${LiveEventGraphQLAttributes}
        }
      }
    `,
    fetchPolicy,
  });
  const liveEvent = LiveEventSchema.validateSync(
    result.data.appConfig.liveEvent
  );
  return liveEvent;
}

export async function fetchVirtualWaitingRoomConfig(
  client: ApolloClient<any>
): Promise<ServerVirtualWaitingRoomConfig | null> {
  try {
    const result = await client.query<{
      appConfig: ServerVirtualWaitingRoomConfig;
    }>({
      query: gql`
    query fetchVirtualWaitingRoomConfig {
      appConfig {
        ${ServerVirtualWaitingRoomConfigGraphQLAttributes}
      }
    }`,
    });
    if (result.data && result.data.appConfig) {
      return result.data.appConfig;
    }
    return null;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function fetchProductSKUByUrlKey(
  client: ApolloClient<any>,
  urlKey: string
): Promise<string | null> {
  const result = await client.query<
    {
      products: { items: [{ sku: string }] } | undefined;
    },
    {
      urlKey: string;
    }
  >({
    query: gql`
      query QueryProductSKUbyUrlKey($urlKey: String!) {
        products(filter: { url_key: { eq: $urlKey } }) {
          items {
            sku
          }
        }
      }
    `,
    variables: {
      urlKey,
    },
  });

  if (!result.data.products) {
    return null;
  }

  const item = result.data.products.items[0];
  if (!item) {
    return null;
  }

  return item.sku;
}

/* eslint-disable complexity */
export async function fetchProduct(
  client: ApolloClient<any>,
  sku: string,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<Product | null> {
  async function query<K extends keyof RemoteProduct>(
    graphQLAttributes: string
  ): Promise<{
    data: {
      products: { items: [Pick<RemoteProduct, K>] } | undefined;
    } | null;
  }> {
    if (fetchPolicy === "cache-only") {
      return new Promise((resolve, reject) => {
        try {
          const result = client.readQuery<{
            products:
              | {
                  items: [Pick<RemoteProduct, K>];
                }
              | undefined;
          }>({
            query: gql`
             query QueryProductBySKU($sku: String!) {
               products(filter: { sku: { eq: $sku } }) {
                 items {
                   ${graphQLAttributes}
                 }
               }
             }
           `,
            variables: {
              sku,
            },
          });
          resolve({ data: result });
        } catch (e) {
          reject(e);
        }
      });
    }
    return client.query<{
      products:
        | {
            items: [Pick<RemoteProduct, K>];
          }
        | undefined;
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query QueryProductBySKU($sku: String!) {
          products(filter: { sku: { eq: $sku } }) {
            items {
              ${graphQLAttributes}
            }
          }
        }
      `,
      variables: {
        sku,
      },
      fetchPolicy,
    });
  }

  const results = await Promise.all([
    query<
      | "id"
      | "entityId"
      | "sku"
      | "name"
      | "type"
      | "thumbnail"
      | "image"
      | "clubPoint"
      | "minClubPoint"
      | "extraClubpoints"
      | "clClubPoint"
      | "priceRange"
      | "specialFromDateStr"
      | "specialToDateStr"
      | "newFromDateStr"
      | "newToDateStr"
      | "mediaContents"
      | "magic360Images"
      | "merchant"
      | "rating"
      | "stockStatus"
      | "enableDisclaimer"
      | "isDisclaimerRequired"
      | "manufacturerSuggestedRetailPrice"
      | "configurableOptions"
      | "variants"
      | "shortDescription"
      | "longDescription"
      | "specs"
      | "displayType"
      | "buttonUrl"
      | "infoMessage"
    >(ProductBaseGraphQLAttributes),
    query<
      | "deliveryMethod"
      | "deliveryMethodBlockIdentifier"
      | "reviewCount"
      | "stockStatus"
      | "productLinks"
      | "relatedProducts"
      | "urlKey"
      | "customizableOptions"
      | "recurringConfiguration"
    >(ProductDetailsAdditionalGraphQLAttributes),
    // FEATURE_MERGE: club_tier_quota present in product interface => [0]
    query<"clubTierQuota" | "quota" | "memberQuota">(
      ProductOverviewBaseClubTierQuotaGraphQLAttributes
    ).catch(() => ({ data: { products: { items: [] } } })),
    // FEATURE_MERGE: club_tier_quota present in product.variants.product interface => [0]
    // query<"variants">(
    //   ProductOverviewVariantProductCubTierQuotaGraphQLAttributes
    // ).catch((err) => {
    //   console.log(err);
    //   return ({ data: { products: { items: [] } } })
    // }),
  ]);

  const data0 = results[0].data;
  const data1 = results[1].data;
  const data2 = results[2].data;
  // const variantProductClubTierQuota = results[3].data;
  if (
    data0 == null ||
    data0.products == null ||
    data1 == null ||
    data1.products == null
  ) {
    return null;
  }

  if (data0.products.items.length <= 0 || data1.products.items.length <= 0) {
    return null;
  }

  const result0 = data0.products.items[0];
  const result1 = data1.products.items[0];

  const product: Omit<RemoteProduct, "qAndACount" | "likeCount"> = {
    ...result0,
    ...result1,
    ...(data2 && data2.products && data2.products.items.length > 0
      ? {
          clubTierQuota: data2.products.items[0].clubTierQuota,
          quota: data2.products.items[0].quota,
          memberQuota: data2.products.items[0].memberQuota,
        }
      : {}),
  };

  const variantProductClubTierQuotaMap: {
    [key in number]: {
      clubTierQuota: ClubTierQuota | null;
      quota: Quota | null;
      memberQuota: Quota | null;
    };
  } = {};
  // if (
  //   variantProductClubTierQuota &&
  //   variantProductClubTierQuota.products &&
  //   variantProductClubTierQuota.products.items[0]
  // ) {
  //   const { variants } = variantProductClubTierQuota.products.items[0];
  //   if (variants != null) {
  //     for (const { product } of variants) {
  //       const { id, clubTierQuota, quota, memberQuota } = product;
  //       variantProductClubTierQuotaMap[id] = {
  //         clubTierQuota: clubTierQuota
  //           ? transformRemoteClubTierQuotaToClubTierQuota(clubTierQuota)
  //           : null,
  //         quota: quota || null,
  //         memberQuota: memberQuota || null,
  //       };
  //     }
  //   }
  // }

  const recurringConfiguration: RecurringConfiguration | null = product.recurringConfiguration
    ? transformRemoteRecurringConfigurationToRecurringConfiguration(
        product.recurringConfiguration
      )
    : null;

  return augmentProductWithNotImplementedAttribute({
    ...product,
    recurringConfiguration,
    variants:
      product.variants == null
        ? null
        : product.variants
            // variants with null product are disabled from server
            .filter(v => v.product != null)
            .map(v => ({
              ...v,
              product: augmentProductWithNotImplementedAttribute({
                ...v.product,
                ...variantProductClubTierQuotaMap[v.product.id],
              }),
            })),
    clubTierQuota: product.clubTierQuota
      ? transformRemoteClubTierQuotaToClubTierQuota(product.clubTierQuota)
      : null,
  });
}
/* eslint-enable complexity */

export async function fetchProductReviews(
  client: ApolloClient<any>,
  sku: string,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<ProductReview[]> {
  const result = await client.query<{
    products: { items: { review: RemoteProductReview[] }[] };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryProductReviews($sku: String!) {
        products(filter: { sku: { eq: $sku } }) {
          items {
            id
            review {
              ${ProductReviewGraphQLAttributes}
            }
          }
        }
      }
    `,
    variables: {
      sku,
    },
    fetchPolicy,
  });

  if (result.data.products == null || result.data.products.items.length === 0) {
    return [];
  }

  const [product] = result.data.products.items;

  if (product.review == null) {
    return [];
  }

  const productReviews = ProductReviewSchema.validateSync(product.review);

  return productReviews;
}

export async function fetchCustomerProductReviews(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<CustomerProductReview[]> {
  const result = await client.query<{
    customer: { productReviews: RemoteCustomerProductReview[] };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryCustomerProductReviews {
        customer {
          id
          productReviews: product_reviews {
            ${CustomerProductReviewGraphqlAttributes}
          }
        }
      }
    `,
    fetchPolicy,
  });
  if (
    result.data.customer == null ||
    result.data.customer.productReviews == null ||
    result.data.customer.productReviews.length === 0
  ) {
    return [];
  }

  const { productReviews } = result.data.customer;

  return CustomerProductReviewsSchema.validateSync(productReviews);
}

export async function fetchRatingCodes(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<RatingCode[]> {
  const result = await client.query<{
    ratingCode: RatingCode[];
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryRatingCodes {
        ratingCode {
          ${RatingCodeGraphQLAttributes}
        }
      }
    `,
    fetchPolicy,
  });
  if (result.data.ratingCode == null) {
    return [];
  }
  return result.data.ratingCode;
}
export async function addProductReview(
  client: ApolloClient<any>,
  ratingVote: RatingVote[],
  productId: number,
  detail: string,
  locale: Locale
) {
  const result = await client.mutate<{
    addProductReview: { reviewId: number };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation AddProductReview(
        $ratingVote: [RatingVoteInput]!
        $productId: Int!
        $detail: String!
      ) {
        addProductReview(
          input: {
            rating_vote: $ratingVote
            product_id: $productId
            detail: $detail
          }
        ) {
          reviewId: review_id
        }
      }
    `,
    variables: {
      ratingVote: ratingVote.map(({ optionId, ratingId }) => ({
        option_id: optionId,
        rating_id: ratingId,
      })),
      productId,
      detail,
    },
    fetchPolicy: "no-cache",
  });

  return result.data.addProductReview.reviewId;
}

export async function fetchProductOverviewsBySKUs(
  client: ApolloClient<any>,
  skus: string[],
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<ProductOverview[] | null> {
  const query = <T>(graphQLAttributes: string) =>
    client.query<{ products: { items: T[] } }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
      query QueryProductOverviewsBySKUs($skus: [String], $pageSize: Int!) {
        products(filter: { sku: { in: $skus } }, pageSize: $pageSize) {
          items {
            ${graphQLAttributes}
          }
        }
      }
    `,
      variables: {
        skus,
        pageSize: skus.length,
      },
      fetchPolicy,
    });

  const result = await query<Product>(ProductOverviewGraphQLAttributes);

  if (result.data.products == null) {
    return null;
  }

  // The result from this query is not in the order of input skus
  // so make it in order
  const productOverviews: ProductOverview[] = [];

  for (let i = 0; i < skus.length; i++) {
    const sku = skus[i];
    const productOverview = result.data.products.items.filter(
      p => p.sku === sku
    )[0];
    if (!productOverview) {
      console.warn(`Missing product of sku ${sku} from QueryProductBySKU`);
      continue;
    }
    productOverviews.push(productOverview);
  }

  return productOverviews;
}

export async function fetchProductLabelsByProductIds(
  client: ApolloClient<any>,
  productIds: number[],
  mode: ProductLabelMode,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{ productId: number; productLabels: ProductLabel[] }[]> {
  const result = await client.query<{
    amLabelProvider: { items: ProductLabel[] }[];
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryProductLabels(
        $productIds: [Int!]!
        $mode: AmLabelMode
      ) {
        amLabelProvider(
          productIds: $productIds
          mode: $mode
        ) {
          items {
            ${ProductLabelGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      productIds,
      mode,
    },
    fetchPolicy,
  });

  const data: { [key in string]: ProductLabel[] } = {};

  if (result && result.data && result.data && result.data.amLabelProvider)  {
    for (const ls of result.data.amLabelProvider) {
      if (ls.items && ls.items.length > 0) {
        for (const item of ls.items) {
          const { productId } = item;
          const productLabels: ProductLabel[] = data[`${productId}`] || [];
          data[`${productId}`] = [
            ...productLabels,
            // Fix image url with site url
            {
              ...item,
              image: item.image ? `${Config.SITE_URL}/${item.image}` : null,
            },
          ];
        }
      }
    }
  }
  
  const res: { productId: number; productLabels: ProductLabel[] }[] = [];

  for (const key of Object.keys(data)) {
    if (data[key]) {
      const [{ productId }] = data[key];
      res.push({ productId, productLabels: data[key] });
    }
  }

  return res;
}

export async function loginWithEmail(
  client: ApolloClient<any>,
  //locale: Locale,
  email: string,
  password: string
): Promise<string> {
  const result = await client.mutate<{
    generateCustomerToken: { token: string };
  }>({
    // context: {
    //   headers: {
    //     Store: getStoreViewCodeForLocale(locale),
    //   },
    // },
    mutation: gql`
      mutation Login($email: String!, $password: String!) {
        generateCustomerToken(email: $email, password: $password) {
          token
        }
      }
    `,
    variables: {
      email,
      password,
    },
    fetchPolicy: "no-cache",
  });

  return result.data.generateCustomerToken.token;
}

export async function fetchCategoryTree(
  client: ApolloClient<any>,
  categoryId: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<RemoteCategoryTree | null> {
  const result = await client.query<{ category: RawRemoteCategoryTree }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query QueryCategory${getStoreViewCodeForLocale(
        locale
      )}($categoryId: Int) {
        category(id: $categoryId) {
          ${CategoryTreeGraphQLAttributes}
        }
      }
    `,
    variables: {
      categoryId,
    },
    fetchPolicy,
  });

  if (result.data.category == null) {
    return null;
  }
  const categoryTree = CategoryTreeSchema.validateSync(
    filterDisabledCategories(result.data.category)
  );
  return categoryTree;
}

export async function fetchCategoryList(
  client: ApolloClient<any>,
  categoryId: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<RemoteCategoryTree | null> {
  const result = await client.query<{ categoryList: RawRemoteCategoryTree[] }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query QueryCategoryList($categoryId: String) {
        categoryList(filters: { ids: { eq: $categoryId } }) {
          ${CategoryTreeGraphQLAttributes}
        }
      }
    `,
    variables: {
      categoryId: `${categoryId}`,
    },
    fetchPolicy,
  });

  if (
    result.data.categoryList == null ||
    result.data.categoryList.length === 0
  ) {
    return null;
  }
  const categoryTree = CategoryTreeSchema.validateSync(
    filterDisabledCategories(result.data.categoryList[0])
  );
  return categoryTree;
}

export async function signupWithEmail(
  client: ApolloClient<any>,
  locale: Locale,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  isSubscribeToNewsletter: boolean
): Promise<string> {
  try {
    const result = await client.mutate<{
      createCustomer: {
        customer: { id: number };
      };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
        mutation Signup(
          $firstName: String!
          $lastName: String!
          $email: String!
          $password: String!
          $isSubscribeToNewsletter: Boolean!
        ) {
          createCustomer(
            input: {
              firstname: $firstName
              lastname: $lastName
              email: $email
              password: $password
              is_subscribed: $isSubscribeToNewsletter
            }
          ) {
            customer {
              id
            }
          }
        }
      `,
      variables: {
        firstName,
        lastName,
        email,
        password,
        isSubscribeToNewsletter,
      },
      fetchPolicy: "no-cache",
    });
  
    return result.data.createCustomer.customer.id;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
  
}

export async function fetchCategoryDescriptionByCategoryId(
  client: ApolloClient<any>,
  categoryId: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<string | null> {
  const result = await client.query<{
    category: { description: string | null };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryDescriptionByCategoryId($categoryId: Int) {
        category(id: $categoryId) {
          id
          description
        }
      }
    `,
    variables: {
      categoryId,
    },
    fetchPolicy,
  });
  if (result.data.category == null) {
    if (fetchPolicy === "cache-only") {
      return null;
    }
    throw Error("Something went wrong");
  }
  return result.data.category.description;
}

export async function fetchCategoryDescriptionFromCategoryListByCategoryId(
  client: ApolloClient<any>,
  categoryId: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<string | null> {
  const result = await client.query<{
    categoryList: { description: string | null }[];
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryDescriptionByCategoryId($categoryId: String) {
        categoryList(filters: { ids: { eq: $categoryId } }) {
          id
          description
        }
      }
    `,
    variables: {
      categoryId: `${categoryId}`,
    },
    fetchPolicy,
  });
  if (
    result.data.categoryList == null ||
    result.data.categoryList.length === 0
  ) {
    if (fetchPolicy === "cache-only") {
      return null;
    }
    throw Error("Something went wrong");
  }
  return result.data.categoryList[0].description;
}



export async function fetchProductsFromCategoryListByCategoryId(
  client: ApolloClient<any>,
  categoryId: number,
  page: number,
  productFilterInfo: ProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{
  products: Product[];
  hasMore: boolean;
  pageSize: number;
} | null> {
  const { sortAttribute } = productFilterInfo;
  const sortInput =
    mapSortAttributeToGraphQLVariable(sortAttribute) || undefined;
  const result = await client.query<{
    products: {
      items: [Product];
      pageInfo: { totalPages: number; pageSize: number };
    };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryProductsByCategoryId(
        $page: Int,
        ${sortInput ? "$sort: ProductAttributeSortInput," : ""}
        $filter: ProductAttributeFilterInput,
      ) {
        products(
          pageSize: 20,
          currentPage: $page,
          ${sortInput ? "sort: $sort," : ""}
          filter: $filter
        ) {
          items {
            ${ProductOverviewGraphQLAttributes}
            ${ProductDetailsAdditionalGraphQLAttributes}
          }
          pageInfo: page_info {
            totalPages: total_pages
            pageSize: page_size
          }
        }
      }
    `,
    variables: {
      page,
      sort: sortInput,
      filter: {
        category_id: { eq: `${categoryId}` },
        ...(productFilterInfo
          ? makeGraphQLFilter(
              getApplicableProductFilterInfo(productFilterInfo),
              productAttributeFilterInputMap
            )
          : {}),
      },
    },
    fetchPolicy,
  });
  if (result.data.products == null) {
    if (fetchPolicy === "cache-only") {
      return null;
    }
    throw Error("Something went wrong");
  }
  const { products } = result.data;
  return {
    products: products.items,
    hasMore: products.pageInfo.totalPages > page,
    pageSize: products.pageInfo.pageSize,
  };
}


export async function fetchProductOverviewsFromCategoryListByCategoryId(
  client: ApolloClient<any>,
  categoryId: number,
  page: number,
  productFilterInfo: ProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{
  productOverviews: ProductOverview[];
  hasMore: boolean;
  pageSize: number;
} | null> {
  const { sortAttribute } = productFilterInfo;
  const sortInput =
    mapSortAttributeToGraphQLVariable(sortAttribute) || undefined;
    const query = <T>(graphQLAttributes: string) =>
    client.query<{
      products: {
        items: T[];
        pageInfo: { totalPages: number; pageSize: number };
      };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
      query QueryProductsByCategoryId(
        $page: Int,
        ${sortInput ? "$sort: ProductAttributeSortInput," : ""}
        $filter: ProductAttributeFilterInput,
      ) {
        products(
          pageSize: 20,
          currentPage: $page,
          ${sortInput ? "sort: $sort," : ""}
          filter: $filter
        ) {
          items {
            ${graphQLAttributes}
          }
          pageInfo: page_info {
            totalPages: total_pages
            pageSize: page_size
          }
        }
      }
    `,
    variables: {
      page,
      sort: sortInput,
      filter: {
        category_id: { eq: `${categoryId}` },
        ...(productFilterInfo
          ? makeGraphQLFilter(
              getApplicableProductFilterInfo(productFilterInfo),
              productAttributeFilterInputMap
            )
          : {}),
      },
    },
    fetchPolicy,
  });
  const result = await query<ProductOverview>(ProductOverviewGraphQLAttributes);
  if (result.data.products == null) {
    if (fetchPolicy === "cache-only") {
      return null;
    }
    throw Error("Something went wrong");
  }
  const { products: _products } = result.data;
  return {
    productOverviews: _products.items,
    hasMore: _products.pageInfo.totalPages > page,
    pageSize: _products.pageInfo.pageSize,
  };
}

export async function getMyCustomer(
  client: ApolloClient<any>,
  locale: Locale
): Promise<Customer | null> {
  const result = await client.query<{ customer: Customer | null }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query GetMyCustomer {
        customer {
          ${CustomerGraphQLAttributes}
        }
      }
    `,
    fetchPolicy: "network-only",
  });

  return result.data.customer;
}

export async function updateMyCustomerInterestCategories(
  client: ApolloClient<any>,
  locale: Locale,
  categoryIds: number[]
): Promise<Customer> {
  const categoryIdsInString = categoryIds.map(String);
  const result = await client.mutate<{
    updateCustomer: { customer: Customer | null };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
    mutation updateCustomer($categoryIds: [String]!) {
      updateCustomer(
          input: {
            interest: $categoryIds,
            has_interests_set: false
          }
        ) {
          customer {
            ${CustomerGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      categoryIds: categoryIdsInString,
    },
    fetchPolicy: "no-cache",
  });
  return result.data.updateCustomer.customer;
}

export async function updateMyCustomerInfo(
  client: ApolloClient<any>,
  locale: Locale,
  firstName: string,
  lastName: string,
  isSubscribeToNewsletter: boolean,
  updatedProfilePic?: string,
): Promise<Customer> {
  if (updatedProfilePic) {
    await client.mutate<{
      updateCustomer: { customer: Customer | null };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
        mutation updateCustomerProfilePicture($file: String!) {
          updateCustomerProfilePicture(file: $file)
        }
      `,
      variables: {
        file: updatedProfilePic,
      },
      fetchPolicy: "no-cache",
    });
  }
  const result = await client.mutate<{
    updateCustomer: { customer: Customer | null };
  }>({
    mutation: gql`
      mutation updateCustomer(
        $firstName: String!,
        $lastName: String!,
        $isSubscribeToNewsletter: Boolean!) {
        updateCustomer(
            input: {
              firstname: $firstName
              lastname: $lastName
              is_subscribed: $isSubscribeToNewsletter
            }
          ) {
            customer {
              ${CustomerGraphQLAttributes}
            }
          }
        }
      `,
    variables: {
      firstName,
      lastName,
      isSubscribeToNewsletter,
    },
    fetchPolicy: "no-cache",
  });
  return result.data.updateCustomer.customer;
}

export async function updateMyCustomerEmail(
  client: ApolloClient<any>,
  email: string,
  password: string,
  locale: Locale
): Promise<void> {
  try {
    const result = await client.mutate<{
      updateCustomer: { reject_reason: string | null; success: boolean };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
        mutation updateCustomerEmail($email: String!, $password: String!) {
          updateCustomerEmail(new_email: $email, current_password: $password) {
            reject_reason
            success
          }
        }
      `,
      variables: {
        email,
        password,
      },
      fetchPolicy: "no-cache",
    });
    const { success, reject_reason } = result.data.updateCustomerEmail;
    if (!success) {
      throw reject_reason;
    }

  } catch (e) {
    const errorMessage = parseGraphQLError(e);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
    throw e;
  }
 
}

export async function resendChangeEmailConfirmation(
  client: ApolloClient<any>,
  locale: Locale
): Promise<void> {
  return client.mutate({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation {
        resendCustomerEmailUpdateEmail
      }
    `,
    fetchPolicy: "no-cache",
  });
}

export async function cancelChangeEmailRequest(
  client: ApolloClient<any>,
  locale: Locale
): Promise<void> {
  return client.mutate({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation {
        cancelCustomerEmailUpdate
      }
    `,
    fetchPolicy: "no-cache",
  });
}

export async function verifyChangeEmail(
  client: ApolloClient<any>,
  customerID: number,
  key: string,
  locale: Locale
): Promise<void> {
  const result = await client.mutate<{
    updateCustomer: { reject_reason: string | null; success: boolean };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation VerifyCustomerEmailUpdate($customerID: Int!, $key: String!) {
        verifyCustomerEmailUpdate(customer_id: $customerID, key: $key) {
          reject_reason
          success
        }
      }
    `,
    variables: {
      customerID,
      key,
    },
    fetchPolicy: "no-cache",
  });
  const { success, reject_reason } = result.data.verifyCustomerEmailUpdate;
  if (!success) {
    throw reject_reason;
  }
}

export async function updateMyCustomerInfoAfterSSOSignup(
  client: ApolloClient<any>,
  locale: Locale,
  isSubscribeToNewsletter: boolean
): Promise<Customer | null> {
  const result = await client.mutate<{
    updateCustomer: { customer: Customer | null };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
    mutation updateCustomer($isSubscribeToNewsletter: Boolean!) {
      updateCustomer(
          input: {
            is_subscribed: $isSubscribeToNewsletter
          }
        ) {
          customer {
            ${CustomerGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      isSubscribeToNewsletter,
    },
    fetchPolicy: "no-cache",
  });
  return result.data.updateCustomer.customer;
}

export async function addProductToCart(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  cartItem: SimpleProductCartItemInput | ConfigurableProductCartItemInput
): Promise<Cart> {
  const mutationFunc = isConfigurableProductCartItemInput(cartItem)
    ? "addConfigurableProductsToCart"
    : "addSimpleProductsToCart";
  const cartItemType = isConfigurableProductCartItemInput(cartItem)
    ? "ConfigurableProductCartItemInput"
    : "SimpleProductCartItemInput";
  const serializedItem = isConfigurableProductCartItemInput(cartItem)
    ? serializeConfigurableProductCartItemInput(cartItem)
    : serializeSimpleProductCartItemInput(cartItem);
  const getCartFunc = isConfigurableProductCartItemInput(cartItem)
    ? (data: any) => data.addConfigurableProductsToCart.cart
    : (data: any) => data.addSimpleProductsToCart.cart;
  const result = await client.mutate({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation AddProductsToCarts(
        $cartId: String!
        $cartItem: ${cartItemType}!
      ) {
        ${mutationFunc}(
          input: { cart_id: $cartId, cart_items: [$cartItem] }
        ) {
          cart {
            ${CartGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      cartId,
      cartItem: serializedItem,
    },
    fetchPolicy: "no-cache",
  });
  return getCartFunc(result.data);
}

export async function setClubPointOnCart(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  clubpoint: number
): Promise<Cart> {
  try {
    const result = await client.mutate<{
      setClubPointOnCart: { cart: Cart };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation SetClubPointOnCart($cartId: String!, $clubpoint: Int!) {
        setClubPointOnCart(input: { cart_id: $cartId, clubpoints: $clubpoint }) {
          cart {
            ${CartGraphQLAttributes}
          }
        }
      }
    `,
      variables: {
        cartId,
        clubpoint,
      },
    });
    return result.data.setClubPointOnCart.cart;
  } catch (e) {
    throw parseGraphQLError(e);
  }
}

export async function fetchCMSPageContentByIdentifier(
  client: ApolloClient<any>,
  type:
    | { type: "cmsBlock"; identifier: string }
    | { type: "cmsPage"; identifier: number }
    | { type: "cmsPageStringId"; identifier: string },
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<CMSPageContent | null> {
  const item: { contentForApp: string } | null = await (async () => {
    if (type.type === "cmsPage" || type.type === "cmsPageStringId") {
      const result = await client.query<
        {
          cmsPage: { contentForApp: string };
        },
        { identifier: number | string }
      >({
        context: {
          headers: {
            Store: getStoreViewCodeForLocale(locale),
          },
        },
        query: gql`
	  query QueryCMSPage($identifier: ${
      type.type === "cmsPage" ? "Int" : "String"
    }!) {
	    cmsPage(${type.type === "cmsPage" ? "id" : "identifier"}: $identifier) {
              id: identifier
              contentForApp: content_for_app
            }
          }
        `,
        variables: {
          identifier: type.identifier,
        },
        fetchPolicy,
      });
      if (result.data == null) {
        return null;
      }
      return result.data.cmsPage || null;
    }
    const result = await client.query<
      {
        cmsBlocks: { items: [{ contentForApp: string }] };
      },
      { identifiers: [string] }
    >({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query QueryCMSBlocks($identifiers: [String!]!) {
          cmsBlocks(identifiers: $identifiers) {
            items {
              contentForApp: content_for_app
            }
          }
        }
      `,
      variables: {
        identifiers: [type.identifier],
      },
      fetchPolicy,
    });
    if (result.data == null) {
      return null;
    }
    return result.data.cmsBlocks != null
      ? result.data.cmsBlocks.items[0]
      : null;
  })();

  if (item == null) {
    return null;
  }
  const cmsBlocks = extractCMSBlocksFromContentForApp(item.contentForApp);
  return {
    items: cmsBlocks,
  };
}

export async function fetchHTMLBasedCMSPageContentByIdentifier(
  client: ApolloClient<any>,
  type:
    | { type: "cmsBlock"; identifier: string }
    | { type: "cmsPage"; identifier: number }
    | { type: "cmsPageStringId"; identifier: string },
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<HTMLBasedCMSPageContent | null> {
  const item: { contentForApp: string } | null = await (async () => {
    if (type.type === "cmsPage" || type.type === "cmsPageStringId") {
      const result = await client.query<
        {
          cmsPage: { contentForApp: string };
        },
        { identifier: number | string }
      >({
        context: {
          headers: {
            Store: getStoreViewCodeForLocale(locale),
          },
        },
        query: gql`
          query QueryCMSPage($identifier: Int) {
            cmsPage(id: $identifier) {
              id: identifier
              contentForApp: content_for_app
            }
          }
        `,
        variables: {
          identifier: type.identifier,
        },
        fetchPolicy,
      });
      if (result.data == null) {
        return null;
      }
      return result.data.cmsPage || null;
    }
    const result = await client.query<
      {
        cmsBlocks: { items: [{ contentForApp: string }] };
      },
      { identifiers: [string] }
    >({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query QueryCMSBlocks($identifiers: [String!]!) {
          cmsBlocks(identifiers: $identifiers) {
            items {
              contentForApp: content_for_app
            }
          }
        }
      `,
      variables: {
        identifiers: [type.identifier],
      },
      fetchPolicy,
    });
    if (result.data == null) {
      return null;
    }
    return result.data.cmsBlocks != null
      ? result.data.cmsBlocks.items[0]
      : null;
  })();

  if (item == null) {
    return null;
  }
  const htmlBasedCMSPageContent = extractCMSBlocksFromContentForAppWithWaitingToFillHTML(
    item.contentForApp
  );
  return htmlBasedCMSPageContent;
}

export async function fetchStaticCMSBlocksByIds(
  client: ApolloClient<any>,
  ids: string[],
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<CMSStaticBlockContent[]> {
  const result = await client.query<{
    cmsBlocks: { items: (CMSStaticBlockContent | null)[] | null } | null;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query CMSStaticBlocks($identifiers: [String!]!) {
        cmsBlocks(identifiers: $identifiers) {
          items {
            ${CMSStaticBlockContentGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      identifiers: ids,
    },
    fetchPolicy,
    // Do not throw error because the missing identifier will fail
    // to update apollo cache and the cache will be retained in each
    // request
    errorPolicy: "all",
  });
  const cmsBlocks: CMSStaticBlockContent[] = [];
  if (result.data.cmsBlocks && result.data.cmsBlocks.items) {
    for (const cmsBlock of result.data.cmsBlocks.items) {
      if (cmsBlock) {
        cmsBlocks.push(cmsBlock);
      }
    }
  }
  // Write back filtered version to cache
  client.cache.writeQuery<{
    cmsBlocks: {
      items:
        | (CMSStaticBlockContent & { __typename: "CmsBlock" } | null)[]
        | null;
      __typename: "CmsBlocks";
    } | null;
  }>({
    query: gql`
      query CMSStaticBlocks($identifiers: [String!]!) {
        cmsBlocks(identifiers: $identifiers) {
          items {
            ${CMSStaticBlockContentGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      identifiers: ids,
    },
    data: {
      cmsBlocks: {
        items: cmsBlocks.map(cmsBlock => ({
          ...cmsBlock,
          __typename: "CmsBlock",
        })),
        __typename: "CmsBlocks",
      },
    },
  });
  return cmsBlocks;
}

export async function fetchMerchantDirectory(
  client: ApolloClient<any>,
  page: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{
  merchantPreviews: MerchantPreview[];
  hasMore: boolean;
  pageSize: number;
} | null> {
  const result = await client.query<{
    merchant: {
      items: MerchantPreview[];
      pageInfo: { totalPages: number; pageSize: number };
    };
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query QueryMerchants($page: Int) {
        merchant(pageSize: 20, currentPage: $page) {
          items {
            ${MerchantPreviewGraphQLAttributes}
          }
          pageInfo: page_info {
            totalPages: total_pages
            pageSize: page_size
          }
        }
      }
    `,
    variables: {
      page,
    },
    fetchPolicy,
  });

  if (!result.data.merchant || !result.data.merchant.items) {
    throw Error("Something went wrong");
  }
  return {
    merchantPreviews: result.data.merchant.items,
    hasMore: result.data.merchant.pageInfo.totalPages > page,
    pageSize: result.data.merchant.pageInfo.pageSize,
  };
}

export async function fetchMerchantPreview(
  client: ApolloClient<any>,
  merchantID: MerchantID,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<MerchantPreview | null> {
  const result = await client.query<{ merchant: { items: MerchantPreview[] } }>(
    {
      context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
      query: gql`
        query QueryMerchant($id: String) {
          merchant(filter: { vendor_id: { eq: $id } }) {
            items {
              ${MerchantPreviewGraphQLAttributes}
            }
          }
        }
      `,
      variables: {
        id: `${merchantID}`,
      },
      fetchPolicy,
    }
  );
  
  if (!result.data.merchant || !result.data.merchant.items) {
    throw Error("Something went wrong");
  }
  return result.data.merchant.items[0];
}

export async function fetchMerchant(
  client: ApolloClient<any>,
  merchantID: MerchantID,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<Merchant | null> {
  const result = await client.query<{ merchant: { items: Merchant[] } }>({
    context: { headers: { store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query QueryMerchant($id: String) {
        merchant(filter: { vendor_id: { eq: $id } }) {
          items {
            ${MerchantGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      id: `${merchantID}`,
    },
    fetchPolicy,
  });

  if (!result.data.merchant || !result.data.merchant.items) {
    throw Error("Something went wrong");
  }
  return result.data.merchant.items[0];
}



export async function fetchNormalProductOverviewsByMerchantId(
  ifMagentoVersion: IfMagentoVersionFn,
  client: ApolloClient<any>,
  entityID: MerchantEntityID,
  productFilterInfo: ProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>,
  page: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{ productOverviews: ProductOverview[]; pageInfo: PageInfo } | null> {
  const sortInput =
    mapSortAttributeToGraphQLVariable(productFilterInfo.sortAttribute) ||
    undefined;
  const products = await (async () => {
    const getGraphQL = (
      itemGraphQLAttribute: string,
      includePageInfo: boolean
    ) => {
      return `
      query QueryNormalProductsByMerchantID(
        $page: Int,
        ${
          sortInput
            ? `$sort: ${
                ifMagentoVersion(">=", "2.3.4")
                  ? "ProductAttributeSortInput"
                  : "ProductSortInput"
              },`
            : ""
        }
        ${
          productFilterInfo != null && ifMagentoVersion(">=", "2.3.4")
            ? "$filter: ProductAttributeFilterInput"
            : ""
        },
      ) {
        products(
          currentPage: $page,
          ${sortInput ? "sort: $sort," : ""}
          ${
            productFilterInfo != null && ifMagentoVersion(">=", "2.3.4")
              ? "filter: $filter"
              : `filter: { vender_id: { eq: "${entityID}" } }`
          },
        ) {
          items {
            ${itemGraphQLAttribute}
          }
          ${
            includePageInfo
              ? `pageInfo: page_info {
            ${PageInfoGraphQLAttributes}
          }`
              : ""
          }
        }
      }
    `;
    };

    const query = <T>(graphQLAttributes: string) =>
      client.query<{
        products: { items: T[]; pageInfo: PageInfo };
      }>({
        context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
        query: gql`
          ${getGraphQL(graphQLAttributes, true)}
        `,
        variables: {
          page,
          sort: sortInput,
          filter: {
            vendor_id: { eq: `${entityID}` },
            ...(productFilterInfo
              ? makeGraphQLFilter(
                  getApplicableProductFilterInfo(productFilterInfo),
                  productAttributeFilterInputMap
                )
              : {}),
          },
        },
        fetchPolicy,
      });
    const result = await query<ProductOverview>(
      ProductOverviewGraphQLAttributes
    );
    const { products } = result.data;
    return products;
  })();
  if (!products) {
    return null;
  }
  return {
    productOverviews: products.items,
    pageInfo: products.pageInfo,
  };
}

export async function fetchFeaturedProductOverviewsByMerchantId(
  client: ApolloClient<any>,
  entityID: MerchantEntityID,
  page: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{ productOverviews: ProductOverview[]; pageInfo: PageInfo } | null> {
  const products = await (async () => {
    const getGraphQL = (
      itemGraphQLAttribute: string,
      includePageInfo: boolean
    ) => {
      return `
      query QueryFeaturedProductsByMerchantID(
        $id: String,
        $page: Int,
      ) {
        merchant(filter: {entity_id: { eq: $id }}) {
          items {
            id: vendor_id
            products: featured_products(
              currentPage: $page,
            ) {
              items {
                ${itemGraphQLAttribute}
              }
              ${
                includePageInfo
                  ? `pageInfo: page_info {
                ${PageInfoGraphQLAttributes}
              }`
                  : ""
              }
            }
          }
        }
      }
    `;
    };

    const query = <T>(graphQLAttributes: string) =>
      client.query<{
        merchant: {
          items: {
            products: { items: T[]; pageInfo: PageInfo };
          }[];
        };
      }>({
        context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
        query: gql`
          ${getGraphQL(graphQLAttributes, true)}
        `,
        variables: {
          id: `${entityID}`,
          page,
        },
        fetchPolicy,
      });
    const result = await query<ProductOverview>(
      ProductOverviewGraphQLAttributes
    );
    const merchant =
      result.data.merchant &&
      result.data.merchant.items &&
      result.data.merchant.items[0];
    if (!merchant) {
      return null;
    }

    const { products } = merchant;
    return products;
  })();

  if (!products) {
    return null;
  }

  return {
    productOverviews: products.items,
    pageInfo: products.pageInfo,
  };
}

export async function fetchCountries(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<RemoteCountry[] | null> {
  const result = await client.query<{
    countries: RemoteCountry[];
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query FetchCountries {
        countries {
          ${CountryGraphQLAttributes}
        }
      }
    `,
    fetchPolicy,
  });

  if (!result.data.countries) {
    return null;
  }

  const countries: RemoteCountry[] = [];
  for (const c of result.data.countries) {
    const country = CountrySchema.validateSync(c);
    countries.push(country);
  }
  return countries;
}

export async function fetchDistricts(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<District[] | null> {
  const result = await client.query<{ city: { items: District[] } }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query FetchDistricts {
        city {
    items {
      ${DistrictGraphQLAttributes}
    }
  }
      }
    `,
    fetchPolicy,
  });

  if (!result.data.city || !result.data.city.items) {
    return null;
  }

  const districts: District[] = [];
  for (const item of result.data.city.items) {
    const district = DistrictSchema.validateSync(item);
    districts.push(district);
  }

  return districts;
}

export async function fetchHotSearches(
  client: ApolloClient<any>,
  locale: Locale
) {
  const result = await client.query<{
    searches: {
      popularSearches: SearchAutoSuggestion[];
    };
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query QueryHotSearches {
        searches {
          popularSearches: popular_searches {
            ${SearchAutoSuggestionGraphQLAttributes}
          }
        }
      }
    `,
    fetchPolicy: "network-only",
  });
  return result.data.searches.popularSearches;
}

export async function fetchSearchSuggestion(
  client: ApolloClient<any>,
  searchTerm: SearchTerm,
  locale: Locale
): Promise<{
  popularSearches: SearchAutoSuggestion[];
  products: { items: { sku: string; name: string }[]; totalCount: number };
  filteredListing: SearchAutoSuggestion[];
}> {
  const searchSuggestionPromise = client.query<{
    searches: {
      category: SearchAutoSuggestion[];
      popularSearches: SearchAutoSuggestion[];
    };
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query QuerySearchSuggestion($search: String!) {
        searches(search: $search) {
          category {
            ${SearchAutoSuggestionGraphQLAttributes}
          }
          popularSearches: popular_searches {
            ${SearchAutoSuggestionGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      search: searchTerm,
    },
    fetchPolicy: "network-only",
  });

  const productSuggestionPromise = client.query<{
    products: { items: Pick<Product, "sku" | "name">[]; totalCount: number };
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query suggestProduct($search: String!, $limit: Int!) {
        products(search: $search, pageSize: $limit) {
          items {
            sku
            name
          }
          totalCount: total_count
        }
      }
    `,
    variables: {
      search: searchTerm,
      limit: Config.SEARCH_PRODUCT_SUGGESTION_LIMIT,
    },
  });

  const result = await Promise.all([
    searchSuggestionPromise,
    productSuggestionPromise,
  ]);

  const { popularSearches, category } = result[0].data.searches;

  return {
    popularSearches: popularSearches,
    filteredListing: category,
    products: result[1].data.products,
  };
}


export async function fetchProductAttributeFilterInputFields(
  client: ApolloClient<any>,
  fetchPolicy: FetchPolicy
): Promise<FilterInputField[]> {
  const result = await client.query<{
    __type: { inputFields: FilterInputField[] };
  }>({
    query: gql`
      query {
        __type(name: "ProductAttributeFilterInput") {
          inputFields {
            name
            type {
              name
            }
          }
        }
      }
    `,
    fetchPolicy,
  });
  if (
    result.data.__type &&
    result.data.__type.inputFields &&
    result.data.__type.inputFields.length
  ) {
    return result.data.__type.inputFields;
  }
  return [];
}


export async function searchProductOverviews(
  client: ApolloClient<any>,
  searchTerm: SearchTerm,
  productFilterInfo: ProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>,
  page: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{
  productOverviews: ProductOverview[];
  pageInfo: PageInfo;
} | null> {
  const sortInput =
    mapSortAttributeToGraphQLVariable(productFilterInfo.sortAttribute) ||
    undefined;
    const getGraphQL = (
      itemGraphQLAttribute: string,
      includePageInfo: boolean
    ) => {
      return productFilterInfo == null
        ? `
        query SearchProducts($search: String!, $page: Int!) {
          products(search: $search, currentPage: $page) {
            items {
              ${itemGraphQLAttribute}
            }
            ${
              includePageInfo
                ? `pageInfo: page_info {
              ${PageInfoGraphQLAttributes}
            }`
                : ""
            }
          }
        }
      `
        : `
        query SearchProducts(
          $search: String!,
          $page: Int!,
          ${sortInput ? "$sort: ProductAttributeSortInput," : ""}
          $filter: ProductAttributeFilterInput
        ) {
          products(
            search: $search,
            filter: $filter,
            currentPage: $page,
            ${sortInput ? "sort: $sort," : ""}
          ) {
            items {
              ${itemGraphQLAttribute}
            }
            ${
              includePageInfo
                ? `pageInfo: page_info {
              ${PageInfoGraphQLAttributes}
            }`
                : ""
            }
          }
        }
      `;
    };
    const query = <T>(graphQLAttributes: string) =>
    client.query<{
      products: {
        items: T[];
        pageInfo: PageInfo;
      };
    }>({
      context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
      query: gql(getGraphQL(graphQLAttributes, true)),
      variables: {
        search: searchTerm,
        page,
        sort: sortInput,
        filter: makeGraphQLFilter(
          getApplicableProductFilterInfo(productFilterInfo),
          productAttributeFilterInputMap
        ),
      },
      fetchPolicy,
    });
  const result = await query<ProductOverview>(ProductOverviewGraphQLAttributes);
  if (result.data.products == null) {
    return null;
  }
  return {
    productOverviews: result.data.products.items,
    pageInfo: result.data.products.pageInfo,
  };
}

export async function fetchSortFields(
  client: ApolloClient<any>,
  search: string,
  filter: GraphQLFilter,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{
  defaultSortField: SortField | null;
  sortFieldOptions: SortFieldOption[];
} | null> {
  const result = await client.query<{
    products: {
      sortFields: {
        defaultSortField: SortField | null;
        sortFieldOptions: (SortFieldOption | null)[] | null;
      };
    };
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query GetSortFields(
        $search: String!
        $filter: ProductAttributeFilterInput
      ) {
        products(search: $search, filter: $filter) {
          sortFields: sort_fields {
            defaultSortField: default
            sortFieldOptions: options {
              label
              value
            }
          }
        }
      }
    `,
    variables: {
      search,
      filter,
    },
    fetchPolicy,
  });
  if (!result.data.products || !result.data.products.sortFields) {
    return null;
  }
  const { sortFieldOptions } = result.data.products.sortFields;
  const validSortFieldOptions: SortFieldOption[] = [];
  if (sortFieldOptions != null) {
    for (const sortFieldOption of sortFieldOptions) {
      if (sortFieldOption) {
        validSortFieldOptions.push(sortFieldOption);
      }
    }
  }
  const res = {
    ...result.data.products.sortFields,
    sortFieldOptions: validSortFieldOptions,
  };
  return res;
}

export async function fetchAggregation(
  ifMagentoVersion: IfMagentoVersionFn,
  client: ApolloClient<any>,
  search: string,
  filter: GraphQLFilter,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<Aggregation[] | null> {
  if (await ifMagentoVersion("<", "2.3.4")) {
    return [];
  }
  const graphQL = `
query GetAggregation($search: String!, $filter: ProductAttributeFilterInput) {
  products(search: $search, filter: $filter) {
    ${AggregationGraphQLAttributes}
  }
}
  `;
  const result = await client.query<{
    products: {
      aggregations?: Aggregation[];
    };
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql(graphQL),
    variables: {
      search,
      filter,
    },
    fetchPolicy,
  });
  if (result.data.products == null) {
    return null;
  }
  const aggregations = result.data.products.aggregations
    ? result.data.products.aggregations
    : [];
  return aggregations;
}

export async function fetchCustomerAddresses(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{
  addresses: RemoteAddress[];
  defaultBilling: number | null;
  defaultShipping: number | null;
} | null> {
  try {
    const result = await client.query<{
      customer: {
        addresses: RemoteAddress[];
        defaultBilling: string | null;
        defaultShipping: string | null;
      };
    }>({
      context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
      query: gql`
      query QueryCustomerAddresses {
        customer {
          id
          addresses {
            ${CustomerAddressGraphQLAttributes}
          }
	  defaultBilling: default_billing
	  defaultShipping: default_shipping
        }
      }
    `,
      fetchPolicy,
    });

    if (!result.data.customer || !result.data.customer.addresses) {
      return null;
    }

    const { addresses, defaultBilling, defaultShipping } = result.data.customer;
    return {
      addresses,
      defaultBilling: defaultBilling ? parseInt(defaultBilling, 10) : null,
      defaultShipping: defaultShipping ? parseInt(defaultShipping, 10) : null,
    };
  } catch (e) {
    throw parseGraphQLError(e);
  }
}

export async function resolveUrl(
  client: ApolloClient<any>,
  locale: Locale,
  urlString: string
): Promise<EntityUrl | null> {
  const url = new URL(urlString);
  try {
    const result = await client.query<{ urlResolver: EntityUrl }>({
      context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
      query: gql`
      query resolveUrl($urlPath: String!) {
        urlResolver(url: $urlPath) {
          ${EntityUrlGraphQLAttributes}
        }
      }
    `,
      variables: {
        urlPath: url.pathname,
      },
      fetchPolicy: "network-only",
    });
    return result.data.urlResolver;
  } catch (e) {
    throw parseGraphQLError(e);
  }
}

export async function fetchCustomerWishlist(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<WishlistItem[]> {
  const query = <T>(graphQLAttributes: string) =>
    client.query<{ customer: { wishlist: { items: T[] } } }>({
      context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
      query: gql`
      query fetchWishlist {
        customer {
          id
          wishlist {
            items {
              ${graphQLAttributes}
            }
          }
        }
      }
    `,
      fetchPolicy,
    });
  const result = await query<WishlistItem>(
    WishlistItemGraphQLAttribtues(ProductOverviewGraphQLAttributes)
  );
  if (result.data.customer == null || result.data.customer.wishlist == null) {
    return [];
  }
  return result.data.customer.wishlist.items;
}

export async function activateCustomer(
  client: ApolloClient<any>,
  customerId: number,
  confirmationKey: string
): Promise<string> {
  const result = await client.mutate<
    {
      customer_access_token: string;
    },
    {
      customerId: number;
      confirmationKey: string;
    }
  >({
    mutation: gql`
      mutation ActivateCustomer($customerId: Int!, $confirmationKey: String!) {
        activateCustomer(
          customer_id: $customerId
          confirmation_key: $confirmationKey
        ) {
          customer_access_token
        }
      }
    `,
    variables: {
      customerId,
      confirmationKey,
    },
    fetchPolicy: "no-cache",
  });
  return result.data.activateCustomer.customer_access_token;
}

export async function changePassword(
  client: ApolloClient<any>,
  locale: Locale,
  currentPassword: string,
  newPassword: string
): Promise<void> {
  try {
    await client.mutate({
      context: { headers: { Store: getStoreViewCodeForLocale(locale) } },      
      mutation: gql`
        mutation ChangePassword(
          $currentPassword: String!
          $newPassword: String!
        ) {
          changeCustomerPassword(
            currentPassword: $currentPassword
            newPassword: $newPassword
          ) {
            id
          }
        }
      `,
      variables: {
        currentPassword,
        newPassword,
      },
      fetchPolicy: "no-cache",
    });
  } catch (e) {
    if (e.message === "GraphQL error: Invalid login or password.") {
      throw Error("invalid-current-password");
    }
    throw e;
  }
}

export async function changeEmailOnLogin(
  client: ApolloClient<any>,
  locale: Locale,
  email: string
): Promise<{ success: boolean; reject_reason?: string }> {
  const result = await client.mutate<{
    data: {
      updateCustomerEmailOnLogin: { success: boolean; reject_reason?: string };
    };
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },      
    mutation: gql`
      mutation ChangeEmail($email: String!) {
        updateCustomerEmailOnLogin(new_email: $email) {
          success
          reject_reason
        }
      }
    `,
    variables: {
      email,
    },
    fetchPolicy: "no-cache",
  });
  return result.data.updateCustomerEmailOnLogin;
}

export async function fetchMagentoVersion(
  client: ApolloClient<any>,
  fetchPolicy: FetchPolicy
): Promise<MagentoVersion | undefined> {
  const result = await client.query<{
    __schema: { types: { name: string }[] };
  }>({
    query: gql`
      {
        __schema {
          types {
            name
          }
        }
      }
    `,
    fetchPolicy,
  });

  if (result.data.__schema == null || result.data.__schema.types.length === 0) {
    return undefined;
  }

  const productAttributeFilterInput = result.data.__schema.types.filter(
    f => f.name === "ProductAttributeFilterInput"
  );

  if (productAttributeFilterInput.length > 0) {
    return "2.3.4";
  }

  return "2.3";
}

export async function registerDeviceToken(
  client: ApolloClient<any>,
  locale: Locale,
  os: OS,
  platform: Platform,
  token: Token,
  isdn: Isdn
): Promise<PNSResponse> {
  const result = await client.mutate<{
    registerDeviceToken: PNSResponse;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation RegisterDeviceToken(
        $os: String!
        $platform: String!
        $token: String!
        $isdn: String!
      ) {
        registerDeviceToken(
          os: $os
          token: $token
          platform: $platform
          isdn: $isdn
        ) {
          code
          description
        }
      }
    `,
    variables: {
      os,
      platform,
      token,
      isdn,
    },
  });
  return result.data.registerDeviceToken;
}
export async function deleteDeviceToken(
  client: ApolloClient<any>,
  locale: Locale,
  os: OS,
  platform: Platform,
  token: Token,
  isdn: Isdn
): Promise<PNSResponse> {
  const result = await client.mutate<{
    deleteDeviceToken: PNSResponse;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation DeleteDeviceToken(
        $os: String!
        $platform: String!
        $token: String!
        $isdn: String!
      ) {
        deleteDeviceToken(
          os: $os
          token: $token
          platform: $platform
          isdn: $isdn
        ) {
          code
          description
        }
      }
    `,
    variables: {
      os,
      platform,
      token,
      isdn,
    },
  });
  return result.data.deleteDeviceToken;
}
export async function changeDeviceToken(
  client: ApolloClient<any>,
  locale: Locale,
  os: OS,
  platform: Platform,
  oldToken: Token,
  newToken: Token,
  isdn: Isdn
): Promise<PNSResponse> {
  const result = await client.mutate<{
    changeDeviceToken: PNSResponse;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation ChangeDeviceToken(
        $os: String!
        $platform: String!
        $oldToken: String!
        $newToken: String!
        $isdn: String!
      ) {
        changeDeviceToken(
          os: $os
          oldToken: $oldToken
          newToken: $newToken
          platform: $platform
          isdn: $isdn
        ) {
          code
          description
        }
      }
    `,
    variables: {
      os,
      platform,
      oldToken,
      newToken,
      isdn,
    },
  });
  return result.data.changeDeviceToken;
}
export async function messageMarkRead(
  client: ApolloClient<any>,
  locale: Locale,
  token: Token,
  isdn: Isdn,
  messageId: string
): Promise<PNSResponse> {
  const result = await client.mutate<{
    messageMarkRead: PNSResponse;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation MessageMarkRead(
        $token: String!
        $messageId: String!
        $isdn: String!
      ) {
        messageMarkRead(
          accessToken: $token
          msgIds: [$messageId]
          isdn: $isdn
        ) {
          code
          description
        }
      }
    `,
    variables: {
      token,
      messageId,
      isdn,
    },
  });
  return result.data.messageMarkRead;
}
export async function setOrderNotification(
  client: ApolloClient<any>,
  locale: Locale,
  isEnabled: NotificationEnableState
): Promise<PNSResponse> {
  console.info(
    `[ClubLike-OPNS] Attempt to ${
      isEnabled ? "enable" : "disable"
    } order notification`
  );
  const result = await client.mutate<{
    setOrderNotification: PNSResponse;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation SetOrderNotification($isEnabled: Int!) {
        setOrderNotification(isEnabled: $isEnabled) {
          code
          description
        }
      }
    `,
    variables: {
      isEnabled,
    },
  });
  console.info(
    `[ClubLike-OPNS] Order notification is ${
      isEnabled ? "enabled" : "disabled"
    }`
  );
  return result.data.setOrderNotification;
}
export async function setPromotionNotification(
  client: ApolloClient<any>,
  locale: Locale,
  isEnabled: NotificationEnableState
): Promise<PNSResponse> {
  console.info(
    `[ClubLike-OPNS] Attempt to ${
      isEnabled ? "enable" : "disable"
    } promotion notification`
  );
  const result = await client.mutate<{
    setPromotionNotification: PNSResponse;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation SetPromotionNotification($isEnabled: Int!) {
        setPromotionNotification(isEnabled: $isEnabled) {
          code
          description
        }
      }
    `,
    variables: {
      isEnabled,
    },
  });
  console.info(
    `[ClubLike-OPNS] Promotion notification is ${
      isEnabled ? "enabled" : "disabled"
    }`
  );
  return result.data.setPromotionNotification;
}

export async function getOppCards(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<OppCard[]> {
  try {
    const result = await client.query<{
      getCustomerOppCard: { cardList: OppCard[] };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query GetCustomerOppCard {
          getCustomerOppCard {
            cardList: card_list {
              ${OppCardGraphQLAttributes}
            }
          }
        }
      `,
      fetchPolicy,
    });
    return result.data.getCustomerOppCard.cardList;
  } catch (e) {
    const errorMessage = parseGraphQLError(e);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
    throw e;
  }
}
export async function deleteOppCard(
  client: ApolloClient<any>,
  cardId: string
): Promise<boolean> {
  try {
    const result = await client.mutate<{ deleteOppCard: boolean }>({
      mutation: gql`
        mutation DeleteOppCard($cardId: String!) {
          deleteOppCard(card_id: $cardId)
        }
      `,
      variables: { cardId },
      fetchPolicy: "no-cache",
    });
    return result.data.deleteOppCard;
  } catch (e) {
    const errorMessage = parseGraphQLError(e);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
    throw e;
  }
}
export async function getCustomerSubscriptions(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<CustomerSubscription[]> {
  try {
    const result = await client.query<{
      getCustomerSubscription: [RemoteCustomerSubscription];
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query GetCustomerSubscriptions {
          getCustomerSubscription {
            ${CustomerSubscriptionGraphQLAttributes}
          }
        }
      `,
      fetchPolicy,
    });
    if (!result.data.getCustomerSubscription) {
      return [];
    }
    return result.data.getCustomerSubscription.map(
      transformRemoteCustomerSubscriptionToCustomerSubscription
    );
  } catch (e) {
    const errorMessage = parseGraphQLError(e);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
    throw e;
  }
}
export async function getCustomerSubscription(
  client: ApolloClient<any>,
  locale: Locale,
  subscriptionId: CustomerSubscriptionId,
  fetchPolicy: FetchPolicy
): Promise<CustomerSubscription> {
  try {
    const result = await client.query<{
      getCustomerSubscription: [RemoteCustomerSubscription];
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query GetCustomerSubscriptions {
          getCustomerSubscription {
            ${CustomerSubscriptionGraphQLAttributes}
          }
        }
      `,
      fetchPolicy,
    });
    if (!result.data.getCustomerSubscription) {
      throw new Error("Not found");
    }
    const filtered = result.data.getCustomerSubscription.filter(
      (c: RemoteCustomerSubscription) => {
        return c.subscription
          ? c.subscription.subscriptionId === subscriptionId
          : false;
      }
    );
    if (filtered.length === 0) {
      throw new Error("Not found");
    }
    return transformRemoteCustomerSubscriptionToCustomerSubscription(
      filtered[0]
    );
  } catch (e) {
    const errorMessage = parseGraphQLError(e);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
    throw e;
  }
}
export async function cancelSubscription(
  client: ApolloClient<any>,
  locale: Locale,
  subscriptionPayment: string,
  subscriptionId: CustomerSubscriptionId
): Promise<boolean> {
  try {
    const result = await client.mutate<{
      cancelSubscription: boolean;
    }>({
      context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
      mutation: gql`
        mutation CancelSubscription(
          $subscriptionPayment: String!
          $subscriptionId: String!
        ) {
          cancelSubscription(
            subscription_payment: $subscriptionPayment
            subscription_id: $subscriptionId
          )
        }
      `,
      variables: {
        subscriptionPayment,
        subscriptionId,
      },
      fetchPolicy: "no-cache",
    });
    return result.cancelSubscription;
  } catch (e) {
    const errorMessage = parseGraphQLError(e);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
    throw e;
  }
}

export async function fetchMerchants(
  client: ApolloClient<any>,
  entityIds: MerchantEntityID[],
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<PartialNGO[] | null> {
  const result = await client.query<{ merchant: {items: PartialNGO[] }}>({
    context: {
      headers: {
        store: getStoreViewCodeForLocale(locale)
      }
    },
    query: gql`
      query QueryMerchants($ids: [String]) {
        merchant(filter: { entity_id: { in : $ids }}) {
          items {
            clubLikeEntityId: entity_id
            clubLikeMerchantId: vendor_id 
            name: store_name
            logo
            donationPurpose: short_desc
            description: about
            youtubeUrl: youtube_url
            websiteUrl: website_url
          }
        }
      }
    `,
    variables: {
      ids: entityIds
    },
    fetchPolicy,
  });
  if (!result.data.merchant || !result.data.merchant.items) {
    throw Error("Something went wrong");
  }
  return result.data.merchant.items;
}

export async function fetchProductsBySKUs(
  client: ApolloClient<any>,
  skus: string[],
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<Product[] | null> {
  const result = await client.query<{
    products: { items: [Product] } | undefined;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryProductBySKU($skus: [String], $pageSize: Int!) {
        products(filter: { sku: { in: $skus } }, pageSize: $pageSize) {
          items {
            ${ProductOverviewGraphQLAttributes}
            ${ProductDetailsAdditionalGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      skus,
      pageSize: skus.length,
    },
    fetchPolicy,
  });

  if (result.data.products == null) {
    return null;
  }

  // The result from this query is not in the order of input skus
  // so make it in order
  const products: Product[] = [];

  for (let i = 0; i < skus.length; i++) {
    const sku = skus[i];
    const product = result.data.products.items.filter(
      p => p.sku === sku
    )[0];
    if (!product) {
      console.warn(`Missing product of sku ${sku} from QueryProductBySKU`);
      continue;
    }
    products.push(product);
  }

  return products;
}

export async function fetchProductOverviewsByCategoryId(
  client: ApolloClient<any>,
  categoryId: number,
  page: number,
  productFilterInfo: ProductFilterInfo | null,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{
  productOverviews: ProductOverview[];
  hasMore: boolean;
  pageSize: number;
} | null> {
  const sortAttribute = productFilterInfo
    ? productFilterInfo.sortAttribute
    : null;
  const result = await client.query<{
    category:
      | {
          products: {
            items: [ProductOverview];
            pageInfo: { totalPages: number; pageSize: number };
          };
        }
      | undefined;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryProductsByCategoryId($categoryId: Int,
                                      $page: Int,
                                      $sort: ProductSortInput) {
        category(id: $categoryId) {
          id
          products(pageSize: 20, currentPage: $page, sort: $sort) {
            items {
              ${ProductOverviewGraphQLAttributes}
            }
            pageInfo: page_info {
              totalPages: total_pages
              pageSize: page_size
            }
          }
        }
      }
    `,
    variables: {
      categoryId,
      page,
      sort: sortAttribute
        ? mapSortAttributeToGraphQLVariable(sortAttribute)
        : { position: "ASC" },
    },
    fetchPolicy,
  });

  if (result.data.category == null || result.data.category.products == null) {
    if (fetchPolicy === "cache-only") {
      return null;
    }
    throw Error("Something went wrong");
  }
  return {
    productOverviews: result.data.category.products.items,
    hasMore: result.data.category.products.pageInfo.totalPages > page,
    pageSize: result.data.category.products.pageInfo.pageSize,
  };
}

export async function fetchFeaturedProductsByMerchantId(
  client: ApolloClient<any>,
  entityID: MerchantEntityID,
  page: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{ products: Product[]; pageInfo: PageInfo } | null> {
  const products = await (async () => {
    const productsFilterGraphQL = `
      query QueryFeaturedProductsByMerchantID(
        $id: String,
        $page: Int,
      ) {
        merchant(filter: { entity_id: { eq: $id }}){
          items {
            products: featured_products(
              currentPage: $page
            ) {
              items {
                ${ProductOverviewGraphQLAttributes}
                ${ProductDetailsAdditionalGraphQLAttributes}
              }
              pageInfo: page_info {
                ${PageInfoGraphQLAttributes}
              }
            }
          }
        }
      }
    `;
    const result = await client.query<{
      merchant: {
        items: {
          products: { 
            items: Product[]; 
            pageInfo: PageInfo 
          };
        }
      }
    }>({
      context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
      query: gql`
        ${productsFilterGraphQL}
      `,
      variables: {
        id: `${entityID}`,
        page,
      },
      fetchPolicy,
    });
    const merchant =
      result.data.merchant &&
      result.data.merchant.items &&
      result.data.merchant.items[0];
    if (!merchant) {
      return null;
    }
    const { products } = merchant;
    if (products == null) {
      return null;
    }
    return products;
  })();

  if (!products) {
    return null;
  }

  return {
    products: products.items,
    pageInfo: products.pageInfo,
  };
}


export async function fetchNormalProductsByMerchantId(
  ifMagentoVersion: IfMagentoVersionFn,
  client: ApolloClient<any>,
  entityID: MerchantEntityID,
  productFilterInfo: ProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>,
  page: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{ products: Product[]; pageInfo: PageInfo } | null> {
  const sortInput =
  mapSortAttributeToGraphQLVariable(productFilterInfo.sortAttribute) ||
  undefined;
  const products = await (async () => {
    const productsFilterGraphQL = `
      query QueryNormalProductsByMerchantID(
        $page: Int,
        ${
          sortInput
            ? `$sort: ${
                ifMagentoVersion(">=", "2.3.4")
                  ? "ProductAttributeSortInput"
                  : "ProductSortInput"
              },`
            : ""
        }
        ${
          productFilterInfo != null && ifMagentoVersion(">=", "2.3.4")
            ? "$filter: ProductAttributeFilterInput"
            : ""
        },
      ) {
        products(
          currentPage: $page,
          ${sortInput ? "sort: $sort," : ""}
          ${
            productFilterInfo != null && ifMagentoVersion(">=", "2.3.4")
              ? "filter: $filter"
              : `filter: { vender_id: { eq: "${entityID}" } }`
          },
        ) {
          items {
            ${ProductOverviewGraphQLAttributes}
            ${ProductDetailsAdditionalGraphQLAttributes}
          }
          pageInfo: page_info {
            ${PageInfoGraphQLAttributes}
          }
        }
      }
    `;
    const result = await client.query<{
      products: { items: Product[]; pageInfo: PageInfo };
    }>({
      context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
      query: gql`
        ${productsFilterGraphQL}
      `,
      variables: {
        page,
        sort: sortInput,
        filter: {
          vendor_id: { eq: `${entityID}` },
          ...(productFilterInfo
            ? makeGraphQLFilter(
                getApplicableProductFilterInfo(productFilterInfo),
                productAttributeFilterInputMap
              )
            : {}),
        },
      },
      fetchPolicy,
    });
    const { products } = result.data;
    if (products == null) {
      return null;
    }
    return products;
  })();
  if (!products) {
    return null;
  }
  return {
    products: products.items,
    pageInfo: products.pageInfo,
  };
}

export async function searchProducts(
  client: ApolloClient<any>,
  searchTerm: SearchTerm,
  productFilterInfo: ProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>,
  page: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{
  productOverviews: Product[];
  pageInfo: PageInfo;
} | null> {
  const sortInput =
    mapSortAttributeToGraphQLVariable(productFilterInfo.sortAttribute) ||
    undefined;
  const graphQL =
    productFilterInfo == null
      ? `
      query SearchProducts($search: String!, $page: Int!) {
        products(search: $search, currentPage: $page) {
          items {
            ${ProductOverviewGraphQLAttributes}
            ${ProductDetailsAdditionalGraphQLAttributes}
          }
          pageInfo: page_info {
            ${PageInfoGraphQLAttributes}
          }
        }
      }
    `
      : `
      query SearchProducts(
        $search: String!,
        $page: Int!,
        ${sortInput ? "$sort: ProductAttributeSortInput," : ""}
        $filter: ProductAttributeFilterInput
      ) {
        products(
          search: $search,
          filter: $filter,
          currentPage: $page,
          ${sortInput ? "sort: $sort," : ""}
        ) {
          items {
            ${ProductOverviewGraphQLAttributes}
            ${ProductDetailsAdditionalGraphQLAttributes}
          }
          pageInfo: page_info {
            ${PageInfoGraphQLAttributes}
          }
        }
      }
    `;
  const result = await client.query<{
    products: {
      items: Product[];
      pageInfo: PageInfo;
    };
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql(graphQL),
    variables: {
      search: searchTerm,
      page,
      sort: sortInput,
      filter: makeGraphQLFilter(
        getApplicableProductFilterInfo(productFilterInfo),
        productAttributeFilterInputMap
      ),
    },
    fetchPolicy,
  });
  if (result.data.products == null) {
    return null;
  }
  return {
    productOverviews: result.data.products.items,
    pageInfo: result.data.products.pageInfo,
  };
}