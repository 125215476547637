import React from "react";

import { match, RouteComponentProps, withRouter } from "react-router";
import { History, Location } from "history";
import { OurNavContext } from "./OurNavContext";
import BackAndroidHandler from "../components/BackAndroidHandler";

interface RouterData {
  history: History;
  location: Location;
  match: match<any>;
}

export interface PresentationContextValue {
  config: (
    history: History,
    location: Location,
    match: match<any>,
    closePath: string,
    goBack: () => void
  ) => void;
  present: (path: string, state?: any, completion?: () => void) => void;
  dismiss: (completion?: () => void) => void;
}

export const PresentationContext = React.createContext<
  PresentationContextValue
>(null as any);

type Props = RouteComponentProps;
interface State {
  presented: boolean;
}

class PresentationContextProviderImpl extends React.PureComponent<
  Props,
  State
> {
  private contextValue: PresentationContextValue;

  router?: RouterData;
  closePath = "/close";
  isPresenting = false;
  goBack?: () => void;

  // for present()
  presentationDidDismiss?: () => void;

  // for dismiss()
  dismissCompletion?: () => void;

  state = {
    presented: false,
  };

  constructor(props: Props) {
    super(props);

    this.contextValue = {
      config: this.config,
      present: this.present,
      dismiss: this.dismiss,
    };
  }

  componentDidMount() {
    window.addEventListener("popstate", this.onPopState);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopState);
  }

  onPopState = () => {
    if (this.goBack) {
      this.goBack();
      if (this.isPresenting) {
        this.props.history.push(this.props.location.pathname);
      }
    }
  };

  onDismiss = () => {
    this.props.history.goBack();
    setTimeout(() => {
      if (this.presentationDidDismiss) {
        this.presentationDidDismiss();
        this.presentationDidDismiss = undefined;
      }

      if (this.dismissCompletion) {
        this.dismissCompletion();
        this.dismissCompletion = undefined;
      }
    }, 200); // matching dismiss animation duration
  };

  config = (
    history: History,
    location: Location,
    match: match<any>,
    closePath: string,
    goBack: () => void
  ) => {
    this.isPresenting = location.pathname !== this.closePath;
    if (
      !this.isPresenting &&
      this.router &&
      this.router.location.pathname !== this.closePath
    ) {
      this.onDismiss();
    }

    this.router = { history, location, match };
    this.goBack = goBack;
    this.closePath = closePath;
  };

  present = (path: string, state?: any, completion?: () => void) => {
    if (this.router) {
      this.router.history.push(path, state);
      this.props.history.push(this.props.location.pathname);
      this.presentationDidDismiss = completion;
      this.setState({ presented: true });
    }
  };

  dismiss = (completion?: () => void) => {
    const path = this.closePath;
    if (this.router) {
      this.router.history.push(path);
      this.props.history.push(this.props.location.pathname);
      this.dismissCompletion = completion;
      this.setState({ presented: false });
    }
  };

  handleAndroidBackPress = () => {
    this.dismiss();
    return true;
  };

  render() {
    const { children } = this.props;
    return (
      <PresentationContext.Provider value={this.contextValue}>
        {this.state.presented && (
          <BackAndroidHandler onBackPress={this.handleAndroidBackPress} />
        )}
        {children}
      </PresentationContext.Provider>
    );
  }
}

export const PresentationContextProvider = withRouter(
  PresentationContextProviderImpl
);

interface PresentationBridgeProps extends RouteComponentProps {
  closePath?: string;
}

const PresentationBridgeImpl: React.FC<PresentationBridgeProps> = props => {
  const bridgeContext = React.useContext(PresentationContext);
  const navContext = React.useContext(OurNavContext);
  const { history, location, match } = props;

  React.useEffect(() => {
    bridgeContext.config(
      history,
      location,
      match,
      props.closePath || "/close",
      navContext.goBack
    );
  });
  return null;
};

export const PresentationBridge = withRouter(PresentationBridgeImpl);
