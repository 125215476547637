import { useEffect, useState } from "react";
import {
  getAccessToken,
  setAccessTokenToStorage,
  clearAccessToken,
} from '../storage';

// define the shape of the token store
export const TokenStore: {
  accessToken: string | null;
} = {
  accessToken: null,
};

export async function setAccessToken(accessToken: string | null) {
  if (accessToken == null) {
    await clearAccessToken();
  } else {
    await setAccessTokenToStorage(accessToken);
  }

  TokenStore.accessToken = accessToken;
}

export function useAccessToken(): boolean {
  const [getAccessTokenFinished, setGetAccessTokenFinished] = useState(false);

  useEffect(() => {
    getAccessToken().then((accessToken: string | null) => {
      if (accessToken) {
        TokenStore.accessToken = accessToken;
      }
      setGetAccessTokenFinished(true);
    });
  }, []);
  return getAccessTokenFinished;
  
}