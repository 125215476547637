export type IndexMap<K extends string | number | symbol, T> = {
  [P in K]: T | undefined;
};

export function indexMapLength<K extends string | number | symbol, T>(
  indexMap: IndexMap<K, T>
): number {
  return Object.entries(indexMap).filter(([_, v]) => v != null).length;
}

export function mapNullable<T, U>(
  maybeValue: T | null,
  map: (value: T) => U
): U | null {
  if (maybeValue == null) {
    return null;
  }
  return map(maybeValue);
}

export function apply<T>(
  target: NonNullable<T> | null | undefined,
  op: (target: NonNullable<T>) => void
) {
  if (target) {
    op(target);
  }
}

export function stringLiteral<T extends string>(str: T): T {
  return str;
}

export type Override<T, V> = keyof V extends keyof T
  ? Omit<T, keyof V> & V
  : never;

export type FormError<T extends IndexMap<string, any>> = Partial<
  IndexMap<keyof T, string>
>;

export enum NumericBoolean {
  YES = 1,
  NO = 0,
}