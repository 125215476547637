import { Plugins } from "@capacitor/core";
import { isHybrid } from "../utils/Platform";
import Config from "../Config";

export interface DeviceInfo {
  appVersion: string;
  buildNumber: string;
}

export async function getInfo(): Promise<DeviceInfo> {
  if (isHybrid()) {
    return Plugins.DeviceInfoPlugin.getInfo();
  }
  return {
    appVersion: Config.WEB_APP_VERSION,
    buildNumber: Config.WEB_BUILD_NUMBER,
  };
}
