import { TranslationMap } from "./type";
const zhHant: TranslationMap = {
  /* CSR */
  "landing.prompt_ie":
    "你正在使用過期或不支援的瀏覽器。\n\n若要體驗Club Hope網上公益平台，請使用Chrome，Firefox，Safari或Edge。",
  "landing.menu.about": "有關我們",
  "landing.menu.enter": "進入Club Hope",
  "landing.menu.other_donations": "進入Club Hope瀏覽更多慈善項目",
  "landing.menu.ngos": "受惠機構",
  "landing.menu.contact": "聯絡我們",

  "landing.intro1":
    "香港電訊設立 Club Hope 網上公益平台，藉此凝聚社會各界力量，鼓勵大眾關注有需要的社群及為社福機構夥伴籌集資源，宣揚「連結愛心，傳遞希望」的訊息。任何人都可以成為 Club Hope 的參與者，支持12間受惠社福機構，並於「動物權益及福利」、「傷健及特殊需要人士」、「環境及社區關愛」、「長者服務」及「音樂及藝術」區域內，選擇心儀的慈善項目，包括單次捐款券、認購及捐助指定服務或產品、以及其他按月捐助項目，透過實際行動幫助有需要的社群。",

  "landing.intro2":
    "Club Hope設有「我的家園」以及五大區域，包括「動物權益及福利」、「傷健及特殊需要人士」、「環境及社區關愛」、「長者服務」及「音樂及藝術」。登入帳戶後，即可瀏覽及支持12間社福機構的項目及產品，為它們籌集更多資源提供服務，得到實際而直接的幫助。",

  "landing.intro3":
    "只要你每季認購及捐助的金額達HK$300元或以上，便可獲得不同的獎勵。獎勵包括現金券、Club Sim及Now E服務等。金額越高，獎勵越豐富。<br><br>你更可「越級挑戰」來提升自己的等級、獲取榮譽徽章及各種獎勵，全力向「大慈善家」稱號邁進。",
  "landing.intro4":
    "The Club會員將其會籍連結Club Hope後，便可在認購及捐助後賺取Club積分。Club積分可作扣減產品之價格。Club積分之使用受條款及細則約束。",
  "landing.intro5":
    "突破傳統模式，因應自己的能力，可選擇以一次性形式或每月形式支持各個項目，靈活性更高。",
  "landing.policy": "私隱聲明",
  "landing.terms": "條款及細則",

  copyright: "Club Hope © {year}. 版權所有",
  "signup_to_donate.title": "請先登入後再捐款",

  "tutorial.previous": "上一頁",
  "tutorial.next": "下一頁",
  "tutorial.close": "關閉",
  "tutorial.dont_show_again": "下次開始時不要再顯示",
  "tutorial.skip": "略過教學",
  "tutorial.city.welcome": "歡迎來到Club Hope",
  "tutorial.city.help1":
    "現在這個社區很多市民都需要你的幫助！有不同類別社福機構正籌集資源，而你的幫助正正能夠令社區變得更美好。",
  "tutorial.city.help2":
    "你可以在社區內穿梭遊覽。如果想進入不同的區域，只需按下滑鼠左鍵，然後根據指示便可進入。",
  "tutorial.city.help3":
    "如果想查看你在社區內的貢獻及成就，可以進入「我的家園」查閱。",
  "tutorial.city.help4": "Club Hope希望可以得到你的支持，讓社區變得更加繁榮。",

  "tutorial.zone.welcome":
    "歡迎你進入不同區域，在這𥚃你可以前往不同社福機構的樓層，了解它們的背景及發展。",

  "tutorial.zone.help1":
    "你可以於區域大堂點擊升降機，前往不同社福機構的樓層，了解更多。",
  "tutorial.zone.help2":
    "如果你想獲取有關該社福機構的資料，可以點擊了解各個項目詳情及馬上支持你喜歡項目。",
  "tutorial.zone.help3":
    "你可以點擊右上方的「購物車」查閱已選項目。確認無誤後便可馬上提交。",

  all_zones_ngos: "區域及機構",
  all_zones: "所有區域",
  all_ngos: "所有機構",
  "all_zones.loading": "正在進入 ClubHope 社區",
  "zone.myhome": "我的家園",
  "zone.eco-and-social": "環境及社區關愛",
  "zone.music-arts-and-education": "音樂及藝術",
  "zone.elderly": "長者服務",
  "zone.animals-and-pets": "動物權益及福利",
  "zone.disability-and-healthcare": "傷健及特殊需要人士",
  "zone.level": "等級 {level}",
  "zone.lobby": "大堂",
  "zone.elevator": "升降機",
  "zone.elevator_goto": "前往其他樓層",

  "orders.title": "交易紀錄",
  "orders.product.sku": "SKU",
  "orders.product.receiver": "收件人",
  "orders.product.action": "操作",

  "ngo.error.mismatch_data": "抱歉，該機構頁面正在進行更新，請稍後再來。",
  "ngo.donate_for": "支持{name}",
  "ngo.about": "機構簡介",
  "ngo.video_intro": "影片",
  "ngo.donation_purpose": "項目",
  "ngo.selected": "已選擇:",
  "ngo.one_time_donation": "單次捐款項目",
  "ngo.subscription_donation": "按月捐助項目",
  "ngo.official_website": "查看官方網站",

  "donation_bag.donation": "捐款",
  "donation_bag.basic_donation": "直接捐款",
  "donation_bag.other_donation": "其他慈善項目",
  "donation_bag.product": "產品",
  "donation_bag.price": "價錢",
  "donation_bag.qty": "數量",
  "donation_bag.subtotal": "小計",
  "donation_bag.add_to": "加入購物車",
  "donation_bag.adding": "請稍等...",
  "donation_bag.add_success": "已成功加入購物車",
  "donation_bag.go_to_bag": "查看購物車({count})",
  "donation_bag.donate_now": "捐款及詳情",
  "donation_bag.title": "購物車",
  "donation_bag.go_to_details": "查看購物車",
  "donation_bag.x_stock_left":
    "最後{stockLevel, plural, one{一件}, other{{stockLevel}件}}",
  "donation_bag.ordered.message.title":
    "感謝你在Club Hope支持各慈善機構，你的訂單編號為：",
  "donation_bag.ordered.message": "我們期待你再次於Club Hope支持各個項目。",
  "donation_bag.ordered.succeed": "交易成功",
  "donation_bag.ordered.return_home": "返回主頁",
  "donation_bag.ordered.return_town": "返回Club Hope",
  "donation_bag.crowd_funding_success": "眾籌成功",
  "donation_bag.out_of_stock": "缺貨",
  "donation_bag.self_collection": "可領取商品",
  "donation_bag.details": "詳情",
  "donation_bag.more_photos": "更多圖片",
  "donation_bag.collapse": "隱藏",
  "empty_donation_bag.title": "你的購物車中沒有商品",
  "empty_donation_bag.lets_go_shopping": "現在就去捐獻",

  "messagebox.title": "個人訊息",
  "messagebox.empty": "你信箱未有任何訊息。",

  "messagebox.GOLD_INCENTIVE_REQ_MET":
    "您於本季度所累積的捐款已達 ${donationAmountMet}。 您將獲得金級獎勵。您將於下季開始時換領獎勵。",
  "messagebox.SILVER_INCENTIVE_REQ_MET":
    "您於本季度所累積捐款已達 ${donationAmountMet}。您將獲得銀級獎勵，並可於下季開始時換領獎勵。當您的季度累積捐款金額達HK${nextDonationAmount}時，您將可換領進階獎勵。",
  "messagebox.BRONZE_INCENTIVE_REQ_MET":
    "您於本季度所累積捐款已達 ${donationAmountMet}。您將獲得銅級獎勵，並可於下季開始時換領獎勵。當您的季度累積捐款金額達HK${nextDonationAmount}時，您將可換領進階獎勵。",

  "about.title": "關於Club Hope",
  "about.description": "Club Hope是一個支持本地慈善機構的捐款平台。",

  "checkout.title": "確認交易",
  "user.profile.title": "我的簡介",
  "user.profile.linkage": "帳戶連結",
  "user.profile.not_connected": "未連結",
  "user.profile.connected": "已連結: {email}",
  "user.profile.disconnect": "取消連結",
  "user.profile.donation_summary": "本季度累積交易金額：",

  "user.title_level.1": "參加者",
  "user.title_level.2": "{level}級 捐款者",
  "user.title_level.3": "{level}級 善長",
  "user.title_level.4": "{level}級 大慈善家",

  "subscription.each_amount": "每項金額: {amount}",

  "subscription.subscribed": "己訂閱",
  "subscription.cycle": "{cycle}捐款",
  "subscription.Once a month": "按月捐助項目",
  "subscription.Once a day": "每日捐款",
  "subscription.Once a week": "每週捐款",
  "subscription.Once a year": "年供項目",
  "subscription.monthAmount": "每月金額",
  "subscription.dayAmount": "每日金額",
  "subscription.weekAmount": "每週金額",
  "subscription.yearAmount": "每年金額",

  "profile.title": "我的帳戶",
  "profile.account.change_name": "修改姓名",
  "profile.account.title": "帳戶資料",
  "profile.account.pic": "個人圖片",
  "profile.account.select_pic": "選擇檔案",
  "profile.account.name": "我的名字",
  "profile.account.change_name_note":
    "請注意，更新名稱同時亦會更新你在的Club Shopping用戶名稱。",
  "profile.account.email": "電郵地圵",
  "profile.account.level": "級別",
  "profile.account.password": "修改密碼",
  "profile.linkage.title": "帳戶連結",
  "profile.subscription.title": "月供計劃訂閱",
  "profile.subscription.subscribed": "已訂閱",
  "profile.subscription.change_payment": "更改付款資料",
  "profile.subscription.cancel": "取消訂閱",
  "achievement.title": "我的貢獻",
  "achievement.badges": "徽章",
  "achievement.certs": "獎狀",
  "statistics.title": "各區域統計資料",
  "statistics.zones_level": "各區域的等級",
  "statistics.zone": "現在 <strong>{zone}</strong> 的䌓榮程度",
  "statistics.mydonation": "本年度我的貢獻度: {amount}",
  "statistics.level": "等級{level}",
  "statistics.completed": "完成",
  "gifts.title": "我的獎勵",
  "gifts.coming_soon": "即將推出",
  "history.title": "交易記錄",
  "history.btn_title": "交易記錄",

  "verify_the_club.verified_succeed.title": "成功啟用",
  "verify_the_club.verified_succeed": "你已成功啟用帳號。",
  "verify_the_club.verified_failed": "你的啟用連結已失效。",

  "terms.p1":
    "凡超過港幣一百元的現金捐款，指定受惠機構會提供捐款收據作扣減稅項。",
  "terms.p2":
    "直接捐款（每月捐款）及購買服務（按月捐助項目）方面，系統會每月定期透過信用卡自動扣數。指定受惠機構將會於每年四月份為捐贈者提供年度捐款收據。",
  "terms.p3":
    "注意：任何使用Club積分的交易或於Club Hope平台上認購任何商品均不能獲發捐款收據作扣減稅項。",

  "seo.default.title": "Club Hope | 網上公益平台",
  "seo.default.sitename": "網上公益平台",
  "seo.landing.description":
    "香港電訊推出Club Hope網上公益平台，透過遊戲方式凝聚社會各界的力量，鼓勵大眾關注有需要的社群及為社福機構籌集資源，宣揚「連結愛心　傳遞希望」的訊息。",
  "seo.default.meta.description":
    "香港電訊推出Club Hope網上公益平台，透過遊戲方式凝聚社會各界的力量，鼓勵大眾關注有需要的社群及為社福機構籌集資源，宣揚「連結愛心　傳遞希望」的訊息。",

  "seo.town.description":
    "參與者可穿梭遊戲城的各個區域，包括「傷健及特殊需要人士」、「動物權益及福利」、「音樂及藝術」、「長者服務」及「環境及社區關愛」，以選擇受惠的社福機構。",

  "seo.myhome.description":
    "如果想查看你在社區內的貢獻及成就，可以進入「我的家園」查閱你的個人等級、交易記錄、所獲取電子徽章及證書記錄等帳戶資料。",

  "seo.zone.eco-and-social.description":
    "關注剩食問題的你，於「環境及社區關愛」區域內，可為基層人士提供食物福袋、為有需要家庭提供食物包、或為社區食堂提供食材，為有需要的長者提供飯餐。社區關愛方面，你可以為患有重病的兒童出一分力，為他們添置康復治療設備外，更可透過支援不同種類的活動，助他們達成願望，面對逆境。",
  "seo.zone.music-arts-and-education.description":
    "藝術是無障礙的共通語言。於「音樂及藝術」區域內，你可以為基層兒童提供音樂唱遊班，或讓兒童參與音樂劇訓練及演出。你更可支持弱能人士創作的藝術作品，購買他們設計的商品。",
  "seo.zone.elderly.description":
    "於「長者服務」區域內，你的幫助可讓腦退化症人士及早接受評估及診斷。你亦可支持社福機構送贈防疫物資、安排義工陪伴長者覆診、為長者清潔家居或改裝家居等。",
  "seo.zone.animals-and-pets.description":
    "於「動物權益及福利」區域內，你可以救助被遺棄的動物，籌集貓狗舍租金，給毛孩一個家。你可支援動物醫療費用，以購買防疫針、心絲蟲藥及中西醫治療的費用。你亦可支持為唐狗等流浪動物絕育，並為流浪貓狗提供罐頭等糧食。導盲犬的培育計劃及專業訓練亦在這範疇內。",
  "seo.zone.disability-and-healthcare.description":
    "於「傷健及特殊需要人士」區域內，你可支持為唐氏綜合症和自閉症患者提供運動課程、營養食品或其他興趣活動。你亦可支援有語言障礙的小朋友，讓他們及早進行言語評估及言語治療服務。你更可認識和關注精神病康復者及智障人士的康復服務，購買庇護工場學員的手作。",

  "rewards.certificate": "捐款證明書",
  "rewards.issue_date": "獲得日期: {date}",
  "rewards.BADGE_FIRST_TIME_DONATION": "首次捐款",
  "rewards.CERTIFICATE_DONATE_ALL_CATEGORIES": "已於所有慈善機構種類捐款",

  "quick_donation.donate_now_title": "直接捐款",
  "quick_donation.donate_now_p1": "你的捐款將會為受惠機構得到直接的幫助。",
  "quick_donation.donate_now_p2":
    "凡超過港幣一百元的現金捐款，指定受惠機構會提供捐款收據作扣減稅項。任何使用Club積分的交易或於Club Hope平台上認購任何商品均不能獲發捐款收據。",
  "quick_donation.donate_now_p3":
    "凡透過 “直接捐款”之項目只接受信用卡付款，並不能使用Club積分作扣減價格之用。",
  "quick_donation.donate_now_p4":
    "根據政府消費券計劃，消費券不可用作捐款。由2021年8月1日至2022年1月31日，Club Hope平台將暫停接受以Tap & Go「拍住賞」萬事達咭付款。",
  "quick_donation.category": "類別",
  "quick_donation.donate_now": "立即捐款",
  "quick_donation.more_detail": "更多機構資料",
  "quick_donation.less_detail": "顯示較少",
  "quick_donation.ngo_intro": "機構簡介",
  "quick_donation.ngo_website": "機構網址",
  "quick_donation.ngo_video": "簡介影片",
  "quick_donation.donation_to": "直接捐款支持{name}",
  "quick_donation.donation_type": "捐款類型",
  "quick_donation.oneoff": "單次捐款",
  "quick_donation.subscription": "每月捐款 (為期12個月)",
  "quick_donation.donation_options": "輸入捐款金額",
  "quick_donation.donation_amount": "輸入捐款金額",
  "quick_donation.per_month": "/每月",
  "quick_donation.donation_helper1": "最低金額: 港幣50元",
  "quick_donation.donation_helper2": "(以港幣50元為單位)",
  "quick_donation.i_want_donation": "立即捐款",

  cancel: "取消",
  copied: "已複製",
  done: "完成",
  save: "儲存",
  next: "下一步",
  submit: "提交",
  not_enought_club_point: "Club積分不足",
  required: "必填",
  go_to_settings: "前往設定",
  "select.placeholder": "請選擇",
  "field.error_message": "請填寫正確的{field}",
  "alert.error.title": "錯誤",
  "alert.error.message": "對不起！系統發⽣錯誤，請重新嘗試。",
  "alert.session_expired": "你的登入狀態已失效，請重新登入。",
  "alert.session_expired.button.login_again": "重新登入",
  "alert.session_expired.button.cancel": "取消",
  "alert.button.ok": "確定",
  "alert.button.back": "返回",
  "alert.deeplink.invalid_url": "不正確的網址({URL})",
  "alert.deeplink.email_verification.fail": "未能啟動帳戶",
  "alert.deeplink.change_email_verification.fail": "無法驗證您的電子郵件更新",
  "alert.fetch_config.error.title": "未能取得設定。請重試。",
  "alert.fetch_config.error.retry": "重試",
  "alert.confirm_exit_app": "你想退出應用嗎？",
  "alert.confirm_exit_app.yes": "是",
  "alert.confirm_exit_app.no": "否",
  "in_app_browser.cancel": "取消",
  hello_world: "你好世界",
  skip_for_now: "暫時跳過",
  email_address: "電郵",
  "tab.category": "類別",
  "tab.articles": "開箱",
  "tab.home": "主頁",
  "tab.likes": "最愛清單",
  "tab.account": "帳戶/設定",
  "tab.rewards": "獎賞",
  login: "登入",
  "login_or_register": "登入或註冊",
  "login.not_yet_pair": "還未連結The Club帳戶嗎？",
  "login.login_with_clubshopping": "以Club Shopping帳號登入",
  "login.clubshopping_account_deprecate": "Club Shopping登入帳戶將不再適用，馬上連結The Club帳戶，享受精彩的購物旅程！",
  "login.visitor": "遊客",
  "login.user_login": "登入",
  "login.login_with_social": "使用The Club登入",
  "login.club_points_promo_text":
    "連結{b, react, children{The Club}}帳戶用Club積分扣減價格及賺取{b, react, children{Club}}積分",
  "login.or_login_with_email": "使用電郵登入",
  "login.forgot_password": "忘記密碼",
  "login.do_not_have_an_account.bak":
    "還沒有註冊成為Club Shopping用戶? {div, react, className{{createAccountClassName}}  onClick{{onCreateAccountButtonClick}} children{立即註冊}}",
  "login.do_not_have_an_account":
    "還沒有註冊成為Club Shopping用戶? {div, react, className{{createAccountClassName}}  onClick{{onCreateAccountButtonClick}} children{立即註冊}}",
  "login.login_failed": "無法登入",
  "login.login_failed_text": "你輸入的電郵或密碼不正確，或者帳戶仍未激活",
  "login.login_with_oauth_failed_text": "無法登入，請重試",
  "login.logging_in": "正在登入",
  email: "電郵",
  password: "密碼",
  redeem: "兌換",
  remove: "刪除",
  or: "或者",
  "clubpoint_widget.use_your_clubpoint": "用Club積分扣減價格",
  "clubpoint_widget.tax_disclaimer":
    "(透過Club Hope認購商品、或於結賬前使用Club積分的交易將不能獲發退稅收據。)",
  "clubpoint_widget.clubpoint_used": "使用的Club積分",
  "clubpoint_widget.per_block": "{perBlock} = 減HK$1",
  "clubpoint_widget.total_amount": "需支付金額",
  verify_the_club: "核實你的帳戶",
  "verify_the_club.description":
    "為加強保障你的The Club會員帳戶免受未經授權交易，請核實你的登記電郵及手機號碼。",
  "verify_the_club.verify_now": "立即核實",
  "verify_the_club.cancel": "取消",
  "verify_the_club.ask_refresh":
    "核實你的登記電郵及手機號碼後，請{RefreshButton, react, onClick{{handleRefreshClick}}}",
  "verify_the_club.refresh": "按此刷新頁面",
  "verify_the_club.refresh.still_not_verified":
    "你的The Club會員帳戶還未核實，請再試一次。",
  order_summary: "摘要",
  "order_summary.subtotal": "小計",
  "order_summary.total": "需支付金額",
  "category.all": "全部",
  "order_summary.clubpoints_required": "扣減Club積分",
  "order_summary.clubpoints_used": "使用Club積分",
  "order_summary.extra_clubpoints_used": "使用Club積分",
  "order_summary.extra_item.discount": "折扣",
  "order_summary.extra_item.delivery_fee": "運費",
  "order_summary.extra_item.initial_subscription_fee": "初始訂閱費用",

  "order_summery.recurring.payment_cycle": "收取款項形式: 每月收取",
  "order_summery.recurring.start_date": "購買服務收取款項開始日期: {date}",
  "order_summery.recurring.end_date": "購買服務收取款項結束日期: {date}",
  "order_summery.recurring.duration": "期數: {months}個月",

  "category.view_all": "查看全部",
  "category.view_more": "查看更多",
  "category.not_found": "找不到分類",
  no_internet_connection: "沒有網路連接",
  no_internet_description: "請檢查網路連接。",
  tap_to_retry: "點擊重試",
  "page.article_list.latest_post.title.bak": "Club Shopping 開箱",
  "page.article_list.latest_post.title": "Club Shopping 開箱",
  "page.article_list.popular_post.title": "熱門文章",
  "page.article_list.cell.post_date": "日期: {PUBLISHED_DATE}",
  "page.article_list.cell.categories": "類別: {CATEGORY_LIST}",
  "page.article_list.cell.read_more": "閱讀更多",
  "page.article_detail.post_date": "日期: {PUBLISHED_DATE}",
  "page.article_detail.categories":
    "類別: {span, react, children{{CATEGORY_LIST_ELEMENTS}}}",
  "page.article_detail.tags":
    "標籤: {span, react, children{{TAG_LIST_ELEMENTS}}}",
  "page.article_detail.author":
    "撰文: {span, react, children{{AUTHOR_ELEMENT}}}",
  "page.article_detail.alert.share_error": "分享失敗",
  "page.article_detail.share.message": `{ARTICLE_TITLE}
{ARTICLE_URL}`,
  "page.forgot_password.title": "忘記密碼",
  "page.forgot_password.instruction":
    "請輸入您的電子郵件。{br, react}我們將向您發送重置密碼的鏈接。",
  "page.forgot_password.input.email.label": "電子郵件",
  "page.forgot_password.request_success": "您將收到電子郵件鏈接重置您的密碼。",
  "page.forgot_password.button.submit": "提交",
  "page.forgot_password.error.invalid_email_format": "電子郵件格式不正確",
  "page.forgot_password.error.fail_reset_password": "電郵地址錯誤，請重試。",
  "page.product_detail.navbar.title": "產品資料",
  "page.product_detail.alert.product_not_found.message": "找不到產品",
  "page.product_detail.sku":
    "{span, react, children{SKU#:}}\xa0{span, react, children{{PRODUCT_SKU}}}",
  "page.product_detail.subscribe_restock.success": "成功登記有貨通知。",
  "page.product_detail.subscribe_restock.failed":
    "未能登記有貨通知，請稍後再試。",
  "page.product_detail.button.add_to_cart": "添加到購物車",
  "page.product_detail.button.subscribe_restock": "有貨通知我",
  "page.product_detail.button.customer_reviews":
    "{numberOfCustomerReviews}則顧客評論",
  "page.product_detail.button.q_and_a_count": "{numberOfQAndA}回答問題",
  "page.product_detail.button.add_review": "+ 添加評論",
  "page.product_detail.button.login_and_add_review": "登入及添加評論",
  "page.product_detail.button.read_all_reviews":
    "閱讀全部{numberOfCustomerReviews}條評論",
  "page.product_detail.product_will_earn_club_points":
    "購買此產品可賺取{clubPoints} Club積分",
  "page.product_detail.club_points_rebate_with_rebate_amount_currency":
    "購買此產品總共可獲{rebateAmount}%回贈。基本連額外回贈共賺取{earnClubPointAmount}Club積分，於下次購物扣減HK${earnClubPointAmountInCurrency}！",
  "page.product_detail.club_points_rebate_with_rebate_amount":
    "購買此產品總共可獲{rebateAmount}%回贈。基本連額外回贈共賺取{earnClubPointAmount}Club積分！",
  "page.product_detail.club_points_rebate":
    "購買此產品可賺取{earnClubPointAmount}Club積分！",
  "page.product_detail.clubpoints_calculator": "用Club積分扣減價格",
  "page.product_detail.tax_disclaimer":
    "(透過Club Hope認購商品、或於結賬前使用Club積分的交易將不能獲發退稅收據。)",
  "page.product_detail.button.try_now": "馬上試試!",
  "page.product_detail.button.third_party_url": "兌換",
  "page.product_detail.num_users_likes_this_item":
    "{likeCount}位用戶喜歡這個產品",
  "page.product_detail.product_description.title": "產品簡介",
  "page.product_detail.delivery_method.title": "運輸方式",
  "page.product_detail.promotions.title": "促銷活動",
  "page.product_detail.detail_description.title": "產品詳情",
  "page.product_detail.similar_product.title": "相關產品",
  "page.product_detail.specification.title": "產品規格",
  "page.product_detail.product_configuration.form.item_picker.placeholder":
    "選擇一個選項",
  "page.product_detail.product_configuration.form.button.done": "完成",
  "page.product_detail.purchase_product.form.radio.none": "無",
  "page.product_detail.purchase_product.button.add_to_cart": "添加到購物車",
  "page.product_detail.purchase_product.form.color_picker.title": "顏色",
  "page.product_detail.purchase_product.form.item_picker.placeholder":
    "選擇一個選項",
  "page.product_detail.purchase_product.form.item_picker.product_option.in_stock":
    "{OPTION}",
  "page.product_detail.purchase_product.form.item_picker.product_option.sold_out":
    "{OPTION} (售罄)",
  "page.product_detail.purchase_product.form.subscribe.not_subscribe":
    "只購買一次",
  "page.product_detail.purchase_product.form.subscribe.subscribe": "訂閱此產品",
  "page.product_detail.purchase_product.form.ree.service": "受管制電器除舊服務",
  "page.product_detail.purchase_product.form.ree.service.free_recycle":
    "法定免費除舊服務",
  "page.product_detail.purchase_product.form.ree.service.no_recycle":
    "放棄法定免費除舊服務",
  "page.product_detail.purchase_product.form.ree.service.decide_later":
    "尚未決定，將在3個工作天內聯絡客戶服務",
  "page.product_detail.purchase_product.form.ree.service.disclaimer":
    "我已閱讀並同意接受法定免費{span, react, children{除舊服務的條款和條件之約束} onClick{{onClickDisclainerLink}} className{{disclaimerLinkClassName}}}.",
  "page.product_detail.purchase_product.form.club_protect.banner.main_text":
    "12個月{span, react, children{電子產品額外保障} className{{mainTextHighlight}}}",
  "page.product_detail.purchase_product.form.club_protect.main_text":
    "附送12個月電子商品意外保障",
  "page.product_detail.purchase_product.form.club_protect.service":
    "高達 ${protectionAmount} 意外保障",
  "page.product_detail.club_protect_modal.title":
    "電子商品意外保障# 體驗無憂購物樂趣",
  "page.product_detail.club_protect_modal.body":
    "電子商品意外保障計劃內容包括：{ul, react, children{{li, react, children{12個月意外損毀保障}}{li, react, children{最高賠償總額高達20,000港元}}}}",
  "page.product_detail.club_protect_modal.ps":
    "# 須受條款及細則約束，詳情請參閲保單條款。電子商品意外保障（「此保障」）由富衛保險有限公司（「富衛保險」）承保，並由富衛保險委任HKT Financial Services (IA) Limited為持牌保險代理人，安排此保障及提供相關服務。",
  "page.product_detail.club_protect_modal.policy_provision": "保單條款",
  "page.product_detail.club_protect_modal.important_note": "重要事項",
  "page.product_detail.club_protect_modal.product_terms_and_conditions":
    "產品條款及細則",
  "page.product_detail.club_protect_modal.faq": "了解更多",
  "page.product_detail.club_protect_banner.main_text": "可獲電子商品意外保障",
  "page.product_detail.club_protect_banner.details": "詳情",
  "try_clubpoint.cannot_use_clubpoint":
    "購物車內有 “直接捐款” 的項目，此帳單只接受信用卡付款，並不能以Club積分扣減價格。",
  "try_clubpoint.pay_less_with_clubpoints": "用Club積分扣減價格",
  "try_clubpoint.tax_disclaimer":
    "(透過Club Hope認購商品、或於結賬前使用Club積分的交易將不能獲發退稅收據。)",
  "try_clubpoint.per_block": "{perBlock}分 = 減HK$1 ",
  "try_clubpoint.clubpoint": "Club積分",
  "try_clubpoint.cash": "需支付金額",
  "page.product_image_gallery.button.description_read_more": "閱讀更多",
  "page.product_image_gallery.button.description_show_less": "閱讀更少",
  "page.product_image_gallery.description_with_read_more":
    "{content}… {ReadMore, react, onClick{{onClickReadMore}}}",
  "page.product_image_gallery.description_with_show_less":
    "{content} {ShowLess, react, onClick{{onClickShowLess}}}",
  "page.product_image_gallery.image_360_hint": "拖動圖像旋轉",
  "page.customer_product_reviews.navbar.title": "評論",
  "page.customer_product_reviews.lets_go_shopping": "去購物",
  "product.sale": "促銷",
  "product.hot": "熱賣",
  "product.new": "新品",
  "product.clubpoint_redemption": "獎賞",
  "product.add_to_cart": "添加到購物車",
  "product.add_to_cart.small": "購物車",
  "product.add_to_cart_success.dev": "已加入購物車",
  "product.details": "詳細",
  "product.as_low_as": "低至",
  "product.from": "由",
  "product.sold_out": "售罄",
  "product.subscribe_restock": "有貨通知我",
  "product.subscribe_restock.success":
    "成功登記返貨通知。我們會在返貨後以電郵通知閣下。",
  "product.subscribe_restock.failed": "未能登記有貨通知，請稍後再試。",
  "product.per_item":
    "每項 {ProductPriceViewInMessageFormat, react, product{{product}} extraPrice{{extraPrice}}}",
  "page.product_detail.bundle_sale.title": "套裝",
  "page.product_detail.product_reviews.title": "評論",
  "page.product_detail.product_reviews.no_reviews": "沒有評論",
  "page.product_detail.product_reviews.merchant_reply": "商家回覆",
  "page.product_detail.subscription_info.title": "訂閱資訊",
  "page.product_detail.subscription_info.billing_cycle": "捐款類型",
  "page.product_detail.subscription_info.free_trials": "免費試用",
  "page.product_detail.subscription_info.free_shipping": "免費送貨",
  "page.product_detail.subscription_info.start_date": "捐款開始日期",
  "page.product_detail.subscription_info.end_date": "捐款結束日期",
  "page.product_detail.club_tier_quota_message.quota_message.bak":
    "此產品只限每個Club Shopping帳戶購買{max}件。",
  "page.product_detail.club_tier_quota_message.quota_message":
    "此產品只限每個Club Shopping帳戶購買{max}件。",
  "page.product_detail.club_tier_quota_message.member_quota_message":
    "此產品只限每個The Club帳戶購買{max}件。",
  "page.product_detail.club_tier_quota_message.club_tier_quota_message":
    "此產品只限每個The Club {clubTier}帳戶購買{max}件。",
  "page.search_suggestion.list.filtered_listing.title": "分類",
  "page.search_suggestion.list.hot_searches.title": "熱門搜索",
  "page.search_suggestion.list.popular_searches.title": "熱門搜索",
  "page.search_suggestion.list.product_searches.title": "產品",
  "page.search_suggestion.list.recently_searches.title": "最近搜索",
  "page.search_suggestion.list.view_all": "查看全部 ({TOTAL_COUNT})",
  "page.search_suggestion.navbar.button.cancel": "取消",
  "page.search_suggestion.navbar.search_input.placeholder": "搜尋商品/商戶",
  "page.search.result_empty.title":
    "找不到任何相關{span, react, className{{SEARCH_TERM_HIGHLIGHT_CLASSNAME}} children{{SEARCH_TERM}}}的結果",
  "page.search.result_empty.back_to_storefront": "回到主商店",
  "page.likes.wish_list.empty.title": "你沒有加入產品至喜愛清單",
  "page.likes.wish_list.empty.description": "快快去購物，揀選你的心頭好吧。",
  "page.likes.wish_list.empty.button.lets_go_shopping": "去購物",
  "page.notification_settings.title": "通知",
  "page.notification_settings.enable_notification": "顯示通知",
  "page.notification_settings.promotion_notification.bak":
    "Club Shopping 促銷及推廣通知",
  "page.notification_settings.promotion_notification":
    "Club Shopping 促銷及推廣通知",
  "page.notification_settings.order_notification": "訂單及運送狀態通知",
  "filter.category.title": "分類",
  "filter.secondary-category.title": "次分類",
  "filter.sort.relevance": "相關性",
  "filter.sort.recommendation": "推薦",
  "filter.sort.price": "價錢",
  "filter.sort.product_name": "產品名稱",
  "filter.newest-arrivals": "最新到貨",
  "filter.sort-by": "排序",
  "filter.sort.ascending": "升序",
  "filter.sort.descending": "降序",
  "filter.general": "一般選項",
  "filter.reset-all": "清除全部",
  "filter.reset": "清除",
  "filter.price": "價格範圍(港元)",
  "filter.apply": "確定",
  "filter.cancel": "取消",
  "filter.title": "選項",
  "filter.clear_all": "清除全部",
  "filter.applied_filters.range_format": "{title}：{min} - {max}",
  "filter.cp_predefined": "商品/獎賞",
  "filter.predefined.all_items": "所有結果",
  "filter.predefined.no_min_club_point_products": "一般商品",
  "filter.predefined.club_point_products": "獎賞",
  signup: "註冊",
  "signup.create_account.bak": "註冊成為Club Shopping用戶",
  "signup.create_account": "註冊成為CLUB SHOPPING用戶",
  "signup.already_have_an_account.bak":
    "已經註冊成為Club Shopping用戶？ {div, react, className{{loginButtonClassName}} onClick{{onLoginButtonClick}} children{登入}}",
  "signup.already_have_an_account":
    "已經註冊成為Club Shopping用戶？ {div, react, className{{loginButtonClassName}} onClick{{onLoginButtonClick}} children{登入}}",
  "signup.receive_marketing_materials.bak":
    "我想收到Club Shopping的最近資訊和優惠",
  "signup.receive_marketing_materials": "我想收到Club Shopping的最近資訊和優惠",
  "signup.accept_terms":
    "本人已閱讀並僅此同意所有相關的條款和條件，包括{a, react, className{{termsAndPrivacyPolicy}} href{{termsAndConditionsLink}} target{_blank} children{條款及細則} onClick{{handleLinkClick}}}、{a, react, className{{termsAndPrivacyPolicy}} href{http://www.hkt.com/Privacy+Statement?language=zh_HK} target{_blank} children{私隱聲明} onClick{{handleLinkClick}}}、{a, react, className{{termsAndPrivacyPolicy}} href{{privacyPolicyLink}} target{_blank} children{個人資料收集聲明} onClick{{handleLinkClick}}}及相關產品資料摘要。",
  "signup.signup_failed": "註冊失敗",
  "signup.signup_with_social": "連結The Club註冊",
  "signup.or_signup_with_email": "或使用電郵註冊",
  "signup.email_already_in_use": "電郵已被使用",
  first_name: "名字",
  last_name: "姓氏",
  tel: "電話",
  "password.weak": "弱",
  "password.good": "好",
  "password.strong": "強",
  "password.requirement": "最少8位字元並包含英文字母、數字及符號。",
  try_again: "重試",
  registration_successful: "已送出啟動電郵",
  "signup_success.text.bak":
    "歡迎光臨Club Shopping! 我們已將啟動連結發送到你的電郵，請點擊確認。",
  "signup_success.text":
    "歡迎光臨Club Shopping! 我們已將啟動連結發送到你的電郵，請點擊確認。",
  "signup_success.shop": "購物",
  "signup_to_shop.title": "登入去購物",
  "signup.social_signup_email_used_fail_title": "此電郵已被登記",
  "signup.social_signup_email_used_fail_text.bak":
    "你的帳戶已與另一個Club Shopping帳戶建立了連結，請先斷開現有連結。",
  "signup.social_signup_email_used_fail_text":
    "你的帳戶已與另一個Club Shopping帳戶建立了連結，請先斷開現有連結。",
  "page.display_language.title": "顯示語言",
  "page.select_category.done":
    "完成 {numOfSelectedCategories, plural, =0{} other{({numOfSelectedCategories})}}",
  "page.select_category.select_your_interest": "選擇你感興趣的商品類別",
  "page.select_category.fail": "選擇類別失敗",
  "page.select_category.fail_text": "選擇類別失敗。請重試",
  "page.select_category.description.bak":
    "歡迎加入Club Shopping。可以把你的興趣告訴我們嗎（可選多項）？你的答案會讓我們找到更適合你的產品給你。",
  "page.select_category.description":
    "歡迎加入Club Shopping。可以把你的興趣告訴我們嗎（可選多項）？你的答案會讓我們找到更適合你的產品給你。",
  "page.settings.orders": "我的訂單",
  "page.settings.subscriptions": "我的訂閱",
  "page.settings.reviews": "我的評語",
  "page.settings.redemption": "兌換獎賞",
  "page.settings.redemption_order":
    "獎賞訂單 {span, react, children{(2020年9月1日前)} className{{redemptionDateStyle}}}",
  "page.settings.redemption_order_date": "2020年9月1日前",
  "page.settings.display_language": "顯示語言",
  "page.settings.address_book": "地址簿",
  "page.settings.my_cards": "我的信用卡",
  "page.settings.notifications": "通知",
  "page.settings.change_password": "更改密碼",
  "page.settings.about_us": "關於我們",
  "page.settings.help_center": "支援中心",
  "page.settings.logout": "登出",
  "page.account.edit": "更改",
  "page.account.signup": "註冊會員",
  "page.account.login": " / 登入",
  "page.account.enjoy_redeeming_rewards":
    "顧客尊享購物獎賞，立即連結The Club獎賞計劃賺取Club積分",
  "page.account.club_points_earned.label": "我的積分",
  "page.account.club_points_earned": "{clubPointsStr}",
  "page.account.club_tier":
    "{tier, select, blue{藍} silver{銀} gold{金} platinum{白金} black{黑} other{}}卡會員",
  "picker.quantity": "數量",
  "page.edit_profile.alert.cannot_fetch_customer": "暫時無法更新帳戶資料",
  "page.edit_profile.done": "完成",
  "page.edit_profile.title": "編輯帳戶資料",
  "page.edit_profile.change_profile_picture": "更改頭像",
  "page.edit_profile.subscribe_newsletter": "確認訂閱電子報",
  "page.edit_profile.linked_account": "已連結的帳戶",
  "page.edit_profile.my_email": "我的電郵",
  "page.edit_profile.verified_account": "帳戶已確認",
  "page.edit_profile.change": "變更",
  "page.edit_profile.update_profile_picture_error": "無法更新頭像",
  "page.edit_profile.update_profile_picture.error.from_photo_blocked":
    "用戶已拒絕使用相簿，請更改設定。",
  "page.edit_profile.update_profile_picture.error.from_photo_denied":
    "用戶已拒絕使用相簿。",
  "page.edit_profile.update_profile_picture.error.take_picture_blocked":
    "用戶已拒絕使用鏡頭，請更改設定。",
  "page.edit_profile.update_profile_picture.error.take_picture_denied":
    "用戶已拒絕使用鏡頭。",
  "page.edit_profile.update_profile_error": "無法更新帳戶資料",
  "page.edit_profile.connect_account_error": "無法連結帳戶",
  "page.edit_profile.link_account.error.email_registered": "此電郵已被登記",
  "page.edit_profile.link_account.error.email_registered.message.bak":
    "你的The Club帳戶已與另一個Club Shopping帳戶建立了連結。請先斷開現有連結。",
  "page.edit_profile.link_account.error.email_registered.message":
    "你的The Club帳戶已與另一個Club Shopping帳戶建立了連結。請先斷開現有連結。",
  "page.edit_profile.disconnect_account_error": "無法中斷帳戶連結",
  "page.edit_profile.disconnect_account_confirm":
    "請確認是否想移除{provider}帳戶連結",
  "page.edit_profile.disconnect_account_confirm.yes": "是",
  "page.edit_profile.disconnect_account_confirm.no": "否",
  "page.my_delivery.title": "地址簿",
  "my_delivery.no_stored": "沒有地址",
  "my_delivery.add_new_address": "+ 新增地址",
  "my_delivery.edit": "編輯",
  "my_delivery.set_as_default_billing": "設定為我的默認帳單地址",
  "my_delivery.unable_set_as_default_billing": "未能設定為我的默認帳單地址",
  "my_delivery.set_as_default_shipping": "設定為我的默認送貨地址",
  "my_delivery.unable_set_as_default_shipping": "未能設定為我的默認送貨地址",
  "my_delivery.delete": "刪除",
  "my_delivery.unable_delete": "未能刪除地址",
  "my_delivery.delete.ask": "請確認是否刪除地址?",
  "my_delivery.shipping": "送貨",
  "my_delivery.billing": "帳單",
  "page.add_address.title": "新增地址",
  "page.edit_address.title": "編輯地址",
  "page.edit_profile.action_sheet.camera.from_photos": "相簿",
  "page.edit_profile.action_sheet.camera.take_picture": "相機",
  "page.edit_profile.action_sheet.camera.cancel": "取消",
  "add_address.info": "聯絡資料",
  "add_address.address": "地址",
  "add_address.use_as_default_billing": "使用作為我的默認帳單地址",
  "add_address.use_as_default_shipping": "使用作為我的默認送貨地址",
  "page.my_orders.title": "我的訂單",
  "wishlist.add_to_wishlist.error": "未能把產品加到最愛清單",
  "wishlist.remove_from_wishlist.error": "未能把產品從最愛清單移除",
  "my_orders.order_info.order_id": "訂單",
  "my_orders.order_info.quantity": "數量",
  "my_orders.order_info.order_total": "總計",
  "my_orders.order_info.order_date": "訂購日期",
  "my_orders.order_info.sub_total": "小計",
  "my_orders.order_info.club_point_earned": "已賺取的Club積分",
  "my_orders.order_info.club_point_earned_pts": "{pts}分",
  "my_orders.order_info.club_protect_enabled":
    "此訂單內其中一件或所有商品享有電子商品意外保障的保障",
  "my_orders.order_info_details": "詳細",
  "my_orders.status": "狀態",
  "my_orders.status.canceled": "已取消",
  "my_orders.status.closed": "已關閉",
  "my_orders.status.complete": "已完成",
  "my_orders.status.pending": "等待中",
  "my_orders.status.pending_clubpoint": "等待付款中",
  "my_orders.status.pending_payment": "等待付款中",
  "my_orders.status.processing": "處理中",
  "my_orders.no_orders_yet": "沒有訂單",
  "my_orders.lets_go_shopping": "立即購物",
  "order_detail.title": "訂單詳情",
  "order_detail.status.canceled": "已取消",
  "order_detail.status.closed": "已關閉",
  "order_detail.status.complete": "已完成",
  "order_detail.status.pending": "等待中",
  "order_detail.status.pending_clubpoint": "等待確認Club積分",
  "order_detail.status.pending_payment": "等待付款中",
  "order_detail.status.processing": "處理中",
  "order_detail.order_id": "訂單",
  "order_detail.purchased_items": "已訂購商品",
  "order_detail.purchased_items.count": "{count}個",
  "order_detail.product.title": "產品名稱",
  "order_detail.item.club_protect.service":
    "高達 ${protectionAmount} 意外保障 {span, react, children{免費} className{{highlightClassName}}}",
  "order_detail.item.club_protect.service.joined": "參加",
  "order_detail.item.club_protect.agreement_1":
    "本人已參閱及明白「{a, react, children{產品條款及細則} className{{agreementLinkClassName}} onClick{{productTermsAndConditionsClick}}}」及「{a, react, children{保障條款及細則} className{{agreementLinkClassName}} onClick{{protectionTermsAndConditionsClick}}}」，並同意把上述資料交予HKT Financial Services (IA) Limited (“HKTIA”)或富衛保險有限公司（「富衛保險」）用作申請「電子產品額外保障」之用。",
  "order_detail.item.club_protect.agreement_2":
    "本人作為受保人已經清楚閱讀並同意 1) 此聲明，2) {a, react, children{HKT Financial Services (IA) Limited的個人資料收集聲明（「HKTIA PICS」）} className{{agreementLinkClassName}} onClick{{hktiaClick}}}及 3) {a, react, children{富衛保險有限公司的個人資料收集聲明 （「FWD PICS」）} className{{agreementLinkClassName}} onClick{{fwdClick}}}，並同意遵守及受HKTIA PICS及FWD PICS約束。",
  "order_detail.item.club_protect.in_protection":
    "此商品享有電子商品意外保障的保障",
  "order_detail.club_protect_modal.insurance_name":
    "保障名稱：12個月電子商品意外保障",
  "order_detail.club_protect_modal.insurance_period":
    "生效日期：由{fromDate}至{toDate}",
  "order_detail.club_protect_modal.policy_provision": "保單條款",
  "order_detail.club_protect_modal.product_terms_and_conditions":
    "產品條款及細則",
  "order_detail.club_protect_modal.faq": "常見問題",
  "order_detail.club_protect_modal.claim": "關於索償",
  "order_detail.promo_code_text": "推廣碼{index}: {promoCodeUrl}",
  "order_detail.promo_code_url":
    "推廣碼{index}: {a, react, children{{promoCodeUrl}} href{{promoCodeUrl}} onClick{{onLinkClicked}}}",
  "order_detail.quantity": "數量",
  "order_detail.price": "價錢",
  "order_detail.subtotal": "小計",
  "order_detail.club_point_earned": "可賺取的Club積分",
  "order_detail.reorder": "再訂購",
  "order_detail.review": "寫評論",
  "order_detail.view_delivery": "查看物流",
  "order_detail.view_insurance": "查看保障",
  "order_detail.redeem": "兌換",
  "order_detail.order_details": "詳細",
  "order_detail.billing_info": "帳單資訊",
  "order_detail.delivery_method": "運送方式",
  "order_detail.delivery_time": "訂單派送信息",
  "order_detail.address": "送貨地址",
  "order_detail.billing_address": "帳單地址",
  "order_detail.payment_method": "付款方法",
  "order_detail.promotions": "使用優惠碼",
  "order_detail.description": "簡介",
  "order_detail.payment_information": "付款資訊",
  "order_detail.payment_info.subtotal": "小計",
  "order_detail.payment_info.club_points_required": "需要的Club積分",
  "order_detail.payment_info.extra_club_points_used": "額外使用Club積分",
  "order_detail.payment_info.club_points_used": "使用Club積分",
  "order_detail.payment_info.discount": "折扣",
  "order_detail.payment_info.shipping": "運費",
  "order_detail.payment_info.amount_payable": "需支付金額",
  "order_detail.payment_info.shipping_fee": "運費",
  "order_detail.payment_info.initial_subscription_fee": "初始訂閱費用",
  "order_detail.customer_service_contact_us": "客戶服務/聯絡我們",
  "order_detail.fulfillment_code": "商品領取二維碼",
  "order_details_qr_codes_redemption.title": "兌換碼",
  "order_details_qr_codes_redemption.empty": "沒有兌換碼",
  "order_details_shipment_status_modal.title": "物流情況",
  "product_list.no_product": "沒有產品",
  "view.share_with_friends.title": "與朋友分享",
  "single_category_page.no_products.message": "所有產品已售罄，請選擇其他類別",
  "single_category_page.no_products.clear_filter_message": "沒有產品",
  "single_category_page.back": "返回",
  "single_category_page.clear_filter": "顯示全部",
  "qr_image_picker.no_image": "未能找到圖片",
  "qr_image_picker_confirm.choose": "選擇",
  "qr_image_picker_confirm.cancel": "取消",
  "qr_image_picker_confirm.scan_failed_title": "掃描失敗",
  "qr_image_picker_confirm.scan_failed_message": "在圖片中未能找到二維碼",
  "qr_scanner.title": "掃描二維碼",
  "qr_scanner.hint": "請放置二維碼到鏡頭前15-25厘米",
  "qr_scanner_not_found.cancel": "取消",
  "qr_scanner_not_found.try_again": "再試一次",
  "qr_scanner_not_found.message": "產品可能已被移除，請再試一次",
  "qr_scanner_not_found.title": "未能找到產品",
  "camera.blocked": "用戶已拒絕使用鏡頭，請更改設定。",
  "camera.denied": "用戶已拒絕使用鏡頭。",
  "qr_scanner_success.text": "掃描成功",
  "qr_scanner_success.message": "{DATA}",
  "qr_scanner_failed.text": "掃描失敗",
  "qr_scanner_failed.message": "{REASON}",
  "last_photo_retriever.error": "不能存取相簿",
  "last_photo_retriever.error_unauthorized": "用戶已拒絕使用相簿，請更改設定。",
  "photos.error": "不能存取相簿",
  "photos.error_unauthorized": "用戶已拒絕使用相簿，請更改設定。",
  "add_to_cart.items_has_been_added_to_cart":
    "已將{numOfItems}件產品加入到購物車",
  "add_to_cart.subtotal": "合共",
  "add_to_cart.checkout": "結帳 ({numOfItems})",
  "add_to_cart.error.sold_out":
    "對不起！此商品暫時缺貨，未能添加商品到購物車。",
  "shopping_cart.title": "購物車",
  "empty_shopping_cart.title": "您的購物車中沒有商品",
  "empty_shopping_cart.lets_go_shopping": "繼續購物",
  "shopping_cart.subsciptional": "這是訂閱產品",
  "shopping_cart.extra_clubpoints": "{extraClubpoints}x Clubpoints",
  "shopping_cart.rebate_amount": "{rebateAmount}%回贈",
  "shopping_cart.add_to_wishlist": "移到喜愛清單",
  "shopping_cart.free_delivery_service":
    "* 消費總額滿HK$200.0即可享免費商品自取服務",
  "shopping_cart.complete_order_to_earn_clubpoints":
    "完成此訂單可賺取{IonIcon, react, src{{clubpointIconSrc}} className{{clubpointsHighlight}}} {clubpointsAmount}Club積分",
  "shopping_cart.points_will_be_transferred":
    "Club積分將於買家取貨後起計10個工作天後存入The Club 帳戶",
  "shopping_cart.checkout": "結帳",
  "shopping_cart.no_changes_are_allowed": "結帳後訂單不能更改",
  "shopping_cart.link_with": "連結",
  "shopping_cart.link_to_earn_point":
    "立即連結The Club帳戶用Club積分扣減價格及賺取 {IonIcon, react, src{{theClubIconSrc}} name{club-point} className{{theClubIconClassName}}}{clubpointsAmount} Club積分",
  "shopping_cart.product_sold_out": "該商品已售罄",
  "merchant_directories.title": "商戶列表",
  "merchant_details.title": "商戶資訊",
  "merchant_details.about": "關於",
  "merchant_details.about.contact_number": "電話號碼",
  "merchant_details.about.opening_hours": "開放時間",
  "merchant_details.return_policy": "退貨須知",
  "merchant_details.shipping_policy": "送貨須知",
  "link_switch.link": "已連結",
  "link_switch.unlink": "未連結",
  "single_merchant.featured": "精選",
  "single_merchant.products": "所有產品",
  "single_merchant.no_segment": "請選擇目錄",
  "single_merchant.featured.no_products.message":
    "暫時沒有精選商品，請按所有商品瀏覽",
  "error.unknown": "未知錯誤",
  "error.unable_to_find_product": "未能找到商品",
  "club_point_journey.title": "Club積分旅程",
  "club_point_journey.point": "{point}分",
  "select_saved_address.title": "地址簿",
  "address_card.title.index": "地址{index}",
  "address_card.name.title": "名字",
  "address_card.telephone.title": "電話號碼",
  "address_card.company.title": "公司名稱",
  "address_card.address.title": "地址",
  "address_form.first_name": "名字",
  "address_form.last_name": "姓氏",
  "address_form.company": "公司名稱",
  "address_form.telephone": "手提電話",
  "address_form.telephone.purpose": "(作取貨及通知用途)",
  "address_form.address": "地址",
  "address_form.room_number": "室/單位/房",
  "address_form.room_number.example": "(例: A室)",
  "address_form.flat_or_floor": "樓層",
  "address_form.flat_or_floor.example": "(例: 43樓)",
  "address_form.block": "座",
  "address_form.block.example": "(例: 1座)",
  "address_form.building": "樓宇/屋苑/屋邨",
  "address_form.building.example": "(例: 太古城, 星輝台, 天星閣)",
  "address_form.street": "街道",
  "address_form.street.example": "(例: 軒尼詩道18號)",
  "address_form.district": "地區",
  "address_form.district.placeholder": "請選擇一個地區",
  "address_form.area": "地域",
  "address_form.area.placeholder": "請選擇一個地域",
  "address_form.region": "地點",
  "address_form.region.placeholder": "請選擇一個地點",
  "address_form.non_delivery_area": "按此查看不提供送貨服務的區域",
  "non_delivery_area_modal.title": "不提供送貨服務的區域",
  "self_pickup_form.region": "地域",
  "self_pickup_form.district": "地區",
  "self_pickup_form.store_type": "類別",
  "self_pickup_form.store_type.all": "全部",
  "self_pickup_form.store_type.o2o_store": "商品領取站",
  "self_pickup_form.store_type.elocker": "智能櫃",
  "self_pickup_form.store_type.o2o_fulfillment": "商品領取站",
  "self_pickup_form.store_type.unknown": "未知的類別",
  "self_pickup_form.pickup_spot": "自取地點",
  "self_pickup_form.opening_hours": "服務時間",
  "update_delivery_endpoint_for_o2o_store.error.no_telephone": "請填寫電話",
  "update_delivery_endpoint_for_o2o_store.error.no_o2o_store": "找不到自取點",
  "promotion.code.title": "請輸入優惠碼",
  "promition.code.use": "使用",
  "promition.code.value": "已輸入優惠碼 - {code}",
  "promotion.code.error.invalid": "優惠碼無效",
  "checkout.filling_info.title": "結帳",
  "checkout.header.step.filling_info": "結帳資訊",
  "checkout.header.step.confirmation": "確認",
  "checkout.header.step.payment": "付款",
  "checkout.header.step.ordered": "已下單",
  "checkout.filling_info.select_a_saved_address": "選擇一個地址",
  "checkout.filling_info.save_address.text": "儲存地址",
  "checkout.filling_info.self_pickup.missing": "請選擇商品自取點",
  "checkout.filling_info.payment_option.free": "使用Club積分",
  "checkout.filling_info.payment_option.opp.new_card":
    "新信用卡<br>(請在下一頁輸入信用卡號碼)",
  "checkout.filling_info.payment_option.opp.save_card": "保存卡以備下次購買",
  "checkout.filling_info.payment_option.opp.missing_card":
    "請選擇信用卡或新信用卡",
  "the_club_otp.cancelled": "Club積分兌換，未能成功接納。",
  "asia_pay.cancelled": "用戶取消付款",
  "asia_pay.failed": "付款失敗",
  "opp_payment.cancelled": "用戶取消付款",
  "opp_payment.failed": "付款失敗",
  "checkout.filling_info.payment_option.tap_and_go": "拍住賞",
  "checkout.filling_info.payment_option.missing": "請選擇付款方式",
  "checkout.delivery_info.title": "運送方式",
  "checkout.delivery_info.delivery_address.text": "送貨",
  "checkout.delivery_info.self_pickup.text": "商品自取",
  "checkout.delivery_info.missing": "請選擇地址類別",
  "checkout.delivery_time.title": "地址類別",
  "checkout.delivery_time.choose_delivery_time.title": "Club Mall",
  "checkout.delivery_time.choose_delivery_time": "選擇送貨時間",
  "choose_delivery_time.error.missing": "請先選擇地址類別",
  "checkout.billing_info.title": "帳單資訊 ",
  "checkout.billing_info.p1":
    "凡超過港幣一百元的現金捐款，指定受惠機構會提供捐款收據作扣減稅項。任何使用Club積分的交易或於Club Hope平台上認購任何商品均不能獲發捐款收據。",
  "checkout.billing_info.p2":
    "Club Hope受惠機構收集所得的個人資料絕對保密。有關資料只交由受惠機構作發送收據之用途。如有任何關於收據的問題，請直接與相關受惠機構聯絡。",
  "checkout.billing_info.same_as_delivery_address.text":
    "送貨地址和帳單地址一樣",
  "checkout.billing_info.custom_address.text": "送貨地址和帳單地址不同",
  "checkout.billing_info.input_billing_address.text":
    "請提供資料，以便受惠機構發出捐款收據。［本人同意Club HKT Limited轉交本人的個人資料及其他資料（「資料」）予指定的受惠機構作發出捐款收據的用途。］",
  "checkout.billing_info.missing": "請選擇帳單資訊 ",
  "checkout.payment_option.title": "付款方法",
  "checkout.payment_info.title": "付款資訊",
  "checkout.payment_method.title": "付款方法",
  "checkout.promotion.title": "使用優惠碼",
  "checkout.select_o2o_store.title": "自取地點",
  "checkout.confirmation.edit_button": "編輯",
  "checkout.confirmation.disclaimer.title": "捐款收據提示",
  "checkout.confirmation.disclaimer.subtitle":
    "凡超過港幣一百元的現金捐款，指定受惠機構會提供捐款收據作扣減稅項",
  "checkout.confirmation.disclaimer.subtitle_extended": "（購買商品除外）",
  "checkout.confirmation.disclaimer.subtitle_fullstop": "。",
  "checkout.confirmation.disclaimer.extended_title":
    "注意：任何使用Club積分的交易或於Club Hope平台上認購任何商品均不能獲發捐款收據作扣減稅項。",
  "checkout.confirmation.disclaimer.extended_p1": "任何使用Club積分的交易；或",
  "checkout.confirmation.disclaimer.extended_p2":
    "於Club Hope平台上認購任何商品",
  "checkout.confirmation.disclaimer.continue": "繼續前往結帳",
  "checkout.confirmation.disclaimer.return": "返回購物車",
  "checkout.ordered.title": "付款成功",
  "checkout.ordered.order_id": "訂單號碼",
  "checkout.ordered.message.title": "多謝惠顧",
  "checkout.ordered.button.my_transaction": "我的訂單",
  "checkout.ordered.button.continue_shopping": "繼續購物",
  "checkout.payment_qr_code.title": "付款二維碼",
  "checkout.payment_qr_code.your_order_id_is": "你的訂單編號是：{orderID}",
  "checkout.payment_qr_code.refresh": "更新",
  "checkout.email.title": "電郵地址",
  "checkout.email.message": "我們會發送訂單確認郵件到這個電郵",
  "checkout.email.change.alert.title": "更改電郵",
  "checkout.email.change.alert.message":
    "如需更改電郵，我們會暫時離開結帳和開啟帳戶設定",
  "checkout.burn_point.alert.message": "未能確認使用Club積分",
  "share.product.messenger.message": `{PRODUCT_NAME}
{PRODUCT_LINK}`,
  "share.product.title.bak": "我想要在 Club Shopping 推薦這件產品給你",
  "share.product.title": "我想要在 Club Shopping 推薦這件產品給你",
  "share.product.error": "無法分享",
  "page.signup_clubpoint.clubpoint": "CLUB積分",
  "page.signup_clubpoint.earn_clubpoints": "購物以賺取CLUB積分",
  "page.signup_clubpoint.link_button": "連結THE CLUB帳戶",
  "modal.social_signup_terms.title": "確認電郵及接收條款",
  "modal.social_signup_terms.subscribe_news.bak":
    "我想收到Club Shopping的最近資訊和優惠",
  "modal.social_signup_terms.subscribe_news":
    "我想收到Club Shopping的最近資訊和優惠",
  "modal.social_signup_terms.agree": "同意",
  "modal.terms_and_conditions.navbar.title": "使用條款",
  "page.change_email.change_email": "更改電郵",
  "page.change_email.submit": "提交",
  "page.change_email.current_email": "現在的電郵 *",
  "page.change_email.new_email": "新電郵 *",
  "page.change_email.re_enter_email": "重新輸入電郵 *",
  "page.change_email.password": "密碼 *",
  "page.change_email.change_email_error": "無法更改電郵",
  "page.change_email.invalid_password": "密碼不正確",
  "page.change_email.verify_your_email.description":
    "檢查您的電子郵件（{NEW_EMAIL}）以確認您的新地址。在您確認之前，通知將繼續發送到您當前的電子郵件地址。",
  "page.change_email.verify_your_email.resend_confirmation": "重新發送確認電郵",
  "page.change_email.verify_your_email.cancel_this_change": "取消此更改",
  "page.change_email.verify_your_email.toast.verification_email_sent":
    "您的驗證電子郵件已發送",
  "page.change_email.verify_your_email.toast.cancelled": "已取消",
  "page.change_email.modal.weve_sent_you_an_verification_email":
    "我們已向您發送了驗證電子郵件",
  "page.change_email.modal.your_email_updated": "您的電子郵件已更新",
  "page.change_email.modal.button.shop": "購物",
  "footer_cms_link.page_not_found": "頁面不存在",
  "customer_service.title": "客戶服務",
  "page.change_password.change_password": "更改密碼",
  "page.change_password.save": "儲存",
  "page.change_password.current_password": "目前密碼 *",
  "page.change_password.new_password": "新密碼 *",
  "page.change_password.re_enter_password": "重新輸入密碼 *",
  "page.change_password.current_password.invalid": "目前密碼不正確",
  "page.change_password.new_password.invalid": "請輸入有效新密碼",
  "page.change_password.re_enter_password.invalid": "與新密碼不符",
  "page.change_password.error.fail_change_password": "無法更改密碼",
  "page.change_password.change_password_success": "已成功更改你的密碼!",
  "page.change_password.forgot_password": "忘記密碼",
  "modal.the_club_email.verify": "驗證",
  "modal.the_club_email.verify_your_email": "驗證你的電郵",
  "modal.the_club_email.cannot_verify_email": "無法驗證電郵",
  "ree.header": "廢電器電子產品生產者責任計劃",
  "ree.message":
    "本產品是《產品環保責任條例》(第603章)所指的受管制電器，閣下可以要求免費除去相同類型和數量的舊電器。閣下必須選擇: (i) 法定免費除舊服務（新電器的交貨日期不同於回收舊電器的日期）；(ii) 放棄法定免費除舊服務；(iii) 尚未決定，將在 3 個工作天內聯絡Club Shopping客戶服務安排或放棄除舊服務。 如Club Shopping客戶服務在 3 個工作天內未收到閣下答覆，則被認定為放棄除舊服務",
  "store_type.o2o_store": "商品領取站",
  "store_type.elocker": "智能櫃",
  "app_update_dialog.force.title": "軟件更新提示",
  "app_update_dialog.force.description":
    "你現時使用的是舊版本, 請先更新以使用最新功能及得到最佳用戶體驗。",
  "app_update_dialog.recommend.title": "軟件更新提示",
  "app_update_dialog.recommend.description":
    "你現時使用的是舊版本, 請選擇更新以使用最新功能及得到最佳用戶體驗。",
  "app_update_dialog.no_thanks": "不更新",
  "app_update_dialog.update": "立即更新",
  "location.denied": "[PH] 已拒絕取得位置",
  "push_notification_messages.title": "通知",
  "push_notification_messages.empty": "沒有通知",
  "push_notification_messages.error": "未知錯誤",
  "push_notification_messages.not_ready": "未啟用通知。請到系統設定啟用。",
  "push_notification_alert.go_to": "前往",
  "customer_product_review.product_link.product_name": "產品",
  "customer_product_review.product_link.merchant_name": "商戶",
  "add_product_review_modal.title": "評價這件商品",
  "add_product_review_modal.detail.placeholder": "請填寫評價",
  "add_product_review.success.title": "評論已提交",
  "page.my_cards.title": "我的信用卡",
  "my_cards.delete": "刪除信用卡",
  "my_cards.empty": "沒有信用卡",
  "my_cards.remove_card.dialog.message": "移除此信用卡{numberQuoted}？",
  "my_cards.remove_card.dialog.button.remove": "移除",
  "page.my_subscriptions.title": "我的訂閱",
  "my_subscriptions.empty": "沒有訂閱",
  "my_subscriptions.subscription_info.subscription_id": "訂閱ID",
  "my_subscriptions.subscription_info.next_billing_amount": "下期收費",
  "my_subscriptions.subscription_info.subscription_name": "訂閱名稱",
  "my_subscriptions.subscription_info.status": "狀態",
  "my_subscriptions.subscription_info.details": "詳細",
  "page.subscription_details.title": "訂閱詳細",
  "subscription_details.subscription_id": "訂閱ID",
  "subscription_details.subscribed_item": "已訂閱項目",
  "subscription_details.subscription_name": "訂閱名稱",
  "subscription_details.quantity": "數量",
  "subscription_details.start_date": "開始日期",
  "subscription_details.end_date": "結束日期",
  "subscription_details.trial_start_date": "開始試用日期",
  "subscription_details.trial_end_date": "試用結束日期",
  "subscription_details.last_billing_date": "上期收費日期",
  "subscription_details.last_billing_amount": "上期收費",
  "subscription_details.next_billing_date": "下期收費日期",
  "subscription_details.next_billing_amount": "下期收費",
  "subscription_details.status": "狀態",
  "subscription_details.review": "寫評論",
  "subscription_details.cancel_subscription": "取消訂閱",
  "subscription_details.cancel.dialog.message": "確定取消此訂閱?",
  "subscription_details.cancel.dialog.button.confirm": "取消此訂閱",
  "subscription_details.cancel.dialog.button.cancel": "保留此訂閱",
  "rebate_circle.rebate": "回贈",
  "open_link_in_external_browser.header": "正在開啟系統瀏覽器",
  "open_link_in_external_browser.message":
    "以下連結將會以系統瀏覽器開啟： {url}",
  "page.club_protect_claim_application.title": "關於索償",
  "page.club_protect_claim_application.faq": "常見問題",
  "hot_product_list.view_more": "更多熱賣貨品",
  "page.brand_index.title": "品牌",
  "page.brand_index.filter.all": "全部品牌",
  "page.brand_index.filter.others": "其他",
  "page.brand_index.search.navbar.search_input.placeholder": "搜尋品牌",
  "page.brand_index.search.navbar.button.cancel": "取消",
};
/* eslint-enable no-template-curly-in-string */

export default zhHant;
