import React, { useState, useCallback, useContext, useEffect } from "react";
import { IonPopover, IonButton } from "@ionic/react";

import { Category, CategoryTree } from "../../models/category";
import { useCategoryTreeMap } from "../../repository/CategoryRepository";
import {
  useStoreConfig,
  useUrlRedirectConfig,
} from "../../repository/ConfigRepository";

import CategoryList from "../CategoryList";
import CategoryButton from "../CategoryButton";
import CategoryTreeList from "../CategoryTreeList";
import { ContentScrollProviderContext } from "../ContentScrollProvider";
import MerchantDirectoriesButton from "../MerchantDirectoriesButton";

import { NavBar } from "../NavBar";
import {
  getPathForMerchantDirectories,
  RootTab,
} from "../../navigation/routes";

import { pageView, actionEvent } from "../../utils/GTM";

import styles from "./styles.module.scss";
import findMatchedRedirectMapping from "../../utils/findMatchedRedirectMapping";
import useOpenUrlWithBrowser from "../../hook/useOpenUrlWithBrowser";

interface CategoryListPopoverState {
  shown: boolean;
  event: any;
}

export interface CategoryListPopoverProps {
  openCategoryListPopover: (event?: Event) => void;
}

export function withCategoryListPopover<P>(
  Component: React.ComponentType<P & CategoryListPopoverProps>
): React.ComponentType<P> {
  const Wrapped: React.FC<P> = (props: P) => {
    const storeConfig = useStoreConfig();
    const { updateContentYScrollEnabled } = useContext(
      ContentScrollProviderContext
    );

    const [state, setState] = useState<CategoryListPopoverState>({
      shown: false,
      event: null,
    });

    const [selectedCategoryTree, setSelectedCategoryTree] = useState<
      CategoryTree | undefined
    >(undefined);

    const categoryTreeMap = useCategoryTreeMap();

    const openCategoryListPopover = useCallback(
      (event: any = null) => {
        if (event) {
          // Persist event for async use.
          event.persist();
        }
        // Make target to event registered target to correct
        // popover position
        event.target = event.currentTarget;
        setState({
          shown: true,
          event,
        });
        pageView({ page: "Category" });
        updateContentYScrollEnabled(false);
      },
      [setState, updateContentYScrollEnabled]
    );

    const closeCategoryListPopover = useCallback(() => {
      setState({
        shown: false,
        event: null,
      });
      updateContentYScrollEnabled(true);
    }, [setState, updateContentYScrollEnabled]);

    const handleWindowResize = useCallback(() => {
      const { shown } = state;
      if (shown) {
        closeCategoryListPopover();
      }
    }, [state, closeCategoryListPopover]);

    useEffect(() => {
      window.addEventListener("resize", handleWindowResize);
      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }, [handleWindowResize]);

    const urlRedirectConfig = useUrlRedirectConfig();
    const openUrlWithBrowser = useOpenUrlWithBrowser();

    const handleCategoryButtonClick = useCallback(
      (category: Category) => {
        actionEvent("Category", "Click", category.name);
        const matched = findMatchedRedirectMapping(
          urlRedirectConfig,
          "CATEGORY",
          `${category.id}`
        );
        if (matched != null) {
          openUrlWithBrowser(matched.targetPath);
          return;
        }

        const categoryTree = categoryTreeMap[category.id];
        setSelectedCategoryTree(categoryTree);
      },
      [categoryTreeMap, openUrlWithBrowser, urlRedirectConfig]
    );

    const handleCategoryTreeDetailItemClick = useCallback(() => {
      closeCategoryListPopover();
    }, [closeCategoryListPopover]);

    const handleCategoryTreeBackClick = useCallback(() => {
      setSelectedCategoryTree(undefined);
    }, [setSelectedCategoryTree]);

    const renderCategoryButton = useCallback(
      (category: Category): JSX.Element =>
        storeConfig ? (
          <CategoryButton
            storeConfig={storeConfig}
            category={category}
            key={category.id}
            onLeftAreaClick={handleCategoryButtonClick}
            onRightAreaClick={handleCategoryButtonClick}
          />
        ) : (
          <div key={category.id} />
        ),
      [handleCategoryButtonClick, storeConfig]
    );

    return (
      <>
        <Component
          {...props}
          openCategoryListPopover={openCategoryListPopover}
        />
        <IonPopover
          mode="ios"
          isOpen={state.shown}
          onDidDismiss={closeCategoryListPopover}
          cssClass={styles.popover}
          event={state.event}
        >
          {!selectedCategoryTree && (
            <div className={styles.buttonList}>
              <CategoryList renderCategory={renderCategoryButton} />
              <MerchantDirectoriesButton
                href={getPathForMerchantDirectories(RootTab.allCategories)}
                onClick={closeCategoryListPopover}
              />
            </div>
          )}
          {selectedCategoryTree && (
            <>
              <NavBar
                toolbarClassName={styles.categoryTreeHeader}
                headerLeft={
                  <IonButton
                    className={styles.categoryTreeBackButton}
                    onClick={handleCategoryTreeBackClick}
                  >
                    <span className={styles.categoryTreeBackButtonIcon} />
                  </IonButton>
                }
                headerTitle={
                  selectedCategoryTree != null && (
                    <span>{selectedCategoryTree.name}</span>
                  )
                }
              />
              <div className={styles.categoryTreeContent}>
                <CategoryTreeList
                  categoryTree={selectedCategoryTree}
                  categoryTreeMap={categoryTreeMap}
                  onDetailItemClick={handleCategoryTreeDetailItemClick}
                />
              </div>
            </>
          )}
        </IonPopover>
      </>
    );
  };
  return Wrapped;
}
