import * as yup from "yup";

import Config from "../Config";

import { IndexMap } from "../utils/type";

export interface CMS_PAGE_TYPE {
  type: "home";
}

export type HTML_BASED_CMS_PAGE_TYPE =
  | {
      type: "cmsPage";
      identifier: number;
    }
  | { type: "category"; identifier: string }
  | { type: "merchant"; identifier: string }
  | { type: "cmsBlock"; identifier: string };

// TODO:
// Refactor the data structure when implement home page content
export interface CMSPageContent {
  items: CMSBlock[];
}
export function CMSPageContent(items: CMSBlock[]): CMSPageContent {
  return {
    items,
  };
}

export interface HTMLBasedCMSPageContent {
  waitingToFillHTML: string;
  matchedCMSBlocks: MatchedCMSBlock[];
}

/**
 * Carousel
 */
export interface CarouselItem {
  desktopImageUrl: string | null;
  mobileImageUrl: string | null;
  linkUrl: string | null;
}

const CarouselItemSchema: yup.Schema<CarouselItem> = yup
  .object({
    desktopImageUrl: yup.string().nullable(),
    mobileImageUrl: yup.string().nullable(),
    linkUrl: yup.string().nullable(),
  })
  .camelCase();

export interface CMSCarousel {
  type: "banner_rotator";
  items: CarouselItem[];
}

const CMSCarouselSchema: yup.Schema<CMSCarousel> = yup
  .object({
    type: yup.string().matches(/banner_rotator/) as yup.Schema<
      "banner_rotator"
    >,
    items: yup.array().of(CarouselItemSchema),
  })
  .camelCase();

/**
 * HTML
 */

export interface CMSStaticBlock {
  type: "static_block";
  items: {
    identifier: string;
  }[];
}

export function isCMSStaticBlock(
  cmsBlock: CMSBlock
): cmsBlock is CMSStaticBlock {
  return cmsBlock.type === "static_block";
}

const CMSStaticBlockSchema: yup.Schema<CMSStaticBlock> = yup
  .object({
    type: yup.string().matches(/static_block/) as yup.Schema<"static_block">,
    items: yup.array().of(
      yup.object({
        identifier: yup.string().required(),
      })
    ),
  })
  .camelCase();

export interface CMSStaticBlockContent {
  identifier: string;
  title: string;
  contentForApp: string | null;
}

export const CMSStaticBlockContentGraphQLAttributes = `
  identifier
  title
  contentForApp: content_for_app
`;

/**
 * Horizontal Product List
 */

export interface CMSHorizontalProductList {
  title: string;
  widgetTemplate:
    | "special_item"
    | "top_seller"
    | "hot_product_list_ajax"
    | null;
  viewMoreUrl: string | null;
  backgroundImageUrl: string | null;
  description: string | null;
  items: { sku: string }[];
}

const CMSHorizontalProductListSchema = yup.object<CMSHorizontalProductList>({
  title: yup.string(),
  widgetTemplate: yup
    .mixed()
    .oneOf(["special_item", "top_seller", "hot_product_list_ajax", null])
    .transform((value: unknown) => {
      if (
        value === "special_item" ||
        value === "top_seller" ||
        value === "hot_product_list_ajax"
      ) {
        return value;
      }
      return null;
    }),
  viewMoreUrl: yup.string().nullable(),
  backgroundImageUrl: yup.string().nullable(),
  description: yup.string().nullable(),
  items: yup.array().of(yup.object({ sku: yup.string().required() })),
});

export interface CMSTopSellerHorizontalProductList
  extends CMSHorizontalProductList {
  type: "top_sellers";
}

const CMSTopSellerHorizontalProductListSchema: yup.Schema<
  CMSTopSellerHorizontalProductList
> = CMSHorizontalProductListSchema.shape({
  type: yup
    .string()
    .matches(/top_sellers/)
    .required() as yup.Schema<"top_sellers">,
}).camelCase();

export interface CMSProductsRecommendationProductList
  extends CMSHorizontalProductList {
  type: "products_recommendation";
}

const CMSProductsRecommendationProductListSchema: yup.Schema<
  CMSProductsRecommendationProductList
> = CMSHorizontalProductListSchema.shape({
  type: yup
    .string()
    .matches(/products_recommendation/)
    .required() as yup.Schema<"products_recommendation">,
}).camelCase();

export interface CMSPopularProductsProductList
  extends CMSHorizontalProductList {
  type: "popular_products";
}

const CMSPopularProductsProductListSchema: yup.Schema<
  CMSPopularProductsProductList
> = CMSHorizontalProductListSchema.shape({
  type: yup
    .string()
    .matches(/popular_products/)
    .required() as yup.Schema<"popular_products">,
}).camelCase();

/**
 * CMS Blog
 */
export interface Blog {
  postId: string;
  name: string;
  shortContent: string;
  url: string;
  featureImageUrl: string | null;
}

const BlogSchema: yup.Schema<Blog> = yup
  .object({
    postId: yup.string(),
    name: yup.string(),
    shortContent: yup.string(),
    url: yup.string(),
    featureImageUrl: yup
      .string()
      .nullable()
      .transform((value, originalValue) => {
        if (!originalValue) {
          return null;
        }
        return value;
      }),
  })
  .camelCase();

export interface CMSBlogBlock {
  type: "recent_blog";
  title: string;
  items: Blog[];
}

const CMSBlogBlockSchema: yup.Schema<CMSBlogBlock> = yup
  .object({
    type: yup
      .string()
      .matches(/recent_blog/)
      .required() as yup.Schema<"recent_blog">,
    title: yup.string(),
    items: yup.array().of(BlogSchema),
  })
  .camelCase();

/**
 * Category bricks
 */
export interface CMSCateogryBrick {
  id: string;
  name: string;
  categoryThumbnail: string | null;
  backgroundColor1: string | null;
  backgroundColor2: string | null;
}

const CMSCateogryBrickSchema: yup.Schema<CMSCateogryBrick> = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
    categoryThumbnail: yup.string().nullable(),
    backgroundColor1: yup.string().nullable(),
    backgroundColor2: yup.string().nullable(),
  })
  .camelCase();

export interface CMSCateogryBricksBlock {
  type: "category_bricks";
  items: CMSCateogryBrick[];
}

const CMSCateogryBricksBlockSchema: yup.Schema<CMSCateogryBricksBlock> = yup
  .object({
    type: yup.string().matches(/category_bricks/) as yup.Schema<
      "category_bricks"
    >,
    items: yup.array().of(CMSCateogryBrickSchema),
  })
  .camelCase();

/**
 * Export
 */

export type CMSBlock =
  | CMSCarousel
  | CMSStaticBlock
  | CMSTopSellerHorizontalProductList
  | CMSProductsRecommendationProductList
  | CMSPopularProductsProductList
  | CMSBlogBlock
  | CMSCateogryBricksBlock;

export interface MatchedCMSBlock {
  cmsBlocks: CMSBlock[];
  matchId: string;
}

const ItemSchemaByType: IndexMap<string, yup.Schema<CMSBlock>> = {
  banner_rotator: CMSCarouselSchema,
  static_block: CMSStaticBlockSchema,
  top_sellers: CMSTopSellerHorizontalProductListSchema,
  products_recommendation: CMSProductsRecommendationProductListSchema,
  popular_products: CMSPopularProductsProductListSchema,
  recent_blog: CMSBlogBlockSchema,
  category_bricks: CMSCateogryBricksBlockSchema,
};

export function parseCMSBlockFromJSON(json: any): CMSBlock | null {
  if (json == null) {
    return null;
  }
  try {
    const ItemSchema = ItemSchemaByType[json.type];
    if (ItemSchema == null) {
      return null;
    }
    const cmsBlock = ItemSchema.validateSync(json);
    if (
      cmsBlock.type === "products_recommendation" ||
      cmsBlock.type === "top_sellers" ||
      cmsBlock.type === "popular_products"
    ) {
      return {
        ...cmsBlock,
        items:
          Config.MAX_HORIZONTAL_LIST_ITEMS &&
          Config.MAX_HORIZONTAL_LIST_ITEMS > 0
            ? cmsBlock.items.slice(0, Config.MAX_HORIZONTAL_LIST_ITEMS)
            : cmsBlock.items,
      };
    }
    return cmsBlock;
  } catch (e) {
    if (process.env.NODE_ENV === "development") {
      console.warn("parseCMSPageContentFrom", e);
    }
    return null;
  }
}

export function parseCMSPageContentFromJson(json: any): CMSPageContent | null {
  const { items } = json;
  if (!Array.isArray(items)) {
    return null;
  }
  const cmsBlocks: CMSBlock[] = [];
  for (const item of items) {
    const cmsBlock = parseCMSBlockFromJSON(item);
    if (cmsBlock != null) {
      cmsBlocks.push(cmsBlock);
    }
  }
  return {
    items: cmsBlocks,
  };
}
