import React, { useMemo, useState } from "react";

interface Context {
  orderId: string | null;
  setOrderId: (orderId: string) => void;
}

export const OrderIDContext = React.createContext<Context>(null as any);

export const OrderIDProvider: React.FC = props => {
  const [orderId, setOrderId] = useState<string | null>(null);

  const contextValue = useMemo(
    () => ({
      orderId,
      setOrderId,
    }),
    [orderId, setOrderId]
  );

  return (
    <OrderIDContext.Provider value={contextValue}>
      {props.children}
    </OrderIDContext.Provider>
  );
};
