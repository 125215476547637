import {
  ProductType,
  ProductImage,
  CLClubPoint,
  PriceRange,
  Product360Image,
  ProductStockStatus,
  ThirdPartyProductDisplayType,
  ProductConfigurableAttributeOption,
  Product360ImageGraphQLAttributes,
  ProductImageGraphQLAttributes,
  RecurringConfiguration,
} from "./product";
import { MediaContent, MediaContentGraphQLAttributes } from "./Media";
import { MerchantPreview, MerchantPreviewGraphQLAttributes } from "./Merchant";
import { MoneyGraphQLAttributes } from "./Price";

import Config from "../Config";
import { RecurringConfigurationGraphQLAttributes } from "./RecurringConfiguration";

export interface ProductOverview {
  id: number;
  entityId: number;
  sku: string;
  name: string;
  type: ProductType;
  thumbnail: ProductImage | null;
  image: ProductImage | null;
  clubPoint: number;
  minClubPoint: number;
  extraClubpoints: number | null;
  clClubPoint?: CLClubPoint | null;
  priceRange: PriceRange | null;
  specialFromDateStr: string | null;
  specialToDateStr: string | null;
  newFromDateStr: string | null;
  newToDateStr: string | null;

  mediaContents: MediaContent[];
  magic360Images: Product360Image[];
  merchant: [MerchantPreview | null];

  rating: number;

  stockStatus: ProductStockStatus;

  manufacturerSuggestedRetailPrice?: string | null;

  variants?: ProductOverviewVariant[] | null;

  displayType?: ThirdPartyProductDisplayType | null;
  buttonUrl?: string | null;
  infoMessage?: string | null;

  enableClubProtection?: boolean | null;
  recurringConfiguration?: RecurringConfiguration | null;
}

export interface ProductOverviewVariant {
  product: ConfiguredProductOverview;
  attributes: ProductConfigurableAttributeOption[];
}

export interface ConfiguredProductOverview {
  id: number;
  entityId: number;
  sku: string;
  name: string;
  type: ProductType;
  thumbnail: ProductImage | null;
  image: ProductImage | null;
  clubPoint: number;
  minClubPoint: number;
  extraClubpoints: number | null;
  clClubPoint?: CLClubPoint | null;
  priceRange: PriceRange | null;
  specialFromDateStr: string | null;
  specialToDateStr: string | null;
  newFromDateStr: string | null;
  newToDateStr: string | null;

  mediaContents: MediaContent[];
  magic360Images: Product360Image[];
  merchant: [MerchantPreview | null];

  rating: number;

  stockStatus: ProductStockStatus;

  manufacturerSuggestedRetailPrice?: string | null;

  enableClubProtection?: boolean | null;
  recurringConfiguration?: RecurringConfiguration | null;
}

export const ProductOverviewVariantGrapQLAttributes = `
  product {
    id
    entityId: entity_id
    sku
    name
    type: type_id
    thumbnail {
      ${ProductImageGraphQLAttributes}
    }
    image {
      ${ProductImageGraphQLAttributes}
    }
    clubPoint: clubpoints
    minClubPoint: min_clubpoints
    extraClubpoints: extra_clubpoints
    clClubPoint: cl_clubpoints
    priceRange: price_range {
      minimumPrice: minimum_price {
        regularPrice: regular_price {
          ${MoneyGraphQLAttributes}
        }
        finalPrice: final_price {
          ${MoneyGraphQLAttributes}
        }
      }
      maximumPrice: maximum_price {
        regularPrice: regular_price {
          ${MoneyGraphQLAttributes}
        }
        finalPrice: final_price {
          ${MoneyGraphQLAttributes}
        }
      }
    }
    specialFromDateStr: special_from_date
    specialToDateStr: special_to_date
    newFromDateStr: new_from_date
    newToDateStr: new_to_date
    mediaContents: media_gallery_entries {
      ${MediaContentGraphQLAttributes}
    }
    magic360Images: magic360_image {
      ${Product360ImageGraphQLAttributes}
    }
    merchant {
      ${MerchantPreviewGraphQLAttributes}
    }
    rating
    stockStatus: stock_status
    manufacturerSuggestedRetailPrice: pro_msrp

    ${
      Config.ENABLE_CLUB_PROTECT
        ? "enableClubProtection: enable_club_protection"
        : ""
    }
  }
  attributes {
    label
    value: value_index
    code
  }
`;

export const ProductOverviewGraphQLAttributes = `
  id
  entityId: entity_id
  sku
  name
  type: type_id
  thumbnail {
    ${ProductImageGraphQLAttributes}
  }
  image {
    ${ProductImageGraphQLAttributes}
  }
  clubPoint: clubpoints
  minClubPoint: min_clubpoints
  extraClubpoints: extra_clubpoints
  clClubPoint: cl_clubpoints
  priceRange: price_range {
    minimumPrice: minimum_price {
      regularPrice: regular_price {
        ${MoneyGraphQLAttributes}
      }
      finalPrice: final_price {
        ${MoneyGraphQLAttributes}
      }
    }
    maximumPrice: maximum_price {
      regularPrice: regular_price {
        ${MoneyGraphQLAttributes}
      }
      finalPrice: final_price {
        ${MoneyGraphQLAttributes}
      }
    }
  }
  specialFromDateStr: special_from_date
  specialToDateStr: special_to_date
  newFromDateStr: new_from_date
  newToDateStr: new_to_date
  mediaContents: media_gallery_entries {
    ${MediaContentGraphQLAttributes}
  }
  magic360Images: magic360_image {
    ${Product360ImageGraphQLAttributes}
  }
  merchant {
    ${MerchantPreviewGraphQLAttributes}
  }
  rating
  stockStatus: stock_status
  manufacturerSuggestedRetailPrice: pro_msrp
  shortDescription: short_description {
    html
  }
  deliveryMethod: delivery_method

  ... on ConfigurableProduct {
    variants {
      ${ProductOverviewVariantGrapQLAttributes}
    }
  }
  ... on ThirdPartyProduct {
    displayType: display_type
    buttonUrl: button_url
    infoMessage: info_message
  }
  ${
    Config.ENABLE_CLUB_PROTECT
      ? "enableClubProtection: enable_club_protection"
      : ""
  }
  ${Config.ENABLE_SUBSCRIPTION ?
    `recurringConfiguration: recurring_configuration {
      ${RecurringConfigurationGraphQLAttributes}
      }`
    : ""
  }
`;