import React, {
  useState,
  useEffect,
  useMemo,
  useLayoutEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/react";
import CSRModal from "../CSRModal";
import { NavBar } from "../NavBar";
import {
  useLogout,
  useCustomer,
  useCSRUser,
  useLinkSocialAccountRequest,
  useUnlinkSocialAccountRequest,
} from "../../repository/AuthRepository";
import CLContent from "../CLContent";
import { LocalizedText } from "../../i18n/Localization";
import styles from "./styles.module.scss";
import { TitleBadge } from "./index";
import moment from "moment";
import { formatMoney } from "../../models/Price";
import { mapNullable } from "../../utils/type";
import {
  getCustomerLinkedSSOEmail,
  Customer,
  getCustomerProfilePicUrl,
} from "../../models/Customer";
import { SSOResponse, useLoginWithTheClub } from "../../useCase/AuthUseCase";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import LinkSocialAccountWidget from "../EditProfilePage/LinkSocialAccountWidget";
import { OAuthProvider } from "../../api/RESTful";
import cn from 'classnames';

interface CustomerModalProps {
  isOpen: boolean;
  onModalDismiss: () => void;
}

type Props = CustomerModalProps;

const CustomerModal: React.FC<Props> = ({ isOpen, onModalDismiss }) => {
  const logout = useLogout();
  const customer = useCustomer();
  const initialCustomer = useRef(customer);
  const unlinkSocialAccount = useUnlinkSocialAccountRequest();
  const loginWithTheClub = useLoginWithTheClub();
  const linkSocialAccount = useLinkSocialAccountRequest();
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const [isLoading, setLoading] = useState(false);

  const csrUser = useCSRUser();
  const [theClubAccountEmail, setTheClubAccountEmail] = useState<string | null>(
    mapNullable(initialCustomer.current, (c) =>
      getCustomerLinkedSSOEmail(c, "the-club")
    )
  );
  const updateSSOEmailsWithCustomer = useCallback(
    (customer: Customer) => {
      //   setIsFacebookAccountEmail(
      //     getCustomerLinkedSSOEmail(customer, "facebook")
      //   );
      setTheClubAccountEmail(getCustomerLinkedSSOEmail(customer, "the-club"));
      //   setGoogleAccountEmail(getCustomerLinkedSSOEmail(customer, "google"));
    },
    [setTheClubAccountEmail]
  );

  const connectSocialAccount = useCallback(
    async (ssoProvider: () => Promise<SSOResponse>) => {
      try {
        setLoading(true);
        const ssoResponse = await ssoProvider();
        const linkResult = await linkSocialAccount(
          ssoResponse.token,
          ssoResponse.provider
        );
        if (linkResult.success !== true) {
          if (linkResult.errorMessage) {
            //Sentry.captureMessage(linkResult.errorMessage);
          }
          presentLocalizedAlert({
            headerId: linkResult.errorMessage
              ? "page.edit_profile.link_account.error.email_registered"
              : "page.edit_profile.connect_account_error",
            messageId: "alert.error.message",
            buttons: [{ textMessageID: "try_again" }],
          });
        } else {
          if (linkResult.customer) {
            updateSSOEmailsWithCustomer(linkResult.customer);
          }
        }
      } catch {
        presentLocalizedAlert({
          headerId: "page.edit_profile.connect_account_error",
          buttons: [{ textMessageID: "try_again" }],
        });
      } finally {
        setLoading(false);
      }
    },
    [
      linkSocialAccount,
      presentLocalizedAlert,
      setLoading,
      updateSSOEmailsWithCustomer,
    ]
  );

  const disconnectSocialAccount = useCallback(
    async (provider: OAuthProvider) => {
      try {
        setLoading(true);
        const unlinkResult = await unlinkSocialAccount(provider);
        if (unlinkResult !== true) {
          presentLocalizedAlert({
            headerId: "page.edit_profile.disconnect_account_error",
            buttons: [{ textMessageID: "try_again" }],
          });
        } else {
          switch (provider) {
            case "facebook":
              //setIsFacebookAccountEmail(null);
              break;
            case "google":
              //setGoogleAccountEmail(null);
              break;
            case "the-club":
              setTheClubAccountEmail(null);
              break;
            default:
              break;
          }
        }
      } catch {
        presentLocalizedAlert({
          headerId: "page.edit_profile.disconnect_account_error",
          buttons: [{ textMessageID: "try_again" }],
        });
      } finally {
        setLoading(false);
      }
    },
    [
      unlinkSocialAccount,
      presentLocalizedAlert,
      setLoading,
      //setIsFacebookAccountEmail,
      //setGoogleAccountEmail,
      setTheClubAccountEmail,
    ]
  );

  const showDisconnectSocialAccountAlert = useCallback(
    (provider: OAuthProvider) => {
      const providerString =
        provider === "facebook"
          ? "Facebook"
          : provider === "google"
          ? "Google"
          : provider === "the-club"
          ? "The Club"
          : "";
      presentLocalizedAlert({
        messageId: "page.edit_profile.disconnect_account_confirm",
        messageArgs: {
          provider: providerString,
        },
        buttons: [
          {
            textMessageID: "page.edit_profile.disconnect_account_confirm.yes",
            handler: () => disconnectSocialAccount(provider),
          },
          {
            textMessageID: "page.edit_profile.disconnect_account_confirm.no",
          },
        ],
      });
    },
    [disconnectSocialAccount, presentLocalizedAlert]
  );

  const onLinkTheClubClick = useCallback(() => {
    if (!theClubAccountEmail) {
      connectSocialAccount(loginWithTheClub);
    } else {
      showDisconnectSocialAccountAlert("the-club");
    }
  }, [
    theClubAccountEmail,
    connectSocialAccount,
    loginWithTheClub,
    showDisconnectSocialAccountAlert,
  ]);

  return (
    <CSRModal
      className={styles.modal}
      isOpen={isOpen}
      onRequestDismiss={onModalDismiss}
      hideHeader={true}
    >
      <NavBar
        titleClassName={styles.modalHeader}
        headerTitle={
          <h4 style={{ color: "black", padding: "20px 0" }}>
            <LocalizedText messageID="user.profile.title" />
          </h4>
        }
        headerRight={
          <IonButton color="pink" onClick={() => logout()}>
            <LocalizedText messageID="page.settings.logout" />
          </IonButton>
        }
      />
      {customer && csrUser ? (
        <CLContent>
          <div className={styles.modalBody}>
            <div className={styles.grid}>
              <div className={styles.user}>
                <div className={styles.profileImage}>
                  {customer ? (
                    <div
                      className={styles.profile}
                      style={{
                        backgroundImage: `url(${
                          getCustomerProfilePicUrl(customer) ||
                          "./assets/images/Avator.png"
                        })`,
                      }}
                    ></div>
                  ) : (
                    <div
                      className={styles.profile}
                      style={{
                        backgroundImage: `url(./assets/images/Avator.png})`,
                      }}
                    ></div>
                  )}
                </div>
                <div className={styles.donation}>
                  <span className={styles.donationTitle}>
                    <LocalizedText messageID="user.profile.donation_summary" />
                  </span>
                  <span className={styles.donationAmount}>
                    {formatMoney({
                      currency: "$",
                      value:
                        csrUser.currentQuarterDonationSummary.totalDonation,
                    })}
                  </span>
                </div>
                {customer && (
                  <div className={styles.nameTitle}>
                    <h2>
                      {customer.firstname} {customer.lastname}{" "}
                    </h2>
                    <TitleBadge />
                  </div>
                )}
              </div>
            </div>
          </div>
        </CLContent>
      ) : null}
    </CSRModal>
  );
};
export default CustomerModal;
