//import "core-js/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import "whatwg-fetch";
import "intl";
import * as serviceWorker from './serviceWorker';
import "@codetrix-studio/capacitor-google-auth";
import App from './App';
import smoothscroll from 'smoothscroll-polyfill';

ReactModal.setAppElement("#root");
ReactDOM.render(<App />, document.getElementById('root'));
smoothscroll.polyfill();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

defineCustomElements(window);

serviceWorker.unregister();
