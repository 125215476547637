import React, { useMemo } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import { LocalizedText } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";
import { isSignInWithAppleSupported } from "../../CLPlugins/AppleSignIn";
import { isiOS } from "../../utils/Platform";
import Config from "../../Config";

interface OAuthButtonsProps {
  titleMessageId?: MessageID;
  showPromoText?: boolean;
  disabled?: boolean;
  onTheClubButtonClick: () => any;
  onFacebookButtonClick: () => any;
  // onGoogleButtonClick: () => any;
  onAppleSignInButtonClick: () => any;
}

const OAuthButtons: React.FC<OAuthButtonsProps> = (
  props: OAuthButtonsProps
) => {
  const {
    titleMessageId,
    showPromoText = true,
    disabled,
    onTheClubButtonClick,
    onFacebookButtonClick,
    // onGoogleButtonClick,
    onAppleSignInButtonClick,
  } = props;

  const showAppleSignInButton = useMemo(() => isSignInWithAppleSupported(), []);

  return (
    <div className={styles.oauthContainer}>
      {titleMessageId && (
        <div className={styles.loginTypeTitle}>
          <LocalizedText messageID={titleMessageId} />
        </div>
      )}
      <div
        className={cn(styles.oauthButtons, {
          [styles.disabled]: disabled,
          [styles.multiline]: isiOS(),
        })}
      >
        <div className={styles.oauthButtons1}>
          <div
            className={styles.oauthTheClubButton}
            onClick={onTheClubButtonClick}
          />
        </div>
        { false && <div className={styles.oauthButtons2}>
          {!(Config.HIDE_OAUTH_FOR_IOS && isiOS()) && (
            <div
              className={styles.facebookButton}
              onClick={onFacebookButtonClick}
            />
          )}
          {!(Config.HIDE_OAUTH_FOR_IOS && isiOS()) && showAppleSignInButton && (
            <div
              className={styles.appleButton}
              onClick={onAppleSignInButtonClick}
            />
          )}
        </div>
      }
      </div>
      { showPromoText ? 
        <div className={styles.oauthText}>
          <div className={styles.clubPointIcon} />
          <LocalizedText messageID="login.club_points_promo_text" />
        </div>: null
      }
    </div>
  );
};

export default OAuthButtons;
