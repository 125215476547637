import React, { useMemo, useState, useCallback } from "react";

import LoginModal from "./LoginModal";
import SignupModal from "./SignupModal";
import SignupToShopModal from "./SignupToShopModal";
import { useChain } from "../hook/utils";
import { useModalState } from "../hook/modal";
import { Customer } from "../models/Customer";
import { actionEvent, pageView } from "../utils/GTM";
import ForgotPasswordModal from "./ForgotPasswordModal";

function logCreateAccountButtonClick() {
  actionEvent("Login Create Account", "Click", "Switch-Btn Switch Signup");
}

function logLoginButtonClick() {
  actionEvent("Create Account", "Click", "Login Switch-Btn Swtich Signup");
}

interface LoginSignupModalContext {
  presentLoginModal: (
    onDidLogin?: ((customer: Customer) => void) | null
  ) => void;
  presentSignupModal: (
    onDidLogin?: ((customer: Customer) => void) | null
  ) => void;
  presentSignupToShopModal: (
    onDidLogin?: ((customer: Customer) => void) | null
  ) => void;
  presentForgotPasswordModal: () => void;
}

export const LoginSignupModalContext = React.createContext<
  LoginSignupModalContext
>(null as any);

export const LoginSignupModalProvider: React.FC = props => {
  const [
    isSignupToShopModalOpen,
    presentSignupToShopModal,
    dismissSignupToShopModal,
  ] = useModalState();

  const [
    isLoginModalOpen,
    presentLoginModal,
    dismissLoginModal,
  ] = useModalState();

  const [
    isSignupModalOpen,
    presentSignupModal,
    dismissSignupModal,
  ] = useModalState();

  const [
    isForgotPasswordModalOpen,
    presentForgotPasswordModal,
    dismissForgotPasswordModal,
  ] = useModalState();

  const [onDidLogin, setOnDidLoginCallback] = useState<
    ((customer: Customer) => void) | null
  >(null);

  const contextValue = useMemo(
    () => ({
      presentLoginModal: (
        onDidLogin: ((customer: Customer) => void) | null = null
      ) => {
        setOnDidLoginCallback(() => onDidLogin);
        presentLoginModal();
        pageView({ page: "User Login" });
      },
      presentSignupModal: (
        onDidLogin: ((customer: Customer) => void) | null = null
      ) => {
        setOnDidLoginCallback(() => onDidLogin);
        presentSignupModal();
      },
      presentSignupToShopModal: (
        onDidLogin: ((customer: Customer) => void) | null = null
      ) => {
        setOnDidLoginCallback(() => onDidLogin);
        presentSignupToShopModal();
      },
      presentForgotPasswordModal: () => {
        presentForgotPasswordModal();
      }
    }),
    [presentLoginModal, presentSignupToShopModal, presentForgotPasswordModal, presentSignupModal,]
  );

  const trackSignup = useCallback(() => {
    pageView({ page: "Create Account" });
  }, []);

  const trackPopUpLogin = useCallback(() => {
    actionEvent("Pop Up Signin", "Click", "Login");
  }, []);

  const trackPopUpSignUp = useCallback(() => {
    actionEvent("Pop Up Signin", "Click", "Sign Up");
  }, []);

  const handleRequestDismissSignupToShopModal = useCallback(() => {
    actionEvent("Pop Up Signin", "Click", "Shop For Now");
    dismissSignupToShopModal();
  }, [dismissSignupToShopModal]);

  const handleRequestDismissForgotPasswordModal = useCallback(() => {
    dismissForgotPasswordModal();
  }, [dismissForgotPasswordModal]);

  const onLoginClick = () => {
    dismissSignupToShopModal();
    presentLoginModal();
    trackPopUpLogin();
  }

  const onSignupClick = () => {
    dismissSignupToShopModal();
    presentSignupModal();
    trackPopUpSignUp();
  }

  const onCreateAccountButtonClick = () => {
    logCreateAccountButtonClick();
    dismissLoginModal();
    presentSignupModal();
    trackSignup();
  }
  const onForgotPaswordButtonClick = () => {
    dismissLoginModal();
    presentForgotPasswordModal();
  }

  const onLoginButtonClick = () => {
    logLoginButtonClick();
    dismissSignupModal();
    presentLoginModal();
  }
  

  return (
    <>
      <LoginSignupModalContext.Provider value={contextValue}>
        {props.children}
      </LoginSignupModalContext.Provider>
      { isSignupToShopModalOpen ?
        <SignupToShopModal
        isModalOpen={isSignupToShopModalOpen}
        onRequestDismiss={handleRequestDismissSignupToShopModal}
        onLoginClick={onLoginClick}
        onSignupClick={onSignupClick}
        /> : null
      }
      {
        isLoginModalOpen ? 
        <LoginModal
          isModalOpen={isLoginModalOpen}
          onRequestDismiss={dismissLoginModal}
          onCreateAccountButtonClick={onCreateAccountButtonClick}
          onForgotPaswordButtonClick={onForgotPaswordButtonClick}
          onDidLogin={onDidLogin}
        /> : null 
      }  
      <SignupModal
        isModalOpen={isSignupModalOpen}
        onRequestDismiss={dismissSignupModal}
        onLoginButtonClick={onLoginButtonClick}
        onDidLogin={onDidLogin}
      />
      {
        isForgotPasswordModalOpen ?
          <ForgotPasswordModal 
            isModalOpen={isForgotPasswordModalOpen} 
            onRequestDismiss={dismissForgotPasswordModal} />
          : null
      }
    </>
  );
};
