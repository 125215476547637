import * as yup from "yup";

import { IndexMap } from "../utils/type";

export type CountryID = string;
export type CountryCode = string;

export interface Country {
  id: CountryID;
  name: string;
  code: CountryCode;
}

export type RegionID = number;
export type RegionCode = string;

export interface Region {
  id: RegionID;
  code: RegionCode;
  name: string;
}

export type DistrictID = number;
export type DistrictName = string;

export interface District {
  id: DistrictID;
  regionId: RegionID;
  name: DistrictName;
}

export type RemoteCountry = Country & {
  regions: Region[];
};

export interface CountryRegions {
  countries: Country[];
  regionsByCountryID: IndexMap<CountryID, Region[]>;
}

export const CountryGraphQLAttributes = `
  id
  code: two_letter_abbreviation
  name: full_name_locale
  regions: available_regions {
    id
    code
    name
  }
`;

export const DistrictGraphQLAttributes = `
  id
  regionId: region_id
  name
`;

export const RegionSchema: yup.Schema<Region> = yup.object({
  id: yup.number(),
  code: yup.string(),
  name: yup.string(),
});

export const CountrySchema: yup.Schema<RemoteCountry> = yup.object({
  id: yup.string(),
  name: yup.string().transform(name => changeCountryNameIfNeeded(name)),
  code: yup.string(),
  regions: yup.array().of(RegionSchema),
});

export const DistrictSchema: yup.Schema<District> = yup.object({
  id: yup.number(),
  regionId: yup.number(),
  name: yup.string(),
});

export function transformRemoteCountries(
  remoteCountries: RemoteCountry[]
): CountryRegions {
  const res: CountryRegions = { countries: [], regionsByCountryID: {} };
  for (const rCountry of remoteCountries) {
    const { id, code, name, regions } = rCountry;
    res.countries.push({ id, code, name });
    res.regionsByCountryID[id] = regions;
  }
  return res;
}

function changeCountryNameIfNeeded(name: string): string {
  const changes: { [key: string]: string } = {
    [`中華人民共和國香港特別行政區`]: "香港",
    "Hong Kong SAR China": "Hong Kong",
  };

  return changes[name] ? changes[name] : name;
}
