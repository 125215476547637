import { hashQuery, getVariableValueList } from "./hashedQuery";

interface GraphQLRequest {
  operationName: string | null;
  variables: { [key in string]: any };
  query?: string;
}

export function getHashedRequestInfo(
  input: RequestInfo,
  _init?: RequestInit
): [RequestInfo, RequestInit | undefined] {
  if (!_init) {
    return [input, _init];
  }
  const init = Object.assign({}, _init);
  const { body } = init;
  if (!body || !isString(body)) {
    return [input, init];
  }
  let request: GraphQLRequest | null = null;
  try {
    const parsed = JSON.parse(body);
    const { operationName, variables, query } = parsed;
    request = {
      operationName: operationName || null,
      variables: variables || {},
      query,
    };
  } catch {
    console.warn("[CLGraphQLFetch]", "body cannot be parsed");
  }
  if (!request) {
    return [input, init];
  }
  if (!isString(input)) {
    return [input, init];
  }
  let chosenURI = input;
  if (request.query && !isMutation(request.query)) {
    const newURI = rewriteURIForHash(chosenURI, request);
    chosenURI = newURI;
    init.method = "GET";
    init.body = undefined;
    init.cache = "no-store";
  }
  return [chosenURI, init];
}

function getRegisterRequestInfo(
  input: RequestInfo,
  _init?: RequestInit
): [RequestInfo, RequestInit | undefined] {
  if (!_init) {
    return [input, _init];
  }
  const init = Object.assign({}, _init);
  const { body } = init;
  if (!body || !isString(body)) {
    return [input, init];
  }
  let request: GraphQLRequest | null = null;
  try {
    const parsed = JSON.parse(body);
    const { operationName, variables, query } = parsed;
    request = {
      operationName: operationName || null,
      variables: variables || {},
      query,
    };
  } catch {
    console.warn("[CLGraphQLFetch]", "body cannot be parsed");
  }
  if (!request) {
    return [input, init];
  }
  if (!isString(input)) {
    return [input, init];
  }
  let chosenURI = input;
  if (request.query && !isMutation(request.query)) {
    const newURI = rewriteURIForHash(chosenURI, request);
    chosenURI = newURI;
    init.method = "PUT";
    init.body = JSON.stringify({
      operationName: request.operationName,
      query: request.query,
    });
  }
  return [chosenURI, init];
}

export function makeCLGraphQLFetch(): typeof fetch {
  return async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    const [hashedRequestInfo, hashedRequestInit] = getHashedRequestInfo(
      input,
      init
    );
    const hashedRes = await fetch(hashedRequestInfo, hashedRequestInit);
    if (hashedRes.status === 410) {
      const [registerRequestInfo, registerRequestInit] = getRegisterRequestInfo(
        input,
        init
      );
      // Regardless of the register response
      await fetch(registerRequestInfo, registerRequestInit);
      return fetch(hashedRequestInfo, hashedRequestInit);
    }
    return hashedRes;
  };
}

// Rewrite request to support hash format in https://github.com/scandipwa/persisted-query
function rewriteURIForHash(chosenURI: string, request: GraphQLRequest): string {
  // Implement the standard HTTP GET serialization.
  const queryParams: string[] = [];
  const addQueryParam = (key: string, value: string) => {
    queryParams.push(`${key}=${encodeURIComponent(value)}`);
  };

  if (request.query) {
    const processed = request.query.replace(/\s+/g, " ");
    addQueryParam("hash", hashQuery(processed));
  }
  if (request.operationName) {
    addQueryParam("operationName", request.operationName);
  }
  if (request.variables) {
    const variableValueList = getVariableValueList(request.variables);
    for (const { variable, value } of variableValueList) {
      addQueryParam(variable, value);
    }
  }

  let fragment = "",
    preFragment = chosenURI;
  const fragmentStart = chosenURI.indexOf("#");
  if (fragmentStart !== -1) {
    fragment = chosenURI.substr(fragmentStart);
    preFragment = chosenURI.substr(0, fragmentStart);
  }
  const queryParamsPrefix = preFragment.indexOf("?") === -1 ? "?" : "&";
  const newURI =
    preFragment + queryParamsPrefix + queryParams.join("&") + fragment;
  return newURI;
}

function isString(maybeString: unknown): maybeString is string {
  return typeof maybeString === "string";
}

function isMutation(query: string): boolean {
  const normalizedQuery = query.trim();
  const regex = new RegExp("^mutation");
  const matched = regex.exec(normalizedQuery);
  return !!matched;
}
