
import React, { useState, useEffect, useCallback } from 'react';
import { IonModal, IonHeader, IonToolbar, IonTitle } from '@ionic/react';
import { ContentScrollProviderContext } from "../ContentScrollProvider";
import { useDebounce, useIsMountedRef } from '../../hook/utils';
import BackAndroidHandler from '../BackAndroidHandler';
import classnames from "classnames";
import styles from "./styles.module.scss";

type CSRModalProps = React.ComponentProps<typeof IonModal> & {
  title?: string;
  className?: string;
  hideHeader?: boolean;
  hasBackBtn?: boolean;
  hasCloseBtn?: boolean;
  custom?: React.ReactNode;
  onRequestDismiss: () => void;
};

const CSRModel: React.FC<CSRModalProps> = props => {
  const isMountedRef = useIsMountedRef();
  const { title, isOpen, className, onRequestDismiss, ref, hideHeader, custom, children, ...rest } = props;
  const { updateContentYScrollEnabled } = React.useContext(ContentScrollProviderContext);
  const debouncedIsOpen = useDebounce(isOpen, 100);
  const [isModalOpen, setModalIsOpen] = useState(isOpen);
  useEffect(() => {
    updateContentYScrollEnabled(!debouncedIsOpen);
  }, [debouncedIsOpen, updateContentYScrollEnabled]);

  useEffect(() => {
    if (debouncedIsOpen) {
      setModalIsOpen(true);
    }
  }, [debouncedIsOpen]);

  const onDidDismiss = useCallback(() => {
    //setModalIsOpen(false);
    onRequestDismiss();
  }, [onRequestDismiss]);

  const handleBackPress = useCallback(() => {
    //setModalIsOpen(false);
    onRequestDismiss();
    return true;
  }, [onRequestDismiss]);

  const onTriangleCloseClicked = useCallback(() => {
    if (!isMountedRef.current) return;
    return onDidDismiss();
  }, [isMountedRef]);

  return (
    <IonModal cssClass={classnames(styles.csrModal, className)} isOpen={debouncedIsOpen} onDidDismiss={onDidDismiss} {...rest}>

      { isModalOpen ? <div className={styles.csrModal__triangle} onClick={onTriangleCloseClicked}>
        <img className={styles.csrModal__close} src="/images/close.png" />
      </div> : null }
      { (!hideHeader && isModalOpen) ? <IonHeader className={styles.csrModal__header}>
        <IonToolbar className={styles.csrModal__toolbar}>          
          <div slot="end" style={{height: "102px"}}>
          </div>
          { title && 
            <IonTitle className="ion-text-center">{ title }</IonTitle>
          }
          {custom ? custom : null}
        </IonToolbar>
      </IonHeader> : null }
      {isModalOpen ? children : null}
      {isModalOpen ? (
        <BackAndroidHandler onBackPress={handleBackPress} />
      ) : null}
    </IonModal>
  );
}
export default React.memo(CSRModel);