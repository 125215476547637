import React, { useState, useCallback, useMemo } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import Input from "../Form/Input";
import { useIntl } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";

interface PasswordInputFieldProps {
  className?: string;
  placeholderID?: MessageID;
  titleMessageID?: MessageID;
  titleWidth?: number;
  errorMessageID?: MessageID;
  value: string;
  onChange: (value: string) => void;
  nextFieldRef?: React.RefObject<HTMLInputElement>;
}

const WrappedAccountPasswordInput = (
  props: PasswordInputFieldProps,
  ref?: React.Ref<HTMLInputElement>
) => {
  const {
    titleMessageID,
    titleWidth,
    errorMessageID,
    value,
    className,
    onChange,
    placeholderID,
    nextFieldRef,
  } = props;
  const [showPassword, setValue] = useState(false);
  const onInputChange = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange]
  );
  const inputType = useMemo(() => (showPassword ? "text" : "password"), [
    showPassword,
  ]);
  const onShowPasswordToggleClick = useCallback(() => {
    setValue(!showPassword);
  }, [setValue, showPassword]);
  const { translate } = useIntl();
  const errorMessage = useMemo(
    () => (errorMessageID ? translate(errorMessageID) : undefined),
    [translate, errorMessageID]
  );
  return (
    <div className={styles.passwordInputContainer}>
      <Input
        className={cn(className, styles.passwordInput)}
        type={inputType}
        titleMessageID={titleMessageID}
        titleWidth={titleWidth}
        errorMessage={errorMessage}
        placeholderId={placeholderID}
        value={value}
        onChange={onInputChange}
        autoComplete="off"
        autoCapitalize="off"
        autoCorrect="off"
        nextFieldRef={nextFieldRef}
        ref={ref}
      />
      <div
        className={cn(
          styles.toggleShowPasswordButton,
          showPassword && styles.showingPassword,
          errorMessage && styles.showError
        )}
        onClick={onShowPasswordToggleClick}
      />
    </div>
  );
};

export default React.forwardRef(WrappedAccountPasswordInput);
