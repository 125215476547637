import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import CLModal from "../CLModal";
import { LocalizedText } from "../../i18n/Localization";
import { pageView } from "../../utils/GTM";

interface Props {
  isModalOpen: boolean;
  onRequestDismiss: () => void;
  onLoginClick: () => void;
  onSignupClick: () => void;
}

const SignupToShopModal: React.FC<Props> = props => {
  const { isModalOpen, onRequestDismiss, onLoginClick, onSignupClick } = props;
  useEffect(() => {
    if (isModalOpen) {
      pageView({ page: "Pop Up Signin" });
    }
  }, [isModalOpen]);
  return (
    <CLModal
      cssClass={styles.modal}
      isOpen={isModalOpen}
      onRequestDismiss={onRequestDismiss}
    >
      <div className={styles.modalBody}>
        <div className={styles.title}>
          <LocalizedText messageID="signup_to_donate.title" />
        </div>
        <div className={styles.signupButton} onClick={onSignupClick}>
          <LocalizedText messageID="signup" />
        </div>
        <div className={styles.loginButton} onClick={onLoginClick}>
          <LocalizedText messageID="login" />
        </div>
        <div className={styles.skipButton} onClick={onRequestDismiss}>
          <LocalizedText messageID="cancel" />
        </div>
      </div>
    </CLModal>
  );
};

export default SignupToShopModal;
