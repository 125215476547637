import { Plugins } from "@capacitor/core";

export async function registeriBeacon(
  uuid: string,
  major?: number,
  minor?: number,
  identifier?: string
) {
  return Plugins.LocationPlugin.registerBeaconRegion({
    uuid,
    major,
    minor,
    identifier,
  });
}

export function listenRegionEnter(
  callback: (info: BeaconRegion) => void
): () => void {
  const listener = Plugins.LocationPlugin.addListener("enterRegion", callback);
  return () => {
    try {
      listener.remove();
    } catch {}
  };
}

export function listenRegionLeave(
  callback: (info: BeaconRegion) => void
): () => void {
  const listener = Plugins.LocationPlugin.addListener("exitRegion", callback);
  return () => {
    try {
      listener.remove();
    } catch {}
  };
}
