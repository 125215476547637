import React, { useContext, memo, useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { LocalizedText, LocaleContext, useIntl } from "../../i18n/Localization";
import { getStoreViewCodeForLocale, Locale } from '../../i18n/locale';
import { withProviders } from "../Provider";
import { ShoppingCartItemCountContext } from "../ShoppingCartItemCountProvider"; 
import ShoppingCartModal from "../ShoppingCartModal";

import { 
  useIsLoggedIn,
  useCustomer,
  useLogout,
  useLoginWithOAuthRequest,
  useLinkSocialAccountRequest,
  useCSRUser,
} from "../../repository/AuthRepository";
import './index.scss';

import {
  LoginSignupModalProvider,
  LoginSignupModalContext,
} from "../LoginSignupModalProvider";

import CustomerCard from '../CustomerCard';
import CLModal from '../CLModal';
import { UIVisibilityContext } from "../UIVisibilityProvider";
import { CityRefContext } from '../CityRefProvider';
import { genPathForShoppingCart } from '../../navigation/routes';
import { OurNavContext } from '../../our-navigation/OurNavContext';
import MessageModal from '../MessageModal';
import { useModalState } from '../../hook/modal';
import AboutModal from '../AboutModal';
import { RepositoryContext } from '../../repository/State';
import { useCartResource } from '../ShoppingCartModal/api';
import Config from '../../Config';

interface TopBarProp {
  style?: object;
}

const TopBar: React.FC<TopBarProp> = (props) => {
  const { INCENTIVE_LINK } = Config;
  const { state: {csr: { notifications }} } = useContext(RepositoryContext);
  const { cityRef } = useContext(CityRefContext);
  const customer  = useCustomer();
  const csrUser = useCSRUser();
  const { navigate } = useContext(OurNavContext);
  const [ messageCount, setMessageCount ] = useState(notifications.length);

  const { visibilities } = useContext(UIVisibilityContext);
  const { count: shoppingCartItemCount } = useContext(
    ShoppingCartItemCountContext
  );
  
  const { removeCouponCodeFromCart } = useCartResource();

  useEffect(() => {
    setMessageCount(notifications.length)
  }, [notifications]);

  const { locale: curLocale,  changeLocaleWithLoadingAndDestroyHistory: changeLocale } = useContext(LocaleContext);
  const { translate, locale } = useIntl();
  
  const switchLocale = async() => {
    let nextLocale = (curLocale == Locale.en) ? Locale.zhHant : Locale.en;
    await changeLocale(nextLocale);
    await removeCouponCodeFromCart();
  }

  const onClickShoppingCartButton = useCallback((isOpen) => {
    if (cityRef) {
      cityRef.current.componentRef.current.stop();      
    }
    navigate(genPathForShoppingCart());
    // setIsShoppingCartModalPresented(isOpen);
  }, [customer, genPathForShoppingCart]);

  const [
    isMessageModalOpen,
    presentMessageModal,
    dismissMessageModal,
  ] = useModalState();

  const [
    isAboutModalOpen,
    presentAboutModal,
    dismissAboutModal,
  ] = useModalState();

  const rewardLink = React.useMemo(() => {
    if (csrUser && csrUser.additionalInfo && csrUser.additionalInfo.rewardLink){
      let link = new URL(csrUser.additionalInfo.rewardLink);
      link.searchParams.set("___store", curLocale == Locale.en ? 'en_US':'zh_Hant_HK');
      return link.toString();
    } else {
      return null;
    }
  }, [csrUser, curLocale, Locale]);

  return (
    <>
      { visibilities.customerCard &&
        <div style={{ position: "fixed", top: 10, left: 10, zIndex: 3, width: "300px" }}>
          <CustomerCard></CustomerCard>
        </div>
      }
      <div className="menu-list">
        <ul className="menu-btns">
          { visibilities.cart &&
            <li className="menu-btn hover-glow" onClick={() => onClickShoppingCartButton(true)}>
              <img className="menu-icon-img" src={`${process.env.PUBLIC_URL}/assets/images/Icon/Cart.png`} alt="" />
              <div className="cricle-number"><span>{shoppingCartItemCount}</span></div>
            </li>
          }
          { visibilities.language &&
            <li className="menu-btn hover-glow"><img className="menu-icon-img" src={curLocale == Locale.en ? `${process.env.PUBLIC_URL}/assets/images/Icon/Language-Chinese.png` : `${process.env.PUBLIC_URL}/assets/images/Icon/Language-Eng.png` } alt="" onClick={() => switchLocale()} /></li>
          }
          { visibilities.info &&
            <li className="menu-btn hover-glow"><img className="menu-icon-img" src={`${process.env.PUBLIC_URL}/assets/images/Icon/Help.png`} alt="" onClick={presentAboutModal} /></li>
          }
          { visibilities.notification ? 
            <li className="menu-btn hover-glow" onClick={presentMessageModal}>
              <img className="menu-icon-img" src={`${process.env.PUBLIC_URL}/assets/images/Icon/mail.png`} alt="" />
              { false && messageCount > 0 ?
              <div className="cricle-number"><span>{messageCount}</span></div>      
              : null
              }
            </li>
            : null
          }
          { visibilities.gift ?
              rewardLink ?
              <li className="menu-btn hover-glow">
                <a target="_BLANK" href={`${rewardLink}`}>
                <img className="menu-icon-img" src={`${process.env.PUBLIC_URL}/assets/images/Icon/my-gift.png`} alt="" /></a></li> :
              <li className="menu-btn hover-glow" >
                <a target="_BLANK" href={`${INCENTIVE_LINK}?___store=${curLocale == Locale.en ? 'en_US':'zh_Hant_HK'}`}><img className="menu-icon-img" src={`${process.env.PUBLIC_URL}/assets/images/Icon/my-gift.png`} alt="" /></a></li>
            : null
          }
        </ul>
        
        { isMessageModalOpen ? 
          <MessageModal messages={notifications} isOpen={isMessageModalOpen} onRequestDismiss={dismissMessageModal} />
          : null
        }
        
        { isAboutModalOpen ? 
          <AboutModal isOpen={isAboutModalOpen} onRequestDismiss={dismissAboutModal} />
          : null
        }
      </div>
  </>)
}

export default memo(withProviders(TopBar, LoginSignupModalProvider));