import React, { useRef, MutableRefObject, createContext, useState, useMemo } from "react";
import City from "./City";
interface CityRefProviderContext {
  cityRef: MutableRefObject<any> | null;
  updateCityRef: (ref: MutableRefObject<any>) => void;
}

const cityRefProviderContext: CityRefProviderContext = {
  cityRef: null,
  updateCityRef: () => {},
};


export const CityRefContext = createContext(cityRefProviderContext);

export const CityRefProvider: React.FC = props => {
  const [ cityRef, setCityRef ] = useState(null);

  const updateCityRef = (ref: any) => {
    setCityRef(ref);
  }

  const value = useMemo(() => {
    return {
      cityRef,
      updateCityRef,
    }
  }, [cityRef, updateCityRef]);

  return (
    <CityRefContext.Provider value={value}>
      { props.children }
    </CityRefContext.Provider>
  )

  
}