import { useCallback, useContext } from "react";
import { IfMagentoVersionFn } from "../models/MagentoVersion";
import { MagentoVersionContext } from "../components/MagentoVersionProvider";

type MagentoVersionFn<T> = (
  ifMagentoVersion: IfMagentoVersionFn,
  ...args: any
) => Promise<T>;

type MagentoVersionFnParams<T, F extends MagentoVersionFn<T>> = F extends (
  ifMagentoVersion: IfMagentoVersionFn,
  ...args: infer A
) => Promise<T>
  ? A
  : never;

export function useMagentoVersionFn<T, F extends MagentoVersionFn<T>>(fn: F) {
  const { ifMagentoVersion } = useContext(MagentoVersionContext);
  return useCallback(
    (...args: MagentoVersionFnParams<T, F>): ReturnType<F> => {
      return fn(ifMagentoVersion, ...args) as any;
    },
    [ifMagentoVersion, fn]
  );
}
