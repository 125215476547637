import React, { lazy, useContext, useCallback } from "react";
import { Router, Redirect, Switch } from "react-router";
import { BrowserRouter, Route } from "react-router-dom";
import { IonContent, IonPage, IonRouterOutlet } from "@ionic/react";
import Header from "../Header";

import {
  PresentationContextProvider,
  OurNavManager,
  OurTabs,
  OurRouterOutlet,
  OurNavContext,
  PresentationRoutes as OurPresentationRoutes,
  OurRouterOutletContext,
  ParentNavContextProvider,
  PresentationBridge,
} from "../../our-navigation";

import {
  genPathForShoppingCart,
  getRouteDefinitionForCheckoutFillingInfo,
  getRouteDefinitionForCheckoutSelectO2oStore,
  getRouteDefinitionForCheckoutConfirmation,
  getRouteDefinitionForCheckoutOrdered,
  getRouteDefinitionForAddressForm,
  getRouteDefinitionForForgotPassword,
  getRouteDefinitionForMPOSPayPayment,
} from "../../navigation/routes";

import OnAppLoad from "../OnAppLoad";
import TopBar from "../../components/TopBar";
import ForgotPasswordPage from "../../components/ForgotPasswordPage";
import CSRLoadingModal from "../CSRLoadingModal";

import { UIVisibilityProvider } from "../UIVisibilityProvider";
import { IonReactRouter } from "@ionic/react-router";
import BackAndroidHandler from "../BackAndroidHandler";
import { CityRefProvider } from "../CityRefProvider";

import DeepLinkHandler from "../DeepLinkHandler";
import VerifyEmailProvider from "../VerifyEmailProvider";
import OppPaymentVerifyProvider from "../OppPaymentVerifyProvider";
import { useIntl } from "../../i18n/Localization";
import { Helmet } from "react-helmet";
import { useModalState } from "../../hook/modal";

const LandingPage = lazy(() => import("../../pages/Landing"));
const QuickDonationPage = lazy(() => import("../../pages/QuickDonation"));
const FillingInfo = lazy(() => import("../Checkout/FillingInfo"));
const TownPage = lazy(() => import("../../pages/Town"));
const Home = lazy(() => import("../../pages/Home"));
const ZonePage = lazy(() => import("../../pages/ZonePage"));
const NGOPage = lazy(() => import("../../pages/NGOPage"));
const ShoppingCartPage = lazy(() => import("../ShoppingCartPage"));
const CheckoutConfirmationPage = lazy(
  () => import("../CheckoutConfirmationPage")
);
const CheckoutOrderedPage = lazy(() => import("../CheckoutOrderedPage"));
const MyOrdersPage = lazy(() => import("../MyOrdersPage"));
const OrderDetailPage = lazy(() => import("../OrderDetailPage"));
const DonationStatisticsPage = lazy(() => import("../DonationStatisticsPage"));
const ProfilePage = lazy(() => import("../ProfilePage"));

const DefaultPopOnAndroidBack: React.FC = () => {
  const { goBack } = useContext(OurNavContext);
  const onBackPress = useCallback(() => {
    goBack();
    return true;
  }, [goBack]);
  return <BackAndroidHandler onBackPress={onBackPress} />;
};
/*
const PresentationRoutes: React.FC = () => {
  return (
  <OurPresentationRoutes>
    <Route
        path={genPathForShoppingCart()} // `/shopping-cart`
        component={ShoppingCartModal}
        exact={true}
      />
    <Route
        path={getRouteDefinitionForCheckoutFillingInfo()} // "/cart/checkout"
        component={FillingInfo}
        exact={true}
      />
     <Route
        path={getRouteDefinitionForCheckoutSelectO2oStore()} //"/cart/select-o2o-store"
        component={CheckoutSelectO2oStorePage}
        exact={true}
      />
      <Route
        path={getRouteDefinitionForCheckoutConfirmation()} // "/cart/confirmation"
        component={CheckoutConfirmationPage}
        exact={true}
      />
      <Route
        path={getRouteDefinitionForCheckoutOrdered()}
        component={CheckoutOrderedPage} // "/cart/ordered"
        preventGoBack={true}
        exact={true}
      />

      <Route
        path={getRouteDefinitionForAddressForm()} //`/my-delivery/:id`
        component={AddressFormModal}
        exact={true}
      />

      <Route
        path={getRouteDefinitionForForgotPassword()} // `/forgot-password`
        component={ForgotPasswordPage}
        exact={true}
      />

      <Route
        path={getRouteDefinitionForMPOSPayPayment()} // "/cart/mpospay-payment/:orderID"
        component={MPOSPayPaymentPage}
        exact={true}
      />
  </OurPresentationRoutes>)
}
*/

const CsrNavigator: React.FC = () => {
  const { translate } = useIntl();
  const [
    isLoadingModalOpen,
    presentLoadingModal,
    dismissLoadingModal,
  ] = useModalState();
  return (
    <IonReactRouter>
      <Helmet
        defaultTitle={translate("seo.default.title")}
        titleTemplate={`%s | ${translate("seo.default.title")}`}
      >
        <meta
          name="description"
          content={translate("seo.default.meta.description")}
        />
        <meta property="og:title" content={translate("seo.default.title")} />
        <meta
          property="og:site_name"
          content={translate("seo.default.title")}
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:description"
          content={translate("seo.default.meta.description")}
        />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`${window.location.href}?lang=zh-hant`} />
        <link
          rel="alternate"
          hrefLang={"en"}
          href={`${window.location.href}?lang=en`}
        />
      </Helmet>

      <PresentationContextProvider>
        <OurNavManager>
          <OppPaymentVerifyProvider>
            <VerifyEmailProvider>
              <CityRefProvider>
                <UIVisibilityProvider>
                  <DeepLinkHandler />
                  <OnAppLoad></OnAppLoad>
                  <ParentNavContextProvider>
                    <OurPresentationRoutes>
                      <Route
                        path={`${process.env.PUBLIC_URL}/forgot-password`}
                        component={ForgotPasswordPage}
                      />
                    </OurPresentationRoutes>
                  </ParentNavContextProvider>
                  <IonPage>
                    <React.Suspense
                      fallback={<CSRLoadingModal isOpen={isLoadingModalOpen} />}
                    >
                      <IonContent>
                        <TopBar
                          style={{
                            "--background": "transparent",
                            zIndex: 3,
                          }}
                        />
                        <IonRouterOutlet>
                          <Route
                            path={`${process.env.PUBLIC_URL}/`}
                            component={LandingPage}
                            exact={true}
                          />
                          <Route path={`${process.env.PUBLIC_URL}/quick-donate`}
                            component={QuickDonationPage}
                            exact={true} />
                          <Route path={`${process.env.PUBLIC_URL}/donation`}
                            component={QuickDonationPage}
                            exact={true} />
                          <Route
                            path={`${process.env.PUBLIC_URL}/town`}
                            component={TownPage}
                            exact={true}
                          />
                          <Route path="/home" component={Home} exact={true} />
                          <Route
                            path={`${process.env.PUBLIC_URL}/zone/:zoneSlug`}
                            component={ZonePage}
                            exact={true}
                          />
                          <Route
                            path={`${process.env.PUBLIC_URL}/zone/:zoneSlug/:ngoSlug/:ngoEntityId`}
                            component={NGOPage}
                          />
                          <Route
                            path={`${process.env.PUBLIC_URL}/shopping-cart`}
                            component={ShoppingCartPage}
                            exact={true}
                          />
                          <Route
                            path={`${process.env.PUBLIC_URL}/my-orders`}
                            component={MyOrdersPage}
                            exact={true}
                          />
                          <Route
                            path={`${process.env.PUBLIC_URL}/statistics`}
                            component={DonationStatisticsPage}
                            exact={true}
                          />
                          <Route
                            path={`${process.env.PUBLIC_URL}/profile`}
                            component={ProfilePage}
                            exact={true}
                          />
                          <Route
                            path={`${process.env.PUBLIC_URL}/cart/checkout`}
                            component={FillingInfo}
                            exact={true}
                          />
                          <Route
                            path={`${process.env.PUBLIC_URL}/cart/confirmation/:checkoutResult?`}
                            component={CheckoutConfirmationPage}
                          />
                          <Route
                            path={`${process.env.PUBLIC_URL}/order-detail/:id`}
                            component={OrderDetailPage}
                            exact={true}
                          />
                          <Route
                            path={`${process.env.PUBLIC_URL}/cart/ordered`}
                            component={CheckoutOrderedPage}
                            preventGoBack={true}
                            exact={true}
                          />
                        </IonRouterOutlet>
                      </IonContent>
                    </React.Suspense>
                  </IonPage>
                </UIVisibilityProvider>
              </CityRefProvider>
            </VerifyEmailProvider>
          </OppPaymentVerifyProvider>
        </OurNavManager>
      </PresentationContextProvider>
    </IonReactRouter>
  );
};

export default CsrNavigator;
