import React, { useEffect, useRef, useContext, useState } from "react";
import CSRModal from "../CSRModal";
import styles from "./styles.module.scss";
import { LocalizedText, LocaleContext } from "../../i18n/Localization";
import CLContent from "../CLContent";
import { useFetchCMSStaticBlockContents } from "../../repository/CMSPageContentRepository";
import { getResources } from "../../models/ResourcesRequestState";
import { useFillSrcUrlScheme } from "../../utils/FillSrcUrlScheme";
import useExtractScriptsAndStyles from "../../hook/useExtractScriptsAndStyles";
import useRegisterPublishDeepLinkOnHTMLContent from "../../hook/useRegisterPublishDeepLinkOnHTMLContent";
import { Locale } from "../../i18n/locale";
import { createPortal } from "react-three-fiber";

type AboutModalProps = {
  isOpen: boolean;
  onRequestDismiss: () => void; 
}

const AboutModal: React.FC<AboutModalProps> = (props) => {
  const { isOpen, onRequestDismiss} = props;
  const { locale: curLocale } = useContext(LocaleContext);
  const identifiers = curLocale == Locale.en ?["club_hope_question_mark_content_eng"]:["club_hope_question_mark_content_chi"];

  const cmsStaticBlockContentsRequestState = useFetchCMSStaticBlockContents(
    identifiers
  );
  const cmsStaticBlockContents = getResources(
    cmsStaticBlockContentsRequestState
  );
  return (
    <CSRModal
      isOpen={isOpen}
      hideHeader={true}
      onRequestDismiss={onRequestDismiss}
      >
      <CLContent scrollY={true}>
        <div className={styles.modalBody}>
          { cmsStaticBlockContents && cmsStaticBlockContents.length > 0 ? 
              cmsStaticBlockContents.map(content => (
                content.contentForApp ? <IFrame key={content.identifier} htmlContent={content.contentForApp}></IFrame>
                :
                null
              ))
          : null }
        </div>
      </CLContent>
    </CSRModal>
  )
}


export default AboutModal;

const IFrame:React.FC<{
  htmlContent: string
}> = ({ htmlContent }) => {
  const contentRef = useRef<HTMLIFrameElement>(null);

  const html = useFillSrcUrlScheme(
    useExtractScriptsAndStyles(contentRef, htmlContent)
  );
  useRegisterPublishDeepLinkOnHTMLContent(contentRef, [html]);
  
  return (
    <iframe style={{ width: "100%", height: "100%", border: 0, }} ref={contentRef} srcDoc={html}></iframe>
  )
  
}
