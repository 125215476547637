import { signIn, isSignInWithAppleSupported } from "../CLPlugins/AppleSignIn";

interface AppleSignInResponse {
  token?: string;
  cancelled: boolean;
}

export async function presentAppleSignInPopup(): Promise<AppleSignInResponse> {
  if (isSignInWithAppleSupported()) {
    try {
      const result = await signIn();
      if (result) {
        return {
          token: result.authorizationCode,
          cancelled: false,
        };
      }
    } catch (e) {
      if (e.message === "cancelled") {
        return {
          cancelled: true,
        };
      }
      throw new Error("cannot-signup");
    }
  }
  throw new Error("apple-sigin-not-support");
}
