import { PartialOrder } from "./models";
import { ApolloClient, gql } from "apollo-boost";
import { Locale, getStoreViewCodeForLocale } from "../../i18n/locale";

const orderGraphQL = `
incrementID: increment_id
currencyCode: currency_code
subtotal
discountDescription: discount_description
discountAmount: discount_amount
shippingAmount: shipping_amount
paymentMethod: payment_method
paymentMethodCode: payment_method_code
items {
  name
  sku
  simpleName: simple_name
  simpleSku: simple_sku
  subtotal: row_total
  qtyOrdered: qty_ordered
}
`;

export async function fetchOrder(
  client: ApolloClient<any>,
  locale: Locale,
  id: string
): Promise<PartialOrder> {
  const result = await client.query<{ salesOrder: PartialOrder }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryOrder($incrementID: String!) {
        salesOrder(increment_id: $incrementID) {
          ${orderGraphQL}
        }
      }
    `,
    variables: {
      incrementID: id,
    },
    fetchPolicy: "network-only",
  });

  return result.data.salesOrder;
}
