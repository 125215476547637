import { useEffect, useState } from "react";

import { getDisplayLanguage } from "../storage";

import Config from "../Config";
import { useLocation } from "react-router";

export enum Locale {
  en = "en",
  zhHant = "zh-hant",
}

export const getQueryParams = ( params: string, url: string ) => {
  
  let href = url;
  //this expression is to get the query strings
  let reg = new RegExp( '[?&]' + params + '=([^&#]*)', 'i' );
  let queryString = reg.exec(href);
  return queryString ? queryString[1] : null;
};

export function removeURLParameter( parameter:string, url:string) {
  //prefer to use l.search if you have a location/link object
  var urlparts= url.split('?');   
  if (urlparts.length >= 2) {

      var prefix= encodeURIComponent(parameter)+'=';
      var pars= urlparts[1].split(/[&;]/g);

      //reverse iteration as may be destructive
      for (var i= pars.length; i-- > 0;) {    
          //idiom for string.startsWith
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
              pars.splice(i, 1);
          }
      }

      url= urlparts[0]+ (pars.length ? '?'+pars.join('&'): "");
      return url;
  } else {
      return url;
  }
}


export type DataByLocale<T> = { [key in Locale]: T };

export async function getLocale(): Promise<Locale> {
  const locale = await getDisplayLanguage();
  if (locale != null) {
    return locale;
  }

  return Locale.zhHant;
}

export function getStoreViewCodeForLocale(locale: Locale): string {
  switch (locale) {
    case Locale.en:
      return Config.STORE_VIEW_CODE.EN_US;
    case Locale.zhHant:
      return Config.STORE_VIEW_CODE.ZH_HANT_HK;
    default:
      return Config.STORE_VIEW_CODE.ZH_HANT_HK;
  }
}

export function useLocale(): Locale | null {
  const [locale, setLocale] = useState<Locale | null>(null);
  const currentUrl = window.location.href;
  const lang = getQueryParams("lang", currentUrl) as Locale;

  useEffect(() => {
    getLocale().then(locale => {
      if (lang && (lang == "en" || lang == "zh-hant")) {
        setLocale(lang);
        window.location.assign(removeURLParameter("lang", currentUrl));
      } else {
        setLocale(locale);
      }
    });
  }, []);

  return locale;
}

export function stringToLocale(value: string): Locale | null {
  switch (value) {
    case Locale.en:
      return Locale.en;
    case Locale.zhHant:
      return Locale.zhHant;
    default:
      return null;
  }
}

export function getLocaleDisplayText(locale: Locale): string {
  switch (locale) {
    case Locale.en:
      return "English";
    case Locale.zhHant:
      return "中文（繁體）";
    default:
      return "";
  }
}
