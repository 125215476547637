import { useEffect, useCallback, RefObject } from "react";
import {
  appEventEmitter,
  AppEventOnClickViewMore,
} from "../utils/SimpleEventEmitter";

export default function(
  htmlContentContainerRef: RefObject<HTMLElement>,
  changes: any[]
) {
  const onClickAnchor = useCallback((e: MouseEvent) => {
    const a = e.currentTarget as HTMLAnchorElement | null;
    if (!a) {
      return;
    }
    const url = a.getAttribute("href");
    if (!url) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    appEventEmitter.publish(AppEventOnClickViewMore(url));
  }, []);

  useEffect(() => {
    if (htmlContentContainerRef.current == null) {
      return;
    }
    const anchors = htmlContentContainerRef.current.querySelectorAll("a");
    anchors.forEach(a => {
      a.onclick = onClickAnchor;
    });
    return () => {
      anchors.forEach(a => (a.onclick = null));
    };
  }, [htmlContentContainerRef, onClickAnchor, ...changes]); // eslint-disable-line react-hooks/exhaustive-deps
}
