import {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
  useMemo,
} from "react";

import { useResourcesRequestState } from "../hook/ResourcesRequestHook";

import { RepositoryContext } from "./State";

import { ResourcesRequestState } from "../models/ResourcesRequestState";
import { useKeepUpdatingRef } from "../hook/utils";

export function useFetchResources<Resources>(
  {
    needStoreConfig,
    memoryCacheProvider,
    localCacheProvider,
    didFetchFromLocalCache,
    remoteResourcesProvider,
    didFetchFromRemote,
  }: {
    needStoreConfig: boolean;
    memoryCacheProvider: () => Resources | null;
    localCacheProvider: () => Promise<Resources | null>;
    didFetchFromLocalCache: (resources: Resources | null) => any;
    remoteResourcesProvider: () => Promise<Resources | null>;
    didFetchFromRemote: (resources: Resources | null) => any;
  },
  deps: any[]
): {
  requestState: ResourcesRequestState<Resources | null>;
  startRequesting: () => void;
  retry: () => void;
  stopRequesting: () => void;
} {
  const requestId = useRef(0);
  const [canStartRequesting, setCanStartRequesting] = useState(false);
  const startRequesting = useCallback(() => {
    setCanStartRequesting(true);
  }, [setCanStartRequesting]);
  const [cachedNeedStoreConfig] = useState(needStoreConfig);
  const [canRunEffect, setCanRunEffect] = useState(
    cachedNeedStoreConfig ? false : true
  );
  const [retryCount, setRetryCount] = useState(0);
  const retry = useCallback(() => {
    setRetryCount(c => c + 1);
  }, []);
  const { state } = useContext(RepositoryContext);
  const {
    state: resourceRequestState,
    willRequest,
    didRequest,
    didRejectRequest,
    stopRequest,
  } = useResourcesRequestState<Resources | null>();
  const stopRequesting = useCallback(() => {
    stopRequest();
    setCanStartRequesting(false);
  }, [setCanStartRequesting, stopRequest]);
  const { storeConfig } = state;
  useEffect(() => {
    if (storeConfig) {
      setCanRunEffect(true);
    }
  }, [storeConfig]);
  useEffect(() => {
    const currentRequestId = requestId.current;
    if (!canStartRequesting || !canRunEffect) {
      return;
    }
    const memoryCache = memoryCacheProvider();
    if (memoryCache) {
      didRequest("memory", memoryCache);
    }
    willRequest();
    localCacheProvider()
      .then(resources => {
        if (currentRequestId !== requestId.current) {
          return;
        }
        didFetchFromLocalCache(resources);
        if (resources) {
          didRequest("local-cache", resources);
        }
      })
      .catch(e => {
        if (currentRequestId !== requestId.current) {
          return;
        }

        console.log("localCacheProvider error", e);
      });
    remoteResourcesProvider()
      .then(resources => {
        if (currentRequestId !== requestId.current) {
          return;
        }
        didFetchFromRemote(resources);
        didRequest("remote", resources);
      })
      .catch(e => {
        if (currentRequestId !== requestId.current) {
          return;
        }
        didRejectRequest(e);
      });
    return () => {
      requestId.current += 1;
    };
  }, [canStartRequesting, canRunEffect, retryCount, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps
  return {
    requestState: resourceRequestState,
    startRequesting,
    retry,
    stopRequesting,
  };
}
type ResourcesProvider<T> = (...args: any) => Promise<T>;
type CacheResourcesProvider<
  T extends (...args: any) => Promise<any>
> = T extends (...args: infer P) => Promise<infer R>
  ? (...args: P) => Promise<R | null>
  : never;
type ExecutionSource = "all" | "remote";

export function useFetchResources_v2<
  Resources,
  RemoteProvider extends ResourcesProvider<Resources>
>({
  memoryCacheProvider,
  localCacheProvider,
  remoteResourcesProvider,
}: {
  memoryCacheProvider?: CacheResourcesProvider<RemoteProvider>;
  localCacheProvider?: CacheResourcesProvider<RemoteProvider>;
  remoteResourcesProvider: RemoteProvider;
}): [
  ResourcesRequestState<Resources>,
  {
    call: (...args: Parameters<RemoteProvider>) => Promise<Resources>;
    refresh: (...args: Parameters<RemoteProvider>) => Promise<Resources>;
  }
] {
  const requestId = useRef(0);
  const unmounted = useRef(false);
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const memoryCacheProviderRef = useKeepUpdatingRef(memoryCacheProvider);
  const localCacheProviderRef = useKeepUpdatingRef(localCacheProvider);
  const remoteResourcesProviderRef = useKeepUpdatingRef(
    remoteResourcesProvider
  );

  const {
    state: resourcesRequestState,
    willRequest,
    didRequest,
    didRejectRequest,
  } = useResourcesRequestState<Resources>();

  const execute = useCallback(
    async (
      source: ExecutionSource,
      ...args: Parameters<RemoteProvider>
    ): Promise<Resources> => {
      requestId.current += 1;
      const currentRequestId = requestId.current;
      if (memoryCacheProviderRef.current != null && source === "all") {
        memoryCacheProviderRef
          .current(...args)
          .then(resources => {
            if (unmounted.current || currentRequestId !== requestId.current) {
              return;
            }
            if (resources) {
              didRequest("memory", resources);
            }
          })
          .catch(e => {
            if (process.env.NODE_ENV === "development") {
              console.log("memoryCacheProvider error", e);
            }
          });
      }
      willRequest();
      if (localCacheProviderRef.current != null && source === "all") {
        localCacheProviderRef
          .current(...args)
          .then(resources => {
            if (unmounted.current || currentRequestId !== requestId.current) {
              return;
            }
            if (resources) {
              didRequest("local-cache", resources);
            }
          })
          .catch(e => {
            if (process.env.NODE_ENV === "development") {
              console.log("localCacheProvider error", e);
            }
          });
      }
      try {
        const resources = await remoteResourcesProviderRef.current(...args);
        if (!unmounted.current && currentRequestId === requestId.current) {
          didRequest("remote", resources);
        }
        return resources;
      } catch (e) {
        if (!unmounted.current && currentRequestId === requestId.current) {
          didRejectRequest(e);
        }
        throw e;
      }
    },
    [
      willRequest,
      didRequest,
      didRejectRequest,

      memoryCacheProviderRef,
      localCacheProviderRef,
      remoteResourcesProviderRef,
    ]
  );

  const call = useMemo(() => execute.bind(null, "all"), [execute]);
  const refresh = useMemo(() => execute.bind(null, "remote"), [execute]);

  return [resourcesRequestState, { call, refresh }];
}
