export function isUrl(str: string): boolean {
  try {
    const url = new URL(str);
    return !!url;
  } catch (e) {
    return false;
  }
}

export function parseQueryParameters(
  queryParamStr: string
): { [key in string]: string } {
  const query = queryParamStr.replace(/^\?/, "");
  const queryVars = query.split("&");
  const res: { [key in string]: string } = {};
  for (const queryVar of queryVars) {
    const [key, value] = queryVar.split("=");
    if (value != null) {
      res[key] = value;
    }
  }
  return res;
}
