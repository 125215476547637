import { EntityID } from "./models/Merchant";

interface Config {
  BUILD: string;
  FB_APP_ID: string;
  WEB_GTM_ID: string | undefined;
  ANDROID_GTM_ID: string | undefined;
  IOS_GTM_ID: string | undefined;
  GOOGLE_CLIENT_ID: string;
  SENTRY_DSN_IOS: string | undefined;
  SENTRY_DSN_ANDROID: string | undefined;
  SITE_URL: string;
  GRAPHQL_ENDPOINT: string;
  RESTAPI_ENDPOINT: string;
  DEEPLINK_URLS?: string[];
  CSR_RESTAPI_ENDPOINT: string;
  STORE_VIEW_CODE: {
    EN_US: string;
    ZH_HANT_HK: string;
  };
  DEFAULT_CATEGORY_ID: number;
  THE_CLUB_UAT_CONFIG: {
    AUTH_SERVER_URL: string;
    REALM: string;
    CLIENT_ID: string;
    REDIRECT_URI: string;
    BACK_URI: string;
  };
  THE_CLUB_LOGIN_URL: string;
  LIVE_CHAT_URL?: string;
  FOOTER_CMS_LINKS_REGEX_SHOULD_OPEN_IN_APP_BROWSER?: string[];
  IMAGE_WIDTH: number;
  IMAGE_HEIGHT: number;
  BITLY_ACCESS_TOKEN: string;
  BITLY_DOMAINS: string[];

  OPPPAYMENT_REDIRECT_SUCCESS_RESULT_URLS?: string[];
  OPPPAYMENT_REDIRECT_SUCCESS_URLS?: string[];
  OPPPAYMENT_REDIRECT_FAILED_URLS?: string[];

  MAX_RECENTLY_SEARCHES_TO_STORE: number;
  SEARCH_PRODUCT_SUGGESTION_LIMIT: number;
  WEB_APP_VERSION: string;
  WEB_BUILD_NUMBER: string;

  SHOW_CLUBPOINT_JOURNEY: boolean;

  MAX_HORIZONTAL_LIST_ITEMS?: number;

  SHOPPING_CART_ICON_ANIMATION_INTERVAL_IN_MS: number;

  HIDE_OAUTH_FOR_IOS: boolean;

  ERROR_RERENDER_LIMIT?: number;

  ADS?: {
    INTERSTITIAL?: string;
    BANNER?: {
      AD_UNIT_ID: string;
      SIZE: "normal" | "large";
    };
  };

  SHOW_ALERT_ON_SENTRY_ERROR: boolean;
  BEACON_REGIONS: BeaconRegion[];

  USE_PUSH: boolean;
  SHOW_ENVELOPE_ICON: boolean;
  OPNS_ANDROID_PLATFORM?: "GCM" | "FCM";

  TIMEZONE: string;

  VIRTUAL_WAITING_ROOM?: {
    ENDPOINT: string;
  };
  SIGN_UP_TERMS_AND_CONDITIONS_LINK?: string;
  SIGN_UP_PRIVACY_POLICY_LINK?: string;
  CLUB_PROTECT_POLICY_PROVISION_LINK?: string;
  CLUB_PROTECT_IMPORTANT_NOTES_LINK?: string;
  CLUB_PROTECT_PRODUCT_TERMS_AND_CONDITIONS_LINK?: string;
  CLUB_PROTECT_FAQ_LINK?: string;
  CLUB_PROTECT_SITE_FAQ_LINK?: string;
  ENABLE_SUBSCRIPTION?: boolean;
  ENABLE_SALE_AND_NEW_PRODUCT_TAG?: boolean;
  ENABLE_REBATE?: boolean;
  ENABLE_REDEMPTION_TAB?: boolean;
  ENABLE_HASHED_QUERY?: boolean;
  ENABLE_CLUB_PROTECT?: boolean;
  ENABLE_REBRAND?: boolean;
  ENABLE_BRAND?: boolean;
  INCENTIVE_LINK?: string;
  CLUBLIKE_CMS_ENDPOINT?: string;
  DONATION_SETTINGS: {
    min: number;
    max: number;
    stepping: number;
    default: number;
  };
  FREE_GIFT_VENDORS: [number];
  DONATION_MAPPING: {
    [key: number]: {
      oneoff: number,
      subscription: number,
    }
  };
}
const Config: Config = {
  ...window.appConfig,
  MAX_RECENTLY_SEARCHES_TO_STORE: 10,
  SEARCH_PRODUCT_SUGGESTION_LIMIT: 3,
  SHOW_CLUBPOINT_JOURNEY: false,
  SHOPPING_CART_ICON_ANIMATION_INTERVAL_IN_MS: 8000,
};

export default Config;
