import moment from "moment-timezone";

import { Money } from "./Price";

import { Override } from "../utils/type";

import Config from "../Config";

export type CustomerSubscriptionId = string;

export interface SubscriptionInfoAddress {
  city: string | null;
  country: string | null;
  firstname: string | null;
  lastname: string | null;
  middlename: string | null;
  postcode: string | null;
  prefix: string | null;
  region: string | null;
  street: string | null;
  suffix: string | null;
  telephone: string | null;
}

export interface SubscriptionInfoSubscription {
  delivery: string | null;
  paymentMethod: string | null;
  quantity: number | null;
  subscriptionId: CustomerSubscriptionId | null;
}

type Brand = "VISA" | "MASTERCARD";

export interface SubscriptionInfoOppCard {
  number: string;
  brand: Brand;
}

export interface CustomerSubscription {
  address: SubscriptionInfoAddress | null;
  endDate: Date | null;
  isActive: boolean;
  lastBilling: Date | null;
  lastBillingAmount: Money | null;
  nextBilling: Date | null;
  nextBillingAmount: Money | null;
  oppCard: SubscriptionInfoOppCard | null;
  orderId: string | null;
  startDate: Date | null;
  status: string | null;
  subscription: SubscriptionInfoSubscription | null;
  subscriptionName: string | null;
  trialStartDate: Date | null;
  trialEndDate: Date | null;
}

export type RemoteCustomerSubscription = Override<
  CustomerSubscription,
  {
    endDate: string | null;
    lastBilling: string | null;
    lastBillingAmount: string | null;
    nextBilling: string | null;
    nextBillingAmount: string | null;
    oppCard: string | null;
    startDate: string | null;
    trialStartDate: string | null;
    trialEndDate: string | null;
  }
>;

export function transformRemoteCustomerSubscriptionToCustomerSubscription(
  remoteCustomerSubscription: RemoteCustomerSubscription
): CustomerSubscription {
  const {
    endDate: _endDate, // 2020年6月18日 下午4:17:20
    lastBilling: _lastBilling, // 2020年6月18日 下午4:17:20
    lastBillingAmount: _lastBillingAmount, // HK$150.0
    nextBilling: _nextBilling, // 2020年6月18日 下午4:17:20
    nextBillingAmount: _nextBillingAmount, // HK$150.0
    oppCard: _oppCard, // 401200xxxxxx0026(VISA)
    startDate: _startDate, // 2020年6月18日 下午4:17:20
    trialStartDate: _trialStartDate, // 2020年6月18日 下午4:17:20
    trialEndDate: _trialEndDate, // 2020年6月18日 下午4:17:20
  } = remoteCustomerSubscription;

  const parseDate = (dateStr: string): Date | null => {
    // 2020年6月18日 下午4:17:20
    const m = moment.tz(
      dateStr,
      ["YYYY年M月D日 下午h:m:s", "YYYY年M月D日 上午h:m:s", "LLL"],
      Config.TIMEZONE
    );
    if (m.isValid()) {
      const isPM = dateStr.indexOf("下午") > -1 || dateStr.indexOf("PM") > -1;
      if (isPM) {
        return m.add(12, "hours").toDate();
      }
      return m.toDate();
    }
    return null;
  };

  const parseMoney = (moneyStr: string): Money | null => {
    // HK$150.0
    const regex = new RegExp("(.+)\\$([\\.,0-9]+)");
    const matched = regex.exec(moneyStr);
    if (matched) {
      const value = parseFloat(matched[2].replace(",", ""));
      if (isNaN(value)) {
        return null;
      }
      return {
        currency: matched[1],
        value,
      };
    }
    return null;
  };

  const parseOppCard = (oppCardStr: string): SubscriptionInfoOppCard | null => {
    // 401200xxxxxx0026(VISA)
    const regex = new RegExp("(.+)\\((.+)\\)");
    const matched = regex.exec(oppCardStr);
    if (matched) {
      return {
        number: matched[1],
        brand: matched[2] as Brand,
      };
    }
    return null;
  };

  const endDate = _endDate ? parseDate(_endDate) : null;

  const lastBilling = _lastBilling ? parseDate(_lastBilling) : null;

  const lastBillingAmount = _lastBillingAmount
    ? parseMoney(_lastBillingAmount)
    : null;

  const nextBilling = _nextBilling ? parseDate(_nextBilling) : null;

  const nextBillingAmount = _nextBillingAmount
    ? parseMoney(_nextBillingAmount)
    : null;

  const oppCard = _oppCard ? parseOppCard(_oppCard) : null;

  const startDate = _startDate ? parseDate(_startDate) : null;

  const trialStartDate = _trialStartDate ? parseDate(_trialStartDate) : null;

  const trialEndDate = _trialEndDate ? parseDate(_trialEndDate) : null;

  return {
    ...remoteCustomerSubscription,
    endDate,
    lastBilling,
    lastBillingAmount,
    nextBilling,
    nextBillingAmount,
    oppCard,
    startDate,
    trialStartDate,
    trialEndDate,
  };
}

export const CustomerSubscriptionGraphQLAttributes = `
address {
  city
  country
  firstname
  lastname
  middlename
  postcode
  prefix
  region
  street
  suffix
  telephone
}
endDate: end_date
isActive: is_active
lastBilling: last_billing
lastBillingAmount: last_billing_amount
nextBilling: next_billing
nextBillingAmount: next_billing_amount
oppCard: opp_card
orderId: order_increment_id
startDate: start_date
status
subscription {
  delivery
  paymentMethod: payment_method
  quantity: qty
  subscriptionId: subscription_id
}
subscriptionName: subscription_name
trialEndDate: trial_end_date
trialStartDate: trial_start_date
`;
