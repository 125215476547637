import { IndexMap } from "./type";

class PromiseCache {
  private cache: IndexMap<string, Promise<any>> = {};

  withCache = <T>(key: string, promiseFactory: () => Promise<T>) => {
    if (this.cache[key] != null) {
      return this.cache[key] as Promise<T>;
    }
    const promise = promiseFactory();
    this.cache[key] = promise;
    return promise;
  };

  clearCache = (key: string) => {
    this.cache[key] = undefined;
  };
}

export default PromiseCache;
