/**
 * TODO: (Steven-Chan)
 * I can't import the library semver correctly, so I just implement one here
 * which only handles 'x.x.x.x.x.x' pattern but not beta / alpha / pre-release
 *
 * If one is '1.0' and another is '1.0.0', the shorter one would get substitue
 * with '0', so '1.0' would treat as '1.0.0'
 */
export function semverLt(v1: string, v2: string): boolean {
  const v1v = v1.split(".");
  const v2v = v2.split(".");

  const splitLength = Math.max(v1v.length, v2v.length);
  for (let i = 0; i < splitLength; i++) {
    const v1value = i > v1v.length ? 0 : parseInt(v1v[i], 10);
    const v2value = i > v2v.length ? 0 : parseInt(v2v[i], 10);
    if (v1value < v2value) {
      return true;
    }
  }

  return false;
}

export type SemverComparator = "<" | "<=" | ">" | ">=" | "=";

export function semverCmp(
  cmp: SemverComparator,
  v1: string,
  v2: string
): boolean {
  const v1v = v1.split(".");
  const v2v = v2.split(".");

  const splitLength = Math.max(v1v.length, v2v.length);
  for (let i = 0; i < splitLength; i++) {
    const v1value = i > v1v.length - 1 ? 0 : parseInt(v1v[i], 10);
    const v2value = i > v2v.length - 1 ? 0 : parseInt(v2v[i], 10);
    switch (cmp) {
      case "<": {
        if (i !== splitLength - 1 ? v1value > v2value : v1value >= v2value) {
          return false;
        }
        break;
      }
      case "<=": {
        if (v1value > v2value) {
          return false;
        }
        break;
      }
      case ">": {
        if (i !== splitLength - 1 ? v1value < v2value : v1value <= v2value) {
          return false;
        }
        break;
      }
      case ">=": {
        if (v1value < v2value) {
          return false;
        }
        break;
      }
      case "=": {
        if (v1value !== v2value) {
          return false;
        }
        break;
      }
      default:
        return false;
    }
  }

  return true;
}
