import { useContext, useEffect, useMemo } from "react";
import { ApolloClient, FetchPolicy } from "apollo-boost";
import { useApolloClient } from "@apollo/react-hooks";

import { useFetchResources, useFetchResources_v2 } from "./Hooks";
import { RepositoryContext, RepositoryState } from "./State";

import {
  CMS_PAGE_TYPE,
  HTML_BASED_CMS_PAGE_TYPE,
  CMSPageContent,
  CMSStaticBlockContent,
  CMSBlock,
  CMSStaticBlock,
  isCMSStaticBlock,
  HTMLBasedCMSPageContent,
  MatchedCMSBlock,
} from "../models/cmsBlock";
import { ResourcesRequestState } from "../models/ResourcesRequestState";

import { useIntl } from "../i18n/Localization";
import { fetchHomePageContent, restAPIClient } from "../api/RESTful";
import {
  fetchStaticCMSBlocksByIds,
  fetchHTMLBasedCMSPageContentByIdentifier,
} from "../api/GraphQL";

import { getHomePageContent, setHomePageContent } from "../storage";
import { useKeepUpdatingRef } from "../hook/utils";
import { Locale } from "../i18n/locale";
import { IndexMap } from "../utils/type";
import { extractCMSBlocksFromContentForApp } from "../utils/CMSBlockExtractor";

function selectCMSPageContent(
  state: RepositoryState,
  pageType: CMS_PAGE_TYPE
): CMSPageContent | null {
  if (pageType.type === "home") {
    return state.cms.pageContent[pageType.type] || null;
  }
  return null;
}

function selectHTMLBasedCMSPageContent(
  state: RepositoryState,
  pageType: HTML_BASED_CMS_PAGE_TYPE
): HTMLBasedCMSPageContent | null {
  return (
    state.cms.htmlBasedCMSPageContent[pageType.type][pageType.identifier] ||
    null
  );
}

async function fetchCategoryPageContent(
  client: ApolloClient<any>,
  identifier: string,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<HTMLBasedCMSPageContent | null> {
  const content = await fetchHTMLBasedCMSPageContentByIdentifier(
    client,
    {
      type: "cmsBlock",
      identifier,
    },
    locale,
    fetchPolicy
  );
  if (content == null) {
    return null;
  }
  // We will further resolve one more level of cms block
  const staticBlockIds = content.matchedCMSBlocks
    .map(m => m.cmsBlocks)
    .reduce((prev, curr) => [...prev, ...curr], [])
    .filter<CMSStaticBlock>(isCMSStaticBlock)
    .flatMap(staticBlock => staticBlock.items.map(i => i.identifier));

  const staticBlockContents =
    staticBlockIds.length > 0
      ? await fetchStaticCMSBlocksByIds(
          client,
          staticBlockIds,
          locale,
          fetchPolicy
        )
      : [];
  const staticBlockContentById: IndexMap<string, CMSStaticBlockContent> = {};
  for (const staticBlockContent of staticBlockContents) {
    staticBlockContentById[staticBlockContent.identifier] = staticBlockContent;
  }

  const replacedMatchedCMSBlocks: MatchedCMSBlock[] = [];

  for (const matchedCMSBlock of content.matchedCMSBlocks) {
    const replacedCMSBlocks: CMSBlock[] = [];
    for (const cmsBlock of matchedCMSBlock.cmsBlocks) {
      if (!isCMSStaticBlock(cmsBlock)) {
        replacedCMSBlocks.push(cmsBlock);
      } else {
        for (const staticBlockItem of cmsBlock.items) {
          const staticBlockContent =
            staticBlockContentById[staticBlockItem.identifier];
          if (
            staticBlockContent != null &&
            staticBlockContent.contentForApp != null
          ) {
            replacedCMSBlocks.push(
              ...extractCMSBlocksFromContentForApp(
                staticBlockContent.contentForApp
              )
            );
          } else {
            replacedCMSBlocks.push(cmsBlock);
          }
        }
      }
    }
    replacedMatchedCMSBlocks.push({
      cmsBlocks: replacedCMSBlocks,
      matchId: matchedCMSBlock.matchId,
    });
  }

  return {
    waitingToFillHTML: content.waitingToFillHTML,
    matchedCMSBlocks: replacedMatchedCMSBlocks,
  };
}

async function fetchMerchantPageContent(
  client: ApolloClient<any>,
  identifier: string,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<HTMLBasedCMSPageContent | null> {
  const content = await fetchHTMLBasedCMSPageContentByIdentifier(
    client,
    {
      type: "cmsPageStringId",
      identifier,
    },
    locale,
    fetchPolicy
  );
  if (content == null) {
    return null;
  }
  // We will further resolve one more level of cms block
  const staticBlockIds = content.matchedCMSBlocks
    .map(m => m.cmsBlocks)
    .reduce((prev, curr) => [...prev, ...curr], [])
    .filter<CMSStaticBlock>(isCMSStaticBlock)
    .flatMap(staticBlock => staticBlock.items.map(i => i.identifier));

  const staticBlockContents =
    staticBlockIds.length > 0
      ? await fetchStaticCMSBlocksByIds(
          client,
          staticBlockIds,
          locale,
          fetchPolicy
        )
      : [];
  const staticBlockContentById: IndexMap<string, CMSStaticBlockContent> = {};
  for (const staticBlockContent of staticBlockContents) {
    staticBlockContentById[staticBlockContent.identifier] = staticBlockContent;
  }

  const replacedMatchedCMSBlocks: MatchedCMSBlock[] = [];

  for (const matchedCMSBlock of content.matchedCMSBlocks) {
    const replacedCMSBlocks: CMSBlock[] = [];
    for (const cmsBlock of matchedCMSBlock.cmsBlocks) {
      if (!isCMSStaticBlock(cmsBlock)) {
        replacedCMSBlocks.push(cmsBlock);
      } else {
        for (const staticBlockItem of cmsBlock.items) {
          const staticBlockContent =
            staticBlockContentById[staticBlockItem.identifier];
          if (
            staticBlockContent != null &&
            staticBlockContent.contentForApp != null
          ) {
            replacedCMSBlocks.push(
              ...extractCMSBlocksFromContentForApp(
                staticBlockContent.contentForApp
              )
            );
          } else {
            replacedCMSBlocks.push(cmsBlock);
          }
        }
      }
    }
    replacedMatchedCMSBlocks.push({
      cmsBlocks: replacedCMSBlocks,
      matchId: matchedCMSBlock.matchId,
    });
  }

  return {
    waitingToFillHTML: content.waitingToFillHTML,
    matchedCMSBlocks: replacedMatchedCMSBlocks,
  };
}

export function useFetchCMSPageContent(): [
  ResourcesRequestState<CMSPageContent | null>,
  (pageType: CMS_PAGE_TYPE) => Promise<CMSPageContent | null>,
  (pageType: CMS_PAGE_TYPE) => Promise<CMSPageContent | null>
] {
  const { locale } = useIntl();
  const { state, dispatch } = useContext(RepositoryContext);

  const stateRef = useKeepUpdatingRef(state);
  const localeRef = useKeepUpdatingRef(locale);

  const [requestState, { call, refresh }] = useFetchResources_v2<
    CMSPageContent | null,
    (pageType: CMS_PAGE_TYPE) => Promise<CMSPageContent | null>
  >({
    memoryCacheProvider: (pageType: CMS_PAGE_TYPE) =>
      Promise.resolve(selectCMSPageContent(stateRef.current, pageType)),
    localCacheProvider: async (pageType: CMS_PAGE_TYPE) => {
      const cmsContent = await (async () => {
        if (pageType.type === "home") {
          return getHomePageContent(localeRef.current);
        }
        return null;
      })();
      if (cmsContent) {
        dispatch({
          type: "UpdateCMSPageContent",
          pageType,
          content: cmsContent,
        });
      }
      return cmsContent;
    },
    remoteResourcesProvider: async (pageType: CMS_PAGE_TYPE) => {
      const cmsContent = await (async () => {
        if (pageType.type === "home") {
          return fetchHomePageContent(restAPIClient, localeRef.current);
        }
        return null;
      })();

      if (cmsContent != null) {
        if (pageType.type === "home") {
          setHomePageContent(locale, cmsContent);
        }
        dispatch({
          type: "UpdateCMSPageContent",
          pageType,
          content: cmsContent,
        });
      }

      return cmsContent;
    },
  });

  return [requestState, call, refresh];
}

export function useFetchCMSStaticBlockContents(
  ids: string[]
): ResourcesRequestState<CMSStaticBlockContent[] | null> {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { state, dispatch } = useContext(RepositoryContext);
  const idsDeps = useMemo(() => ids.join(":"), [ids]);
  const { requestState, startRequesting } = useFetchResources(
    {
      needStoreConfig: true,
      memoryCacheProvider: () => null,
      localCacheProvider: () =>
        fetchStaticCMSBlocksByIds(client, ids, locale, "cache-only"),
      didFetchFromLocalCache: () => {},
      remoteResourcesProvider: () =>
        fetchStaticCMSBlocksByIds(client, ids, locale, "network-only"),
      didFetchFromRemote: () => {},
    },
    [locale, idsDeps]
  );
  useEffect(() => {
    startRequesting();
  }, [startRequesting]);
  return requestState;
}

export function useFetchHTMLBasedCMSPageContent(): [
  ResourcesRequestState<HTMLBasedCMSPageContent | null>,
  (
    pageType: HTML_BASED_CMS_PAGE_TYPE
  ) => Promise<HTMLBasedCMSPageContent | null>,
  (
    pageType: HTML_BASED_CMS_PAGE_TYPE
  ) => Promise<HTMLBasedCMSPageContent | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { state, dispatch } = useContext(RepositoryContext);

  const stateRef = useKeepUpdatingRef(state);
  const localeRef = useKeepUpdatingRef(locale);

  const [requestState, { call, refresh }] = useFetchResources_v2<
    HTMLBasedCMSPageContent | null,
    (
      pageType: HTML_BASED_CMS_PAGE_TYPE
    ) => Promise<HTMLBasedCMSPageContent | null>
  >({
    memoryCacheProvider: (pageType: HTML_BASED_CMS_PAGE_TYPE) =>
      Promise.resolve(
        selectHTMLBasedCMSPageContent(stateRef.current, pageType)
      ),
    localCacheProvider: async (pageType: HTML_BASED_CMS_PAGE_TYPE) => {
      const htmlBasedCMSPageContent = await (async () => {
        if (pageType.type === "category") {
          return fetchCategoryPageContent(
            client,
            pageType.identifier,
            localeRef.current,
            "cache-only"
          );
        } else if (pageType.type === "merchant") {
          return fetchMerchantPageContent(
            client,
            pageType.identifier,
            localeRef.current,
            "cache-only"
          );
        }
        return fetchHTMLBasedCMSPageContentByIdentifier(
          client,
          pageType,
          localeRef.current,
          "cache-only"
        );
      })();
      if (htmlBasedCMSPageContent) {
        dispatch({
          type: "UpdateHTMLBasedCMSPageContent",
          pageType,
          content: htmlBasedCMSPageContent,
        });
      }
      return htmlBasedCMSPageContent;
    },
    remoteResourcesProvider: async (pageType: HTML_BASED_CMS_PAGE_TYPE) => {
      const htmlBasedCMSPageContent = await (async () => {
        if (pageType.type === "category") {
          return fetchCategoryPageContent(
            client,
            pageType.identifier,
            localeRef.current,
            "network-only"
          );
        } else if (pageType.type === "merchant") {
          return fetchMerchantPageContent(
            client,
            pageType.identifier,
            localeRef.current,
            "network-only"
          );
        }
        return fetchHTMLBasedCMSPageContentByIdentifier(
          client,
          pageType,
          locale,
          "network-only"
        );
      })();

      if (htmlBasedCMSPageContent != null) {
        dispatch({
          type: "UpdateHTMLBasedCMSPageContent",
          pageType,
          content: htmlBasedCMSPageContent,
        });
      }

      return htmlBasedCMSPageContent;
    },
  });

  return [requestState, call, refresh];
}
