import React, { useContext, useState, useCallback, useRef } from 'react';
import cn from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router';
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import { useResetPassword } from "../../repository/AuthRepository";
import { validateEmail } from "../../utils/validation";
import LoadingModalProvider, {
  LoadingModalContext,
} from "../LoadingModalProvider";
import { PresentationContext } from '../../our-navigation';
import CLModal from '../CLModal';
import ModalHeader from '../ModalHeader';
import CLContent from '../CLContent';
import styles from "./styles.module.scss";
import { LocalizedText, useIntl } from '../../i18n/Localization';
import Input from '../Form/Input';
import { PrimaryButton } from '../Button';
import { useIsMountedRef } from '../../hook/utils';
import CSRModal from '../CSRModal';


type ForgotPasswordModalProps = {
  isModalOpen: boolean;
  onRequestDismiss: () => void;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = (props: ForgotPasswordModalProps) => {
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const { isModalOpen, onRequestDismiss } = props;
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);
  const { show: showLoadingModal, hide: hideLoadingModal } = useContext(
    LoadingModalContext
  );
  const { translate } = useIntl();
  const resetPassword = useResetPassword();
  const { dismiss } = useContext(PresentationContext);

  const onModalDismiss = useCallback(() => {
    setEmail("");
    onRequestDismiss();
  }, [setEmail, onRequestDismiss]);

  const onEmailInputChange = useCallback((value: string) => {
    setEmail(value);
  }, [setEmail]);

  

  const emailInputRef = useRef<HTMLInputElement>(null);
  const onClickSubmitButton = useCallback(async () => {
    setIsLoading(true);
    const isEmailValid = validateEmail(email);
    console.log(isEmailValid);
    setIsEmailValid(isEmailValid);
    if (!isEmailValid) {
      setIsLoading(false);
      return;
    }
    showLoadingModal();
    try {
      await resetPassword(email);
      presentLocalizedAlert({
        headerId: "page.forgot_password.request_success",
        buttons: [
          {
            textMessageID: "alert.button.ok",
            handler: () => {
              onModalDismiss();
              dismiss();
            },
          },
        ],
      });
    } catch {
      presentLocalizedAlert({
        headerId: "page.forgot_password.error.fail_reset_password",
        buttons: [
          {
            textMessageID: "alert.button.ok",
          },
        ],
      });
    } finally {
      setIsLoading(false);
      hideLoadingModal();
    }
  }, [
    email,
    resetPassword,
    dismiss,
    presentLocalizedAlert,
    showLoadingModal,
    hideLoadingModal,
  ]);
  
  return (
    <>
      <CSRModal
        isOpen={isModalOpen}
        onRequestDismiss={onModalDismiss}
        backdropDismiss={false}
        hideHeader={false}
        title={translate("page.forgot_password.title")}
      >
        <CLContent className={styles.ionContent}>
        <p className={styles.instruction}>
          <LocalizedText messageID="page.forgot_password.instruction" />
        </p>
        <Input
          className={styles.emailInput}
          type="email"
          placeholderId="page.forgot_password.input.email.label"
          value={email}
          errorMessage={
            isEmailValid === false
              ? translate("page.forgot_password.error.invalid_email_format")
              : undefined
          }
          onChange={setEmail}
        />
        
        <PrimaryButton
          className={cn(styles.signupButton)}
          disabled={ isLoading }
          loading={isLoading}
          onClick={onClickSubmitButton}>
          <LocalizedText messageID="page.forgot_password.button.submit" />
        </PrimaryButton>
      </CLContent>

      </CSRModal>
    </>
  )
}

export default ForgotPasswordModal;