import React, { useMemo, useCallback, useEffect } from "react";
import { MagentoVersion, IfMagentoVersionFn } from "../models/MagentoVersion";
import { semverCmp, SemverComparator } from "../utils/semver";
import { useFetchMagentoVersion } from "../repository/MagentoVersionRepository";
import { getResources } from "../models/ResourcesRequestState";
import { FullContentLoadingView } from "./LoadingView";

interface MagentoVersionContext {
  ifMagentoVersion: IfMagentoVersionFn;
}

export const MagentoVersionContext = React.createContext<MagentoVersionContext>(
  null as any
);

export const MagentoVersionProvider: React.FC = props => {
  const [
    magentoVersionResource,
    fetchMagentoVersion,
  ] = useFetchMagentoVersion();

  useEffect(() => {
    fetchMagentoVersion();
  }, [fetchMagentoVersion]);

  const magentoVersion = getResources(magentoVersionResource);

  const ifMagentoVersion = useCallback(
    (cmp: SemverComparator, version: MagentoVersion) => {
      if (!magentoVersion) {
        throw new Error("Unknown magento version");
      }
      return semverCmp(cmp, magentoVersion, version);
    },
    [magentoVersion]
  );

  const value = useMemo(
    () => ({
      ifMagentoVersion,
    }),
    [ifMagentoVersion]
  );

  return (
    <MagentoVersionContext.Provider value={value}>
      {!magentoVersion ? <FullContentLoadingView /> : props.children}
    </MagentoVersionContext.Provider>
  );
};

export default MagentoVersionProvider;
