import React, { useEffect, useState, useCallback } from "react";
// eslint-disable-next-line no-restricted-imports
import { IonModal } from "@ionic/react";
import { ContentScrollProviderContext } from "../ContentScrollProvider";
import BackAndroidHandler from "../BackAndroidHandler";
import { useDebounce } from "../../hook/utils";

type CLModalProps = React.ComponentProps<typeof IonModal> & {
  onRequestDismiss: () => void;
};

const CLModal: React.FC<CLModalProps> = props => {
  const { isOpen, onRequestDismiss, ref, children, ...rest } = props;
  const { updateContentYScrollEnabled } = React.useContext(
    ContentScrollProviderContext
  );
  const debouncedIsOpen = useDebounce(isOpen, 100);
  const [isModalOpen, setModalIsOpen] = useState(isOpen);

  useEffect(() => {
    updateContentYScrollEnabled(!debouncedIsOpen);
  }, [debouncedIsOpen, updateContentYScrollEnabled]);

  useEffect(() => {
    if (debouncedIsOpen) {
      setModalIsOpen(true);
    }
  }, [debouncedIsOpen]);

  const onDidDismiss = useCallback(() => {
    onRequestDismiss();
    setModalIsOpen(false);
  }, [onRequestDismiss]);

  const handleBackPress = useCallback(() => {
    onRequestDismiss();
    setModalIsOpen(false);
    return true;
  }, [onRequestDismiss]);

  return (
    <IonModal isOpen={debouncedIsOpen} onDidDismiss={onDidDismiss} {...rest}>
      {isModalOpen ? children : null}
      {isModalOpen ? (
        <BackAndroidHandler onBackPress={handleBackPress} />
      ) : null}
    </IonModal>
  );
};

export default React.memo(CLModal);
