import React, { useCallback, useContext } from "react";
import { OurNavContext } from "../../our-navigation";
import useOpenUrlWithBrowser from "../../hook/useOpenUrlWithBrowser";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    "href"
  > {
  to: string;
}

const CLLink: React.FC<Props> = props => {
  const { to, onClick, ...otherProps } = props;
  const { navigate } = useContext(OurNavContext);
  const openUrlWithBrowser = useOpenUrlWithBrowser();
  const onClick_ = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        onClick(e);
      }

      if (to.startsWith("http://") || to.startsWith("https://")) {
        openUrlWithBrowser(to);
        return;
      }

      navigate(to);
    },
    [onClick, navigate, to, openUrlWithBrowser]
  );
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a {...otherProps} onClick={onClick_} />;
};

export default CLLink;
