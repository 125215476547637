import moment from "moment-timezone";
import { Decimal } from "decimal.js";
import { Override } from "../utils/type";

import { Customer } from "./Customer";
import { MediaContent } from "./Media";
import { Money, areMoneyEqual } from "./Price";
import { StoreConfig } from "./StoreConfig";

import Config from "../Config";
import { ProductOverviewVariant } from "./ProductOverview";

const SERVER_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export type ProductTodoAttribute_Number = number;

/**
 * TODO (Steven-Chan):
 * expands this list when need to identifier different types
 *
 * This type is only for the app to handle some special delivery methods
 * specially, but should always assume there are other delivery methods returned
 * from server.
 */
export type ProductDeliveryMethod = "warehouse" | "pickupnow" | "evoucher";

export type ProductStockStatus = "IN_STOCK" | "OUT_OF_STOCK";

export type ThirdPartyProductDisplayType = "BUTTON_WITH_LINK" | "INFO_MESSAGE";
export type PriceTypeEnum = "FIXED" | "PERCENT" | "DYNAMIC";
export type ProductType = "simple" | "configurable" | "virtual" | string;
export type CLClubPoint = string;
export interface Product360Image {
  id: number;
  url: string;
  position: number;
}

export const Product360ImageGraphQLAttributes = `
  id
  url: large_image_url
  position
`;

export interface PriceRange {
  minimumPrice: {
    regularPrice: Money;
    finalPrice: Money;
  };
  maximumPrice: {
    regularPrice: Money;
    finalPrice: Money;
  };
}

// FEATURE_MERGE: club_tier_quota present in prduct interface => ProductOverviewBaseGraphQLAttributes
export const ProductOverviewBaseClubTierQuotaGraphQLAttributes = `
id
clubTierQuota: club_tier_quota {
  isEnabled: is_enabled
  tierConfig: tier_config {
    endTime: end_time
    max
    startTime: start_time
    tier
  }
}
quota {
  message
  value
}
memberQuota: member_quota {
  message
  value
}
`;
export interface ProductOverviewBaseClubTierQuota {
  clubTierQuota: ClubTierQuota | null;
  quota: Quota | null;
  memberQuota: Quota | null;
}

export interface Quota {
  message: string | null;
  value: number | null;
}

export interface ClubTierQuota {
  isEnabled: boolean;
  tierConfig: TierConfig[];
}

export type RemoteClubTierQuota = Override<
  ClubTierQuota,
  {
    tierConfig: RemoteTierConfig[];
  }
>;

export interface TierConfig {
  startTime: Date | null;
  endTime: Date | null;
  max: number | null;
  tier: string;
}

export type RemoteTierConfig = Override<
  TierConfig,
  {
    startTime: number | null;
    endTime: number | null;
  }
>;

function transformRemoteTierConfigToTierConfig(
  remoteTierConfig: RemoteTierConfig
): TierConfig {
  const { startTime, endTime } = remoteTierConfig;
  return {
    ...remoteTierConfig,
    startTime: startTime
      ? moment.tz(startTime * 1000, Config.TIMEZONE).toDate()
      : null,
    endTime: endTime
      ? moment.tz(endTime * 1000, Config.TIMEZONE).toDate()
      : null,
  };
}

export function transformRemoteClubTierQuotaToClubTierQuota(
  remoteClubTierQuota: RemoteClubTierQuota
): ClubTierQuota {
  return {
    ...remoteClubTierQuota,
    tierConfig: remoteClubTierQuota.tierConfig.map(
      transformRemoteTierConfigToTierConfig
    ),
  };
}

export type ThirdPartyProduct<
  T extends Partial<{
    displayType: ThirdPartyProductDisplayType | null;
    buttonUrl: string | null;
    infoMessage: string | null;
  }>
> = T & {
  displayType: ThirdPartyProductDisplayType;
  buttonUrl: string | null;
  infoMessage: string | null;
};

export function isThirdPartyProduct<
  T extends Partial<{
    displayType: ThirdPartyProductDisplayType | null;
    buttonUrl: string | null;
    infoMessage: string | null;
  }>
>(product: T): product is ThirdPartyProduct<T> {
  const { displayType } = product;
  return displayType != null;
}

export const ProductConfigurableOptionGraphQLAttributes = `
  id
  label
  attributeCode: attribute_code
  values {
    label: store_label
    value: value_index
  }
  position
`;

export const ProductOverviewVariantProductCubTierQuotaGraphQLAttributes = `
id
... on ConfigurableProduct {
  variants {
    product {
      id
      clubTierQuota: club_tier_quota {
        isEnabled: is_enabled
        tierConfig: tier_config {
          endTime: end_time
          max
          startTime: start_time
          tier
        }
      }
      quota {
        message
        value
      }
      memberQuota: member_quota {
        message
        value
      }
    }
  }
}
`;

export interface RelatedProductLink {
  linkType: "related";
  linkedProductSKU: string;
}

export interface UpsellProductLink {
  linkType: "upsell";
  linkedProductSKU: string;
}

export type ProductLink = RelatedProductLink | UpsellProductLink;

export type RecurringDateType =
  | { type: "unknown"; message: string }
  | { type: "known"; date: Date };

export interface RecurringConfiguration {
  isRecurringEnable: boolean;
  isSubscriptionOnly: boolean;
  billingCycle: string | null;
  isEnableTrial: boolean;
  isFreeShipping: boolean;
  startDate: RecurringDateType | null;
  endDate: RecurringDateType | null;
}

export interface RemoteRecurringConfiguration {
  isRecurringEnable: boolean;
  isSubscriptionOnly: boolean;
  billingCycle: string | null;
  isEnableTrial: boolean;
  isFreeShipping: boolean;
  startDate: string | null;
  endDate: string | null;
}

export function transformRemoteRecurringConfigurationToRecurringConfiguration(
  remoteRecurringConfiguration: RemoteRecurringConfiguration
): RecurringConfiguration {
  const {
    startDate: _startDate,
    endDate: _endDate,
  } = remoteRecurringConfiguration;
  const parseDateStr = (maybeDateStr: string): RecurringDateType => {
    const m = moment.tz(maybeDateStr, "YYYY-MM-DD", Config.TIMEZONE);
    if (m.isValid()) {
      return { type: "known", date: m.toDate() };
    }
    return { type: "unknown", message: maybeDateStr };
  };

  const startDate: RecurringDateType | null = _startDate
    ? parseDateStr(_startDate)
    : null;
  const endDate: RecurringDateType | null = _endDate
    ? parseDateStr(_endDate)
    : null;

  return {
    ...remoteRecurringConfiguration,
    startDate,
    endDate,
  };
}

export interface ProductConfigurableOptionValue {
  label: string;
  value: number;
  // TODO(1285):
  // We temp remove swatch data as server is not fully support swatch data
  // in some products
  // swatchData: {
  //   type: "TEXTUAL" | "COLOR" | "IMAGE" | "EMPTY";
  //   value: string;
  // } | null;
}

export interface ProductConfigurableOption {
  id: number;
  label: string;
  attributeCode: string;
  values: ProductConfigurableOptionValue[];
  position?: number | null;
}

export function isProductConfigurableOptionColorOption(
  _option: ProductConfigurableOption
): boolean {
  // TODO(1285):
  // We temp disable color picker as server is not fully ready for this
  // return option.attributeCode === "color_family";
  return false;
}

export interface ProductConfigurableAttributeOption {
  label: string;
  value: number;
  code?: string;
}

interface ProductCustomizationOptionInterface {
  optionId: number;
  title: string;
  isRequired: boolean;
  sortOrder?: number | null;
}

export const ProductCustomizationOptionInterfaceGraphQLAttributes = `
  optionId: option_id
  title
  isRequired: required
  sortOrder: sort_order
`;

export interface ProductCustomizableOptionCheckbox
  extends ProductCustomizationOptionInterface {
  checkboxOptions: {
    id: number;
    title: string;
    price: number | null;
    priceType: PriceTypeEnum | null;
  }[];
}

export const ProductCustomizableOptionCheckboxGraphQLAttributes = `
  checkboxOptions: value {
    id: option_type_id
    title
    price
    priceType: price_type
  }
`;

export function isProductCustomizableOptionCheckbox(
  option: ProductCustomizationOption
): option is ProductCustomizableOptionCheckbox {
  return (option as any).checkboxOptions != null;
}

interface ProductCustomizableOptionTextField
  extends ProductCustomizationOptionInterface {
  textInput: {
    maxCharacters: number | null;
    price: number | null;
    priceType: PriceTypeEnum | null;
  };
}

export const ProductCustomizableOptionTextFieldGraphQLAttributes = `
  textInput: value {
    maxCharacters: max_characters
    price
    priceType: price_type
  }
`;

export function isProductCustomizableOptionTextField(
  option: ProductCustomizationOption
): option is ProductCustomizableOptionTextField {
  return (option as any).textInput != null;
}

interface ProductCustomizableOptionTextArea
  extends ProductCustomizationOptionInterface {
  areaInput: {
    maxCharacters: number | null;
    price: number | null;
    priceType: PriceTypeEnum | null;
  };
}

export const ProductCustomizationOptionTextAreaGraphQLAttributes = `
  areaInput: value {
    maxCharacters: max_characters
    price
    priceType: price_type
  }
`;

export function isProductCustomizableOptionTextArea(
  option: ProductCustomizationOption
): option is ProductCustomizableOptionTextArea {
  return (option as any).areaInput != null;
}

interface ProductCustomizableOptionDate
  extends ProductCustomizationOptionInterface {
  dateInput: {
    sku: string;
    price: number | null;
    priceType: PriceTypeEnum | null;
  };
}

export const ProductCustomizationOptionDateGraphQLAttributes = `
  dateInput: value {
    sku
    price
    priceType: price_type
  }
`;

export function isProductCustomizableOptionDate(
  option: ProductCustomizationOption
): option is ProductCustomizableOptionDate {
  return (option as any).dateInput != null;
}

interface ProductCustomizableOptionDropDown
  extends ProductCustomizationOptionInterface {
  dropDownOptions: {
    id: number;
    title: string;
    price: number | null;
    priceType: PriceTypeEnum | null;
  }[];
}

export const ProductCustomizationOptionDropDownGraphQLAttributes = `
  dropDownOptions: value {
    id: option_type_id
    title
    price
    priceType: price_type
  }
`;

export function isProductCustomizableOptionDropDown(
  option: ProductCustomizationOption
): option is ProductCustomizableOptionDropDown {
  return (option as any).dropDownOptions != null;
}

export interface ProductCustomizationOptionMultipleSelect
  extends ProductCustomizationOptionInterface {
  selectOptions: {
    id: number;
    title: string;
    price: number | null;
    priceType: PriceTypeEnum | null;
  }[];
}

export const ProductCustomizationOptionMultipleSelectGraphQLAttributes = `
  selectOptions: value {
    id: option_type_id
    title
    price
    priceType: price_type
  }
`;

export function isProductCustomizationOptionMultipleSelect(
  option: ProductCustomizationOption
): option is ProductCustomizationOptionMultipleSelect {
  return (option as any).selectOptions != null;
}

export interface ProductCustomizableOptionFile
  extends ProductCustomizationOptionInterface {
  fileInput: {
    fileExtension: string | null;
    imageSizeX: number | null;
    imageSizeY: number | null;
    price: number | null;
    priceType: PriceTypeEnum | null;
  };
}

export const ProductCustomizableOptionFileGraphQLAttributes = `
  fileInput: value {
    fileExtension: file_extension
    imageSizeX: image_size_x
    imageSizeY: image_size_y
    price
    priceType: price_type
  }
`;

export function isProductCustomizableOptionFile(
  option: ProductCustomizationOption
): option is ProductCustomizableOptionFile {
  return (option as any).fileInput != null;
}

export interface ProductCustomizableOptionRadio
  extends ProductCustomizationOptionInterface {
  radioOptions: {
    id: number;
    title: string;
    price: number | null;
    priceType: PriceTypeEnum | null;
  }[];
}

export const ProductCustomizableOptionRadioGraphQLAttributes = `
  radioOptions: value {
    id: option_type_id
    title
    price
    priceType: price_type
  }
`;

export function isProductCustomizableOptionRadio(
  option: ProductCustomizationOption
): option is ProductCustomizableOptionRadio {
  return (option as any).radioOptions != null;
}

export function isProductSale<
  T extends {
    priceRange: PriceRange | null;
    specialFromDateStr: string | null;
    specialToDateStr: string | null;
  }
>(product: T, now: Date = new Date()): boolean {
  const { priceRange } = product;
  if (priceRange == null) {
    return false;
  }
  const { minimumPrice, maximumPrice } = priceRange;

  if (
    !areMoneyEqual(minimumPrice.regularPrice, minimumPrice.finalPrice) ||
    !areMoneyEqual(maximumPrice.regularPrice, maximumPrice.finalPrice)
  ) {
    const { specialFromDateStr, specialToDateStr } = product;
    const specialFromDate =
      specialFromDateStr != null
        ? moment(specialFromDateStr, SERVER_DATETIME_FORMAT).toDate()
        : null;
    // The to date is inclusive
    const specialToDate =
      specialToDateStr != null
        ? moment(specialToDateStr, SERVER_DATETIME_FORMAT)
            .add(1, "days")
            .toDate()
        : null;
    if (specialFromDate != null && now < specialFromDate) {
      return false;
    }
    if (specialToDate != null && now > specialToDate) {
      return false;
    }
    return true;
  }
  return false;
}

export function isProductNew<
  T extends {
    newFromDateStr: string | null;
    newToDateStr: string | null;
  }
>(product: T): boolean {
  const { newFromDateStr, newToDateStr } = product;
  if (newFromDateStr == null || newToDateStr == null) {
    return false;
  }
  const newFromDate = moment(newFromDateStr, SERVER_DATETIME_FORMAT).toDate();
  // The to date is inclusive
  const newToDate = moment(newToDateStr, SERVER_DATETIME_FORMAT)
    .add(1, "days")
    .toDate();
  const now = new Date();
  if (newFromDate > now) {
    return false;
  }
  if (newToDate < now) {
    return false;
  }
  return true;
}

export function doesProductHave360Images<
  T extends { magic360Images: Product360Image[] }
>(product: T): boolean {
  return product.magic360Images.length > 0;
}

export function getProductMerchant<T>(product: {
  merchant: [T | null];
}): T | null {
  const [merchant] = product.merchant;
  return merchant != null ? merchant : null;
}

export type ProductCustomizationOption =
  | ProductCustomizableOptionTextArea
  | ProductCustomizableOptionDate
  | ProductCustomizableOptionDropDown
  | ProductCustomizationOptionMultipleSelect
  | ProductCustomizableOptionTextField
  | ProductCustomizableOptionFile
  | ProductCustomizableOptionRadio
  | ProductCustomizableOptionCheckbox;

export function getPriceOfCustomizableOptionPriceType(
  originalPrice: number,
  priceType: PriceTypeEnum | null,
  value: number | null
) {
  if (priceType == null || value == null) {
    return 0;
  }
  switch (priceType) {
    case "FIXED":
      return value;
    case "PERCENT":
      return (originalPrice * value) / 100;
    default:
      // TODO: DYNAMIC wont be handled because no use case in web site
      return 0;
  }
}

export function getProductCustomizationOptionAdditionalPrice(
  originalPrice: number,
  option: ProductCustomizationOption
): number {
  function getMaybePrice(): number | null {
    if (isProductCustomizableOptionTextArea(option)) {
      const { priceType, price } = option.areaInput;
      return getPriceOfCustomizableOptionPriceType(
        originalPrice,
        priceType,
        price
      );
    }
    if (isProductCustomizableOptionDate(option)) {
      const { priceType, price } = option.dateInput;
      return getPriceOfCustomizableOptionPriceType(
        originalPrice,
        priceType,
        price
      );
    }
    if (isProductCustomizableOptionDropDown(option)) {
      const { priceType, price } = option.dropDownOptions[0];
      return getPriceOfCustomizableOptionPriceType(
        originalPrice,
        priceType,
        price
      );
    }
    if (isProductCustomizationOptionMultipleSelect(option)) {
      return option.selectOptions.reduce<number>(
        (acc, { priceType, price }) => {
          const p = getPriceOfCustomizableOptionPriceType(
            originalPrice,
            priceType,
            price
          );
          return p ? acc + p : acc;
        },
        0
      );
    }
    if (isProductCustomizableOptionTextField(option)) {
      const { priceType, price } = option.textInput;
      return getPriceOfCustomizableOptionPriceType(
        originalPrice,
        priceType,
        price
      );
    }
    /** TODO: File
     *
     *
     *
     *
     *
     *
     */
    if (isProductCustomizableOptionRadio(option)) {
      const { priceType, price } = option.radioOptions[0];
      return getPriceOfCustomizableOptionPriceType(
        originalPrice,
        priceType,
        price
      );
    }
    if (isProductCustomizableOptionCheckbox(option)) {
      return option.checkboxOptions.reduce<number>(
        (acc, { priceType, price }) => {
          const p = getPriceOfCustomizableOptionPriceType(
            originalPrice,
            priceType,
            price
          );
          return p ? acc + p : acc;
        },
        0
      );
    }

    return null;
  }
  return getMaybePrice() || 0;
}

export function requiredCustomizationOptionIds(
  customizationOptions: ProductCustomizationOption[]
): number[] {
  return customizationOptions
    .filter(opt => opt.isRequired)
    .map(opt => opt.optionId);
}

export interface ProductSpec {
  label: string;
  value: string;
}

export interface ProductColor {
  label: string;
  value: number;
  data:
    | {
        type: "TEXTUAL";
        value: string;
      }
    | {
        type: "COLOR";
        value: string;
      }
    | {
        type: "IMAGE";
        value: string;
      }
    | {
        type: "EMPTY";
      };
}

export function makeProductColorFromProductConfigurableOptionValue(
  option: ProductConfigurableOptionValue
): ProductColor {
  // TODO(1285):
  // We temp remove swatch data as server is not fully support swatch data
  // in some products
  // const { swatchData } = option;
  // if (swatchData == null) {
  //   return {
  //     ...option,
  //     data: {
  //       type: "EMPTY",
  //     },
  //   };
  // }
  // return {
  //   ...option,
  //   data: {
  //     ...swatchData,
  //   },
  // };
  return {
    ...option,
    data: {
      type: "EMPTY",
    },
  };
}

export interface ProductImage {
  disabled: boolean;
  label: string;
  url: string;
}

export const ProductImageGraphQLAttributes = `
  disabled
  url
  label
`;

export type ProductDescriptionMedia =
  | ProductDescriptionImage
  | ProductDescriptionVideo;

export interface ProductDescriptionImage {
  type: "description-image";
  url: string;
}

export interface ProductDescriptionVideo {
  type: "description-video";
  thumbnail: string;
  url: string;
}

export interface ProductTodoAttribute_Review {
  createdAt: Date;
  customer: Customer;
  rating: number;
  comment: string;
  merchantReply: string;
}

export function doesProductHaveOptionsToConfigure<
  T extends {
    configurableOptions?: ProductConfigurableOption[] | null;
    customizableOptions: ProductCustomizationOption[] | null;
  }
>(product: T): boolean {
  const { configurableOptions, customizableOptions } = product;
  const hasOptionsToConfigure = !(
    (configurableOptions == null || configurableOptions.length === 0) &&
    (customizableOptions == null || customizableOptions.length === 0)
  );
  return hasOptionsToConfigure;
}

export function constructMediaUrlForProduct(
  storeConfig: StoreConfig,
  mediaContent: MediaContent
): string {
  return `${storeConfig.baseMediaUrl}catalog/product/${mediaContent.filePath}`;
}

export function getProductShareUrl<T extends { urlKey: string }>(product: T) {
  return `${Config.SITE_URL}/${product.urlKey}.html`;
}

export function augmentProductWithNotImplementedAttribute<T>(
  product: T & {
    qAndACount?: ProductTodoAttribute_Number;
    likeCount?: ProductTodoAttribute_Number;
  }
): T & {
  qAndACount: ProductTodoAttribute_Number;
  likeCount: ProductTodoAttribute_Number;
} {
  return {
    ...product,
    qAndACount: 777,
    likeCount: 777,
  };
}

export function isSoldOut<
  T extends {
    stockStatus: ProductStockStatus;
    priceRange: PriceRange | null;
    minClubPoint: number;
    variants?: ProductOverviewVariant[] | null;
  }
>(productOverview: T) {
  if (productOverview.stockStatus === "OUT_OF_STOCK") {
    return true;
  }

  const { priceRange, minClubPoint, variants } = productOverview;
  if (priceRange == null) {
    return false;
  }

  const minMoney = priceRange.minimumPrice.finalPrice;
  const maxMoney = priceRange.maximumPrice.finalPrice;

  // min club point for parent product
  let hasMinClubPoint = minClubPoint ? true : false;

  if (!hasMinClubPoint && variants != null) {
    for (const {
      product: { minClubPoint: variantMinClubPoint, stockStatus },
    } of variants) {
      if (stockStatus === "IN_STOCK" && variantMinClubPoint) {
        hasMinClubPoint = true;
        break;
      }
    }
  }

  return minMoney.value === 0 && maxMoney.value === 0 && !hasMinClubPoint;
}

export function getRebatedClubPoint<
  T extends {
    extraClubpoints: number | null;
    clClubPoint?: CLClubPoint | null;
  }
>(productOverview: T): Decimal {
  const { extraClubpoints, clClubPoint } = productOverview;
  const extraClubPointDecimal = extraClubpoints
    ? new Decimal(extraClubpoints)
    : new Decimal(0);
  const clClubPointDecimal = clClubPoint
    ? new Decimal(clClubPoint)
    : new Decimal(0);
  return extraClubPointDecimal.add(clClubPointDecimal);
}
export function isRebate<
  T extends {
    extraClubpoints: number | null;
    clClubPoint?: CLClubPoint | null;
  }
>(productOverview: T): boolean {
  const rebatedClubPoint = getRebatedClubPoint(productOverview);
  return rebatedClubPoint.greaterThan(new Decimal(1));
}

export function getConfiguredProductOfLowestPrice<
  ConfiguredProductType extends {
    priceRange: PriceRange | null;
    minClubPoint: number;
  },
  T extends {
    variants?: { product: ConfiguredProductType }[] | null;
    type: ProductType;
  }
>(product: T, clubPointConversionRate: number): ConfiguredProductType | null {
  const { variants } = product;
  if (
    product.type !== "configurable" ||
    variants == null ||
    variants.length === 0
  ) {
    return null;
  }
  let res: {
    product: ConfiguredProductType;
    fullClubPoint: number;
  } | null = null;
  for (const variant of variants) {
    const { priceRange, minClubPoint } = variant.product;
    if (priceRange == null) {
      continue;
    }
    const finalPrice = priceRange.minimumPrice.finalPrice;
    const fullClubPoint =
      minClubPoint + finalPrice.value * clubPointConversionRate;
    if (res == null || fullClubPoint < res.fullClubPoint) {
      res = { product: variant.product, fullClubPoint };
    }
  }
  return res ? res.product : null;
}

export function getConfiguredProductOfHighestPrice<
  ConfiguredProductType extends {
    priceRange: PriceRange | null;
    minClubPoint: number;
  },
  T extends {
    variants?: { product: ConfiguredProductType }[] | null;
    type: ProductType;
  }
>(product: T, clubPointConversionRate: number): ConfiguredProductType | null {
  const { variants } = product;
  if (
    product.type !== "configurable" ||
    variants == null ||
    variants.length === 0
  ) {
    return null;
  }
  let res: {
    product: ConfiguredProductType;
    fullClubPoint: number;
  } | null = null;
  for (const variant of variants) {
    const { priceRange, minClubPoint } = variant.product;
    if (priceRange == null) {
      continue;
    }
    const finalPrice = priceRange.minimumPrice.finalPrice;
    const fullClubPoint =
      minClubPoint + finalPrice.value * clubPointConversionRate;
    if (res == null || fullClubPoint > res.fullClubPoint) {
      res = { product: variant.product, fullClubPoint };
    }
  }
  return res ? res.product : null;
}

export function getLowestFinalPrice<
  T extends {
    priceRange: PriceRange | null;
  }
>(product: T) {
  if (product.priceRange == null) {
    return 0;
  }
  return product.priceRange.minimumPrice.finalPrice.value;
}

export function isProductOrItsVariantsHasMinClubPoint<
  ProductType extends {
    minClubPoint: number;
  },
  T extends {
    minClubPoint: number;
    variants?: { product: ProductType }[] | null;
  }
>(productOverview: T): boolean {
  if (productOverview.minClubPoint && productOverview.minClubPoint > 0) {
    return true;
  }
  if (
    productOverview.variants == null ||
    productOverview.variants.length === 0
  ) {
    return false;
  }
  for (const { product } of productOverview.variants) {
    const { minClubPoint } = product;
    if (minClubPoint && minClubPoint > 0) {
      return true;
    }
  }
  return false;
}

export function sortConfigurableOptions(
  configurableOptions: ProductConfigurableOption[]
) {
  return [...configurableOptions].sort((a, b) =>
    a.position != null && b.position != null ? a.position - b.position : 0
  );
}

export function sortCustomizableOptions(
  customizableOptions: ProductCustomizationOption[]
) {
  return [...customizableOptions].sort((a, b) =>
    a.sortOrder != null && b.sortOrder != null ? a.sortOrder - b.sortOrder : 0
  );
}

export function isProductOrItsVariantsHasClubProtect<
  ProductType extends {
    priceRange: PriceRange | null;
    enableClubProtection?: boolean | null;
  },
  T extends {
    priceRange: PriceRange | null;
    enableClubProtection?: boolean | null;
    variants?: { product: ProductType }[] | null;
  }
>(productOverview: T): boolean {
  if (
    productOverview.variants == null ||
    productOverview.variants.length === 0
  ) {
    const { enableClubProtection, priceRange } = productOverview;
    if (enableClubProtection == null || priceRange == null) {
      return false;
    }
    return isClubProtectEligible(
      enableClubProtection,
      priceRange.minimumPrice.finalPrice.value
    );
  }
  for (const { product } of productOverview.variants) {
    const { enableClubProtection, priceRange } = product;
    if (enableClubProtection != null && priceRange != null) {
      return isClubProtectEligible(
        enableClubProtection,
        priceRange.minimumPrice.finalPrice.value
      );
    }
  }
  return false;
}

export const CLUB_PROTECT_MIN_PRICE = 300;

export function isClubProtectEligible(
  enableClubProtection: boolean,
  price: number
): boolean {
  if (enableClubProtection === true && price >= CLUB_PROTECT_MIN_PRICE) {
    return true;
  }
  return false;
}

// export interface ProductOverviewBase {
//   id: number;
//   entityId: number;
//   sku: string;
//   name: string;
//   type: "simple" | "configurable" | "virtual" | string;
//   thumbnail: ProductImage | null;
//   image: ProductImage | null;
//   extraClubPoint: number | null;
//   priceRange: {
//     minimumPrice: {
//       regularPrice: Money;
//       finalPrice: Money;
//     };
//     maximumPrice: {
//       regularPrice: Money;
//       finalPrice: Money;
//     };
//   } | null;
//   specialPrice: number | null;
//   specialFromDateStr: string | null;
//   specialToDateStr: string | null;
//   newFromDateStr: string | null;
//   newToDateStr: string | null;
//   clubPoint: number;
//   minClubPoint: number;

//   mediaContents: MediaContent[];
//   magic360Images: Product360Image[];
//   merchant: [MerchantPreview | null];

//   rating: number;

//   deliveryMethod: ProductDeliveryMethod | null;
//   deliveryMethodBlockIdentifier: string | null;

//   stockStatus: ProductStockStatus;

//   enableDisclaimer: boolean;
//   isDisclaimerRequired: boolean;

// }

// recurringConfiguration: RecurringConfiguration | null;
// shortDescription: HTMLText

// export interface ProductOverview extends ProductOverviewBase {
//   configurableOptions?: ProductConfigurableOption[] | null;
//   variants?: ProductVariant[] | null;
// }

// const ProductOverviewBaseGraphQLAttributes = `
//   id
//   entityId: entity_id
//   sku
//   name
//   type: type_id
//   thumbnail {
//     url
//     label
//   }
//   image {
//     url
//     label
//   }
//   clubPoint: clubpoints
//   minClubPoint: min_clubpoints
//   extraClubPoint: extra_clubpoints
//   priceRange: price_range {
//     minimumPrice: minimum_price {
//       regularPrice: regular_price {
//         ${MoneyGraphQLAttributes}
//       }
//       finalPrice: final_price {
//         ${MoneyGraphQLAttributes}
//       }
//     }
//     maximumPrice: maximum_price {
//       regularPrice: regular_price {
//         ${MoneyGraphQLAttributes}
//       }
//       finalPrice: final_price {
//         ${MoneyGraphQLAttributes}
//       }
//     }
//   }
//   specialPrice: special_price
//   specialFromDateStr: special_from_date
//   specialToDateStr: special_to_date
//   newFromDateStr: new_from_date
//   newToDateStr: new_to_date
//   mediaContents: media_gallery_entries {
//     ${MediaContentGraphQLAttributes}
//   }
//   magic360Images: magic360_image {
//     ${Product360ImageGraphQLAttributes}
//   }
//   merchant {
//     ${MerchantPreviewGraphQLAttributes}
//   }
//   rating
//   stockStatus: stock_status
//   enableDisclaimer: enable_disclaimer
//   isDisclaimerRequired: is_disclaimer_required
//   shortDescription: short_description {
//     html
//   }
// `;

// // shortDescription: short_description {
// //   html
// // }
// // ${RecurringConfigurationGraphQLAttributes}

// export const ProductVariantGrapQLAttributes = `
//   product {
//     ${ProductOverviewBaseGraphQLAttributes}
//     shortDescription: short_description {
//       ${HTMLTextGrapQLAttributes}
//     }
//     longDescription: description {
//       ${HTMLTextGrapQLAttributes}
//     }
//     specs: custom_attributes {
//       label
//       value
//     }
//   }
//   attributes {
//     label
//     value: value_index
//   }
// `;

// export const ProductOverviewGraphQLAttributes = `
//   ${ProductOverviewBaseGraphQLAttributes}
//   ... on ConfigurableProduct {
//     configurableOptions: configurable_options {
//       ${ProductConfigurableOptionGraphQLAttributes}
//     }
//     variants {
//       ${ProductVariantGrapQLAttributes}
//     }
//   }
//   position
// `;

// export interface ConfiguredProduct extends ProductOverviewBase {
//   shortDescription: HTMLText;
//   longDescription: HTMLText;
//   specs: ProductSpec[];
// }

// export interface Product extends ProductOverview {
//   shortDescription: HTMLText;
//   longDescription: HTMLText;
//   specs: ProductSpec[];
//   customizableOptions: ProductCustomizationOption[] | null;

//   productLinks: ProductLink[] | null;

//   urlKey: string;

//   reviewCount: number;
//   qAndACount: ProductTodoAttribute_Number;
//   likeCount: ProductTodoAttribute_Number;

//   stockStatus: "IN_STOCK" | "OUT_OF_STOCK";
//   recurringConfiguration: RecurringConfiguration | null;
// }
// export interface ProductVariant {
//   product: ConfiguredProduct;
//   attributes: ProductConfigurableAttributeOption[];
// }