import React, { useEffect, useState, useCallback } from "react";
import { ApolloClient, gql } from "apollo-boost";
import { Locale, getStoreViewCodeForLocale } from "../i18n/locale";
import { useApolloClient } from "@apollo/react-hooks";
import { useIntl } from "../i18n/Localization";
import { GraphQLFn, GraphQLFnParams, parseGraphQLError } from "../api/GraphQL";
import { useGraphQLFn } from "../hook/graphql";
import { getCartID, setCartID } from "../storage";
import { IfMagentoVersionFn } from "../models/MagentoVersion";
import { useMagentoVersionFn } from "../hook/MagentoVersion";
import { useKeepUpdatingRef } from "../hook/utils";
import { TokenStore } from "../api/TokenStore";
export function useCartIDFromStorage() {
  const [cartID, setCartID] = useState<string | null>(null);
  const [getFinished, setGetFinished] = useState(false);

  // Load cartID from storage
  useEffect(() => {
    (async () => {
      setCartID(await getCartID());
      setGetFinished(true);
    })();
  }, []);
  return { cartID, getCartIDFinished: getFinished };
}

type CartAPIFn<T> = (
  client: ApolloClient<any>,
  locale: Locale,
  cartID: string,
  ...args: any
) => Promise<T>;

export type CartAPIFnParams<F extends CartAPIFn<any>> = F extends (
  client: ApolloClient<any>,
  locale: Locale,
  cartID: string,
  ...args: infer A
) => Promise<any>
  ? A
  : never;

export type CartIDInjectingFn = <T, F extends CartAPIFn<T>>(
  fn: F,
  ...args: CartAPIFnParams<F>
) => ReturnType<F>;

interface CartIDContext {
  cartID: string | null;

  createCartFromPreviousCart: (cartID: string) => Promise<void>;
  removeCart: () => Promise<void>;
  reorder: (orderId: string) => Promise<void>;

  // Inject cart ID from context to the function
  callCartAPI: CartIDInjectingFn;

  // Inject cart ID from context to the function
  // If cart ID is null, or the API responds with error of inactive cart or
  // wrong store view code, it would handle gracefully by creating a new cart
  // and set the correct current store to the cart
  callCartAPIGracefully: CartIDInjectingFn;
}

export const CartIDContext = React.createContext<CartIDContext>(null as any);

export const CartIDProvider: React.FC<{ cartID: string | null }> = props => {
  const [cartID, setCartIDInternally] = useState<string | null>(props.cartID);
  const cartIDRef = useKeepUpdatingRef(cartID);
  
  const client = useApolloClient();
  const { locale } = useIntl();
  const setStoreOnCart_ = useGraphQLFn(setStoreOnCart);
  const createEmptyCart_ = useGraphQLFn(useMagentoVersionFn(createEmptyCart));
  const createCartFromGuestCart_ = useGraphQLFn(
    useMagentoVersionFn(createCartFromGuestCart)
  );
  const reorder_ = useGraphQLFn(reorderFromOrder);

  const setCartIDAndPersist = React.useCallback(
    async (cartID: string | null) => {
      setCartIDInternally(cartID);
      return setCartID(cartID);
    },
    []
  );

  const createCartFromPreviousCart = useCallback(
    async (cartID: string) => {
      const newCartID = await createCartFromGuestCart_(cartID);
      return setCartIDAndPersist(newCartID);
    },
    [createCartFromGuestCart_, setCartIDAndPersist]
  );

  const removeCart = useCallback(async () => {
    setCartIDAndPersist(null);
  }, [setCartIDAndPersist]);

  const reorder = useCallback(
    async (orderId: string) => {
      const newCartID = await reorder_(orderId);
      return setCartIDAndPersist(newCartID);
    },
    [reorder_, setCartIDAndPersist]
  );

  const callCartAPIWithCartID = React.useCallback(
    <T, F extends GraphQLFn<T>>(
      fn: F,
      cartID: string,
      isGraceful: boolean,
      ...args: GraphQLFnParams<F>
    ): ReturnType<F> => {
      return fn(client, locale, cartID, ...args).catch(async e => {
        console.log(e);
        if (!isGraceful) {
          throw e;
        }
        let updatedCartID = cartID;
        const msg = parseGraphQLError(e) || e.message;
        if (
          msg &&
          msg.startsWith("Current user does not have an active cart")
        ) {
          await setCartIDAndPersist(null);
          updatedCartID = await createEmptyCart_();
        } else if (
          msg &&
          msg.startsWith("Wrong store code specified for cart")
        ) {
          await setStoreOnCart_(cartID);
        } else if (
          msg &&
          msg.startsWith("The current user cannot perform operations on cart")
        ) {
          // In case of change account
          await setCartIDAndPersist(null);
          updatedCartID = await createEmptyCart_();
        } else if (msg && msg.startsWith("Could not find a cart with ID")) {
          // In case of changing env
          await setCartIDAndPersist(null);
          updatedCartID = await createEmptyCart_();
        } else {
          // Unhandled
          throw e;
        }

        return callCartAPIWithCartID(fn, updatedCartID, isGraceful, ...args);
      }) as any;
    },
    [client, locale, setCartIDAndPersist, createEmptyCart_, setStoreOnCart_]
  );

  const callCartAPI = React.useCallback(
    <T, F extends CartAPIFn<T>>(
      fn: F,
      ...args: CartAPIFnParams<F>
    ): ReturnType<F> => {
      if (cartIDRef.current == null) {
        return Promise.reject(new Error("no cart ID")) as any;
      }
      //@ts-ignore
      return callCartAPIWithCartID(fn, cartIDRef.current, false, ...args);
    },
    [callCartAPIWithCartID, cartIDRef]
  );

  const callCartAPIGracefully = React.useCallback(
    <T, F extends CartAPIFn<T>>(
      fn: F,
      ...args: CartAPIFnParams<F>
    ): ReturnType<F> => {
      if (cartIDRef.current == null) {
        let newCartID: string;
        return createEmptyCart_()
          .then(newCartID_ => {
            newCartID = newCartID_;
            return setCartIDAndPersist(newCartID);
          })
          .then(() => {

            //@ts-ignore
            return callCartAPIWithCartID(fn, newCartID, true, ...args);
          }) as any;
      }
      
      //@ts-ignore
      return callCartAPIWithCartID(fn, cartIDRef.current, true, ...args);
    },
    [createEmptyCart_, setCartIDAndPersist, callCartAPIWithCartID, cartIDRef]
  );

  const value = React.useMemo(
    () => ({
      cartID,
      createCartFromPreviousCart,
      removeCart,
      callCartAPI,
      callCartAPIGracefully,
      reorder,
    }),
    [
      cartID,
      createCartFromPreviousCart,
      removeCart,
      callCartAPI,
      callCartAPIGracefully,
      reorder,
    ]
  );

  return (
    <CartIDContext.Provider value={value}>
      {props.children}
    </CartIDContext.Provider>
  );
};

async function createEmptyCart(
  ifMagentoVersion: IfMagentoVersionFn,
  client: ApolloClient<any>,
  locale: Locale
): Promise<string> {
  const context = {
    headers: {
      Store: getStoreViewCodeForLocale(locale),
    },
  };

  try {
    if (await ifMagentoVersion(">=", "2.3.4")) {
      if (TokenStore.accessToken) {
        const result = await (async () => {
          return client.query<{ customerCart: { id: string } }>({
            context,
            query: gql`
              query {
                customerCart {
                  id
                }
              }
            `,
            fetchPolicy: "no-cache",
          });
        })();
        const cartId = result.data.customerCart.id;
        return await setStoreOnCart(client, locale, cartId);
      }
    }
    throw new Error("Should create empty cart");
  } catch {
    const result = await (async () => {
      // Create a cart with a randomly-generated cart ID
      return client.mutate<{ createEmptyCart: string }>({
        context,
        mutation: gql`
          mutation {
            createEmptyCart
          }
        `,
        fetchPolicy: "no-cache",
      });
    })();
    return result.data.createEmptyCart;
  }
}

async function setStoreOnCart(
  client: ApolloClient<any>,
  locale: Locale,
  cartID: string
): Promise<string> {
  await client.mutate({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation SetStoreOnCart($cartID: String!) {
        setStoreOnCart(input: { cart_id: $cartID }) {
          cart {
            store_view_code
          }
        }
      }
    `,
    variables: {
      cartID,
    },
    fetchPolicy: "no-cache",
  });
  return cartID;
}

async function mergeGuestCart(
  useMergeCarts: boolean,
  client: ApolloClient<any>,
  locale: Locale,
  guestCartID: string,
  customerCartID: string
) {
  const mutation = useMergeCarts
    ? gql`
        mutation MergeCarts($guestCartID: String!, $customerCartID: String!) {
          mergeCarts(
            source_cart_id: $guestCartID
            destination_cart_id: $customerCartID
          ) {
            store_view_code
          }
        }
      `
    : gql`
        mutation MergeGuestCart(
          $guestCartID: String!
          $customerCartID: String!
        ) {
          mergeGuestCart(
            input: {
              guest_cart_id: $guestCartID
              customer_cart_id: $customerCartID
            }
          ) {
            cart {
              store_view_code
            }
          }
        }
      `;
  await client.mutate({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation,
    variables: {
      guestCartID,
      customerCartID,
    },
    fetchPolicy: "no-cache",
  });
  return customerCartID;
}

async function createCartFromGuestCart(
  ifMagentoVersion: IfMagentoVersionFn,
  client: ApolloClient<any>,
  locale: Locale,
  guestCartID: string | null
) {
  let newCartID = await createEmptyCart(ifMagentoVersion, client, locale);
  if (guestCartID != null) {
    try {
      newCartID = await mergeGuestCart(
        await ifMagentoVersion(">=", "2.3.4"),
        client,
        locale,
        guestCartID,
        newCartID
      );
    } catch (e) {
      console.log("failed to merge cart", e);
    }
  }

  return newCartID;
}

export async function reorderFromOrder(
  client: ApolloClient<any>,
  locale: Locale,
  orderId: string
): Promise<string> {
  const result = await client.mutate<{
    reorder: { cartId: string };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation Reorder($orderId: String!) {
        reorder(increment_id: $orderId) {
          cartId: cart_id
        }
      }
    `,
    variables: {
      orderId,
    },
  });
  return result.data.reorder.cartId;
}
