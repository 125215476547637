import React, { useCallback } from "react";
// eslint-disable-next-line no-restricted-imports

import { LocalizedText } from "../../i18n/Localization";
import { PrimaryButton } from "../Button";
import { actionEvent } from "../../utils/GTM";

import styles from "./styles.module.scss";
import CSRModal from "../CSRModal";

interface SignupSuccessModalProps {
  isModalOpen: boolean;
  onRequestDismiss: () => void;
}

const SignupSuccessModal: React.FC<SignupSuccessModalProps> = (
  props: SignupSuccessModalProps
) => {
  const { isModalOpen, onRequestDismiss } = props;

  const handleDismiss = useCallback(() => {
    actionEvent("Verify Email", "Click", "Confirm");
    onRequestDismiss();
  }, [onRequestDismiss]);

  return (
    <CSRModal
      className={styles.modal}
      isOpen={isModalOpen}
      hideHeader={true}
      onRequestDismiss={onRequestDismiss}
    >
      <div className={styles.modalBody}>
        <div className={styles.title}>
          <LocalizedText messageID="registration_successful" />
        </div>
        <div className={styles.text}>
          <LocalizedText messageID="signup_success.text" />
        </div>
        <PrimaryButton
          onClick={handleDismiss}
          className={styles.shoppingButton}
        >
          <LocalizedText messageID="alert.button.ok" />
        </PrimaryButton>
      </div>
    </CSRModal>
  );
};

export default SignupSuccessModal;
