import { useApolloClient } from "@apollo/react-hooks";
import { useFetchResources_v2 } from "./Hooks";

import { fetchMagentoVersion } from "../api/GraphQL";
import { MagentoVersion } from "../models/MagentoVersion";
import { ResourcesRequestState } from "../models/ResourcesRequestState";

export function useFetchMagentoVersion(): [
  ResourcesRequestState<MagentoVersion | undefined>,
  () => Promise<MagentoVersion | undefined>
] {
  const client = useApolloClient();

  const [requestState, { call }] = useFetchResources_v2<
    MagentoVersion | undefined,
    () => Promise<MagentoVersion | undefined>
  >({
    localCacheProvider: async () => {
      return fetchMagentoVersion(client, "cache-only");
    },
    remoteResourcesProvider: async () => {
      return fetchMagentoVersion(client, "network-only");
    },
  });

  return [requestState, call];
}
