export interface BillingOptions {
  once_day: String;
  once_month: String;
  once_week: String;
  once_year: String;
}

export interface CustomOptionLabels {
  am_billing_cycle: String;
  am_discount_amount: String;
  am_enable_free_trials: String;
  am_initial_fee_amount: String;
  am_limit_discount_cycle: String;
  am_number_trial_days: String;
  end_date: String;
  free_shipping: String;
  start_date: String;
}

export default interface RecurringConfiguration {
  base_discount: string;
  //Base discount

  base_discount_amount: string;
  //Base discount amount

  billing_cycle: String;
  //Billing cycle,Every %1 %2s/once_day/once_week/once_month/once_year/customer decide

  billing_options: BillingOptions;
  //Billing options, Use if billing_cycle is customer decide

  custom_option_labels: CustomOptionLabels;
  //Custom option labels

  discount_cycle: number;
  //Discount cycle

  discount_type: string;
  //Discount type

  end_date: string;
  // End date

  fee_type: string;
  //Fee type

  initial_fee: string;
  //Initial fee

  initial_fee_amount: string;
  //Initial fee amount

  is_enable_discount: boolean;
  //Is enable discount

  is_enable_discount_cycle: Boolean;
  //Is enable discount cycle

  is_enable_fee: Boolean;
  //Is enable fee

  is_enable_trial: Boolean;
  //Is enable trial

  is_free_shipping: Boolean;
  //Is free shipping

  is_recurring_enable: Boolean;
  //Is recurring enable, determine subscription charging

  is_subscription_only: Boolean;
  //Is subscription only

  number_trial_days: number;
  // Number trial days

  start_date: String;
  // subscription start date
}

export const RecurringConfigurationGraphQLAttributes = `
  isRecurringEnable: is_recurring_enable
  isSubscriptionOnly: is_subscription_only
  startDate: start_date
  endDate: end_date
  billingCycle: billing_cycle
  isEnableTrial: is_enable_trial
  isFreeShipping: is_free_shipping
  billingOptions: billing_options {
    once_day
    once_month
    once_week
    once_year
  }
`