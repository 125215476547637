import React, { createContext, useContext, useEffect, useCallback } from "react";
import { useRouteMatch, useLocation } from "react-router";
import { OurNavContext } from "../../our-navigation/OurNavContext";
import { LoadingModalContext } from "../LoadingModalProvider";
import { useActivateCustomer } from "../../repository/AuthRepository";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import qs from "qs";
import CSRModal from "../CSRModal";
import { IonButton } from "@ionic/react";
import { useModalState } from "../../hook/modal";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import styles from "./styles.module.scss";
import { PrimaryButton } from "../Button";

type InitialVerifyEmailContext =  {
  id: string;
  key: string;
  back_uri: string;
}

const VerifyEmailContext = createContext(null);

const VerifyEmailProvider : React.FC = props => {
  const { replace } = useContext(OurNavContext);
  const { show: showLoadingView, hide: hideLoadingView } = useContext(LoadingModalContext);
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);

  let match = useRouteMatch({
    path: "/customer/account/confirm"
  });

  let { search } = useLocation();
  
  const { translate } = useIntl();
  const activateCustomer = useActivateCustomer();
  const [ isEmailVerifiedModal, presentEmailVerifiedModal, hideEmailVerifiedModal ] = useModalState();

  const handleActivateEmail = useCallback(async (customerId, confirmationKey) => {
    if (customerId == null || confirmationKey == null) {
      return;
    }

    try {
      const customer = await activateCustomer(
        customerId,
        confirmationKey
      );
      if (!customer) {   
        throw new Error(translate("verify_the_club.verified_failed"));
      }
      presentEmailVerifiedModal();
    } catch (e) {
      presentLocalizedAlert({
        headerId: "alert.error.title",
        messageId: "alert.deeplink.email_verification.fail",
        buttons: [
          {
            textMessageID: "alert.button.ok",
          },
        ],
      });
    } finally {
      hideLoadingView();
    }
  }, []);

  useEffect(() => {
    if (match) {
      showLoadingView();
      const { 
        id: customerId, 
        key: confirmationKey,
        back_uri,
       } = qs.parse(search, { ignoreQueryPrefix: true }) as InitialVerifyEmailContext;
      if (customerId && confirmationKey) {
        handleActivateEmail(customerId, confirmationKey);
      }

      if (back_uri) {
        replace(back_uri);
      } else {
        replace("/town");
      }
    }
  }, [match, search]);
  
  return (
    <>
      <VerifyEmailContext.Provider value={null}> 
        {props.children}
      </VerifyEmailContext.Provider>
      { isEmailVerifiedModal ? <CSRModal 
        className={styles.modal}
        title={translate("verify_the_club.verified_succeed.title")}
        isOpen={isEmailVerifiedModal} 
        onRequestDismiss={hideEmailVerifiedModal}
        >
        <p className={styles.title}><LocalizedText messageID="verify_the_club.verified_succeed" /></p>
        <PrimaryButton className={styles.button} onClick={hideEmailVerifiedModal}>
          <LocalizedText messageID="alert.button.ok" />
        </PrimaryButton>
      </CSRModal>
      : null }
    </>
  )
}

export default VerifyEmailProvider;