import { useMemo, useEffect, RefObject } from "react";

export default function useExtractScriptsAndStyles(
  containerRef: RefObject<HTMLElement|HTMLIFrameElement>,
  htmlContent: string
) {
  const { scriptStrings, styleStrings, html } = useMemo<{
    scriptStrings: string[];
    styleStrings: string[];
    html: string;
  }>(() => {
    const html = document.createElement("div");
    html.innerHTML = htmlContent;

    const styles = html.getElementsByTagName("style");
    const styleStrings: any[] = [];
    for (let i = styles.length - 1; i >= 0; i--) {
      styleStrings.push(styles[i].innerText);
      const { parentNode } = styles[i];
      if (parentNode) {
        parentNode.removeChild(styles[i]);
      }
    }
    styleStrings.reverse();

    const scripts = html.getElementsByTagName("script");
    const scriptStrings: string[] = [];
    for (let i = scripts.length - 1; i >= 0; i--) {
      scriptStrings.push(scripts[i].innerText);
      const { parentNode } = scripts[i];
      if (parentNode) {
        parentNode.removeChild(scripts[i]);
      }
    }
    scriptStrings.reverse();

    return { scriptStrings, styleStrings, html: html.innerHTML };
  }, [htmlContent]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const styles = styleStrings.map(s => {
      const d = document.createElement("style");
      d.textContent = s;
      return d;
    });

    for (let i = 0; i < styles.length; i++) {
      styles[i].dataset.ref = "cl-html";
      container.appendChild(styles[i]);
    }

    return () => {
      for (let i = 0; i < styles.length; i++) {
        const { parentNode } = styles[i];
        if (parentNode) {
          parentNode.removeChild(styles[i]);
        }
      }
    };
  }, [containerRef, styleStrings]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const scripts = scriptStrings.map(s => {
      const d = document.createElement("script");
      d.textContent = `try {
${s}
} catch (e) {
  console.error('[CL] Error in HTML Content', e);
  window.Sentry.captureException(e);
}`;
      return d;
    });

    for (let i = 0; i < scripts.length; i++) {
      scripts[i].dataset.ref = "cl-html";
      container.appendChild(scripts[i]);
    }

    return () => {
      for (let i = 0; i < scripts.length; i++) {
        const { parentNode } = scripts[i];
        if (parentNode) {
          parentNode.removeChild(scripts[i]);
        }
      }
    };
  }, [containerRef, scriptStrings]);

  return html;
}
